import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AcNotification } from 'angular-cesium';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GroupBy, LastDataModel, Medicion } from '../../../components/device-datos/device-datos.component';
import { GetDatePipe } from '../../../pipes/pipes/pipe-date.pipe';
import { GeoDevicesService, LastDataDevice, TipoDato } from '../../../services/mapa/geodevices.service';
import { MapaServiceService } from '../mapa-service.service';
import { MapItem } from './map_item.interface';




@Component({
  selector: 'app-map-element-item',
  templateUrl: './map-element-item.component.html',
  styleUrls: ['./map-element-item.component.scss']
})
export class MapElementItemComponent {
  @Input('item') set item(i: {
    entityMap: AcNotification,
    lastDataDevice: LastDataDevice, selected: boolean
  }) {
    this.item$.next({ ...i, details: this.generateRawDetails(i), haveSomeSeletedItems: this.haveSomeSeletedItem(i) })
  };
  item$: BehaviorSubject<any> = new BehaviorSubject(null)
  constructor(private readonly pipeDate: GetDatePipe, private mapService: MapaServiceService) { }


  private generateRawDetails(item: {
    entityMap: AcNotification,
    lastDataDevice: LastDataDevice, selected: boolean,
  }) {

    return item.lastDataDevice ? item.lastDataDevice.tiene.map(detail => ({
      code: detail.to.es.codigo,
      title: detail.to.es.nombre,
      value: detail.to.lastValor ? `${detail.to.lastValor} ${detail.to.es.unidad}` : null, //Remove null values
      lastDate: this.pipeDate.getDatePipe(Number(detail.to.lastFecha))
    })) : []
  }

  isSelected(name): boolean {
    const filterSelected = this.mapService.tipeDataSelected$.value;
    return filterSelected.some(filter => {
      return filter.codigo === name.code
    }
    );
  }

  get filterSelectedLength(): number {
    return this.mapService.tipeDataSelected$.value.length;
  }

  haveSomeSeletedItem(item): boolean {
    if (!item || !item.lastDataDevice) {
      return false
    }

    const filterSelected = this.mapService.tipeDataSelected$.value;
    return item.lastDataDevice.tiene.some(ld => filterSelected.some(f => f.codigo === ld.to.es.codigo))
  }


}
