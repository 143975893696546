interface Array<T> {
    firstOrDefault(this: T[], predicate?: (item: T) => boolean): T;
    where(this: T[], predicate: (item: T) => boolean): T[];
    add(this: T[], item: T): void;
    addRange(this: T[], items: T[]): void;
    addIfNotExist(this: T[], item: T, propertyExpression?: (key: T) => any): void;
    addRangeIfNotExist(this: T[], items: T[], propertyExpression?: (key: T) => any): void;
    removeBy(this: T[], predicate: (item: T) => boolean): void;
    remove(this: T[], item: T): boolean;
    removeRange(this: T[], items: T[]): void;
    sumBy(this: T[], selector: (x: T) => number): number;
    orderBy(this: T[], propertyExpression: (item: T) => any): T[];
    orderByDescending(this: T[], propertyExpression: (item: T) => any): T[];
    orderByMany(this: T[], propertyExpressions: [(item: T) => any]): T[];
    orderByManyDescending(this: T[], propertyExpressions: [(item: T) => any]): T[];
    any(this: T[], predicate: (item: T) => boolean): boolean;
    intersect(this: T[], collection: T[], propertyExpression?: (item: T) => any): T[];
    difference(this: T[], collection: T[], propertyExpression?: (item: T) => any): T[];
    merge(this: T[], collection: T[]): T[];
    isEmpty(this: T[]): boolean;
}


function firstOrDefault<T>(this: T[], predicate?: (item: T) => boolean): T {
    if (predicate === undefined || predicate === null) {
        if (this.length === 0) {
            return null;
        }
        return this[0];
    }
    for (const item of this) {
        if (predicate(item)) {
            return item;
        }
    }
    return null;
}
function where<T>(this: T[], predicate: (item: T) => boolean): T[] {
    const result: Array<T> = [];
    for (const item of this) {
        if (predicate(item)) {
            result.push(item);
        }
    }
    return result;
}

function add<T>(this: T[], item: T): void {
    if (item === undefined || item === null) {
        return;
    }
    this.push(item);
}
function addRange<T>(this: T[], items: T[]): void {
    if (items === undefined || items === null) {
        return;
    }
    items.forEach(item => this.push(item));
}
function addIfNotExist<T>(this: T[], item: T, propertyExpression?: (key: T) => any): void {
    if (item === undefined || item === null) {
        return;
    }
    if ((propertyExpression === undefined || propertyExpression === null) && this.includes(item)) {
        this.push(item);
        return;
    }
    if (!this.any(thisItem => propertyExpression(thisItem) === propertyExpression(item))) {
        this.push(item);
        return;
    }

}
function addRangeIfNotExist<T>(this: T[], items: T[], propertyExpression?: (key: T) => any): void {
    if (items === undefined || items === null) {
        return;
    }
    items.forEach(item => this.addIfNotExist(item, propertyExpression));
}
function removeBy<T>(this: T[], predicate: (item: T) => boolean): void {

    const itemToRemoveCollection: Array<T> = this.where(predicate);
    this.removeRange(itemToRemoveCollection);
}
function remove<T>(this: T[], itemToRemove: T): boolean {
    const index = this.indexOf(itemToRemove);
    if (index >= 0) {
        this.splice(index, 1);
        return true;
    }
    return false;
}
function removeRange<T>(this: T[], itemToRemoveCollection: T[]) {
    if (itemToRemoveCollection === undefined || itemToRemoveCollection === null) {
        return;
    }

    itemToRemoveCollection.forEach(itemToRemove => this.remove(itemToRemove));
}
function sumBy<T>(this: T[], selector: (x: T) => number): number {
    return this.reduce((pre, cur) => pre + selector(cur), 0);
}
function orderBy<T>(this: T[], propertyExpression: (item: T) => any): T[] {

    const compareFunction = (item1: any, item2: any): number => {
        if (propertyExpression(item1) > propertyExpression(item2)) { return 1; }
        if (propertyExpression(item2) > propertyExpression(item1)) { return -1; }
        return 0;
    };
    return this.sort(compareFunction);

}
function orderByDescending<T>(this: T[], propertyExpression: (item: T) => any): T[] {
    const compareFunction = (item1: any, item2: any): number => {
        if (propertyExpression(item1) > propertyExpression(item2)) { return -1; }
        if (propertyExpression(item2) > propertyExpression(item1)) { return 1; }
        return 0;
    };
    return this.sort(compareFunction);
}
function orderByMany<T>(this: T[], propertyExpressions: [(item: T) => any]): T[] {
    const compareFunction = (item1: any, item2: any): number => {
        for (const propertyExpression of propertyExpressions) {
            if (propertyExpression(item1) > propertyExpression(item2)) { return 1; }
            if (propertyExpression(item2) > propertyExpression(item1)) { return -1; }
        }
        return 0;
    };
    return this.sort(compareFunction);
}
function orderByManyDescending<T>(this: T[], propertyExpressions: [(item: T) => any]): T[] {
    const compareFunction = (item1: any, item2: any): number => {
        for (const propertyExpression of propertyExpressions) {
            if (propertyExpression(item1) > propertyExpression(item2)) { return -1; }
            if (propertyExpression(item2) > propertyExpression(item1)) { return 1; }
        }
        return 0;
    };
    return this.sort(compareFunction);
}
function any<T>(this: T[], predicate: (item: T) => boolean): boolean {

    const item: T = this.firstOrDefault(predicate);
    return item !== null;
}

function intersect<T>(this: T[], collection: T[], propertyExpression?: (item: T) => any): T[] {

    if (this.isEmpty() || collection.isEmpty()) {
        return [];
    }
    
    if (propertyExpression === undefined || propertyExpression === null) {
        return this.where(thisItem => collection.includes(thisItem));
    }
    return this.where(thisItem => collection.any(collectionItem => propertyExpression(thisItem) === propertyExpression(collectionItem)));

}
function difference<T>(this: T[], collection: T[], propertyExpression?: (item: T) => any): T[] {

    if (this.isEmpty() && collection.isEmpty()) {
        return [];
    }
    if (this.isEmpty()) {
        return Array.from(collection);
    }
    if (collection.isEmpty()) {
        return  Array.from(this);
    }
    if (propertyExpression === undefined || propertyExpression === null) {
        return this.where(thisItem => !collection.includes(thisItem));
    }
    return this.where(thisItem => collection.any(collectionItem => propertyExpression(thisItem) !== propertyExpression(collectionItem)));
}


function merge<T>(this: T[], collection: T[]): T[] {
    let arrayMerged = [];
    if (this.isEmpty() && collection.isEmpty()) {
        return arrayMerged;
    }
    if (this.isEmpty()) {
        arrayMerged.addRange(collection);
        return arrayMerged;
    }
    if (collection.isEmpty()) {
        arrayMerged.addRange(this);
        return arrayMerged;
    }
    arrayMerged = Array.from(new Set(arrayMerged.concat(this, collection)));
    return arrayMerged;
}
function isEmpty<T>(this: T[]): boolean {
    return this === undefined || this === null || this.length === 0
}
Array.prototype.firstOrDefault = firstOrDefault;
Array.prototype.where = where;
Array.prototype.add = add;
Array.prototype.addRange = addRange;
Array.prototype.addIfNotExist = addIfNotExist;
Array.prototype.addRangeIfNotExist = addRangeIfNotExist;
Array.prototype.removeBy = removeBy;
Array.prototype.remove = remove;
Array.prototype.removeRange = removeRange;
Array.prototype.sumBy = sumBy;
Array.prototype.orderBy = orderBy;
Array.prototype.orderByDescending = orderByDescending;
Array.prototype.orderByMany = orderByMany;
Array.prototype.orderByManyDescending = orderByManyDescending;
Array.prototype.any = any;
Array.prototype.intersect = intersect;
Array.prototype.difference = difference;
Array.prototype.merge = merge;
Array.prototype.isEmpty = isEmpty;