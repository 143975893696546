import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatatypeTreeHierarchi, DataTypeItem } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DeviceGraphItem } from 'src/app/models/device-graph.model';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { State, ListItemHierachi } from 'src/app/models/contract/list-item.model';
import * as lodash from 'lodash';
import { AlarmFormComponent } from 'src/app/sections/alarms/alarm-form/alarm-form.component';

@Component({
  selector: 'app-alarm-data-type-navigate',
  templateUrl: './alarm-data-type-navigate.component.html',
  styleUrls: ['./alarm-data-type-navigate.component.scss']
})
export class AlarmDataTypeNavigateComponent implements OnInit {
  @Input() dataTypeChildrenList: DatatypeTreeHierarchi[];
  @Input() loadingChildren: boolean;
  @Input() deviceDatatype: DeviceGraphItem[] = [];
  @Input() dataTypeSelectedCollection: DataTypeItem[] = [];
  @Output() datatypeItemSelected: EventEmitter<DataTypeItem> = new EventEmitter<DataTypeItem>();
  @Output() datatypeItemUnSelected: EventEmitter<DataTypeItem> = new EventEmitter<DataTypeItem>();
  @Output() datatypeItemNavegate: EventEmitter<DeviceGraphItem> = new EventEmitter<DeviceGraphItem>();
  parent: DeviceGraphItem;
  historicParent = [];
  dataTypeSettings: DeviceListSettings =
    {
      titleType: 'factory',
      actionIcon: 'add-circle',
      selectable: State.selectable
    };

  public intersection: ListItemHierachi[];

  public itemObservable: ListItemHierachi[];
  constructor(private alarmForm: AlarmFormComponent) {
  }

  ngOnInit() {
    if (this.dataTypeChildrenList !== undefined) {
      this.itemObservable = lodash.sortBy(this.dataTypeChildrenList.map(item => item.root), ['name'], ['asc']);
    }
  }

  ngOnChanges(): void {
    this.alarmForm.devicesSelectedSubject.subscribe((data: DeviceGraphItem[]) => {
      this.deviceDatatype = [];
      this.deviceDatatype = data;
      this.intersection = lodash.cloneDeep(this.dataTypeChildrenList.map(item => item.root));
      for (const dispositivoSeleccionado of this.deviceDatatype) {
        this.intersection = this.intersect(this.intersection, dispositivoSeleccionado.dataType.map(item => item.uuid));
      }
      this.itemObservable = lodash.sortBy(this.intersection, ['name'], ['asc']);
    });
  }

  public selectedItem(selected: DataTypeItem) {
    this.datatypeItemSelected.emit(selected);
  }

  public unSelectedItem(item: DataTypeItem) {
    this.datatypeItemUnSelected.emit(item);
  }

  public navigate(item: any) {
    this.itemObservable = lodash.sortBy(item.children, ['name'], ['asc']);
    this.goBack(item, this.itemObservable);
  }

  goBackNavigation(event: ListItemHierachi) {
    if (lodash.isEmpty(event.parent)) {
      this.itemObservable = lodash.sortBy(this.intersection, ['name'], ['asc']);
      this.parent = null;
    } else {
      this.itemObservable = lodash.sortBy(event.parent.children, ['name'], ['asc']);
      this.parent = event.parent;
    }
  }

  goBack(item: any, collection) {
    this.historicParent.add(collection);
    this.parent = item;
  }

  intersect(arr: ListItemHierachi[], dataTypeUUIDSelectedDevice: string[]): ListItemHierachi[] {
    const array = [...arr];
    for (const datatype of array) {
      if (!this.searchTree(datatype, dataTypeUUIDSelectedDevice)) {
        arr.removeBy(item => item.uuid === datatype.uuid);
      }

    }
    return arr;
  }

  searchTree(element: ListItemHierachi, matchingUuid: string[]): boolean {
    if (element.isLeaf()) {
      return matchingUuid.includes(element.uuid);
    }
    const children = [...element.children];
    for (const child of children) {
      if (!child.hasAnyDescendentById(matchingUuid)) {
        element.children.removeBy(item => item.uuid === child.uuid);
      } else {
        if (!this.searchTree(child, matchingUuid)) {
          element.children.removeBy(item => item.uuid === child.uuid);
        }
      }
    }
    return !element.isLeaf();
  }

}
