import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FactoriesService } from 'src/app/services/factories/factories.service';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { FactoryCollection } from 'src/app/models/factory/factory.collection.model';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import { NgxChartType } from 'src/app/models/ngx-chart.model';
import { LoginService } from 'src/app/services/login/login.service';
import { User } from 'src/app/models/user.model';
import { State, ListItem } from 'src/app/models/contract/list-item.model';
import * as lodash from 'lodash';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { Subject } from 'rxjs';
import { MatBottomSheetRef } from '@angular/material';
import { BottomSheetDeviceComponent } from '../bottom-sheet-device/bottom-sheet-device.component';
import { DevicesRepresentedService } from 'src/app/services/addDevices/devices-represented.service';

@Component({
  selector: 'app-graphic-add-devices',
  templateUrl: './graphic-add-devices.component.html',
  styleUrls: ['./graphic-add-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FactoriesService]
})

export class GraphicAddDevicesComponent implements OnInit {

  userAcepted = false;
  @ViewChild('autocomplete', { static: false }) autocomplete;
  // Formcontrols
  factoria = new FormControl();
  selected = new FormControl(0);
  selectedFactory = new FormControl(0);
  factoriesSelected: FactoryCollection;
  selectedDeviceList: DeviceGraphItem[] = [];
  chartType: NgxChartType;
  tipoDatos: DeviceGraphDataType[] = [];
  factoryCollection: FactoryCollection;
  childrens: Array<DeviceGraphItem[]> = [];
  deviceSettings: DeviceListSettings = {
    titleType: 'factory',
    actionIcon: 'add-circle',
    selectable: State.selected
  };
  allDeviceCollection: DeviceGraphItem[] = [];
  devicesSelectedSubject = new Subject();
  public title: Array<string> = [];
  public loading: Array<boolean> = [];
  public parents: Array<Array<ListItem>> = [[]];
  public isRoot: Array<boolean> = [];

  public devicesRepresented: Array<DeviceGraphItem>;
  public datatypeRepresented: DeviceGraphDataType[];
  private readonly differencePropertyFilter: string = 'uuid';

  constructor(
    private factoriesService: FactoriesService,
    private loginService: LoginService,
    private cdRef: ChangeDetectorRef,
    private dispositivoService: DispositivoService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetDeviceComponent>,
    private representedDataService: DevicesRepresentedService
  ) { }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.representedDataService.datatypeRepresentedObservable.subscribe(
      datatype => this.datatypeRepresented = this.representedDataService.toDeviceGraphDataType(datatype));
    this.representedDataService.devicesRepresentedObservable.subscribe(devices => this.devicesRepresented = devices);

    this.initSelect();

    this.factoriesService.factorySelectedObservable.subscribe(
      factorySelectedCollection => this.factoriesSelected = factorySelectedCollection);
  }
  initSelect() {
    const usuario: User = this.loginService.currentUserValue;
    this.factoriesService.getFactories(usuario.user).pipe(
    ).subscribe((data: FactoryCollection) => {
      this.factoryCollection = data;

    });
  }

  showAutocomplete() {
    this.autocomplete.open();
  }

  selectFactoryEvent(selectedFactoryCollection) {
    if (selectedFactoryCollection === null || selectedFactoryCollection === undefined) {
      return;
    }
    this.autocomplete.close();

    const resultCompare: number = selectedFactoryCollection.length - this.factoriesSelected.collection.length;

    if (resultCompare === 1) {
      const factoryDiff = lodash.difference(selectedFactoryCollection, this.factoriesSelected.collection);

      for (let i = 0; i < factoryDiff.length; i++) {
        this.factoriesSelected.collection.add(factoryDiff[i]);
        this.getChildrenFactory(factoryDiff[i].uuid, this.factoriesSelected.collection.length - 1);
        this.getTitleForCard(factoryDiff[i].name, this.factoriesSelected.collection.length - 1);
        this.addParent(factoryDiff[i].uuid, factoryDiff[i].name, this.factoriesSelected.collection.length - 1);
      }

    } else if (resultCompare === -1) {
      const factoryDiff = lodash.difference(this.factoriesSelected.collection, selectedFactoryCollection);
      this.removeDataOfFactoryUnselected(factoryDiff);
      this.factoriesSelected.collection.remove(factoryDiff[0]);
      this.factoriesSelected = this.factoriesSelected;
    }
  }

  private removeDataOfFactoryUnselected(factoryDiff) {
    const index = lodash.findIndex(this.factoriesSelected.collection, { uuid: factoryDiff[0].uuid });
    this.title.remove(this.title[index]);
    this.childrens.remove(this.childrens[index]);
    this.loading.remove(this.loading[index]);
    this.isRoot.remove(this.isRoot[index]);
    this.parents.remove(this.parents[index]);
  }

  addParent(uuid, name, i) {
    if (this.parents[i] === undefined) {
      this.parents[i] = [];
    }
    const item: ListItem = { uuid, name };
    this.parents[i].add(item);
  }

  getTitleForCard(name, i) {
    this.title[i] = '';
    this.title[i] = name;
  }

  getChildrenFactory(uuid, i) {
    this.childrens[i] = [];
    this.loading[i] = true;
    this.dispositivoService.getDeviceChildrenAndDataTypesGraphQl(uuid)
      .subscribe(
        next => {
          this.childrens[i] = lodash.sortBy(this.checkDeviceSelectedCollection(this.getDeviceCompatibleCollection(next)), ['name'], ['asc']);
          this.loading[i] = false;
          this.cdRef.detectChanges();
        }
      );
  }

  private checkDeviceSelectedCollection(deviceSelected: DeviceGraphItem[]): DeviceGraphItem[] {
    const aux: DeviceGraphItem[] = lodash.intersectionBy(deviceSelected, this.selectedDeviceList, 'uuid');
    for (let i = 0; i < aux.length; i++) {
      aux[i].selectedState = State.selected;
    }
    return deviceSelected;
  }

  private getDeviceCompatibleCollection(deviceCollection: DeviceGraphItem[]): DeviceGraphItem[] {
    this.disableAllDevices(deviceCollection);
    this.disableSelectedDevices(deviceCollection);
    return deviceCollection;
  }

  private disableAllDevices(deviceCollection: DeviceGraphItem[]) {
    for (let i = 0; i < deviceCollection.length; i++) {
      deviceCollection[i].selectedState = State.notSelectable;
      this.checkCompatibleDatatype(deviceCollection, i);
    }
  }

  private checkCompatibleDatatype(deviceCollection: DeviceGraphItem[], i: number) {
    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < deviceCollection[i].dataType.length; j++) {
      for (const dataType of this.datatypeRepresented) {
        if (deviceCollection[i].dataType[j].uuid === dataType.uuid) {
          deviceCollection[i].selectedState = State.selectable;
        }
      }
    }
  }

  private disableSelectedDevices(deviceCollection: DeviceGraphItem[]) {
    const intersection: DeviceGraphItem[] = lodash.intersectionBy(deviceCollection, this.devicesRepresented, this.differencePropertyFilter);

    for (let i = 0; i < intersection.length; i++) {
      intersection[i].selectedState = State.notSelectable;
    }
  }

  navigate(selected: DeviceGraphItem, i: number) {
    if (lodash.isEmpty(this.parents[i])) {
      this.isRoot[i] = true;
    } else {
      this.isRoot[i] = false;
    }

    this.getTitleForCard(selected.name, i);
    this.getChildrenFactory(selected.uuid, i);
    this.addParent(selected.uuid, selected.name, i);
  }

  goBack(i) {
    const uuidParent = this.parents[i][this.parents[i].length - 2].uuid;
    const nameParent = this.parents[i][this.parents[i].length - 2].name;

    this.parents[i].remove(this.parents[i][this.parents[i].length - 2]);
    this.parents[i].remove(this.parents[i][this.parents[i].length - 1]);

    if (lodash.isEmpty(this.parents[i])) {
      this.isRoot[i] = true;
    } else {
      this.isRoot[i] = false;
    }

    const aux: DeviceGraphItem = new DeviceGraphItem();
    aux.uuid = uuidParent;
    aux.name = nameParent;
    this.navigate(aux, i);
  }

  public selectedDeviceGraphItem(item: DeviceGraphItem) {
    this.selectedDeviceList.add(item);
    item.selectedState = State.selected;

    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  removeChipset(item: DeviceGraphItem) {
    item.selectedState = State.selectable;
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    for (let i = 0; i < this.childrens.length; i++) {
      for (let j = 0; j < this.childrens[i].length; j++) {
        if (this.childrens[i][j].uuid === item.uuid) {
          this.childrens[i][j].selectedState = State.selectable;
        }
      }
    }
  }

  public unSelectedDeviceGraphItem(item: DeviceGraphItem) {
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    item.selectedState = State.selectable;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  getAllDeviceCollection(items: DeviceGraphItem[]): DeviceGraphDataType[][] {
    this.allDeviceCollection = items;
    return this.allDeviceCollection.map(item => item.dataType);
  }

  addDevices() {
    this._bottomSheetRef.dismiss();
    this.userAcepted = true;
    return this.selectedDeviceList;
  }
}
