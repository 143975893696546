import { WidgetType } from '../widgets/widget-interfaces.model';
import { ChartOrientationType } from '../plotly/plotly-chart.model';
import { UsuarioModel } from 'src/app/sections/alarmas/models/alarma-models'; 

export class NewDashbordModel {
    uuid: string;
    name: string;
    widgets: NewWidgetsModel[];
    permisoDashboard: PermisoDashboard[];
    constructor(newUUID: string, newName: string, newWidgets: NewWidgetsModel[], permisoDashboard: PermisoDashboard[]) {
        this.uuid = newUUID;
        this.name = newName;
        this.widgets = newWidgets;
        this.permisoDashboard = permisoDashboard;
    }
}
export class PermisoDashboard {
    uuid?: string;
    from: UsuarioModel;
    to: any;
    owner?: boolean;
    deleted?: number;

}
export class NewWidgetsModel {
    uuid: string;
    name: string;
    widgetType: WidgetType;
    devices: DevicesModel[];
    dataType: DataTypeModel[];
    period?: any;
    min?: number;
    max?: number;
    limit?: number;
    x: number;
    y: number;
    cols: number;
    rows: number;
    relationUuid: string;
    orientation: ChartOrientationType;
}
export class DevicesModel {
    uuid: string;
    name: string;
}
export class DataTypeModel {
    uuid: string;
    name: string;
    unity: string;
    codigo: string;
}
