import { ChartElement, ChartElementColor, Chart } from './chart.model';
import { LynxColors } from './lynxColors.model';
import { DataTypeWidgetParam } from './widgets/widget-interfaces.model';

export class NgxChartElement implements ChartElement {
    uuid: any;
    data:any
    color?: NgxChartElementColor;
    label: string;
    borderWidth?: number;
    fill?:boolean;

}

export enum NgxChartType{
    LINE = 'line' ,
    BAR = 'bar' ,
    RADAR = 'radar',
    PIE =  'pie',
    POLAR_AREA = 'polarArea',
    DOUGHTNUT = 'doughnut',
    BUBBLE = 'bubble',
    SCATTER = 'scatter'
}

export class NgxLegend {
   
    index: number;
    name: string;
    color: string;
    show: boolean;
}
export class NgxChartElementColor implements ChartElementColor {
    private _hexColor:string;
    private _backgroundColor: string;
    private _borderColor: string;
    private _pointBackgroundColor: string;
    private _pointBorderColor: string;
    private _pointHoverBackgroundColor: string;
    private _pointHoverBorderColor: string;
   

    public constructor(newLynxColor?: LynxColors) {
        if (newLynxColor !== undefined && newLynxColor !== null) {
            this.addHexColor(newLynxColor.color);
        }
    }


    public addRgbColor(newRgbColor: string) {
        this.addHexColor(this.rgbToHexFormatColor(newRgbColor));
    }

    public addHexColor(newHexColor: string,opacity?:string) {
    
        this._hexColor = newHexColor;
        let rgb = this.hexToRgbFormatColor(newHexColor);
        let oColor = {
            backgroundColor: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ','+opacity+')',
            borderColor: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ','+opacity+')',
            pointBackgroundColor: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ','+opacity+')',
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ','+opacity+')',
        }
        return oColor;
    }


    public get backgroundColor(): string {
        return this._backgroundColor;
    }
    public get hexColor(): string {
        return this._hexColor;
    }


    //TODO - 
    private removeAlphaHexColor(hexColor: string): string {
        if (!this.hasHexAlphaDefined(hexColor)) {
            return hexColor;
        }
        return hexColor.substring(0, hexColor.length - 2);
    }
    //TODO - 
    private rgbToHexFormatColor(rgbColor: string) {
        return rgbColor;
    }
    private hexToRgbFormatColor(hexColor: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    //TODO - 
    private hasHexAlphaDefined(colorToCheck: string): boolean {
        return false;
    }
    //TODO - 
    private buildNgxChartElementColor(newRgbColor: string) {
        throw new Error("Method not implemented.");
    }

}

export class NgxChart implements Chart<NgxChartElement>{
    id?: number;
    dataSetArray: NgxChartElement[];
    dataLabelArray: string[];
    type?: NgxChartType;
    title?: string;
    dataType: DataTypeWidgetParam;

}