import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'getAlarmaCampoPipe',
    pure: true
})
export class GetAlarmaCampoPipe implements PipeTransform {
    transform(value: number, args?: any): any {
        return this.getAlarmaCampoPipe(value)
    }

    getAlarmaCampoPipe(date: number): number {
        if (date != null) {
            const now = Date.now() * 10000
            const diff = now - date
            // const formateado = moment.unix(date / 1000000000); // .format('DD-MM-YYYY HH:mm:ss');

            return diff;
        } else {
            return 99999999999999999;
        }
    }
    getInvalidatedDatePipe(invalidated: number): String {
        if (invalidated != null) {
            return new Date(invalidated / 1000000).toISOString();
        } else {
            return 'Sin invalidar'
        }

    }
}