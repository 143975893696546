import gql from 'graphql-tag';

////////////////////////////
// definir los interfaces //
////////////////////////////
// Interfaces
export interface ReportItf {
  uuid: string;
  titulo: string;
  codigo: string;
  logo: string;
  idioma: string;
  params: any[];
  rows: any [];
  tables: any [];
}

export interface DatoUuid {
  dato: {
    uuid: string;
  };
}
export interface Nume {
  dato: {
    numero: number;
  };
}

export interface Dato {
    uuid: string;
    periodo: string;
    tiene: Dispositivo;
    es: TipoDato;
}
export interface TipoDato {
    uuid: string;
    nombre: string;
    unidad: string;
  }
export interface Dispositivo {
    uuid: string;
    nombre: string;
    tiene: Dato [];
  }
export interface Datos {
    datos: Dato [];
}
export interface IQuery {
  datos: any;
}

///////////////
// Mutations //
///////////////


export const deleteReport = gql `
  mutation deleteReport($puuid: String)  {
     dato: deleteReport(puuid: $puuid)
}`;

export const createReport =  gql `
mutation createReport($titulo: String, $codigo: String, $logo: String) {
  dato: CreateReport(titulo: $titulo, codigo: $codigo, logo: $logo){
    uuid
  }
}`;

export const deleteReportFabrica = gql `
  mutation deleteReportFabrica($puuid: String)  {
     dato: deleteReportFabrica(puuid: $puuid)
}`;


/////////////
// QUERYS //
////////////


export const FindReportCodeQuery = gql`
query findReportCodes {
  findReportCodes
}
   `;


export const QueryDato =  gql`
  query QueryDato($uuid: String) {
      datos:
      findDato (uuid:$uuid) {
      uuid
      periodo
      tiene{
        uuid
        nombre
        nombreTecnico
        descripcion
      }
      es {
        uuid
        unidad
        nombre
      }
    }
}`;
  
export const QueryDatos =  gql`
  query QueryDatos($d_uuid: String) {
      datos:
      findDatoByDispositivo (uuid:$d_uuid) {
      uuid
      periodo
      tiene{
        uuid
        nombre
        nombreTecnico
        descripcion
      }
      es {
        uuid
        unidad
        nombre
      }
    }
}`;


export const QueryDatosPeriodo =  gql`
  query QueryDatosPeriodo($periodo: String, $nombre: String) {
      datos:
      Dato (filter:
          {periodo: $periodo,
          es: {nombre_contains: $nombre}} ) {
      uuid
      periodo
      tiene{
        uuid
        nombre
        nombreTecnico
        descripcion
      }
      es {
        uuid
        unidad
        nombre
      }
    }
}`;

export const QueryFabricas = gql `
query QueryFabricas {
  datos: fabricasTodas {
    uuid
    nombre
    labels
    __typename
  }
}`;

export const QueryGeoPos = gql`
query QueryGeoPos($uuid: ID) {
  findGeoPos(uuid: $uuid) {
    uuid
    latitud
    longitud
  }
}
`

export const QueryAllGeoPos = gql`
query geopos {
  geoPos{
    uuid
    nombre
    longitud
    latitud
    labels
  }
}


`

export const QueryAllDevices = gql`
query QueryAllDevices {
  dispositivosByUsuario {
    uuid
    nombre
    labels
    tipo {
      uuid
      nombre
      __typename
    }
    tiene {
      to {
        stats_last
        es {
          uuid
          nombre
          unidad
          codigo
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
`

export const QueryAllDevicesUltimasMediciones = gql`
query QueryAllDevicesUltimasMediciones {
  dispositivos	 {
    uuid
    nombre
    estructuraCantidad
    estructura {
      uuid
      nombre
      nombreTecnico
      descripcion
      tipo {
        nombre
        uuid
        __typename
      }
      estructuraCantidad
      tiene {
        to {
          uuid
          stats_count
          es {
            uuid
            nombre
            codigo
            __typename
          }
          __typename
        }
        __typename
      }
      estado {
        nombre
        __typename
      }
      __typename
    }
    __typename
  }
}
`
export const QueryLastDataDispositivo = gql`
query QueryLastDataDispositivo($uuid: String) {
  findEstructura(uuid: $uuid) {
    uuid
    nombre
    labels
    estructura {
      tiene {
        to {
          uuid
          stats_count
          stats_last
          lastFecha
          lastValor
          lastEntrada {
            lastFecha,
            lastValor,
          }
          es {
            uuid
            nombre
            codigo
            unidad
            nombreCode
            descripcion
            agrupacion {
              uuid
              nombre
              nombreCode
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
    tiene {
      to {
        lastFecha
        lastValor
        lastEntrada {
          lastFecha
          lastValor
        }
        stats_last
        es {
          uuid
          nombre
          unidad
          codigo
          nombreCode
          descripcion
          agrupacion {
            uuid
            nombre
            nombreCode
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}

`

export const QueryFindLastMeasuresWithDescendancy = gql`
query QueryFindLastMeasuresWithDescendancy($uuidDevice: String) {
  findLastMeasuresWithDescendancy(uuidDevice: $uuidDevice) {
    uuid
    nombre
    tiene {
      to {
        uuid
        lastFecha
        lastValor
        es {
          uuid
          nombre
          nombre_es
          nombre_en
          nombreCode
          descripcion
          codigo
          unidad
          agrupacion {
            uuid
            nombre
            nombre_es
          }

        }
      }
    }
  }
}

`


export const QueryTiposDatos = gql`
query	TiposDato {
  tipoDatos{
    uuid
    nombre
    codigo
    nombreCode
    descripcion
    factorDecimal
    unidad
    displayField
    agrupacion{
      nombre
      nombreCode    
    }    
  }
}
`
/*
export const QueryEstructura = gql `
  query findEstructura($uuid: ID) {
    datos: 
    Dispositivo(filter: {uuid: $uuid}) {
      uuid
      nombre
      estructuraCantidad
      estructura {
            uuid
            nombre
      }
    }
}`;
*/

export const QueryEstructura = gql`
query QueryEstructura($uuid: String) {
  datos:
  findEstructura(uuid: $uuid) { 
    uuid
    nombre
    estructuraCantidad
    estructura {
          uuid
          nombre
    }
  }
}`;


export const QueryDispositivos = gql `
query findDispositivos {
  datos:
  Dispositivo {
    uuid
    nombre
  }
}`;

// Todos los reports
export const QueryReports = gql`
    query QueryReports{
        datos:
        reports {
            uuid
            titulo
            codigo
            logo
            idioma
        }
}`;
