import { PlotlyChartType } from '../plotly/plotly-chart.model';

export class DeviceWidgetParam {
    uuid: string;
    name: string;
    constructor(uuid: string, name: string) {
        this.uuid = uuid;
        this.name = name;
    }
}
export class DataTypeWidgetParam {
    uuid: string;
    name: string;
    unity?: string;
    constructor(uuid: string, name: string, unity: string) {
        this.uuid = uuid;
        this.name = name;
        this.unity = unity;
    }
}
export class WidgetBasicParam {
    uuid: string;
    name: string;
    devices: DeviceWidgetParam[];
    dataType: any;
    rangeDate?: DateRangeParam;
}
export class SingleDataTypeWidgetBasicParam extends WidgetBasicParam {
    dataType: DataTypeWidgetParam;
}
export class MultipleDataTypeWidgetBasicParam extends WidgetBasicParam {
    dataType: DataTypeWidgetParam[];
}
export class LimitWidgetParam extends MultipleDataTypeWidgetBasicParam {
    limit: number;
}
export class RangeWidgetParam extends LimitWidgetParam {
    min: number;
    max: number;
}
export class DateRangeParam {
    period: PeriodType;
}


export class GaugeWidget {
    uuid: string;
    name: string;
    dataGauge: DataGauge;
    dataType: DataTypeWidgetParam;
    dataSize?: DataSize;
    x: number;
    y: number;
    cols: number;
    rows: number;
    relationUUID: string;
}
export class DataSize {
    x: number;
    y: number;
    width: number;
    height: number;
    minWidth?: number;
    minHeight?: number;
    widthPx?: number;
    heightPx?: number;
}

export class GaugeNumberFormat {
    valueformat: any;
}

export class DataGauge {
    value: number;
    number?: GaugeNumberFormat
    title: { text: string };
    type: PlotlyChartType;
    mode?: string;
    delta?: { reference: number, valueformat: any };
    gauge?: {
        axis: { range: number[] },
        steps?: [
            { range: number[], color: string },
            { range: number[], color: string }],
        threshold?: {
            line: { color: string, width: number },
            thickness: number,
            value: number
        }
    };
}

export abstract class Widget<TParam extends WidgetBasicParam> {
    uuid: string;
    name: string;
    widgetType: WidgetType;
    params: TParam;
}
export enum WidgetType {
    CLUSTER_COLUMN = 'ClusteredColumns',
    DIGITAL = 'digital',
    GAUGE = 'Gauge',
    LINE = 'Line',
    AREA = 'Area',
    PIE = 'Pie',
    TABLE = 'Table',
    UNDEFINED = 'undefined'
}

export enum PeriodType {
    HOUR = 'Hour',
    DAY = 'Day',
    WEEK = 'Week',
    MONTH = 'Month'
}
