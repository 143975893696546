import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

interface UpdatedWidgetSizePosition {
    widgetRelationUUID: string;
    width: number;
    height: number;
    x: number;
    y: number;
}

export class UpdateWidgetSizePositionBuilder {
    private _WIDGETRELATIONUUID: string;
    private _WIDTH: number;
    private _HEIGHT: number;
    private _X: number;
    private _Y: number;


    private get _mutation() {
        return gql `mutation actualizarWidgetSizePosition($widgetRelationUUID: String, $width: Int, $height: Int, $x: Int, $y: Int) {
            updateDashboardWidget(dashboardWidget: $widgetRelationUUID, width: $width, height: $height, x: $x, y: $y) {
                uuid
                width
                height
                x
                y
            }
          }`;
    }

    public withWidgetRelationUUID(widgetUUID: string): this {
        this._WIDGETRELATIONUUID = widgetUUID;
        return this;
    }

    public withWidgetWidth(newWidth: number): this {
        this._WIDTH = newWidth;
        return this;
    }
    public withWidgetHeight(newHeight: number): this {
        this._HEIGHT = newHeight;
        return this;
    }
    public withWidgetX(newX: number): this {
        this._X = newX;
        return this;
    }
    public withWidgetY(newY: number): this {
        this._Y = newY;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: UpdatedWidgetSizePosition = {
            widgetRelationUUID: this._WIDGETRELATIONUUID,
            width: this._WIDTH,
            height: this._HEIGHT,
            x: this._X,
            y: this._Y
        };
        const grapqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return grapqlMutationBuilder
        .withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
