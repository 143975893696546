import gql from 'graphql-tag';


export const QueryDatos =  gql`
query QueryDatos($d_uuid: String) {
  getDatosFromDevice(uuid: $d_uuid) {
    uuid
    periodo
    descripcion
    tiene{
      uuid
      nombre
      nombreTecnico
      descripcion
    }
    es {
      uuid
      unidad
      nombre
    }
  }
}`;



export const QueryEstructura = gql `
  query QueryEstructura($uuid: String) {
  getDispositivo(uuid: $uuid) {
    uuid
    nombre
    estructuraCantidad
    estructura {
          uuid
          nombre
    }
  }
}`;

