import { DataTypeWidgetParam, DataSize } from '../widgets/widget-interfaces.model';
import { DataPie } from 'src/app/components/charts/plotly/plotly.component';

export class PlotlyWidget {
    uuid: string;
    name: string;
    dataChart: PlotlyData[] = [];
    layoutChart?: PlotlyLayout;
    dataType: DataTypeWidgetParam | DataTypeWidgetParam[];
    dataSize: DataSize;
    x: number;
    y: number;
    cols: number;
    rows: number;
    relationUUID: string;
}

export class PlotlyData {
    uuid: string[];
    x: string[];
    y: number[];
    type: PlotlyChartType;
    widgetName?: string;
    name: string;
    fill?: FillType; // area
    textInfo?: string; // pie
    textPosition?: string; // pie
    unity?: string;
    orientation?: ChartOrientationType;
}

export class PlotlyLayout {
    barMode?: BarMode;
    showLegend: boolean;
    title?: string;
    margin?: {
        t?: number,
        r?: number,
        l?: number,
        b?: number,
        pad?: number
    };
}

export class PlotlyGraph {
    data: PlotlyGraphData[] | DataPie[];
    layout?: LayoutGraphData;
    config?: ConfigGraphData;
}

export class PlotlyGraphData {
    x: string[] | number[];
    y: number[];
    type: PlotlyChartType;
    name: string;
    orientation: OrientationType;
    mode?: string;
}

export class LayoutGraphData {
    title?: string;
    width?: number;
    height?: number;
    showlegend?: boolean;
    autosize?: boolean;
    margin?: {
        t?: number,
        r?: number,
        l?: number,
        b?: number
        pad?: number
    };
    legend?: {
        orientation?: ChartOrientationType,
        x?: number,
        y?: number,
        type?: string
    };
    annotations?: [{
        xref?: string,
        yref?: string,
        x?: number,
        xanchor?: string,
        y?: number,
        yanchor?: string,
        text?: string,
        font?: {
            family?: string;
            size?: number;
            color?: string;
        }
        showarrow?: boolean
    }];
    xaxis?: {
        tickangle: number
    };
}

export class ConfigGraphData {
    displayModeBar?: boolean;
    responsive?: boolean;
    scrollZoom?: boolean;
    displaylogo: boolean;
}

export enum PlotlyChartType {
    SCATTER = 'scatter',
    BAR = 'bar',
    PIE = 'pie',
    GAUGE = 'indicator',
    TABLE = 'table',
    DIGITAL = 'digital'
}
export enum FillType {
    NONE = 'none',
    TOZEROY = 'tozeroy',
    TOZEROX = 'tozerox',
    TONEXTY = 'tonexty',
    TONEXTX = 'tonextx',
    TOSELF = 'toself',
    TONEXT = 'tonext'
}

export enum BarMode {
    GROUP = 'group',
    STACK = 'stack'
}

export enum ChartOrientationType {
    VERTICAL = 'v',
    HORIZONTAL = 'h',
    NONE = ''
}
