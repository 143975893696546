import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LatestMeasurementsComponent } from 'src/app/sections/visualizacionFolder/latest-measurements/latest-measurements.component';


const routes: Routes = [
  { path: 'latest-measurements', component: LatestMeasurementsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LatestMeasurementsRoutingModule { }
