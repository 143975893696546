import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface RemoveFactoryToUserModel {
  from: string;
  to: string;
}

export class RemoveFactoryToUserBuilder {
  private _USER: string;
  private _FACTORY: string;

  private get _mutation() {
    return gql`mutation removeDispositivoUsuario($from: String!, $to: String!) {
          removeUsuarioDispositivos(from: $from, to: $to) {
            uuid
            __typename
          }
        }
        `;
  }

  public withUser(userUUID: string): this {
    this._USER = userUUID;
    return this;
  }

  public withFactory(factoryUUID: string): this {
    this._FACTORY = factoryUUID;
    return this;
  }

  public build(): MutationOptions {
    const mutationParams: RemoveFactoryToUserModel = {
      from: this._USER,
      to: this._FACTORY
    };
    const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
    return graphQlMutationBuilder.withMutation(this._mutation)
      .withParams(mutationParams)
      .build();
  }
}
