import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LynxColors } from 'src/app/models/lynxColors.model';
import 'rxjs/add/operator/map';
@Injectable({
  providedIn: 'root'
})
export class ColorsService {
  
  constructor(private http: HttpClient) { }
  
  public getColors():Observable<LynxColors[]>{
   return this.http.get<LynxColors[]>('/assets/data/colorsPressets.json');
  }

  
  
}
