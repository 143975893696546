import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewAlarmParametro {
    alarmaUUID: string;
    clave: string;
    valor: string;
}

export class AlarmaParametroBuilder {
    private _ALARMUUID: string;
    private _CLAVE: string;
    private _VALOR: string;

    private get _mutation() {
        return gql `mutation addAlarmaParametro($alarmaUUID: String, $clave: String, $valor: String) {
            addAlarmaParametro(alarma: $alarmaUUID, clave: $clave, valor: $valor) {
              uuid
            }
          }`;
    }

    public withAlarmUuid(newAlarmUuid: string): this {
        this._ALARMUUID = newAlarmUuid;
        return this;
    }

    public withClave(newClave: string): this {
        this._CLAVE = newClave;
        return this;
    }

    public withValor(newValor: string): this {
        this._VALOR = newValor;
        return this;
    }

    public build() {
        let mutationParams: NewAlarmParametro;
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = {
            alarmaUUID: this._ALARMUUID,
            clave: this._CLAVE,
            valor: this._VALOR
        };
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
