import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export interface FindDatoParams {
    deviceUUID: string;
    dataTypeUUID: string;
}

export class FindDatoFromDeviceAndDataTypeBuilder {
    private _DEVICEUUID: string;
    private _DATATYPEUUID: string;

    private get _query(): DocumentNode {
        return gql`query findDatoFromDeviceAndDatatype($deviceUUID: String, $dataTypeUUID: String){
            findDatoFromDeviceAndDatatype(
                deviceUUID: $deviceUUID,
                dataTypeUUID:$dataTypeUUID
            ) {
              uuid
              stats_ptop
              stats_pbottom
              descripcion
              es {
                uuid
                nombre
                descripcion
                codigo
                }
            tiene
            {
            uuid
            nombre
            }
            }
          }`;
    }

    public withDeviceUUID(newDeviceUUID: string): this {
        this._DEVICEUUID = newDeviceUUID;
        return this;
    }

    public withDataTypeUUID(newDatatypeUUID: string): this {
        this._DATATYPEUUID = newDatatypeUUID;
        return this;
    }

    public build(): PureQueryOptions {
        let queryParams: FindDatoParams;
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        queryParams = {
            deviceUUID: this._DEVICEUUID,
            dataTypeUUID: this._DATATYPEUUID
        };
        return graphQlBuilder
            .withQuery(this._query)
            .withParams(queryParams)
            .build();
    }
}
