import { Component, OnInit, Input, Output, ViewChild} from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/catch';
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from 'ngx-filter-pipe';  
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
// Para el modal de modificación de los datos!
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { PopUpComponent } from 'src/app/components/utilidades/ventana-dsp/pop-up.component';
import { DialogData } from 'src/app/components/utilidades/interfaces/ventana-dsp.data';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { NavegacionDependenciasComponent } from 'src/app/components/navegacion-dependencias/navegacion-dependencias.component';



// Definimos la forma de un dispositivo en nuestra query
interface Dispositivo {
    uuid: string;
    nombre: string;
    descripcion: string;
    estructura: DataDispositivo[],
    estructuraFrom: DataDispositivo[],
    Dato: {
        descripcion: string;
        calcula?:{
            disabled: string;
        } 
    };
    tiene: DataDispositivo[];
}
// Definimos la estructura de un dispositivo dentro del campo estructura->to->dispositivo
interface DataDispositivo {
    getDispositivo: Dispositivo;
}

// Establecemos que dispsitivos es un array de dispositivos
interface DataDispositivos {
    getDispositivo: Dispositivo;
}

interface DispositivoFrom {
    uuid: string;
    nombre: string;
    descripcion: string;
    estructura: {
        from: DataDispositivo[]
    };
}



interface mergeEstructura {
    mergeEstructura: null;
}

// Query para el modal de añadir dependencias
const queryFabricas = gql `
  query QueryFabricas {
    datos: fabricasTodas {
      uuid
      nombre
    }
}`;

const queryDispositivosRaiz = gql`
  query queryDispositivosRaiz{
    dispositivosRaiz{
      uuid
      nombre
      descripcion
      estructuraCantidad
      tiene {
        to {
            descripcion
          stats_count
        }
      }
    }
  } `;



const queryDispositivosRaizModal = gql`
    query queryDispositivosRaiz{
     datos: bbddRaiz{
       uuid
       nombre
      }
    } `;


// Query para los dispositivos que cuelgan de un dispositivo concreto por uuid
const queryListadoDispositivos = gql`
    query queryListadoDispositivos($uuid: String) {
        getDispositivo(uuid: $uuid) {
        uuid
        nombre
        estructura {
                uuid
                nombre
                descripcion
                __typename
            }
        estructuraFrom {
                uuid
                nombre
                descripcion
                __typename
        }
            __typename
                uuid
                nombre
                descripcion
                __typename
        tiene {
            to {
            descripcion
            uuid
            stats_count
            es {
                uuid
                nombre
                unidad
                __typename
            }
            calcula {
                disabled
                __typename
            }
            __typename
            }
            __typename
        }
        __typename
        }
    }
    `;

const queryNombreDispositivos = gql`
        query queryNombreDispositivos {
            dispositivosRaiz {
                uuid
                nombre
            }
        }`;


const queryActualizarDatosDispositivo = gql`
mutation($uuid: ID!, $nombre: String!, $descripcion: String) {
    UpdateDispositivo(uuid: $uuid, nombre: $nombre, descripcion: $descripcion) {
    uuid
    nombre
    descripcion
    }
                    }`;


const queryAddDispAEstructura = gql`
    mutation($estructura: String, $dispositivo: String){
        mergeEstructura(estructura: $estructura, dispositivo: $dispositivo)
        }`;


const queryDeleteEsctructura = gql`
    mutation($estructura: String, $dispositivo: String){
        deleteEstructura(estructura: $estructura, dispositivo: $dispositivo)
    } `;


// Para el toast:
declare var M: any;



@Component({
    selector: 'app-arbolDispositivos',
    templateUrl: './arbolDispositivos.component.html',
    styleUrls: ['./arbolDispositivos.component.scss']
})




export class arbolDispositivos implements OnInit {
    // Variables para la tabla de angular material de los datos

    displayedColumns: string[] = ['uuidDato', 'uuidMedicion', 'nombreMedicion', 'cantidadDatos', 'unidadMedicion', 'botones'];
    dataSourceDato: MatTableDataSource<DataDispositivo>;
    @ViewChild('TableDatosPaginator', { static: true }) tabledatosPaginator: MatPaginator;
    loadingDatos = true;
    
    displayedColumnsTo: string[] = ['nombre', 'uuidDispositivo', 'descripcion', 'botones'];
    dataSourceTo: MatTableDataSource<DataDispositivo>;
    @ViewChild('TableToPaginator', { static: true }) tableToPaginator: MatPaginator;
    loadingTo = true;


    displayedColumnsFrom: string[] = ['nombre', 'uuidDispositivo', 'descripcion', 'botones'];
    dataSourceFrom: MatTableDataSource<DataDispositivo>;
    @ViewChild('TableFromPaginator', { static: true }) tableFromPaginator: MatPaginator;
    loadingFrom = true;

    // Fin de las variables para la prueba de navegacion por modal


    @Input() uuidAnterior: string;
    @Output() uuid: string;
    public uuidmostrar: any;
    data: Observable<DataDispositivos>;
    public querySubscription: any;
    dispositivos: DataDispositivo[]; // El tipo de dato que esperams recibir de aquí!
    mySubscription: any; // Para hacer creer a angular que no hemos navegado al mimo componente!
    @Input() uuidMostrarModal: string;
    uuidMostrar: string;
    tipoDato: DataDispositivo[];
    dispositivosFrom: DataDispositivo[];
    listadoDispositivosDropdown: any;
    dispositivosTipoData: DataDispositivo[];
    @Input() opcion: string;
    value: any;
    nombreDisp: string;
    uuidDispositivoDepende: string; // Para recuperar el uuid de la tabla
    noDescMsg =  'No desc.'

   


    // Para el modal:
    uuidModal: string;
    nombreModal: string;
    descripcionModal: string;
    nombre: string;
    tituloModal: string;
    closeResult: string; // Para abrir y cerrar el modal!


    // Para el modal de añadir dispositivos:
    uuidAddDisp: string;
    public vpop: DialogData = {
        fabricas: [],
        dispositivo: {
          uuid: '',
          nombre: ''
        },
        dato: {
          uuid: '',
          periodo: '',
          descripcion:''
        },
        tipoDato: {
          uuid: '',
          nombre: '',
          unidad: ''
        }
      };



    listaDispositivosAdd = [];
    @Input() dispositivoAdd: DataDispositivo[];
    status: boolean = true;


    searchStr: string;
    descripcionModalMutation: string;
    nombreModalMutation: string;


    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }


    // Para la paginacion:
    p1: number = 1;
    p2: number = 1;
    p3: number = 1;


    showError: any;




   /*  userFilter: any = { nombre: '' }; */


    dataToast: mergeEstructura;

    constructor(
        private activatedRoute: ActivatedRoute,
        private apollo: Apollo,
        private router: Router,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private toastrservice: ToastrService,
        private dialog: MatDialog
        ) { }


    ngOnInit() {

        // Con las dos suscripciones de debajo, hacemos creer a angular que no hemos visitado el componente
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });

        // Nos suscribimos al parametro de uuid del link para saber a que dispositivo tenemos que navegar
        // a continuacion.
        // Nota: El "this.activatedRoute.params.subscribe" inferior puede modificarse por
        //       "this.activatedRoute.queryParams.subscribe" en funcion del resultado que se espere
        this.uuidmostrar = this.activatedRoute.params.subscribe(params => {
            // const uuid = params['uuid'];
            this.uuid = params.uuid;
                });

        // Para ver los datos que se están trayeno en la respuesta de la query:
        this.getData()  


        // Query para extraer datos que se cargan en el modal (no es la mejor forma de hacerlo desde luego):
        this.querySubscription = this.apollo
                .query<DataDispositivos>({
                    query: queryNombreDispositivos
                })
                // .valueChanges
                .subscribe(({ data }) => {
                    this.listadoDispositivosDropdown = data.getDispositivo;
                    this.querySubscription.unsubscribe();
                });

        } // Cierre el onInit()


    // Obtener datos

    getData() {
        this.querySubscription = this.apollo
            .query<DataDispositivos>({
                query: queryListadoDispositivos,
                fetchPolicy: 'network-only',
                variables: {
                    uuid: this.uuid
                }
            })
            // .valueChanges
            .subscribe(({ data, loading }) => {
                const dispositivo=data.getDispositivo;
                this.nombreDisp = dispositivo.nombre;
                this.dispositivosTipoData = dispositivo.tiene;
                this.dataSourceDato = new MatTableDataSource(this.dispositivosTipoData); // Para la tabla ngUI
                this.dataSourceDato.filterPredicate = (data, filter: string) => {
                    const accumulator = (currentTerm, key) => {
                        return this.nestedFilterCheck(currentTerm, data, key);
                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };
                this.dataSourceDato.paginator = this.tabledatosPaginator; // Para la paginacion d ela tabla ngUI

                
                this.dispositivos = dispositivo.estructura //.to;
                this.dataSourceTo = new MatTableDataSource(this.dispositivos); // Para la tabla ngUI
                this.dataSourceTo.filterPredicate = (data, filter: string) => {
                    const accumulator = (currentTerm, key) => {
                        return this.nestedFilterCheck(currentTerm, data, key);
                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };
                
                this.dataSourceTo.paginator = this.tableToPaginator;
                this.dispositivosFrom = dispositivo.estructuraFrom;
                this.dataSourceFrom = new MatTableDataSource(this.dispositivosFrom); // Para la tabla ngUI
                this.dataSourceFrom.filterPredicate = (data, filter: string) => {
                    const accumulator = (currentTerm, key) => {
                        return this.nestedFilterCheck(currentTerm, data, key);
                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };
                this.dataSourceFrom.paginator = this.tableFromPaginator;
                
                this.loadingDatos = loading;
                this.nombre = dispositivo.nombre;
                this.querySubscription.unsubscribe();
            });
    }

    // Este método guarda el historial de la navegación, por lo que con esto podemos navegar al estado anterior del
    // componente y así navegar hacia atras de manera unitaria con los datos cacheados!
    previousState() {
        window.history.back();
    }

    // Para el modal (ORIGINAL), fuera del oninit:
    open(content) {
        this.modalService.open(content,
            { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });



    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    // Estas dos funciones son para añadir dispositios a estructuras
    // en función si el dispositivo va a depender de otros o son otros
    // los que van a depender de este dispositivo
    mergeEstructuraDependen() {
        this.apollo.mutate<mergeEstructura>({
            mutation: queryAddDispAEstructura,
            variables: {
                estructura: this.uuid,
                dispositivo: this.uuidAddDisp.trim()
            }
        }).subscribe(
            ({data}) => {
                if (data.mergeEstructura) {
                    this.toastrservice.success('Estructura modificada correctamente', '',
                    {closeButton: true});
                    this.getData();
                } else if (data.mergeEstructura == null){
                    this.toastrservice.error('No se ha podido modificar la estructura', '',
                    {closeButton: true});
                }
            },
            error => {
                if (error){
                    this.toastrservice.error('No se ha podido modificar la estructura', '',
                    {closeButton: true});
                } else if (!error) {
                    this.toastrservice.success('Estructura modificada correctamente', '',
                    {closeButton: true});
                }
            }
            );
    }



    mergeEstructuraDependeDe() {
        this.apollo.mutate<mergeEstructura>({
            mutation: queryAddDispAEstructura,
            variables: {
                estructura: this.uuidAddDisp.trim(),
                dispositivo: this.uuid
            }
        }).subscribe(
            ({ data }) => {
                if (data.mergeEstructura) {
                    this.toastrservice.success('Estructura modificada correctamente', '',
                        { closeButton: true });
                        this.getData();
                } else if (data.mergeEstructura == null) {
                    this.toastrservice.error('No se ha podido modificar la estructura', '',
                        { closeButton: true });
                }},
            error => {
                if (error) {
                    this.toastrservice.error('No se ha podido modificar la estructura', '',
                        { closeButton: true });
                } else if (!error) {
                    this.toastrservice.success('Estructura modificada correctamente', '',
                        { closeButton: true });
                }});
        }


    // Función para borrar que el dispositivo actual dependa de otro
    deleteEstructura(d){
        if (confirm('¿Estás seguro de eliminar el dispositivo "' +
            d.nombre +  '" de la estructura de ' + this.nombreDisp + '?')) {
            this.apollo.mutate({
                mutation: queryDeleteEsctructura,
                variables: {
                    estructura: this.uuid,
                    dispositivo: d.uuid
                }
            }).subscribe(() => { this.getData(); })}
        };


     // Función para borrar que el dispositivo actual dependa de otro
     deleteEstructuraDependeDe(dispositivo){

        if (confirm('¿Hacer que ' + this.nombreDisp +' deje de depender de ' +
        dispositivo.Dispositivo.nombre + '?')) {
            this.apollo.mutate({
                mutation: queryDeleteEsctructura,
                variables: {
                    estructura: dispositivo.Dispositivo.uuid,
                    dispositivo: this.uuid
                }
            }).subscribe((data) => { this.getData(); });
        }
    }



    ////////////////////////////////////////////////////////////

    // Método que extrae datos de la fila de la tabla para luego cargarla en el modal:
    saveData(d) {
        this.tituloModal = d.nombre;
        this.nombreModal = d.nombre;
        this.descripcionModal = d.descripcion;
        this.uuidModal = d.uuid;

    }


    // Función que va a actualizar los datos existentes en la base de datos
    actualizarDatos() {
        this.apollo.mutate({
            mutation: queryActualizarDatosDispositivo,
            variables: {
                uuid: this.uuidModal,
                nombre: this.nombreModal,
                descripcion: this.descripcionModal
            }
        }).subscribe(() => { this.getData(); });
        // this.sleep(250).then(() => { window.location.reload() });
    }


    // Para filtrar la tabla:
    applyFilterDato(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceDato.filter = filterValue.trim().toLowerCase();
    }

    applyFilterTo(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceTo.filter = filterValue.trim().toLowerCase();
    }

    applyFilterFrom(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceFrom.filter = filterValue.trim().toLowerCase();
    }


    copiado(event: Event) { 
        if (event) { 
            this.toastrservice.info('', 'uuid copiado al portapapeles',
                { closeButton: true, timeOut: 2000 });
        } 
    } 





    mergeEstructuraDependenNav(uuid_dependencia) {
        this.apollo.mutate<mergeEstructura>({
            mutation: queryAddDispAEstructura,
            variables: {
                estructura: this.uuid,
                dispositivo: uuid_dependencia.trim()
            }
        }).subscribe(
            ({data}) => {
                if (data.mergeEstructura) {
                    this.toastrservice.success('Estructura modificada correctamente', '',
                    {closeButton: true});
                    this.getData();
                } else if (data.mergeEstructura == null){
                    this.toastrservice.error('No se ha podido modificar la estructura', '',
                    {closeButton: true});
                }
            },
            error => {
                if (error){
                    this.toastrservice.error('No se ha podido modificar la estructura', '',
                    {closeButton: true});
                } else if (!error) {
                    this.toastrservice.success('Estructura modificada correctamente', '',
                    {closeButton: true});
                }
            }
            );
    }

    navegacionDependenciasTo() {
        const qpf: Subscription = this.apollo.query<any>({
            query: queryDispositivosRaizModal,
            fetchPolicy: 'network-only'

            }).subscribe(({data}) => {
                this.vpop.fabricas = [];
                this.vpop.fabricas = data.datos;    
                this.vpop.fabricas.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); 
                const dialogRef = this.dialog.open(NavegacionDependenciasComponent, {
                    width: '50%',
                    height: '60%',
                    data: this.vpop
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.mergeEstructuraDependenNav(result.uuid)
                    this.getData();
                });
            });
        }


        mergeEstructuraDependenFromNav(uuid_dependencia) {
            this.apollo.mutate<mergeEstructura>({
                mutation: queryAddDispAEstructura,
                variables: {
                    estructura: uuid_dependencia,
                    dispositivo: this.uuid
                }
            }).subscribe(
                ({data}) => {
                    if (data.mergeEstructura) {
                        this.toastrservice.success('Estructura modificada correctamente', '',
                        {closeButton: true});
                        this.getData();
                    } else if (data.mergeEstructura == null){
                        this.toastrservice.error('No se ha podido modificar la estructura', '',
                        {closeButton: true});
                    }
                },
                error => {
                    if (error){
                        this.toastrservice.error('No se ha podido modificar la estructura', '',
                        {closeButton: true});
                    } else if (!error) {
                        this.toastrservice.success('Estructura modificada correctamente', '',
                        {closeButton: true});
                        this.getData();
                    }
                }
                );
        }
    

        navegacionDependenciasFrom() {
            const qpf: Subscription = this.apollo.query<any>({
                query: queryDispositivosRaizModal,
                fetchPolicy: 'network-only'
                }).subscribe(({data}) => {
                    this.vpop.fabricas = [];
                    this.vpop.fabricas = data.datos;        
                    this.vpop.fabricas.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); 
                    const dialogRef = this.dialog.open(NavegacionDependenciasComponent, {
                        width: '50%',
                        height: '60%',
                        data: this.vpop
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        this.mergeEstructuraDependenFromNav(result.uuid)       
                        this.getData();                        
                    });
                });
            }
    
    








    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }


    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        // Para que al cambiar de componente nos desuscribamos y no tengamos problemas de memoria:
        this.querySubscription.unsubscribe();
        // Puede no darse siempre esta suscripción!
        if (this.mySubscription) {
            this.mySubscription.unsubscribe();
        }
    }
}



