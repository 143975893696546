import { DeviceInfo } from './../../models/deivce-info';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-device-info-item',
  templateUrl: './device-info-item.component.html',
  styleUrls: ['./device-info-item.component.scss']
})
export class DeviceInfoItemComponent implements OnInit {

  @Input() info: DeviceInfo = null;
  @Input() preview: DeviceInfo = null;
  constructor() { }

  ngOnInit() { 
  }
 

}
