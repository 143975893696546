import { FactoryModel } from "../FactoryModel";
import { LynxCollection } from '../contract/collection.model';
import { ListItem } from '../contract/list-item.model';
import { FactoryDetailGraphQLResponse } from '../graphQL/factory-graphQL.respponse';


export class FactoryCollection extends LynxCollection<FactoryModel> {

    constructor() {
        super();
    }

    public buildItem(newItem: ListItem): FactoryModel {
        if (newItem === null || newItem === undefined) {
            return null;
        }
        let item: FactoryDetailGraphQLResponse;
        item.descripcion = newItem.name;
        item.nombre = newItem.name;
        item.uuid = newItem.uuid;
        return new FactoryModel(item);
    }

}