import { Component, OnInit, OnDestroy, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { NavigationEnd } from '@angular/router';
import {
    queryNavegacionDato, cambiarFormula, asignarFuncion, cambiarVariable, queryDispositivosRaiz, queryFunciones, ActualizarDato,
    queryAddDependency, queryDeleteDepedency, recalcularDato, cambiarZonaHoraria, ActualizarDescripcion, updateDato, queryDatoByDependencyTo, queryDatoByDependencyFrom
} from './querys/queryNavDatos';
import { NgbModal, ModalDismissReasons, NgbButtonLabel } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DialogData } from 'src/app/components/utilidades/interfaces/ventana-dsp.data';
import { MatDialog } from '@angular/material';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { NavegacionDependenciasComponent } from '../navegacion-dependencias/navegacion-dependencias.component';
import { TimezoneService, Timezone } from 'src/app/services/timezone.service';





// Query que recupera los datos del servidor del tipo de dato
interface Dato {
    descripcion?: string;
    uuid?: string;
    next?: number;
    invalidated?: number;
    stats_q1?: number;
    stats_q3?: number;
    stats_min?: number;
    stats_max?: number;
    stats_median?: number;
    stats_mean?: number;
    stats_stddev?: number;
    stats_pbottom?: number;
    stats_ptop?: number;
    stats_count?: number;
    periodo?: string;
    zonaHoraria?: string;
    // entradas?: [
    //     {
    //     uuid: string;
    //     topic: string;
    //     desde?: number;
    //     hasta?: number;
    //     Source: {
    //         uuid: string;
    //         topic: string;
    //         nombre: string;
    //             }
    //         }
    //     ]
    entradas?: [
        {
            from: {
                uuid: any
                nombre: string
                entradas: {
                    topic: string
                    uuid: any
                }
            }
        }
    ]
    es?: {
        uuid?: string;
        nombre?: string;
    };
    tiene?: {
        uuid: string;
        nombre: string;
    };
    depende?: {
        to?: [Depende],
        from?: [Depende]
    };
    dependeTo?: [Depende]
    dependeFrom?: [Depende]
    calcula?: {
        uuid?: string;
        disabled?: string;
        error?: string;
        formula?: string;
        from?: {
            uuid?: string;
            codigo?: string;
        }
    };
}

interface Depende {
    uuid: string;
    variable: string;
    to: Dato;
}

interface DataDato {
    to: Dato;
    uuid: string;
    variable?: string;
}

interface addDependency {
    addDependency: Number;
}

interface deleteDependency {
    deleteDependency: Number;
}

interface Funcion1 {
    uuid: string;
    codigo: string;
}

interface Funcion {
    funciones: [Funcion1]
}



@Component({
    selector: 'app-arbolDatos',
    templateUrl: './arbolDatos.component.html',
    styleUrls: ['./arbolDatos.component.scss'],
    animations: [
        // Each unique animation requires its own trigger. The first argument of the trigger function is the name
        trigger('rotatedState', [
            state('default', style({ transform: 'rotate(0)' })),
            state('rotated', style({ transform: 'rotate(-360deg)' })),
            transition('rotated => default', animate('450ms ease-out')),
            transition('default => rotated', animate('450ms ease-in'))
        ])]
})


export class arbolDatos implements OnInit, OnDestroy {


    // Para rotar los iconos
    state: string = 'default';

    // Para la zona horaria:
    timezoneCollection: Timezone[] = [];
    utcCollection: string[] = [];
    zonaHorariaDato = 'Sin zona horaria definida';
    arrayTz: string[] = [];
    arrayTz2: string[];
    _jsonURL = 'assets/data/timezones.json';


    // Definimos las variables que vamos a utilizar dentro de la clase:
    dato: any;
    panelOpenState = false; // Para el desplegable del error:
    panelHeight = '25px';
    uuid: string;
    mySubscription: any; // Para hacer creer a angular que no hemos navegado al mimo componente!
    public querySubscription: any;
    to: Dato;
    datoActual = {
        uuid: '', nombre: '', uuidMedicion: '', nombreMedicion: '', next: 0, invalidated: 0, stats_median: 0, stats_stddev: 0, stats_pbottom: 0,
        stats_ptop: 0, stats_count: 0, disabled: '', error: '', codigo: ''
    };
    dependeTo: [Depende];
    dependeFrom: [Depende];
    data: DataDato;
    nombreHeader: string;
    dependeHeader: string;
    datoHeader: string;
    datoDescripcion: string;
    datoUpperLimit: number;
    datoLowerLimit: number;

    // Data Sources para los datos:
    displayedColumnsSources: string[] = ['sourceuuid', 'sourcetopic', 'entradauuid', 'entradatopic', 'desde', 'hasta'];
    dataSourceSources: MatTableDataSource<any>;
    @ViewChild('TableSourcesPaginator', { static: false }) tableSourcesPaginator: MatPaginator;

    displayedColumnsTo: string[] = ['uuidDato', 'nombreDispositivo', 'tipoDato', 'formula', 'datosTotales', 'next', 'invalidated', 'limiteInferior',
        'limiteSuperior', 'mediana', 'desviacion', 'fCalculo', 'dependen', 'botones'];
    dataSourceTo: MatTableDataSource<DataDato>;
    @ViewChild('TableToPaginator', { static: true }) tableToPaginator: MatPaginator;

    displayedColumnsFrom: string[] = ['uuidDato', 'nombreDispositivo', 'tipoDato', 'formula', 'datosTotales', 'next', 'invalidated', 'limiteInferior',
        'limiteSuperior', 'mediana', 'desviacion', 'fCalculo', 'dependen', 'botones'];
    dataSourceFrom: MatTableDataSource<DataDato>;
    @ViewChild('TableFromPaginator', { static: true }) tableFromPaginator: MatPaginator;
    Paginator;


    loading = true;
    datoActualuuidMedicion: string;
    datoActualuuidNombre: string;
    uuidRelacionDepende: string;

    uuidDispositivo: string;
    introManual: boolean = false;


    // Para modificar la función de cálculo:
    funciones: any;
    opcionesFuncion = [];
    sinFuncion: string = 'Sin funcion definida';
    public vpop: DialogData = {
        fabricas: [],
        dispositivo: {
            uuid: '',
            nombre: ''
        },
        dato: {
            uuid: '',
            periodo: '',
            descripcion: ''
        },
        tipoDato: {
            uuid: '',
            nombre: '',
            unidad: ''
        }
    };

    // Para el modal
    closeResult: string;
    TituloModal: string;
    NombreDispositivoModal: string;
    TipoDatoModal: string;
    uuidModal: string;
    FuncionCalculoModal: string;
    Pbottom: number;
    Ptop: number;
    variableManualModal: string;
    opcionesVariableModal = [];
    opcionesVariableModal2 = []
    variableModal: string;
    config = {
        displayKey: 'description', //if objects array passed which key to be displayed defaults to description
        search: true, //true/false for the search functionlity defaults to false,
        height: 'auto',
        width: '1000px',
        placeholder: 'Seleccionar variable', // text to be displayed when no item is selected defaults to Select,
        limitTo: 10, // options.length // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
        moreText: 'extra', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'Sin resultados', // text to be displayed when no items are found while searching
        searchPlaceholder: 'Buscar variable', // label thats displayed in search input,
        clearOnSelection: true // clears search criteria when an option is selected if set to true, default is false
    };
    public querySubscription1: any;
    uuidDependencia: string;


    constructor(
        private activatedRoute: ActivatedRoute,
        private apollo: Apollo,
        private router: Router,
        private modalService: NgbModal,
        private toastrservice: ToastrService,
        public dialog: MatDialog,
        private zone: NgZone,
        private timezoneService: TimezoneService,
        private http: HttpClient
    ) { }

    ngOnInit() {

        // Con las dos suscripciones de debajo, hacemos creer a angular que no hemos visitado el componente
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });

        // Nos suscribimos al parametro de uuid del link para saber a que dispositivo tenemos que navegar
        // a continuacion.
        // Nota: El "this.activatedRoute.params.subscribe" inferior puede modificarse por
        //       "this.activatedRoute.queryParams.subscribe" en funcion del resultado que se espere
        this.activatedRoute.params.subscribe(params => {
            // const uuid = params['uuid'];
            this.uuid = params.uuid;
        });

        this.getData();

        // Para extraer las opciones de zonas horarias:
        // this.getTimeZoneCollection()





    } // cierra el OnInit


    // Para recuperar datos [Aquí metemos todas las consultas que vayan a traernos datos]

    getData() {
        this.querySubscription = this.apollo
            .query<any>({
                query: queryNavegacionDato,
                fetchPolicy: 'network-only',
                variables: {
                    uuid: this.uuid
                }
            })
            // .valueChanges
            .subscribe(({ data, loading }) => {
                this.to = data.getDato;
                this.dato = data.getDato;
                this.getDatoByDependencyTo(this.dato)
                // Comentado para poder acceder hasta la navegación de dato, hay que solucionarlo
                let datosDependencias = this.getDatoByDependencyTo(this.dato) 
                this.dataSourceSources = new MatTableDataSource(datosDependencias.dependenciasFrom)//(this.to.entradas)
                this.dataSourceSources.paginator = this.tableSourcesPaginator;
                this.dataSourceSources.filterPredicate = (data, filter: string) => {
                    const accumulator = (currentTerm, key) => {
                        return this.nestedFilterCheck(currentTerm, data, key);
                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };

                // Rellenamos datos para cargar en la vista[¿no permite pasar objetos?] 
                this.datoDescripcion = this.to.descripcion;
                this.datoUpperLimit = data.getDato.limiteSuperior;
                this.datoLowerLimit = data.getDato.limiteInferior;
                this.uuidDispositivo = this.to.tiene.uuid;
                this.nombreHeader = this.to.es.nombre;
                this.dependeHeader = this.to.tiene.nombre;
                this.datoActual.uuid = this.to.uuid;
                this.datoActual.nombre = this.to.tiene.nombre;
                this.datoActualuuidNombre = this.to.tiene.nombre;
                this.datoActual.uuidMedicion = this.to.es.uuid;
                this.datoActualuuidMedicion = this.to.es.uuid;
                this.datoActual.nombreMedicion = this.to.es.nombre;
                this.datoActual.next = this.to.next;
                this.datoActual.invalidated = this.to.invalidated;
                this.datoActual.stats_median = this.to.stats_median;
                this.datoActual.stats_stddev = this.to.stats_stddev;
                this.datoActual.stats_pbottom = this.to.stats_pbottom;
                this.datoActual.stats_ptop = this.to.stats_ptop;
                this.datoActual.stats_count = this.to.stats_count;
                if (this.to.calcula) { this.datoActual.codigo = this.to.calcula.from.codigo } else { this.datoActual.codigo = 'Sin funcion de calculo' }
                if (this.to.calcula) { this.datoActual.disabled = this.to.calcula.disabled }
                if (this.to.calcula) { this.datoActual.error = this.to.calcula.error }
                if (this.to.calcula) { this.datoHeader = this.to.calcula.from.codigo } else { this.datoHeader = 'Sin funcion de calculo' };
                if (this.to.zonaHoraria != null) { this.zonaHorariaDato = this.to.zonaHoraria } else { this.zonaHorariaDato = 'Sin zona horaria' }
                // if (!this.to.calcula) { this.to.calcula.Funcion.codigo = 'Sin funcion de calculo }'}
                // Restablecemos a 0 la longitud de los arrays de las variables de las formulas
                this.opcionesVariableModal.length = 0;
                this.opcionesVariableModal2.length = 0;
                // Para las opciones de la formula:


                // Con esto retornamos todo lo que está entre los {} de la formula del dato actual!         
                if (this.to.calcula) {
                    var rxp = /{([^}]+)}/g,
                        str = this.to.calcula.formula,
                        curMatch;

                    while (curMatch = rxp.exec(str)) {
                        this.opcionesVariableModal.push(curMatch[1]);
                    }
                } else {
                    this.opcionesVariableModal.push('variable_fake');
                    this.opcionesVariableModal2.push('variable_fake');
                }
                this.loading = loading;
                this.querySubscription.unsubscribe();
            });

        this.apollo.query<Funcion>({
            query: queryFunciones,
            fetchPolicy: 'network-only'
        }).subscribe(({ data }) => {
            this.funciones = data.funciones;
            // this.funciones.push({uuid: null, codigo: 'Sin funcion'})
            for (let _i = 0; _i < this.funciones.length; _i++) {
                this.opcionesFuncion.push(this.funciones[_i].codigo);
            }
            this.opcionesFuncion.filter((item, index) => this.opcionesFuncion.indexOf(item) === index);
            this.opcionesFuncion = this.opcionesFuncion.filter(function (val) { if (val) return val })
        });


        this.getTimeZoneCollection()

    }



    // Para navegar a cualquier nivel del objeto que forman las tablas y poder filtrar!

    rotate() {
        this.state = (this.state === 'default' ? 'rotated' : 'default');
    }

    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }


    previousState() {
        window.history.back();
    }

    getDatoByDependencyTo(dato: Dato) {
        let arrayOfDatosTo = [];
        let arrayOfDatosFrom = [];
        let datoTo = dato.dependeTo; //.map(r=>r.uuid)
        let datoFrom = dato.dependeFrom; //.map(r=>r.uuid)  
        this.dataSourceTo = new MatTableDataSource(arrayOfDatosTo)
        this.dataSourceTo.filterPredicate = (data, filter: string) => {
            const accumulator = (currentTerm, key) => {
                return this.nestedFilterCheck(currentTerm, data, key);
            };
            const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            // Transform the filter by converting it to lowercase and removing whitespace.
            const transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };

        this.dataSourceFrom = new MatTableDataSource(arrayOfDatosFrom)      
        this.dataSourceFrom.filterPredicate = (data, filter: string) => {
            const accumulator = (currentTerm, key) => {
                return this.nestedFilterCheck(currentTerm, data, key);
            };
            const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
            // Transform the filter by converting it to lowercase and removing whitespace.
            const transformedFilter = filter.trim().toLowerCase();
            return dataStr.indexOf(transformedFilter) !== -1;
        };

        for (let i=0; i<datoTo.length; i++) {
            this.apollo
            .query<any>({
                query: queryDatoByDependencyTo,
                fetchPolicy: 'network-only',
                variables: {
                    uuidDep:  datoTo[i].uuid
                }
            }).
            subscribe(({ data, loading }) => {
                const datapush = this.dataSourceTo.data;
                let dato = data.findDatoByDependencyTo;
                dato.dependenciaActual = datoTo[i];
                datapush.push(dato);
                this.dataSourceTo.data = datapush.filter((v,i,a)=>a.findIndex(t=>(t.uuid === v.uuid))===i);
                arrayOfDatosTo.push(dato)
            } )
        }
        this.dataSourceTo.paginator = this.tableFromPaginator;
        

        for (let i=0; i<datoFrom.length; i++) {
            this.apollo
            .query<any>({
                query: queryDatoByDependencyFrom,
                fetchPolicy: 'network-only',
                variables: {
                    uuidDep:  datoFrom[i].uuid
                }
            }).
            subscribe(({ data, loading }) => {
                const datapush = this.dataSourceFrom.data;
                let dato = data.findDatoByDependencyFrom;
                dato.dependenciaActual = datoFrom[i]
                datapush.push(dato);
                this.dataSourceFrom.data = datapush.filter((v,i,a)=>a.findIndex(t=>(t.uuid === v.uuid))===i);
                arrayOfDatosFrom.push(dato)
            } )
        }
        this.dataSourceFrom.paginator = this.tableFromPaginator;

        return {'dependenciasFrom': arrayOfDatosFrom, 'dependenciasTo': arrayOfDatosTo} 

    }



    // Para el modal (ORIGINAL), fuera del oninit:
    open(content) {
        this.modalService.open(content,
            { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    // Método que extrae datos de la fila de la tabla para luego cargarla en el modal:
    saveData(d) {
        this.TituloModal = d.tiene.nombre;
        this.NombreDispositivoModal = d.tiene.nombre;
        this.TipoDatoModal = d.es.nombre;
        this.uuidModal = d.uuid;
        this.variableModal = d.dependenciaActual.variable;
        this.uuidRelacionDepende = d.dependenciaActual.uuid;
    }

    actualizarDato() {
        this.apollo.mutate({
            mutation: ActualizarDato,
            variables: {
                uuid: this.uuidModal,
                stats_pbottom: this.Pbottom,
                stats_ptop: this.Ptop
            }
        }).subscribe(() => { this.getData(); });
    }


    addDependency() {
        this.apollo.mutate<addDependency>({
            mutation: queryAddDependency,
            variables: {
                datouuid: this.uuid,
                depuuid: this.uuidDependencia.trim()
            }
        }).subscribe(({ data }) => {
            if (data.addDependency && this.uuidDependencia.trim() != this.uuid) {
                this.toastrservice.success('', 'Dependencia añadida correctamente',
                    { closeButton: true });
                this.getData();

            } else {
                this.toastrservice.error('Verifica el uuid introducido', 'No se ha podido añadir la dependencia',
                    { closeButton: true });

            }
        });

    }

    deleteDependency(d) {
        if (confirm('¿Eliminar la dependencia ' + d.tiene.nombre + ' del dispositivo ' +
            this.dependeHeader + '?')) {
            this.apollo.mutate<deleteDependency>({
                mutation: queryDeleteDepedency,
                variables: {
                    duuid: d.dependenciaActual.uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    this.toastrservice.error('', 'No se ha podido eliminar la dependencia',
                        { closeButton: true });

                } else if (data.deleteDependency != 0) {
                    this.toastrservice.success('', 'Dependencia eliminada correctamente',
                        { closeButton: true });
                    this.getData();
                }
            }
            )
        };
    }


    applyFilterSources(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceSources.filter = filterValue.trim().toLowerCase();
    }

    applyFilterTo(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceTo.filter = filterValue.trim().toLowerCase();
    }

    applyFilterFrom(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceFrom.filter = filterValue.trim().toLowerCase();
    }

    copiado(event: Event) {
        if (event) {
            this.toastrservice.info('', 'uuid copiado al portapapeles',
                { closeButton: true, timeOut: 2000 });
        }
    }

    recalcularDato() {
        // Aplicar aquí la mutation cuando sepa la estructura;
        this.apollo.mutate({
            mutation: recalcularDato,
            variables: {
                uuidDato: this.uuid
            }
        }).subscribe(({ data, errors }) => {
            if (errors) {
                this.toastrservice.error('', 'No se ha podido recalcular el dato',
                    { closeButton: true });

            } else if (data) {
                this.toastrservice.success('', 'Recalculando el dato...',
                    { closeButton: true });
                this.getData();
            }
        });
    }

    cambiarVariable() {
        if (this.introManual == true) {
            this.variableModal = this.variableManualModal;
        } else {
            this.variableModal = this.variableModal
        }
        this.apollo.mutate<any>({
            mutation: cambiarVariable,
            variables: {
                dependencia: this.uuidRelacionDepende,
                variable: this.variableModal
            }
        }).subscribe(({ data, errors }) => {
            if (errors || data.cambiarVariable == null) {
                this.toastrservice.error('', 'No se ha podido cambiar la variable',
                    { closeButton: true });

            } else if (data.cambiarVariable != null) {
                this.toastrservice.success('', 'Variable cambiada correctamente',
                    { closeButton: true });
                this.getData();
            }
            this.getData();
            this.introManual = false;
            this.variableManualModal = '';
        })
    }


    cambiarFormula() {
        this.apollo.mutate<any>({
            mutation: cambiarFormula,
            fetchPolicy: 'no-cache',
            variables: {
                calcula: this.to.calcula.uuid,
                formula: this.to.calcula.formula
            }
        }).subscribe(({ data, errors }) => {
            if (errors || data.cambiarFormula == null) {
                this.toastrservice.error('', 'No se ha podido cambiar la fórmula',
                    { closeButton: true });

            } else if (data) {
                this.toastrservice.success('', 'Formula cambiada correctamente',
                    { closeButton: true });
                this.getData();
            }

        })
    }



    cambiarFuncion(event) {
        this.apollo.mutate<any>({
            mutation: asignarFuncion,
            variables: {
                dato: this.uuid,
                funcion: event.uuid
            }
        }).subscribe(({ data, errors }) => {
            if (data && data.asignarFuncion != null && !errors) {
                this.toastrservice.success('', 'Función modificada correctamente',
                    { closeButton: true });
                this.getData();

            } else {
                this.toastrservice.error('Posible dato de entrada', 'Error al modificar la función',
                    { closeButton: true });

            }
        })
    }


    habilitarManual(event) {
        this.introManual = event.target.checked;
    }

    cambiarDescripcion(desc: string) {
        this.apollo.mutate<any>({
            mutation: ActualizarDescripcion,
            variables: {
                uuid: this.uuid,
                descripcion: desc
            }
        }).subscribe(({ data, errors }) => {
            if (data && data.UpdateDato != null && !errors) {
                this.toastrservice.success('', 'Descripción modificada correctamente',
                    { closeButton: true });
                this.getData();

            } else {
                this.toastrservice.error('Error', 'Error al modificar la descripción',
                    { closeButton: true });

            }
        })
    }

    updateDatoAction(descripcion: string, upperLimit: number, lowerLimit: number) {
        this.apollo.mutate<any>({
            mutation: updateDato,
            variables: {
                dato: this.uuid,
                descripcion: descripcion,
                limiteInferior: lowerLimit,
                limiteSuperior: upperLimit
            }
        }).subscribe(({ data, errors }) => {
            if ((upperLimit>= lowerLimit) && data.updateDato&& !errors) {
                this.toastrservice.success('Información del dato actualizada correctamente.', 'Éxito',
                    { closeButton: true });
                this.getData();

            } else {

                this.toastrservice.error('Error al actualizar la información del dato. El límite inferior es mayor que límite superior, o el valor de alguno de los límites es superior al máximo permitido (Int 32 bit).', 'Error',
                    { closeButton: true });
                this.getData();

            }
            
        })
    }




    depNav(uuid_dependencia) {
        this.apollo.mutate<addDependency>({
            mutation: queryAddDependency,
            variables: {
                datouuid: this.uuid,
                depuuid: uuid_dependencia.trim()
            }
        }).subscribe(({ data }) => {
            if (data.addDependency && uuid_dependencia.trim() != this.uuid) {
                this.toastrservice.success('', 'Dependencia añadida correctamente',
                    { closeButton: true });
                this.getData();

            } else {
                this.toastrservice.error('Verifica el uuid introducido', 'No se ha podido añadir la dependencia',
                    { closeButton: true });

            }
        });

    }


    navegacionDependenciasTo() {
        const qpf: Subscription = this.apollo.query<any>({
            query: queryDispositivosRaiz,
            fetchPolicy: 'network-only'
        }).subscribe(({ data }) => {
            this.vpop.fabricas = [];
            this.vpop.fabricas = data.datos;
            this.vpop.fabricas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
            const dialogRef = this.dialog.open(NavegacionDependenciasComponent, {
                panelClass: 'dialogNavegacion',
                width: '45%',
                height: '60%',
                data: this.vpop
            });
            dialogRef.afterClosed().subscribe(result => {

                this.depNav(result.uuid)
            });
        });
    }


    cambiarZonaHoraria(event) {
        this.apollo.mutate<any>({
            mutation: cambiarZonaHoraria,
            variables: {
                uuid: this.uuid,
                zonaHoraria: event.value
            }
        }).subscribe(({ data, errors }) => {
            if (data && data.UpdateDato != null && !errors) {
                this.toastrservice.success('', 'Zona horaria modificada correctamente',
                    { closeButton: true });
                this.getData();
            } else {
                this.toastrservice.error('', 'No ha sido posible modificar la zona horaria',
                    { closeButton: true });
            }
        })
    }

    getTimeZoneCollection() {
        this.timezoneService.getTimezoneCollection().subscribe((data: Timezone[]) => {
            this.timezoneCollection = data;
            for (const timezone of data) {
                for (const utc of timezone.utc) {
                    this.utcCollection.push(utc);
                }
            }
            this.utcCollection = this.utcCollection.sort();
        });
    }






    ngOnDestroy() {
        // Desuscribirnos de las querys!
        this.querySubscription.unsubscribe();
        this.mySubscription.unsubscribe();


    }
}