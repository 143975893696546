
import { Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl} from '@angular/forms';


interface XData {
  data: string;
}


@Component({
  selector: 'app-report-param',
  templateUrl: './report-row.component.html',
  styleUrls: ['./report-row.component.scss']
})
export class ReportRowComponent implements OnInit, OnDestroy {
  public rRow = {
    titulo: '',
    orden: '',
    unidadT: '',
    unidadK: ''
  };

  constructor(
    public dialogRef: MatDialogRef<ReportRowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: XData) {
    }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }


}
