import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { FactoriesService } from 'src/app/services/factories/factories.service';
import { Device } from 'src/app/models/device.model';
import { FactoryModel } from "src/app/models/FactoryModel";
import { FactoryCollection as FactoryCollection } from 'src/app/models/factory/factory.collection.model';

@Component({
  selector: 'app-menu-dispositivos',
  templateUrl: './menu-dispositivos.component.html',
  styleUrls: ['./menu-dispositivos.component.scss']
})
export class MenuDispositivosComponent implements OnInit {
  searchField: FormControl = new FormControl();;
  factoriasSelected: FactoryCollection;
  results: Observable<Array<Device>>;
  
  onEnter(value: string) {
    this.filterDevice(value);
  }
  @Input() showInput: boolean;
  @Input() arrayAFiltrar: Array<Device>;
  @Output() arrayAFiltrarEmitter: EventEmitter<Array<Device>> = new EventEmitter<Array<Device>>();

  filtrado: Array<Device>;

  constructor(private dispositivoService: DispositivoService,
    private factoriesService: FactoriesService) { }

  ngOnInit() {
    this.searchField = new FormControl();
    this.factoriesService
      .factorySelectedObservable
      .pipe(
        tap(factorySelectedCollection => this.factoriasSelected = factorySelectedCollection),
      ).subscribe(factorySelectedCollection => this.filterDevice(''));

    this.searchDispositvo();
    this.results = this.dispositivoService.deviceCollectionObservable;
      this.filterDevice('');
  }

  searchDispositvo() {
    this.searchField.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(term => this.filterDevice(term));
  }
  
  filterDevice(term: string) {
    this.filtrado = this.arrayAFiltrar.filter(device => device.name.toLowerCase().includes(term.toLowerCase()));
    this.arrayAFiltrarEmitter.emit(this.filtrado);
   
  }

}
