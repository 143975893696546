import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { FormFilterComponent } from '../form-filter/form-filter.component';
import { FactoryModel } from 'src/app/models/FactoryModel';
import { DateRangeComponent } from '../date-range/date-range.component';
import { NgxChartElement } from 'src/app/models/ngx-chart.model';
import { LynxColors } from 'src/app/models/lynxColors.model';
import { NavbarComponent } from 'src/app/components/shared/navbar/navbar.component';
import { DateRange } from 'src/app/models/dateRange.model';
import { Observable } from 'apollo-link';
import 'rxjs/add/observable/of';
import { Moment } from 'moment';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import { ToastService } from 'src/app/services/toast/toast.service';
import { DataTypeService } from 'src/app/services/datatype/data-type.service';
import { DatatypeTreeHierarchi, DataTypeItem } from 'src/app/models/datatype/datatype-hierarchi.model';
import { PlotlyChartType, ChartOrientationType } from 'src/app/models/plotly/plotly-chart.model';
import * as lodash from 'lodash';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { WidgetParameter } from '../tab-stepper/tab-stepper.component';

export interface TabStepperData {
  chartId?: number;
  chartTitle: string;
  devices: DeviceGraphItem[];
  tipoDato: DeviceGraphDataType[];
  unidad: string;
  rangeDate: DateRange;
  period: WidgetParameter;
  chartType: PlotlyChartType;
  orientation?: ChartOrientationType;
  min: number;
  max: number;
  limit: number;
  timeZone: string;
}

interface UpdateGraphTitleModel {
  graphicNumber: number;
  title: string;
}

interface DispositivosInterface {
  uuid: string;
  descripcion: string;
  estructura?:
  {
    nombre: string,
    uuid: string
  }
}

interface DataRepresentedCollection {
  deviceCollection: Array<DeviceGraphItem>;
  dataTypeCollection: Array<DeviceGraphDataType>;
}

interface DataToConfirmModal {
  title: string;
  message: string;
  existingData: boolean;
}

export interface ChangeColor {
  index?: number;
  customColor?: any;
}

export interface GeneratedDate {
  from: Moment;
  to: Moment;
}

@Component({
  selector: 'app-side-graphs',
  templateUrl: './side-graphs.component.html',
  styleUrls: ['./side-graphs.component.scss']
})

export class SideGraphsComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @ViewChild('calendarEmit', { static: false }) calendarEmit: DateRangeComponent;
  @Input() dispositivosList: DispositivosInterface[] = [];
  generatedChart: TabStepperData[] = [];
  events: string[] = [];
  opened = false;
  factoriesSelected: Array<FactoryModel>;
  chartData: NgxChartElement[];
  chartLabels: string[];
  generated = false;
  generatedDate: GeneratedDate[];
  dataSource: any[] = [];
  changeColor: ChangeColor;
  displayedColumns: string[] = ['show', 'color', 'name', 'actions'];
  hideShowDevice: any;
  legendObser: Observable<NgxChartElement[]>;
  event: any[] = [];
  widthVar: string;
  rowHeight: string;
  colorsArray: LynxColors[];
  dataTypeTree: DatatypeTreeHierarchi[];
  public cargando = false;
  dataCollection: DataRepresentedCollection[] = [];
  createGraphicButton = 'SIDE_GRAPH.CREATE_GRAPHIC';
  deleteGraphicsButton = 'SIDE_GRAPH.DELETE_GRAPHICS';
  maxGraphNumber = 4;
  chosenPeriod: boolean;
  periodObject: WidgetParameter;

  constructor(
    private navbar: NavbarComponent,
    public dialog: MatDialog,
    private toastService: ToastService,
    private datatypeService: DataTypeService,
    private translate: TranslateService) { }

  createEmit($event) {
    this.sidenav.open();
    this.toggleEvent();
  }

  getUpdatedChart(event) {
    this.generatedChart = [];
    this.generatedChart.push(event);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FormFilterComponent, {
      width: '50%'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  public generateChart($event: TabStepperData) {
    if ($event.period.clave === '' && $event.period.valor === '') {
      this.chosenPeriod = false;
    }
    this.getDataForAddDevice($event);
    this.generated = true;
    let auxGenerated: TabStepperData[] = [];
    this.generatedChart.push($event);
    auxGenerated = [...this.generatedChart];
    this.generatedChart = [];
    this.generatedChart = auxGenerated;
    if (this.generatedChart.length > this.maxGraphNumber) {
      this.generatedChart.pop();
    }
    this.sidenav.close();
    if (this.generatedChart.length < this.maxGraphNumber) {
      this.generatedChart.length > 1 ? this.widthVar = '2' : this.widthVar = '1';
      this.generatedChart.length > 1 ? this.rowHeight = '1:1' : this.rowHeight = '2:1';
    } else {
      let auxMessageMaxGraph: null;
      let auxMessageNoMore: null;

      this.translate.get('SIDE_GRAPH.MAX_GRAPH').subscribe(value => auxMessageMaxGraph = value);
      this.translate.get('SIDE_GRAPH.NO_MORE').subscribe(value => auxMessageNoMore = value);

      this.toastService.showInfo(auxMessageMaxGraph, auxMessageNoMore);
    }
  }

  deleteGraphSelected(graphNumber) {
    this.generatedChart.remove(this.generatedChart[graphNumber]);
    if (this.generatedChart.length > 0) {
      this.generated = true;
    } else {
      this.generated = false;
    }

    this.adviceOfDelete();
  }

  adviceOfDelete() {
    let auxMessageMaxGraph: null;

    this.translate.get('SIDE_GRAPH.GRAPH_DELETE').subscribe(value => auxMessageMaxGraph = value);

    this.toastService.showSuccess(auxMessageMaxGraph, '');
  }

  getDataForAddDevice(graph: TabStepperData) {
    const auxData: DataRepresentedCollection = { dataTypeCollection: [], deviceCollection: [] };
    auxData.dataTypeCollection = graph.tipoDato;
    auxData.deviceCollection = graph.devices;
    this.dataCollection.add(auxData);
  }

  toggleEvent() {
    this.navbar.onToggleSideNav();
  }

  deleteModal(graphNumber) {
    const opt: DataToConfirmModal = {
      title: 'CONFIRM_MODAL.TITLE',
      message: 'CONFIRM_MODAL.MESSAGE.QUESTION',
      existingData: this.generatedChart.length > 0 ? true : false
    };
    this.openDialogConfirm(opt, graphNumber);
  }

  openDialogConfirm(opt: DataToConfirmModal, graphNumber: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: opt
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteGraphSelected(graphNumber);
      }
    });

  }

  ngOnInit() {
    this.datatypeService.getDataType().subscribe(data => {
      this.dataTypeTree = lodash.sortBy(data, ['name'], ['asc']);
    });
  }

  updateTitle(updateObject: UpdateGraphTitleModel) {
    this.generatedChart[updateObject.graphicNumber].chartTitle = updateObject.title;
  }

  getChosenPeriod($event: boolean) {
    this.chosenPeriod = $event;
  }

  getPeriod($event: WidgetParameter) {
    this.periodObject = $event;
  }
}
