import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  noUserFound: string;
  public existsUser = false;
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router) {

    this.loginForm = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  loginApp(valuesLoginForm) {
    this.loginService.login(valuesLoginForm).subscribe(authResponse =>{
      if (authResponse.status === 200 ) {
        this.loginService.miUsuario().subscribe(res=>{
          this.loginService.setMiUsuario(res);
          this.router.navigate(['']);

        }) 
       } else {
         this.noUserFound = 'Usuario incorrecto.';
      }
    });
  }

  verify(){
    this.loginService.isAuthenticated().subscribe(res =>{
      console.log(res);
    });
  }

  logout(){
    this.loginService.logout().subscribe(res =>{
      console.log(res);
    });
  }

}
