import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LastStatesComponent } from 'src/app/sections/visualizacionFolder/last-states/last-states.component';


const routes: Routes = [
  { path: 'last-states', component: LastStatesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LastStatesRoutingModule { }
