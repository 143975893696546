import { TableWidget, DataTableWidget } from './table-widget.model';
import { DataSize } from './widgets/widget-interfaces.model';
import * as _ from 'lodash';


export class TableWidgetBuilder {

    private _TABLEUUID: string;
    private _TABLENAME: string;
    private _WIDGETNAME: string;
    private _DEVICECOLLECTION: string[];
    private _DATATYPECOLLECTION: string[];
    private _DATASIZE: DataSize;
    private _TABLEVALUES: number[];
    private _X: number;
    private _Y: number;
    private _ROW: number;
    private _COL: number;
    private _RELATIONUUID: string;
    private _UNITY: string;

    public withTableUUID(newTableUUID: string): TableWidgetBuilder {
        this._TABLEUUID = newTableUUID;
        return this;
    }

    public withTableName(newTableName: string): TableWidgetBuilder {
        this._TABLENAME = newTableName;
        return this;
    }

    public withWidgetName(newWidgetName: string): TableWidgetBuilder {
        this._WIDGETNAME = newWidgetName;
        return this;
    }

    public withDeviceCollection(newDeviceCollection: string[]): TableWidgetBuilder {
        this._DEVICECOLLECTION = newDeviceCollection;
        return this;
    }

    public withDataTypeCollection(newDataTypeCollection: string[]): TableWidgetBuilder {
        this._DATATYPECOLLECTION = newDataTypeCollection;
        return this;
    }

    public withUnity(newUnity: string): TableWidgetBuilder {
        this._UNITY = newUnity;
        return this;
    }

    public withDataSize(newDataSize: DataSize): TableWidgetBuilder {
        this._DATASIZE = newDataSize;
        return this;
    }

    public withTableValues(newTableValues: number[]): TableWidgetBuilder {
        this._TABLEVALUES = newTableValues;
        return this;
    }

    public withXPos(newPos: number): TableWidgetBuilder {
        this._X = newPos;
        return this;
    }
    public withYPos(newPos: number): TableWidgetBuilder {
        this._Y = newPos;
        return this;
    }
    public withCol(newCol: number): TableWidgetBuilder {
        this._COL = newCol;
        return this;
    }
    public withRow(newRow: number): TableWidgetBuilder {
        this._ROW = newRow;
        return this;
    }
    public withRelationUUID(newRelation: string): TableWidgetBuilder {
        this._RELATIONUUID = newRelation;
        return this;
    }

    public build(): TableWidget {
        const dataTableAux: DataTableWidget = {
            columns: this.getTableColumns(),
            dataSource: this.getTableDataSource(),
            unity: this._UNITY
        };

        const oTableWidget: TableWidget = {
            uuid: this._TABLEUUID,
            name: this._TABLENAME,
            dataTable: dataTableAux,
            dataSize: this._DATASIZE,
            x: this._X, 
            y: this._Y,
            cols: this._COL,
            rows: this._ROW,
            relationUUID: this._RELATIONUUID
        };
        return oTableWidget;
    }

    private getTableColumns(): string[] {
        const columnsAux: string[] = [];
        columnsAux.push('dispositivos');
        for (const dataType of this._DATATYPECOLLECTION) {
            columnsAux.push(dataType);
        }
        return columnsAux;
    }

    private getTableDataSource(): any[] {
        let counter = 0;
        const aux: any = [];
        // RECORREMOS LOS DISPOSITIVOS
        for (let deviceIndex = 0; deviceIndex < this._DEVICECOLLECTION.length; deviceIndex++) {
            const object: any = {};
            // AÑADIMOS AL OBJETO QUE HEMOS CREADO LA PROPIEDAD QUE RECOGERA LOS NOMBRES DE LOS DISPOSITIVOS A PINTAR
            object.dispositivos = this._DEVICECOLLECTION[deviceIndex];
            // RECORREMOS LOS TIPOS DE DATOS
            for (let dataTypeIndex = 0; dataTypeIndex < this._DATATYPECOLLECTION.length; dataTypeIndex++) {
                // VAMOS AÑADIENDO LOS TIPOS DE DATOS COMO NUEVAS PROPIEDADES AL OBJETO CREADO
                // Y LE AÑADIMOS LOS VALORES DE TABLA VALUE QUE VARIARAN SEGUN EL COUNTER
                // QUE VA SUMANDO LOS DATOS SEGUN LOS DISPOSITIVOS Y LOS TIPOS DE DATOS QUE TENGA
                object[this._DATATYPECOLLECTION[dataTypeIndex]] = 
                this._TABLEVALUES[counter + (this._DEVICECOLLECTION.length * dataTypeIndex)];
            }
            counter++;
            aux.push(object);
        }
        return aux;
    }
}
