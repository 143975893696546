import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface ComboPeriod {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent implements OnInit {

  @Output() selectionTimeRangeValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectionPeriodValue: EventEmitter<string> = new EventEmitter<string>();

  periodControl = new FormControl();
  hourControl = new FormControl();
  dayControl = new FormControl();
  weekControl = new FormControl();
  monthControl = new FormControl();

  periodCombo: ComboPeriod[] = [
    { value: 'Hour', viewValue: 'Hour' },
    { value: 'Day', viewValue: 'Day' },
    { value: 'Week', viewValue: 'Week' },
    { value: 'Month', viewValue: 'Month' }
  ];

  hourCombo: ComboPeriod[] = [
    { value: '8', viewValue: '8' },
    { value: '16', viewValue: '16' },
    { value: '24', viewValue: '24' }
  ];

  dayCombo: ComboPeriod[] = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' }
  ];

  weekCombo: ComboPeriod[] = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' }
  ];

  monthCombo: ComboPeriod[] = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' },
    { value: '8', viewValue: '8' },
    { value: '9', viewValue: '9' },
    { value: '10', viewValue: '10' },
    { value: '11', viewValue: '11' },
    { value: '12', viewValue: '12' }
  ];
  constructor() { }

  ngOnInit() {
  }

  selectionChangeTimeRangeValue($event) {
    this.selectionTimeRangeValue.emit($event.value);
  }

  selectionChangePeriodValue($event) {
    this.selectionPeriodValue.emit($event.value);
  }

}
