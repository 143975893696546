import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataTypeMapper } from 'src/app/models/datatype/data-type.mapper';
import { DataTypeService } from '../datatype/data-type.service';
import { DataTypeItem, DatatypeTreeHierarchi } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DataGroup } from "src/app/models/datatype/DataGroup";
import { map } from 'rxjs/operators';
import { DataTypeAggregateGraphQlResponse, DataTypeDetailAggregateGraphQlResponse, DataTypeGroupGraphQlResponse } from 'src/app/models/graphQL/data-type-aggegate-GQL.response';
import * as lodash from 'lodash';
import { DataType } from 'src/app/models/data-type.model';
import { DataTypeGroup } from 'src/app/models/data-type-aggregate.model';
import { ListItem, ListItemHierachi } from 'src/app/models/contract/list-item.model';


@Injectable({
  providedIn: 'root'
})
export class FakeServiceService {
  newDataTypeCollection: DatatypeTreeHierarchi = new DatatypeTreeHierarchi();
  constructor(private http: HttpClient) { }

  public getFactoriesData(): Observable<any> {
    return this.http.get('/assets/data/tablaLastMeasurements.json');
  }

  public getLanguagesData(): Observable<any> {
    return this.http.get('/assets/data/idiomas.json');
  }

  public getDispositivosData(): Observable<any> {
    return this.http.get('/assets/data/dispositivos.json');
  }

  public getDeviceLabelsMapping(): Observable<any> {
    return this.http.get('/assets/data/deviceLabelsMapping.json');
  }

  public getDataType(): Observable<DatatypeTreeHierarchi[]> {
     return this.http.get<DataTypeAggregateGraphQlResponse>('/assets/data/datatype.json').pipe(
      map(item =>{
        const allDataTypeReversedCollection =   item.tipoDatos.map(item => this.findRoot(item.agrupacion[0],DataTypeMapper.dataTypeDetailAggregateGQlResponseToDataTypeItem(item)));
        const allDataTypeGruped: DatatypeTreeHierarchi[]=[]
        allDataTypeReversedCollection.forEach(rootItem => 
          {
            const rootGrouped = allDataTypeGruped.firstOrDefault(item => item.root.uuid === rootItem.uuid);
            if(lodash.isEmpty(rootGrouped)){
              const newRoot:DatatypeTreeHierarchi = new DatatypeTreeHierarchi();
              newRoot.root = rootItem;
              allDataTypeGruped.push(newRoot);
            }else{
              rootGrouped.addChildren(rootItem.children);
            }
          });
        return allDataTypeGruped;
      }
    )); 
  }

  execTest() {
    this.getDataType().subscribe(result => {
  });
    }

  findRoot(arr: DataTypeGroupGraphQlResponse,children:DataGroup|DataTypeItem): DataGroup {
    if(arr === null || arr === undefined)
    {
      const orphan = Object.assign(new DataGroup(),{uuid:"-99",name:"tipo de dato huérfano"});
      orphan.addChild(children);
      return orphan;
    }
    const newDataType =  DataTypeMapper.dataTypeAggregateGQLResToDataGroup(arr);
    if (!lodash.isEmpty(arr.agrupacion)) {
      const parent :DataGroup =  this.findRoot(arr.agrupacion[0],newDataType);
      newDataType.addChild(children);
      return parent;
    }
    newDataType.addChild(children);
    return newDataType;
  }

}
