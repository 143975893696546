import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { TableWidget } from 'src/app/models/table-widget.model';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent implements OnInit, OnChanges {
  @Input() table: TableWidget;
  @Input() uuid: string;
  @Input() height: number;
  @Output() readyRender: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('divContainer', { static: false }) divContainer: ElementRef;

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.displayedColumns = this.table.dataTable.columns;
    this.dataSource = new MatTableDataSource(this.table.dataTable.dataSource);
  }

  ngOnChanges(change: SimpleChanges) {
    setTimeout(() => {
      this.animated(change);
      this.resizeDivContainerTable();
    }, 100);
  }

  resizeDivContainerTable() {
    if (this.divContainer !== undefined) {
      this.divContainer.nativeElement.style.height = this.calculateNewTableSize() + 'px';
      this.divContainer.nativeElement.children[0].style.height = this.height + 'px';
    }
  }

  calculateNewTableSize(): number {
   return this.height = this.height * 0.90;
  }

  animated(event) {
    this.readyRender.emit(this.uuid);
  }

}
