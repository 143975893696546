import { Component, OnInit, Input } from '@angular/core';
import { Alarm } from 'src/app/models/alarm/alarm.model';

@Component({
  selector: 'app-alarm-detail',
  templateUrl: './alarm-detail.component.html',
  styleUrls: ['./alarm-detail.component.scss']
})
export class AlarmDetailComponent implements OnInit {

  @Input() detailedAlarm: Alarm;

  constructor() { }

  ngOnInit() {
  }

}
