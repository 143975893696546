import { NgModule } from '@angular/core';
import { Apollo, ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloLink, split } from 'apollo-link';
import { environment } from 'src/environments/environment';
import { getOperationAST } from 'graphql';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { LoginService } from './services/login/login.service';
import { take } from 'rxjs/operators';


@NgModule({
  exports: [HttpClientModule, ApolloModule, HttpLinkModule],
})

export class GraphQLModule {
  constructor(apollo: Apollo,
              httpLink: HttpLink,
              private router: Router,
              private loginService: LoginService) {

    const uri = environment.graphQlHost;
    const http = httpLink.create({ uri });

    let miUsuarioSubscription: Subscription = new Subscription();

    // websocket instantiation
    const protocol = window.location.protocol.replace('http', 'ws');
    const host = window.location.host;
    const ws = new WebSocketLink({
      uri: `${protocol}//${host}/${environment.ws_graphql}`,
      options: {
        timeout: 60000,
        reconnect: true
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError) {
        const netError =  networkError as HttpErrorResponse;
        if (netError.status === 401){
          this.loginService.refreshToken().pipe(take(1)).subscribe(res => {
            miUsuarioSubscription.unsubscribe();
            if (Number(res['status']) === 400){
              this.router.navigate(['/login']);
            }else
            {
              miUsuarioSubscription = this.loginService.miUsuario().subscribe(user=>{
                this.loginService.setMiUsuario(user);
              })
            }
          });
        }
      }
    });


    // Creamos el split entre llamadas http y via websocket
    apollo.create({
      link: ApolloLink.split(
        operation => {
          const operationAST = getOperationAST(operation.query, operation.operationName);
          return !!operationAST && operationAST.operation === 'subscription';
        },
        ws,
        errorLink.concat(http),
      ),
      cache: new InMemoryCache()
    });
  }
}