import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface UpdateStateModel {
   uuid: string;
   enabled: boolean;
   usuario: string
   nombre: string;
   apellidos: string;
   zonaHoraria: string;
   idioma: string;
   mail: string;
   rol: string[];
}

export class ChangeUserStateBuilder {
    private _USERUUID: string;
    private _USERENABLED: boolean;
    private _USER: string;
    private _NAME: string;
    private _SURNAMES: string;
    private _TIMEZONE: string;
    private _LANGUAGE: string;
    private _MAIL: string;
    private _ROLES: string[];

    private get _mutation() {
        return gql`mutation updateUsuario($uuid: String!, $enabled: Boolean, $usuario: String, $nombre: String, $apellidos: String, $zonaHoraria: String, $idioma: String, $mail: String, $rol: [String]) {
            updateUsuario(uuid: $uuid, nombre: $nombre, apellidos: $apellidos, mail: $mail, idioma: $idioma, zonaHoraria: $zonaHoraria, usuario: $usuario, rol: $rol, enabled: $enabled) {
              uuid
              __typename
            }
          }
          `;
    }

    public withUserUUID(userUuid: string): this {
        this._USERUUID = userUuid;
        return this;
    }

    public withUserEnabled(userEnabled: boolean): this {
        this._USERENABLED = userEnabled;
        return this;
    }

    public withUser(usuario: string): this {
        this._USER = usuario;
        return this 
    }

    public withName(nombre: string): this {
        this._NAME = nombre;
        return this 
    }

    public withSurnames(apellidos: string): this {
        this._SURNAMES = apellidos;
        return this 
    }

    public withTimeZone(zonaHoraria: string): this {
        this._TIMEZONE = zonaHoraria;
        return this 
    }

    public withLanguage(idioma: string): this {
        this._LANGUAGE = idioma;
        return this 
    }

    public withEmail(mail: string): this {
        this._MAIL = mail;
        return this 
    }

    public withRoles(rol: string[]): this {
        this._ROLES = rol;
        return this 
    }
    
    
    public build(): MutationOptions {
        const mutationParams: UpdateStateModel  = { uuid: this._USERUUID, enabled: this._USERENABLED, usuario: this._USER, nombre: this._NAME, apellidos: this._SURNAMES, zonaHoraria: this._TIMEZONE, idioma: this._LANGUAGE, mail: this._MAIL, rol: this._ROLES  };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
