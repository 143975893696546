import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import gql from 'graphql-tag';
import {
  QueryReportFabrica,  QueryDato, createReportParam, deleteReportParam, updateReportParam,
  createReportFabrica, ReportItf, DatoUuid, IdeleteReportParam, IupdateReportParam} from './interfaces.consultas';
import { QueryReports } from '../interfaces.consultas';
import { ConditionalExpr } from '@angular/compiler';

  /// Empieza el programa


@Injectable()
export class GraphFuncionesFabrica{
    private subscrip: Subscription [] = [];

    constructor(
        private apollo: Apollo ) {
    }

    ///////////////
    // MUTATIONS //
    ///////////////

    
    // Crear REPORT
    crearReportFabrica(titulo, codigo, logo, idioma): Observable<any> {
      return this.apollo.mutate<DatoUuid>({
        mutation: createReportFabrica ,
        variables: {titulo,codigo,logo,idioma},
        refetchQueries: [{query: QueryReports}]});
    }
   
    updateReportFabrica(nuuid, titulo, codigo, logo, idioma): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          datos:   updateReportFabrica(uuid: "${nuuid}", titulo: "${titulo}", codigo: "${codigo}", logo: "${logo}", idioma: "${idioma}") {
              uuid
          }
        }`,
      refetchQueries: [{query: QueryReports},{query: QueryReportFabrica, variables: {uuid: nuuid}}]});
    }
    // Crear ReportRow
    crearReportRow(report, titulo, orden, unidadT, unidadK): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: createReportRow(titulo: "${titulo}", orden: ${orden}, unidadT: "${unidadT}", unidadK: "${unidadK}")  {
            uuid
          }
        }`,  
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crear Report --> ReportRow
    crearInludeRRW(report, reportrow): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: createIncludesRW(report: "${report}", reportRow: "${reportrow}")
        }`,
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Borra enlace Report --> ReportRow
    borrarIncludeRRW(report,reportrow): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteIncludesRW(report: "${report}", reportRow: "${reportrow}")
        }`,
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Update ReportRow
    updateReportRow(report, uuid, titulo, orden, unidadT, unidadK): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: updateReportRow(uuid: "${uuid}", titulo: "${titulo}", orden: ${orden}, unidadT: "${unidadT}", unidadK: "${unidadK}") {
            uuid
          }
        }`,
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Borra  ReportRow
    borrarReportRow(report, reportrow): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteReportRow(reportRow: "${reportrow}")
        }`,   
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Borra [ReportRowParam] ReportRow --> Dato
    borrarReportRowParam(report, reportrow, dato): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteReportRowParam(reportRow: "${reportrow}", dato: "${dato}" )
        }`, 
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crea [ReportRowParam] ReportRow --> Dato
    crearReportRowParam(report, reportrow, dato): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: createReportRowParam(reportRow: "${reportrow}", dato: "${dato}" )
        }`,
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Update REPORTPARAM
    updateReportParam(report, uuid, codigo, periodo, unidadT, unidadK, tipo): Observable<any> {
      return this.apollo.mutate<IupdateReportParam>({
        mutation: updateReportParam,
        variables: {uuid, codigo, periodo, unidadT, unidadK, tipo},
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crear REPORTPARAM
    crearReportParam(report, dato, codigo, periodo, unidadT, unidadK, tipo): Observable<any> {
      return this.apollo.mutate<DatoUuid>({
        mutation: createReportParam,
        variables: {report, dato, codigo, periodo, unidadT, unidadK, tipo},
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report} }]});
    }
    // Borrar REPORTPARAM
    borrarReportParam(report,puuid: string): Observable<any> {
      return this.apollo.mutate<IdeleteReportParam>({
        mutation: deleteReportParam,
        variables: {puuid},
        refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crear REPORTTABLE
    createReportTable(report, titulo, consumoTitulo, consumoK, itemTitulo, itemK, ratioTitulo, ratiomedioTitulo): Observable<any> {
      return this.apollo.mutate({
     mutation : gql `mutation MyMutation {
      __typename
      datos: createReportTable(titulo: "${titulo}", consumoTitulo: "${consumoTitulo}", consumoK: "${consumoK}", itemTitulo: "${itemTitulo}",
       itemK: "${itemK}", ratioTitulo: "${ratioTitulo}", ratiomedioTitulo: "${ratiomedioTitulo}") {
        uuid
      }
    }`,
     refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Editar REPORTTABLE
    updateReportTable(report, uuid, titulo, consumoTitulo, consumoK, itemTitulo, itemK, ratioTitulo, ratiomedioTitulo): Observable<any> {
       return this.apollo.mutate({
      mutation : gql `mutation MyMutation {
        __typename
        datos: UpdateReportTable(uuid: "${uuid}", titulo: "${titulo}", consumoTitulo: "${consumoTitulo}", consumoK: "${consumoK}",
                                 itemTitulo: "${itemTitulo}",itemK: "${itemK}", ratioTitulo: "${ratioTitulo}",
                                 ratiomedioTitulo: "${ratiomedioTitulo}") {
          uuid
        }
      }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crear enlace Report [INCLUDES] ReportTable
    crearReportReportTableInclude(report, reportTable): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: createIncludesRT(report: "${report}", reportTable: "${reportTable}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report} }]});
    }
    // borra enlace Report [INCLUDES] ReportTable
    borraReportReportTableInclude(report, reportTable): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteIncludesRT(report: "${report}", reportTable: "${reportTable}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report} }]
      });
    }
    // Editar ReportTableParam Dis
    updateReportReportTableParamDis(report, puuid, codigo): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: updateReportTableParamDis(puuid: "${puuid}", codigo: "${codigo}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Editar ReportTableParam TipoDato
    updateReportReportTableParamTD(report, puuid, codigo): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: updateReportTableParamTD(puuid: "${puuid}", codigo: "${codigo}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Editar ReportTableParam Dis
    borrarReportReportTableParamDis(report, puuid): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteReportTableParamDis(puuid: "${puuid}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Editar ReportTableParam TipoDato
    borrarReportReportTableParamTD(report, puuid,): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteReportTableParamTD(puuid: "${puuid}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // borra ReportTable
    borraReportReportTable(report, reportTable): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: deleteReportTable(report: "${reportTable}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crear ReportTableDispositivo
    crearReportReportTableDis(report, reportTable, dsp, cod): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: createReportTableParamDSP(reportTable: "${reportTable}", dsp: "${dsp}", cod: "${cod}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }
    // Crear reporTableTipoDato
    crearReportReportTableTD(report, reportTable, tipoDato, codigo): Observable<any> {
      return this.apollo.mutate({
        mutation : gql `mutation MyMutation {
          __typename
          datos: createReportTableParamTD(reportTable: "${reportTable}", tipoDato: "${tipoDato}", codigo: "${codigo}")
        }`, refetchQueries: [{query: QueryReportFabrica, variables: {uuid: report}}]});
    }

    ////////////
    // QUERYS //
    ////////////

    // Cargar un Dato
    dameDato(dato: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: QueryDato,
      variables: {uuid: dato}
      }).valueChanges;
    }

    // Cargar toda la estructura del report
    cargarReportFabrica(ruuid: string): Observable<any> {
      return this.apollo.watchQuery<any>({
          query: QueryReportFabrica,
          variables: {uuid: ruuid}}).valueChanges;
  }

    // Estrutura del Report
    // Partiendo de a consulta a grahpQl
    obtenerDatosReportFabrica(rp: any) {
      const tmp: ReportItf = {
        uuid: rp.uuid,
        titulo: rp.titulo,
        codigo: rp.codigo,
        logo: rp.logo,
        idioma: rp.idioma,
        params: [],
        rows: [],
        tables: []
      };
      rp.params.map( p => tmp.params.push({ // REPORTPARAM
        uuid: p.uuid,
        codigo: p.codigo,
        tipo: p.tipo,
        periodo: p.periodo,
        unidadT: p.unidadT,
        unidadK: p.unidadK,
        dato: {
          uuid: p.to.uuid,
          periodo: p.to.periodo,
          dispositivo: {
            uuid: p.to.tiene.uuid,
            nombre: p.to.tiene.nombre,
          },
          tipoDato: {
            uuid: p.to.es.uuid,
            nombre: p.to.es.nombre,
            unidad: p.to.es.unidad
          }
        }
      }));
      rp.reportRows.map(r => {   // REPORTROW
          const dato = [];
          r.to.params.map(d => dato.push({
            uuid: d.to.uuid,
            periodo: d.to.periodo,
            dispositivo: {
              uuid: d.to.tiene.uuid,
              nombre: d.to.tiene.nombre,
            },
            tipoDato: {
              uuid: d.to.es.uuid,
              nombre: d.to.es.nombre,
              unidad: d.to.es.unidad
            }
          }));
          tmp.rows.push({
            uuid_inc: r.uuid,
            uuid: r.to.uuid,
            titulo: r.to.titulo,
            orden: r.to.orden,
            unidadT: r.to.unidadT,
            unidadK: r.to.unidadK,
            dato
        });
      });
      rp.reportTables.map(t => {  // REPORTABLE
        const dispositivos = [];
        t.to.dispositivos.map(ds => dispositivos.push({
          uuid: ds.uuid,
          codigo: ds.codigo,
          dispositivo: {
            uuid: ds.to.uuid,
            nombre: ds.to.nombre
          }
        }));
        const tipoDatos = [];
        t.to.tipoDatos.map(ds => tipoDatos.push({
          uuid: ds.uuid,
          codigo: ds.codigo,
          tipoDatos: {
            uuid: ds.to.uuid,
            nombre: ds.to.nombre,
            unidad: ds.to.unidad
          }
        }));
        tmp.tables.push({
          uuid_inc: t.uuid,
          uuid: t.to.uuid,
          titulo: t.to.titulo,
          consumoTitulo: t.to.consumoTitulo,
          consumoK: t.to.consumoK,
          itemTitulo: t.to.itemTitulo,
          itemK: t.to.itemK,
          ratioTitulo: t.to.ratioTitulo,
          ratiomedioTitulo: t.to.ratiomedioTitulo,
          dispositivos,
          tipoDatos  
        });
      });
      return {report: tmp, loading: false};
    }
}
