import { AlarmSupervisa, AlarmTypeResponse, AlarmState } from '../alarm.model';
import { DeviceGraphItem, DeviceGraphDataType } from '../../device-graph.model';
import { AlarmType } from '../../alarmType.model';

export class AlarmMapper {

    public static toDevicesFromAlarmResponse(alarmSupervisa: AlarmSupervisa[]): DeviceGraphItem[] {
        const deviceCollection: DeviceGraphItem[] = [];
        for (const supervisa of alarmSupervisa) {
            const newDevice = new DeviceGraphItem();
            const newDataType = new DeviceGraphDataType();

            newDataType.uuid = supervisa.Dato.es.uuid;
            newDataType.name = supervisa.Dato.es.nombre;
            newDataType.unity = supervisa.Dato.es.unidad;

            newDevice.uuid = supervisa.Dato.tiene.uuid;
            newDevice.name = supervisa.Dato.tiene.nombre;
            newDevice.dataType.push(newDataType);

            deviceCollection.push(newDevice);
        }
        return deviceCollection;
    }

    public static getStateFromResponse(alarmSupervisa: AlarmSupervisa[]): AlarmState {
        let desactivatedAlarmCounter = 0;
        for (const supervisa of alarmSupervisa) {
            if (supervisa.state === 'Alarmada') {
                return AlarmState.alarmed;
            }
            if (supervisa.state === 'OK' || supervisa.state == null || supervisa.state === 'Activada') {
                supervisa.state = 'Activada';
                return AlarmState.activated;
            } else if (supervisa.state === 'Desactivada') {
                desactivatedAlarmCounter++;
                if (desactivatedAlarmCounter === alarmSupervisa.length) {
                    return AlarmState.desactivated;
                }
            }
        }
    }

    public static toAlarmType(alarmType: AlarmTypeResponse): AlarmType {
        const newAlarmType: AlarmType = new AlarmType();
        newAlarmType.uuid = alarmType.uuid;
        newAlarmType.code = alarmType.codigo;
        newAlarmType.name = alarmType.nombre;

        return newAlarmType;
    }

    public static getUuidFromSupervisa(alarmSupervisa: AlarmSupervisa[]): string[] {
        const uuidSupervisaCollection: string[] = [];
        for (const supervisa of alarmSupervisa) {
            uuidSupervisaCollection.push(supervisa.uuid);
        }
        return uuidSupervisaCollection;
    }

}
