export interface ListItem{
    uuid:string;
    name:string;    
}

export enum State {
    selected = "selected",
    selectable = "selectable",
    notSelectable = "notSelectable"
}

export interface ListItemSelectable extends ListItem{
    selectedState?: State;
}


export interface ListItemHierachi extends ListItem{
    parent?: ListItemHierachi;
    children?: ListItemHierachi[];
    isLeaf():boolean;
    hasDescendentById(uuid:String):boolean;
    hasAnyDescendentById(uuid:String[]);
    
}

