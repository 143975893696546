import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface EditAlarmModel {
   uuid: string;
   nombre: string;
   descripcion: string;
   subject: string;
   body: string;
}

export class EditAlarmBuilder {
    private _ALARMUUID: string;
    private _ALARMNAME: string;
    private _ALARMDESCRIPTION: string;
    private _ALARMSUBJECT: string;
    private _ALARMBODY: string;

    private get _mutation() {
        return gql`mutation actualizarAlarma($uuid: ID!, $nombre: String, $descripcion: String, $subject: String, $body: String) {
            updateAlarma(uuid: $uuid, nombre: $nombre, descripcion: $descripcion, subject: $subject, body: $body) {
              uuid
            }
          }`;
    }

    public withUUID(uuid: string): this {
        this._ALARMUUID = uuid;
        return this;
    }

    public withName(uuid: string): this {
        this._ALARMNAME = uuid;
        return this;
    }

    public withDescription(uuid: string): this {
        this._ALARMDESCRIPTION = uuid;
        return this;
    }

    public withSubject(uuid: string): this {
        this._ALARMSUBJECT = uuid;
        return this;
    }

    public withBody(uuid: string): this {
        this._ALARMBODY = uuid;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: EditAlarmModel  = {
            nombre: this._ALARMNAME,
            uuid: this._ALARMUUID,
            descripcion: this._ALARMDESCRIPTION,
            body: this._ALARMBODY,
            subject: this._ALARMSUBJECT
        };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
