import { Injectable } from '@angular/core';
import { Alarm, AlarmSupervisa, AlarmParams } from 'src/app/models/alarm/alarm.model';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Budget } from 'src/app/models/budget.model';
import { AlarmType } from 'src/app/models/alarmType.model';
import { AlarmTypeBuilder } from 'src/app/models/graphQL/alarm-type-graphQL.request';
import { QueryOptions, MutationOptions } from 'apollo-client';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import * as _ from 'lodash';
import { AlarmTypeResponseGraphQL } from 'src/app/models/graphQL/alarm-type-graphQL.response';
import { AlarmByUserBuilder } from 'src/app/models/graphQL/alarmByUser-graphQL.request';
import { AlarmResponseGraphQL } from 'src/app/models/graphQL/alarmByUser-graphQL.response';
import { AlarmMapper } from 'src/app/models/alarm/mapper/alarmMapper';
import { DeleteAlarmBuilder } from 'src/app/models/graphQL/mutations/deleteAlarm.mutation';
import { CreateAlarmBuilder } from 'src/app/models/graphQL/mutations/createAlarm.mutation';
import { AddAlarmaInformaBuilder } from 'src/app/models/graphQL/mutations/addAlarmaInforma.mutation';
import { AlarmaParametroBuilder } from 'src/app/models/graphQL/mutations/addAlarmaParametro.mutation';
import { FindDatoFromDeviceAndDataTypeBuilder } from 'src/app/models/graphQL/datoFromDeviceAndDatatype.request';
import { FindDatoFromDeviceAndDataTypeResponseGraphQL } from 'src/app/models/graphQL/datoFromDeviceAndDataType.response';
import { AlarmaSupervisaBuilder } from 'src/app/models/graphQL/mutations/addAlarmaSupervisa.mutation';
import { ChangeAlarmStateBuilder } from 'src/app/models/graphQL/mutations/changeStateAlarm.mutation';
import { EditAlarmBuilder } from 'src/app/models/graphQL/mutations/EditAlarm.mutation';
import { EditAlarmValueBuilder } from 'src/app/models/graphQL/mutations/EditAlarmValue.mutation';
import { LimitInfluxParams } from 'src/app/models/limitInfluxParams.model';

export interface AlarmIntervalValue {
  value: number;
}

@Injectable({
  providedIn: 'root'
})

export class AlarmService {

  private alarmCollectionBS: BehaviorSubject<Alarm[]>;
  public alarmCollectionObservable: Observable<Alarm[]>;
  private budgetCollectionBS: BehaviorSubject<Budget[]>;
  public budgetCollectionObservable: Observable<Budget[]>;

  constructor(
    private apolloService: ApolloGraphQlService,
    private http: HttpClient) {
    this.alarmCollectionBS = new BehaviorSubject<Alarm[]>(new Array<Alarm>());
    this.alarmCollectionObservable = this.alarmCollectionBS.asObservable();
    this.budgetCollectionBS = new BehaviorSubject<Budget[]>(new Array<Budget>());
    this.budgetCollectionObservable = this.budgetCollectionBS.asObservable();
  }

  public getAlarmCollectionByUser(userUUID: string): Observable<Alarm[]> {
    const queryOptions: QueryOptions = new AlarmByUserBuilder()
      .withUserUUID(userUUID)
      .build();
    const alarmCollection = this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toAlarmResponse);
    if (!_.isEmpty(alarmCollection)) {
      alarmCollection.subscribe(item => { this.alarmCollectionBS.next(item); });
      return alarmCollection;
    }
  }

  public createAlarm(newAlarm: Alarm) {
    const mutationOptions: MutationOptions = new CreateAlarmBuilder()
      .withAlarmName(newAlarm.name)
      .withAlarmDescription(newAlarm.description)
      .withAlarmBody(newAlarm.body)
      .withAlarmSubject(newAlarm.subject)
      .withAlarmTypeUuid(newAlarm.type.uuid)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public getDatoUuid(deviceUuid: string, dataTypeUuid: string) {
    const queryOptions: QueryOptions = new FindDatoFromDeviceAndDataTypeBuilder()
      .withDeviceUUID(deviceUuid)
      .withDataTypeUUID(dataTypeUuid)
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toLimitParam);
  }

  public changeAlarmState(alarmUuid: string, newState: string) {
    const mutationOptions: MutationOptions = new ChangeAlarmStateBuilder()
      .withAlarmUUID(alarmUuid)
      .withState(newState)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  private toLimitParam(responseFromGraphQL: FindDatoFromDeviceAndDataTypeResponseGraphQL): LimitInfluxParams {
    let newLimitInfluxParams: LimitInfluxParams;
    if (!_.isEmpty(responseFromGraphQL.Dato)) {
      newLimitInfluxParams = {
      uuid: responseFromGraphQL.Dato[0].uuid,
      ptop: responseFromGraphQL.Dato[0].stats_ptop,
      pbottom: responseFromGraphQL.Dato[0].stats_pbottom
    };
    }
    return newLimitInfluxParams;
  }

  public addAlarmaInforma(alarmUUID: string, userUUID: string) {
    const mutationOptions: MutationOptions = new AddAlarmaInformaBuilder()
      .withAlarmUuid(alarmUUID)
      .withUserUuid(userUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public addAlarmaParametro(alarmUUID: string, clave: string, valor: string) {
    const mutationOptions: MutationOptions = new AlarmaParametroBuilder()
      .withAlarmUuid(alarmUUID)
      .withClave(clave)
      .withValor(valor)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public addAlarmaSupervisa(alarmUUID: string, datoUUID: string) {
    const mutationOptions: MutationOptions = new AlarmaSupervisaBuilder()
      .withAlarmUuid(alarmUUID)
      .withDatoUuid(datoUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public deleteAlarm(alarmUUID: string) {
    const mutationOptions: MutationOptions = new DeleteAlarmBuilder()
      .withAlarmUUID(alarmUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public getBudgetCollection(): Observable<Budget[]> {
    this.alarmCollectionObservable.subscribe(data => {
      let budgetCollection: Budget[] = [];
      budgetCollection = [
        { uuid: '1', name: 'ALARM.TOTAL', value: data.length, selected: true },
        { uuid: '2', name: 'ALARM.ACTIVATED', value: data.filter(item => item.state === 'Activada').length, selected: false },
        { uuid: '3', name: 'ALARM.DEACTIVATED', value: data.filter(item => item.state === 'Desactivada').length, selected: false },
        { uuid: '4', name: 'ALARM.ALARMED', value: data.filter(item => item.state === 'Alarmada').length, selected: false }
      ];
      this.budgetCollectionBS.next(budgetCollection);
    });
    return this.budgetCollectionObservable;
  }

  public updateAlarmCollection(newAlarmCollection: Alarm[]) {
    this.alarmCollectionBS.next(newAlarmCollection);
  }

  public updateBudgetCollection(newBudgetCollection: Budget[]) {
    this.budgetCollectionBS.next(newBudgetCollection);
  }

  getAllAlarmTypes(): Observable<Array<AlarmType>> {
    const queryOptions: QueryOptions = new AlarmTypeBuilder()
      .build();
    const allAlarmTypeCollection = this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toAlarmTypeResponse);
    if (!_.isEmpty(allAlarmTypeCollection)) {
      return allAlarmTypeCollection;
    }
  }

  private toAlarmResponse(alarmFromGraphQLCollection: AlarmResponseGraphQL): Array<Alarm> {
    const newAlarmCollection: Alarm[] = [];
    for (const alarmResponse of alarmFromGraphQLCollection.listadoAlarmas) {
      const newAlarm = new Alarm();
      newAlarm.uuid = alarmResponse.uuid;
      newAlarm.name = alarmResponse.nombre;
      newAlarm.subject = alarmResponse.subject;
      newAlarm.body = alarmResponse.body;
      newAlarm.description = alarmResponse.descripcion;
      newAlarm.type = AlarmMapper.toAlarmType(alarmResponse.tipo);
      newAlarm.state = AlarmMapper.getStateFromResponse(alarmResponse.supervisa);
      newAlarm.param = new AlarmParams();
      newAlarm.param.uuid = alarmResponse.parametros[0].uuid;
      newAlarm.param.clave = alarmResponse.parametros[0].clave;
      newAlarm.param.valor = alarmResponse.parametros[0].valor;
      newAlarm.devices = AlarmMapper.toDevicesFromAlarmResponse(alarmResponse.supervisa);
      newAlarm.supervisa = AlarmMapper.getUuidFromSupervisa(alarmResponse.supervisa);
      newAlarmCollection.push(newAlarm);
    }
    return newAlarmCollection;
  }


  private toAlarmTypeResponse(alarmTypeFromGraphQL: AlarmTypeResponseGraphQL): Array<AlarmType> {
    const auxAlarmTypeCollection: AlarmType[] = [];
    for (const alarm of alarmTypeFromGraphQL.tipoAlarmas) {
      const newAlarmType = new AlarmType();
      newAlarmType.uuid = alarm.uuid;
      newAlarmType.code = alarm.codigo;
      newAlarmType.name = alarm.nombre;
      auxAlarmTypeCollection.push(newAlarmType);
    }
    return auxAlarmTypeCollection;
  }

  public updatedAlarm(alarmToUpdate: Alarm) {
    const mutationOptions: MutationOptions = new EditAlarmBuilder()
      .withUUID(alarmToUpdate.uuid)
      .withName(alarmToUpdate.name)
      .withSubject(alarmToUpdate.subject)
      .withBody(alarmToUpdate.body)
      .withDescription(alarmToUpdate.description)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public updatedAlarmValue(alarmToUpdate: Alarm) {
    const mutationOptions: MutationOptions = new EditAlarmValueBuilder()
      .withUUIDParametro(alarmToUpdate.param.uuid)
      .withClaveActualizada(alarmToUpdate.type.code)
      .withValorActualizado(alarmToUpdate.param.valor.toString())
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  getIntervalAlarmFetch(): Observable<AlarmIntervalValue> {
    return this.http.get<AlarmIntervalValue>('/assets/data/intervalAlarmFetch.json');
  }

}
