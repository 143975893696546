import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss']
})
export class DeviceStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
