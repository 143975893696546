import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ContentComponent } from '../../sections/dashboard/content/content.component';
import { MaterialModule } from '../../module/material/material.module';
import { BarChartComponent } from 'src/app/components/charts/bar-chart/bar-chart.component';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarDashboardComponent } from 'src/app/sections/dashboard/content/sidebar-dashboard/sidebar-dashboard.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GridsterModule } from 'angular-gridster2';
//TRANSLATE
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { GaueChartComponent } from 'src/app/components/widgets/gauge/gaue-chart/gaue-chart.component';
import { WidgetChartsComponent } from 'src/app/components/widgets/charts/widget-charts/widget-charts.component';
import { WidgetPieChartComponent } from 'src/app/components/widgets/charts/widget-pie-chart/widget-pie-chart.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { GridStackModule } from 'src/app/ext-tools/projects/grid-stack/src/public_api';
@NgModule({
  declarations: [
    ContentComponent,
    BarChartComponent,
    SidebarDashboardComponent,
    GaueChartComponent,
    WidgetChartsComponent,
    WidgetPieChartComponent

  ],
  imports: [
    CommonModule,
    GridsterModule,
    GridStackModule,
    GaugeChartModule,
    DashboardRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModuleModule,
    MaterialModule,
    ChartsModule,
    HttpClientModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule

  ],
  exports: [
    ContentComponent,
    BarChartComponent,
    SidebarDashboardComponent,
    GaueChartComponent,
    WidgetChartsComponent,
    WidgetPieChartComponent


  ]
})
export class DashboardModule { }


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}