import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { LoginService } from 'src/app/services/login/login.service';
@Pipe({
    name: 'getDatePipe',
    pure: true
})
export class GetDatePipe implements PipeTransform {

    constructor(private loginService: LoginService) {}
    // date: Date;
    transform(value: number, args?: any): any {
        return this.getDatePipe(value)
    }
    getDatePipe(date: number): String {
        const timezone = this.loginService.currentUserValue.timeZone
        if (date != null) {
            if (date.toString().length == 11) {
                const formateado = moment.unix(date).format('DD-MM-YYYY HH:mm:ss');
                return formateado;

            } else if (date.toString().length == 13) {
                const formateado = moment.unix(date / Math.pow(10,3)).tz(timezone).format('DD-MM-YYYY HH:mm:ss');
                return formateado;

            } else if (date.toString().length == 16) {
                const formateado = moment.unix(date / Math.pow(10,6)).tz(timezone).format('DD-MM-YYYY HH:mm:ss');
                return formateado;

            } else if (date.toString().length == 19) {
                const formateado = moment.unix(date / Math.pow(10,9)).tz(timezone).format('DD-MM-YYYY HH:mm:ss');
                return formateado;

            } else {
                return 'Formato no reconocido'
            }
        } else {
            return 'Sin fecha registrada';
        }
    }
    getInvalidatedDatePipe(invalidated: number): String {
        if (invalidated != null) {
            return new Date(invalidated / 1000000).toISOString();
        } else {
            return 'Sin invalidar'
        }

    }
}
