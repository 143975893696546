import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphicsRoutingModule } from './graphics-routing.module';
import { SideGraphsComponent } from 'src/app/components/side-graphs/side-graphs.component';
import { MaterialModule } from '../material/material.module';
import { FormFilterComponent } from 'src/app/components/form-filter/form-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangeSliderComponent } from 'src/app/components/form-filter/range-slider/range-slider.component';
import { Ng5SliderModule } from 'ng5-slider';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import {  MomentDateAdapter } from '@angular/material-moment-adapter';
import { DashboardModule } from '../dashboard/dashboard.module';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperComponent } from 'src/app/components/stepper/stepper.component';
import { DispotreeComponent } from 'src/app/components/form-filter/dispotree/dispotree.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgChartGeneratorComponent } from 'src/app/components/ng-chart-generator/ng-chart-generator.component';

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// create our const var with the information about the format that we want

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    SideGraphsComponent,
    FormFilterComponent,
    RangeSliderComponent,
    StepperComponent,
    DispotreeComponent,
    NgChartGeneratorComponent


  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  imports: [
    CommonModule,
    GraphicsRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    AngularFontAwesomeModule,
    FontAwesomeModule,
    DashboardModule,
    SharedModuleModule,
    ColorPickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    SideGraphsComponent,
    FormFilterComponent,
    RangeSliderComponent,
    MaterialModule,
    StepperComponent,
    DispotreeComponent,
    NgChartGeneratorComponent

  ],
  entryComponents: [FormFilterComponent],
})
export class GraphicsModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
