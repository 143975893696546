import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-fab-button-close',
  templateUrl: './fab-button-close.component.html',
  styleUrls: ['./fab-button-close.component.scss']
})
export class FabButtonCloseComponent implements OnInit {

  private _fixed = false;
  public open = false;
  public spin = true;
  public direction = 'up';
  public animationMode = 'scale';

  @Input() button1: string;
  @Input() button2: string;
  @Input() button3: string;
  @Input() generated: boolean;

  @Output() eraseEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() createEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() createWidgetEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  get fixed(): boolean {
    return this._fixed;
  }

  set fixed(fixed: boolean) {
    this._fixed = fixed;
    if (this._fixed) {
      this.open = true;
    }
  }

  public stopPropagation(event: Event): void {
    // Prevent the click to propagate to document and trigger
    // the FAB to be closed automatically right before we toggle it ourselves
    event.stopPropagation();
  }

  eraseAction() {
    this.eraseEmitter.emit(true);
  }
  createAction() {
    this.createEmitter.emit(true);
  }
  createWidget() {
    this.createWidgetEmitter.emit(true);
  }

  isDashboard() {
    if (this.button2 !== undefined) {
      return true;
    }
    return false;
  }

}
