import { Component, OnInit, ViewChild, EventEmitter, Output, OnChanges, Input } from '@angular/core';
import { MatCalendar } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TimezoneService } from 'src/app/services/timezone.service';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Moment>;
  @ViewChild('calendar1', { static: false }) calendar1: MatCalendar<Moment>;

  @Output() fromEmitted: EventEmitter<Moment> = new EventEmitter<Moment>();
  @Output() toEmitted: EventEmitter<Moment> = new EventEmitter<Moment>();
  @Input() reset: boolean;
  selectedDateFrom: Moment;
  selectedDateTo: Moment;
  locale: string;
  convertedDate: Moment;
  today = moment();
  public formCalendar: FormGroup;
  constructor(
    private fb: FormBuilder,
    private timeZoneService: TimezoneService) {
    this.formCalendar = fb.group({
      fromCalendar: [''],
      toCalendar: ['']
    });
  }

  setDefaultDates() {
    this.selectedDateFrom = moment().subtract(1, 'days').startOf('day');
    this.selectedDateTo = moment();
    this.calendar = null;
    this.calendar1 = null;
    this.fromEmitted.emit(this.selectedDateFrom);
    this.toEmitted.emit(this.selectedDateTo);
  }

  ngOnInit() {
    this.setDefaultDates();
    this.timeZoneService.currentTimeZone$.subscribe((data: string) => {
      this.locale = data;
      this.convertedDate = moment().subtract(1, 'hour');
    });
  }
  ngOnChanges() {
    if (this.reset) {
      this.setDefaultDates();
    }
  }
  emitFrom($event) {
    this.fromEmitted.emit(moment($event));
  }
  emitTo($event) {
    this.toEmitted.emit(moment($event).endOf('day'));
  }
  directDate(date) {
    switch (date) {
      case 'today':
        this.selectedDateFrom = moment().startOf('day');
        this.selectedDateTo = moment();
        this.calendar._goToDateInView(moment(), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        this.fromEmitted.emit(moment(this.selectedDateFrom));
        this.toEmitted.emit(moment(this.selectedDateTo));
        break;
      case 'yesterday':
        this.selectedDateFrom = moment().subtract(1, 'days').startOf('day');
        this.selectedDateTo = moment();
        this.calendar._goToDateInView(moment().subtract(1, 'days'), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        this.fromEmitted.emit(moment(this.selectedDateFrom));
        this.toEmitted.emit(moment(this.selectedDateTo));
        break;
      case 'lastWeek':
        this.selectedDateFrom = moment().subtract(7, 'days').startOf('day');
        this.selectedDateTo = moment();
        this.calendar._goToDateInView(moment().subtract(7, 'days'), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        this.fromEmitted.emit(moment(this.selectedDateFrom));
        this.toEmitted.emit(moment(this.selectedDateTo));
        break;
      case 'lastMonth':
        this.selectedDateFrom = moment().subtract(1, 'month').startOf('day');
        this.selectedDateTo = moment();
        this.calendar._goToDateInView(moment().subtract(1, 'month'), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        this.fromEmitted.emit(moment(this.selectedDateFrom));
        this.toEmitted.emit(moment(this.selectedDateTo));
        break;
    }
  }


}
