import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GeoDevicesService, LastDataDevice } from 'src/app/services/mapa/geodevices.service';
import { DeviceModel } from 'src/app/models/device.model';
import { GetDatePipe } from 'src/app/pipes/pipes/pipe-date.pipe';
import { TimezoneService } from 'src/app/services/timezone.service';
import { LoginService } from 'src/app/services/login/login.service';


export interface LastDataModel {
  uuid?: string;
  nombreDispositivo: string;
  mediciones: Medicion[];
  medicionesAgrupadas: any;
  medicionesTable: (Medicion)[];
  medicionesTableFiltered: (Medicion)[];
  children: LastDataModel[];
  childrenRequest?: Observable<DeviceModel[]>
}

export interface Medicion {
  nombre: string;
  stats_last: number;
  lastFecha: string;
  lastFechaEpoch: number;
  unidad: string;
  codigo: string;
  nombreCode: string;
  descripcion: string;
  // agrupacion: {
  //   uuid: string;
  //   nombre: string;
  //   nombreCode: string;
  // }
}

export interface GroupBy {
  codigo: string;
  nombreCode: string;
  isGroupBy: boolean;
}

export interface MedicionesTable {
  Nombre: string;
  Medidas: any[];
}

@Component({
  selector: 'app-device-datos',
  templateUrl: './device-datos.component.html',
  styleUrls: ['./device-datos.component.scss'],
  providers: [
    GetDatePipe
],
})
export class DeviceDatosComponent implements OnInit {

  @Input() loading: boolean;
  @Input() deviceId: string = "";
  @Input() isModal = true;
  @Input() lastDataDeviceMapped: LastDataModel = null;
  @Output() hideEventEmitter = new EventEmitter<boolean>();

  displayedColumns: string[] = ['nombre', 'lastFecha','stats_last', 'unidad'];
  dataSource: LastDataDevice[] = [];

  lastDataDeviceMapped$: Observable<LastDataModel>;
  lastDataDeviceMapa: LastDataModel;
  // lastDataDevice$: Observable<LastDataDevice>;
  loadingText = 'LOADING.DEVICES';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { device: string, selectedDataTypes: string[] },
    private geoDevicesService: GeoDevicesService,
    private getDatePipe: GetDatePipe,
    private timeZoneService: TimezoneService,
    private loginService: LoginService,
    private datePipe: GetDatePipe
  ) { }

  ngOnInit() {
    //IsModal = true se abre al invocarlo desde la pantalla de mapa  

    if (this.isModal && this.data.device !== "") {
      this.lastDataDeviceMapped$ = this.geoDevicesService.getLastDataDispositivo(this.data.device).pipe(
        map(lastData => {
          let lastDataMapped: LastDataDevice;
          if (this.data.selectedDataTypes.length > 0){
            lastDataMapped = {
              nombre: lastData.nombre,
              labels: lastData.labels,
              uuid: lastData.uuid,
              estructura: lastData.estructura,
              tiene: lastData.tiene.filter(tiene => this.data.selectedDataTypes.includes(tiene.to.es.uuid))
            }
          } else {
              lastDataMapped = {
                nombre: lastData.nombre,
                labels: lastData.labels,
                uuid: lastData.uuid,
                estructura: lastData.estructura,
                tiene: lastData.tiene
            }
          }
          return lastDataMapped;
        }),
        map(device=> {
          let lastDataMapped: LastDataModel = {
            uuid: device.uuid,
            nombreDispositivo: device.nombre,
              mediciones: _.flattenDeep(device.tiene.map(tiene=> {
                let dato: Medicion;
                let lastFecha, lastFechaEpoch
                if (!_.isNil(tiene.to) && !_.isNil(tiene.to.lastFecha)){
                  lastFecha = this.datePipe.getDatePipe(parseFloat(tiene.to.lastFecha))
                  lastFechaEpoch = tiene.to.lastFecha;
                }
                return dato = {nombre: tiene.to.es.nombre,
                  stats_last: !_.isNil(tiene.to.lastValor)?  Number(tiene.to.lastValor) :  !_.isNil(tiene.to.stats_last)? Number(tiene.to.stats_last): null,
                  lastFecha: !_.isNil(tiene.to.lastFecha)?  lastFecha  : null,
                  lastFechaEpoch: !_.isNil(tiene.to.lastFecha)?  lastFechaEpoch  : null,
                  // lastFecha: !_.isNil(tiene.to.lastEntrada)?  tiene.to.lastEntrada.lastFecha: "",
                  codigo: tiene.to.es.codigo,
                  unidad: tiene.to.es.unidad,
                  nombreCode: !_.isNil(tiene.to.es.codigo) ? tiene.to.es.codigo : '',
                  descripcion: tiene.to.es.descripcion,
                }
              // })
            })).filter(dato => dato.codigo!=='record' && dato.codigo!=='otros' && !dato.nombre.includes('_CLB(normalizado)') ),
            //}), //.filter(dato => dato.codigo !== null),
            medicionesAgrupadas: '',
            medicionesTable: [],
            medicionesTableFiltered: [],
            children: []
          }
          return lastDataMapped;
        }),
        map(device => {
          device.medicionesAgrupadas = _.groupBy(device.mediciones , group  => group.codigo)
          let record: (Medicion)[] = [];
          Object.entries(device.medicionesAgrupadas).forEach(entry=>{
            let groupBy: GroupBy = {
              codigo: (entry[0]),
              nombreCode: "",
              isGroupBy: true
            }
            record.push(...device.medicionesAgrupadas[entry[0]]);
          });

          device.medicionesTable = record;
          this.filterDuplicatesByCodeModal(device);
          return this.lastDataDeviceMapa;
        }),

      );
    } else {
      this.filterDuplicatesByCode()
    }
  }


  filterDuplicatesByCode() {
    const grouped = _.groupBy(this.lastDataDeviceMapped.medicionesTable, medicion => medicion.nombreCode);
    let values= [];   
    for(let k of Object.keys(grouped)) {
      let valuesAux = grouped[k]
      const append = valuesAux.filter(medicion => medicion.lastFechaEpoch != null).sort(({lastFechaEpoch:a}, {lastFechaEpoch:b}) => b-a)
      const group = {nombre: append[0].codigo, isGroup: true}
      values.push(group); values.push(append[0])  
    }
    this.lastDataDeviceMapped.medicionesTableFiltered =  values.filter(
      (v, index) => index === values.findIndex(
        other => v.nombre === other.nombre
      ));
  }

  filterDuplicatesByCodeModal(device) {
    const grouped = _.groupBy(device.medicionesTable, medicion => medicion.nombreCode);
    let values= [];   
    for(let k of Object.keys(grouped)) {
      let valuesAux = grouped[k]
      const append = valuesAux.filter(medicion => medicion.lastFechaEpoch != null).sort(({lastFechaEpoch:a}, {lastFechaEpoch:b}) => b-a)
        values.push(append[0])
    }
    device.medicionesTableFiltered = values.filter(v => {return v !== undefined})
    this.lastDataDeviceMapa = device;
  }
  // detectChanges() {
  //   this.timeZoneService.currentTimeZone$.subscribe((data: string) => {
  //     this.timezoneBS.next(data);
  //   });
  // }
}
  