import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface UserDeleted {
   userdUUID: string;
}

export class RemoveUserBuilder {
    private _USERUUID: string;

    private get _mutation() {
        return gql`mutation deleteUsuario($userdUUID: ID!) {
            deleteUsuario(uuid: $userdUUID) {
              uuid
            }
          }`;
    }

    public withUserUUID(userdUUID: string): this {
        this._USERUUID = userdUUID;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: UserDeleted  = { userdUUID: this._USERUUID };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
