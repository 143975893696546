import { LynxColors } from './lynxColors.model';
import { DeviceGraphItem } from './device-graph.model';
import * as _ from 'lodash';

export interface Label {
    label: string;
}
export class NgxDevice {

    devices: DeviceGraphItem[];
    colors: LynxColors[];

    public getLabels(devices): string[] {
        const labels: string[] = [];
        if (!_.isEmpty(devices)) {
            for (const device of devices) {
                (device.name || device.dataTypeName) ? labels.push(device.name || device.dataTypeName) : labels.push(device.label);
            }
        }
        return labels;
    }
    public getUuid(devices: DeviceGraphItem[]): string[] {
        const uuid: string[] = [];
        for (const device of devices) {
            uuid.push(device.uuid);
        }
        return uuid;
    }
}
