import { Component, OnInit, Input, ViewChild, Renderer2, ElementRef, Output, EventEmitter,Inject} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { catchError } from 'rxjs/operators';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApolloGraphQlService } from 'src/app/services/apollo/apollo-graphql.service';

interface DispositivosInterface {
  uuid:string;
  descripcion: string;
  estructura?:
      {
        nombre:string,
        uuid:string
      }
}

@Component({
  selector: 'app-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss']
})
export class FormFilterComponent implements OnInit {
  @ViewChild ('parent',{static: false}) elem;
  @ViewChild("dispositivos",{static:false}) dispositivos: ElementRef;

  @Output() sendRequestForm: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  
  public rangeSliderValues = ['1 Hour', '1 Day', '1 Week', '1 Month', '1 Year'];
  dispositivosList:DispositivosInterface[] = [];
  dispositivosSelected: any[] = [];
  factoriasSelected:any= []

  constructor(
    private storageService: LocalstorageService,
    private apolloService:ApolloGraphQlService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<FormFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.storageService.itemValue.subscribe((nextvalue)=>{
      this.reload();     
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
 private initForm(): FormGroup {    
    return new FormGroup({
      from: new FormControl( null, Validators.required),
      to: new FormControl( null)     
    });
  }

  reload(){  
    this.storageService.getLocal('factoriasSelected')
    .pipe(
      catchError((err, caught) => {
        return err;
      })
    ).subscribe((result) => { 
      (!result) ? this.factoriasSelected = [] : this.factoriasSelected = result;   
      if(this.factoriasSelected){
        if(this.factoriasSelected.length < 1){
            this.dispositivosList = []; 
            this.dispositivosSelected = [];
        }else{
            for(let id of this.factoriasSelected){   
              this.dispositivosList = [];     
              this.getDispositivos(id[1]);         
            }
          }
      }else{
        this.dispositivosList.pop();   
        this.dispositivosSelected = [];  
      }
    });      
  }

  getDispositivos(id:string){
    let query = `{
                  Dispositivo (uuid: "${id}") {
                    uuid
                    name
                    descripcion
                    estructura {
                          uuid
                          name
                          descripcion
                          estructura {
                              uuid
                              nombre
                          }
                    }
                  }
                  }`;
    /*  this.apolloService.executeQuery(query).subscribe((data:any) =>{
       for(let i = 0;i < data.Dispositivo.length; i++ ){
         this.dispositivosList.push(data.Dispositivo[i])
       }
          
     }); */
    
  }

  ngOnInit() {
   this.reload();
   this.form = this.initForm();
  }

  parentToogle(el){
   let childID = el.nextElementSibling;
  (childID.classList.contains('noVisibleChild')) ? childID.classList.remove('noVisibleChild') : childID.classList.add('noVisibleChild');
  (el.firstChild.classList.contains('left')) ? el.firstChild.classList.remove('left') : el.firstChild.classList.add('left');
  }
  
  factoriaToogle(el){
    
    let childID = el.nextElementSibling;
    (childID.classList.contains('noVisibleChild')) ? childID.classList.remove('noVisibleChild') : childID.classList.add('noVisibleChild');
    (el.firstChild.classList.contains('left')) ? el.firstChild.classList.remove('left') : el.firstChild.classList.add('left');
    
  }
  selectedItem(el,uuid,nombre){
    (el.classList.contains('activeChild')) ? el.classList.remove('activeChild') : el.classList.add('activeChild');
   this.addDispositoselected(uuid,nombre);
   
  }
  addDispositoselected(uuid,nombre){
    let data = {
      uuid: uuid,
      nombre: nombre
    }
    if(!this.dispositivosSelected.some(e => e.uuid === data.uuid)){
       this.dispositivosSelected.push(data);
    }else{
     this.dispositivosSelected = this.removeDispotisivoSelected(this.dispositivosSelected,data);
       
    }  
  }

  removeDispotisivoSelected(array, element){
    return array.filter(el => el.uuid !== element.uuid);
  }
  submitNew(){
    let data = {
      fechaRango:[
        {
          from: this.form.get('from').value,
          to: this.form.get('to').value
        }       
      ],
      dispositivosSeleccionado: this.dispositivosSelected,


    }
    this.sendRequestForm.emit(data);
  }
  
}
