import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { Options } from 'ng5-slider';

interface SimpleSliderModel {
  value: number;
  options: Options;
}

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  rangeValue:number = 1;
  @Input() rangeLabels:any = [];
  @Output() rangeSliderEmitter: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() {    
  }

  ngOnInit() {
    this.rangeSlider.options.ceil =  this.rangeLabels.length-1;
  }
  valueChange(value: number): void {
   this.rangeSliderEmitter.emit(value)
  }

  rangeSlider: SimpleSliderModel = {
    value: 0,
    options: {
      floor: 0,
      ceil: this.rangeLabels.length-1,     
      showTicks: true,
      showSelectionBar: true,
      getLegend: (value: number): string => {      
        return  this.rangeLabels[value];
      }
    }
  };

}
