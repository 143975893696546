import { ToastrService } from "ngx-toastr";
import { IridiumeRequestModalComponent } from "./iridium-request-modal/iridium-request-modal.component";
import {
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { Apollo } from "apollo-angular";
import {
  MatDialog
} from "@angular/material/dialog";
import { iridiumEvents } from "./../../models/graphQL/iridium-events-table-graphQL.request";
import moment from 'moment-timezone';

@Component({
  selector: "app-iridium-events",
  templateUrl: "./iridium-events.component.html",
  styleUrls: ["./iridium-events.component.scss"],
})
export class IridiumEventsComponent implements OnInit {
  sub: any;
  loading: boolean;
  filtered: string[];
  // Para la tabla de eventos de Iridium
  displayedColumnsIridium: string[] = [
    "dataCreated",
    "dispositivo",
    "tipo",
    "tag",
    "valor",
    "unidad"
  ];
  alarmsID = ["LluviaIncrAlarmID", "NivelAlarmID", "LluviaDecAlarmID", "NivelDecAlarmID"];
  alarmsId5Minutales = ["LluviaContAlarmID", "NivelContAlarmID"];
  dataSourceIridium: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  timeZone = "Etc/GMT+4";
  timeZoneDisplay = "UTC-4";

  constructor(
    private apollo: Apollo,
    public dialog: MatDialog,
    public toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.sub = this.apollo
      .query<any>({
        query: iridiumEvents,
        fetchPolicy: "network-only",
      })
      .subscribe(({ data, loading }) => {
        this.filterEvents(data);

        this.dataSourceIridium = new MatTableDataSource(this.filtered);
        this.dataSourceIridium.filterPredicate = (data, filter: string) => {
          const accumulator = (currentTerm, key) => {
            return this.nestedFilterCheck(currentTerm, data, key);
          };
          const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
          // Transform the filter by converting it to lowercase and removing whitespace.
          const transformedFilter = filter.trim().toLowerCase();
          return dataStr.indexOf(transformedFilter) !== -1;
        };
        this.dataSourceIridium.paginator = this.paginator;
        this.loading = loading;
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(IridiumeRequestModalComponent, {
      width: '20%'
    });
    dialogRef.componentInstance.toastRequest.subscribe((response) => {
      if (response.peticionIridium == true) {
        this.toastrService.success(
          "Solicitud realizada con éxito",
          "Solicitud exitosa"
        );
      } else {
        this.toastrService.error(
          "Por favor, verifique los parámetros",
          "Solicitud erronea"
        );
      }
    });
  }

  filterEvents(ds) {
    this.filtered = [];
    for (let elem of ds.getIridiumEvents) {
      if (
        elem.msg.status.valores ||  elem.msg.status.alarmas) {
        elem.formatedData =  moment.unix(Number(elem.msg.header.timestamp)).tz(this.timeZone).format('YYYY-MM-DD HH:mm:ss')
        this.filtered.push(elem);
      }
    }
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceIridium.filter = filterValue.trim().toLowerCase();
}

}
