import { Component, Input, OnInit } from '@angular/core';
import { AcEntity, AcNotification } from 'angular-cesium';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import { GetDatePipe } from '../../../../pipes/pipes/pipe-date.pipe';
import { MapaServiceService, MapElementData } from '../../../../sections/mapa/mapa-service.service';
import { DataDevice, LastDataDevice } from '../../../../services/mapa/geodevices.service';


interface DataDeviceWithFecha extends DataDevice {
  fecha: string;
}

@Component({
  selector: 'app-html-layer-device',
  templateUrl: './html-layer-device.component.html',
  styleUrls: ['./html-layer-device.component.scss']
})
export class HtmlLayerDeviceComponent implements OnInit {
  entity;
  entityData$: MapElementData;
  //Data of entity filtered by Type of Data selected
  filterEntityData$: BehaviorSubject<DataDeviceWithFecha[]> = new BehaviorSubject([]);

  @Input() set e(e) {
    if (e) {
      this.entity = e;
      this.mapService.getElementData(e.id)
        .pipe(pluck('lastDataDevice'),
          switchMap(lastDataDevice => this.mapService.tipeDataSelected$.map(tipeSelected => lastDataDevice.tiene.filter(
            data => tipeSelected.some(type => type.codigo === data.to.es.codigo)
          )))
        )
        .subscribe(response => {
          this.entity.show = false;
          const dataParsed: DataDeviceWithFecha[] = response.map(data => {
            const result: DataDeviceWithFecha = { ...data, fecha: null };
            result.fecha = data.to.lastFecha ? this.pipeDate.getDatePipe(Number(data.to.lastFecha)).toString() : null;
            return result;
          })
          this.filterEntityData$.next(dataParsed)
          this.entity.show = true;

        })
    }

  };

  idSelected = [];
  constructor(private mapService: MapaServiceService, private pipeDate: GetDatePipe) {
    console.log(this.filterEntityData$.value)
    this.mapService.mapElementsFiltered$.subscribe(elems => this.idSelected = elems.map(e => e.entityMap.id));
  }

  ngOnInit() {



  }

  selectData(entity: AcNotification) {
    this.mapService.mapFilterText$.next(entity.id);
  }

  isSelected(id): boolean {
    return this.idSelected.some(selected => selected === id)
  }

}
