import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export interface AlarmListParams {
    userUUID: string;
}

export class AlarmByUserBuilder {
    private _USERUUID: string;
    private get _query(): DocumentNode {
        return gql `query listAlarmByUser($userUUID: String) {
            listadoAlarmas(usuario: $userUUID) {
              uuid
              nombre
              descripcion
              subject
              body
              tipo {
                uuid
                codigo
                nombre
              }
              parametros {
                uuid
                clave
                valor
              }
              informa {
                uuid
                disabled
                to {
                  uuid
                  usuario
                }
              }
              supervisa {
                uuid
                state
                disabled
                to {
                  tiene {
                    uuid
                    nombre
                  }
                  es {
                    uuid
                    nombre
                    unidad
                  }
                }
              }
            }
          }`;
    }

    public withUserUUID(userUUID: string): this {
        this._USERUUID = userUUID;
        return this;
    }

    public build(): PureQueryOptions {
        let queryParams: AlarmListParams;
        const graphQLBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        queryParams = {userUUID: this._USERUUID};
        return graphQLBuilder
        .withQuery(this._query)
        .withParams(queryParams)
        .build();
    }
}
