import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComparativeGraphsRoutingModule } from './comparative-graphs-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ComparativeGraphsRoutingModule
  ]
})
export class ComparativeGraphsModule { }
