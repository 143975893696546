import { Component, OnInit, ElementRef,ViewChild,Renderer2 } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { DispositivosInterface,DispositivoTreeview } from '../../../models/dispositivo.model';
import { ApolloGraphQlService } from 'src/app/services/apollo/apollo-graphql.service';
import { PureQueryOptions } from 'apollo-client';

@Component({
  selector: 'app-dispotree',
  templateUrl: './dispotree.component.html',
  styleUrls: ['./dispotree.component.scss']
})

export class DispotreeComponent implements OnInit {

  dispositivosList: DispositivosInterface[] = [];
  dispositivosSelected: any[] = [];
  factoriasSelected: any = []
  @ViewChild('dispositivos',{static:false}) dispositivos: ElementRef;

  constructor(
    private storageService: LocalstorageService,
    private apolloService: ApolloGraphQlService, 
    private renderer: Renderer2) {
    this.storageService.itemValue.subscribe((nextvalue) => {
      this.reload();
    })
  }

  reload() {
    this.storageService.getLocal('factoriasSelected')
      .pipe(
        catchError((err, caught) => {
          return err;
        })
      ).subscribe((result) => {
        (!result) ? this.factoriasSelected = [] : this.factoriasSelected = result;
        if (this.factoriasSelected) {
          if (this.factoriasSelected.length < 1) {
            this.dispositivosList = [];
            this.dispositivosSelected = [];
          } else {
            for (let id of this.factoriasSelected) {
              this.dispositivosList = [];
              this.getDispositivos(id[1]);
            }
          }
        } else {
          this.dispositivosList.pop();
          this.dispositivosSelected = [];
        }
      });
  }

  getDispositivos(id: string) {
    let query = `{
                  Dispositivo (uuid: "${id}") {
                    uuid
                    name
                    descripcion
                    estructura {
                          uuid
                          name
                          descripcion
                         estructura {
                          to {
                            Dispositivo {
                              uuid
                              nombre
                 
                            }
                            }
                          }
                    }
                  }
                  }`;
    /* this.apolloService.executeQuery(query).subscribe((data: any) => {
      for (let i = 0; i < data.Dispositivo.length; i++) {
        this.dispositivosList.push(data.Dispositivo[i])
      }
    }); */

  }
  parentToogle(el){
    let childID = el.nextElementSibling;
   (childID.classList.contains('noVisibleChild')) ? childID.classList.remove('noVisibleChild') : childID.classList.add('noVisibleChild');
   (el.firstChild.classList.contains('left')) ? el.firstChild.classList.remove('left') : el.firstChild.classList.add('left');
   }
   
  factoriaToogle(el){     
     let childID = el.nextElementSibling;
     (childID.classList.contains('noVisibleChild')) ? childID.classList.remove('noVisibleChild') : childID.classList.add('noVisibleChild');
     (el.firstChild.classList.contains('left')) ? el.firstChild.classList.remove('left') : el.firstChild.classList.add('left');
   
   }
   selectedItem(el,uuid,nombre){
    
    this.addDispositoselected(uuid,nombre);    
   }
   addDispositoselected(uuid,nombre){
     let data = {
       uuid: uuid,
       nombre: nombre
     }
     if(!this.dispositivosSelected.some(e => e.uuid === data.uuid)){
        this.dispositivosSelected.push(data);
     }else{
      this.dispositivosSelected = this.removeDispotisivoSelected(this.dispositivosSelected,uuid);
        
     }  
    
   }
 
   removeDispotisivoSelected(array, element){
     return array.filter(el => el.uuid !== element);
   }
  ngOnInit() {
    this.reload
  }
  removeChip(id){
    this.dispositivosSelected = this.removeDispotisivoSelected(this.dispositivosSelected,id);
    this.clearActiveClass(id);
  }
  clearActiveClass(id){
    
    let el = this.renderer.selectRootElement(this.dispositivos.nativeElement.children[0].children[1].children);
    let childrens=[];
    let childs = [];
    for(let elem of el){
      childrens.push(elem.children[1].children)     
    }
  
    for(let child of childrens){
      child.some(e => e.id === id)
    }
   
  }
}
