import { Injectable } from '@angular/core';
import { dataField, gatewaysTo, gatewaysFrom, sources, entradas, LMping, gatewaysRaiz, sourceRezagados, elemPing,
        atributosGateway,atributosDispositivo,atributosLMPing,atributosMbBus, atributosTcpBus, atributosTcpDevice, atributosMbRtuDevice,
        updateDispositivo, updateGateway,
        updateLMping, updateMbBus,updateMbDevice,updateMbRtuDevice,updateTcpBus,updateTcpDevice
      }  from './querys/querys';
import { elemPingInterface } from './models/models';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/publishReplay';
import { MatTableDataSource } from '@angular/material/table';


@Injectable({
  providedIn: 'root'
})


export class FieldDataService {

  // Definimos las variablesa que vamos a asignar
  // gatewaysFrom: string[] = [];
  // gatewaysTo: string[] = [];
  // Sources: string[] = [];
  // entradas: any;
  // lmPing: any;

  constructor(private apollo: Apollo,
    private http: HttpClient) { }


  // Para mostrar en la tabla inicial los dispositivos que tienen ping:
  getelemPing(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: elemPing,
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(map(response => response.data = response.data))
  }


  // "funcion" que va a calcular la cantidad de sources rezagados para cada gateway!
  getsourceRezagados(gateway_uuid: string, set_time: number): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: sourceRezagados,
      variables: {
        gwuuid: gateway_uuid,
        time: set_time
      },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response =>
        response.data = response.data.sourceRezagados
      ))
  }


  // Gateways raiz (los que no cuelgan de ningun otro):
  getGatewaysRaiz(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gatewaysRaiz,
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response =>
        response.data = response.data.gatewaysRaiz
      ))
  }

  // Gateways de los que cuelga el actual
  getGatewaysFrom(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gatewaysFrom,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.findGateway.gateways))
      .pipe(map(response => new MatTableDataSource(response)));
  }

  // Gateways que cuelgan de este
  getGatewaysTo(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: gatewaysTo,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.findGateway.gateways))
    // .pipe(map(response => new MatTableDataSource(response)));
  }

  // Informacion del gateway que estamos navegando
  getGatewayActual(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: dataField,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.findGateway));
  }

  // Informacion sobre atributos del label
  getAtributosGateWay(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosGateway,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.findGateway));
  }
  getAtributosLMPing(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosLMPing,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.findLmPing));
  }
  getAtributosDispositivo(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosDispositivo,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.findDispositivo));
  }
  getAtributosMbBus(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosMbBus,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.MbBus[0]));
  }
  getAtributosTcpBus(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosTcpBus,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.TcpBus[0]));
  }
  getAtributosTcpDevice(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosTcpDevice,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.TcpDevice[0]));
  }
  getAtributosMbRtuDevice(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: atributosMbRtuDevice,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data.MbRtuDevice[0]));
  }


  // Función que va a actualizar los datos existentes en la base de datos
  actualizarDispositivo(dispositivo) {
    this.apollo.mutate({
        mutation: updateDispositivo,
        variables: {
            uuid: dispositivo.actualuuid,
            nombre: dispositivo.nombre,
            descripcion: dispositivo.descripcion,
            nombreTecnico: dispositivo.nombreTecnico
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarGateway(dispositivo) {
    this.apollo.mutate({
        mutation: updateGateway,
        variables: {
            uuid: dispositivo.actualuuid,
            nombre: dispositivo.nombre,
            topic: dispositivo.topic
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarLMping(dispositivo) {
    this.apollo.mutate({
        mutation: updateLMping,
        variables: {
            uuid: dispositivo.actualuuid,
            host: dispositivo.host
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarTcpBus(dispositivo) {
    this.apollo.mutate({
        mutation: updateTcpBus,
        variables: {
            uuid: dispositivo.actualuuid,
            nombre: dispositivo.nombre,
            Ip: dispositivo.Ip,
            mask: dispositivo.mask
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarTcpDevice(dispositivo) {
    this.apollo.mutate({
        mutation: updateTcpDevice,
        variables: {
          uuid: dispositivo.actualuuid,
          nombre: dispositivo.nombre,
          Ip: dispositivo.Ip,
          mask: dispositivo.mask
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarMbBus(dispositivo) {
    this.apollo.mutate({
        mutation: updateMbBus,
        variables: {
          uuid: dispositivo.actualuuid,
          nombre: dispositivo.nombre,
          path: dispositivo.path,
          baudRate: dispositivo.baudRate,
          parity: dispositivo.parity,
          dataBits: dispositivo.dataBits,
          stopBits: dispositivo.stopBits,
          timeout: dispositivo.timeout,
          wait: dispositivo.wait
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarMbdevice(dispositivo) {
    this.apollo.mutate({
        mutation: updateMbDevice,
        variables: {
            uuid: dispositivo.actualuuid,
            mbid: dispositivo.mbid
        }
    }).subscribe((data) => { 
      
    });
  }

  actualizarMbRtuDevice(dispositivo) {
    this.apollo.mutate({
        mutation: updateMbRtuDevice,
        variables: {
          uuid: dispositivo.actualuuid,
          nombre: dispositivo.nombre,
          path: dispositivo.path,
          baudRate: dispositivo.baudRate,
          parity: dispositivo.parity,
          dataBits: dispositivo.dataBits,
          stopBits: dispositivo.stopBits
        }
    }).subscribe((data) => { 
      
    });
  }

  //fin labels dispositivo

  // Sources del gateway actual
  getSources(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: sources,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(
        map(response => response.data.findGateway.sources
        )).pipe(map(response => new MatTableDataSource(response)));
  }

  // Para obtener información acerca de los sources
  getEntradas(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: entradas,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
  }

  // Para extraer información del ping
  getLMping(actualuuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: LMping,
      variables: { uuid: actualuuid },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(
        map(response => response.data.findLmPing)
      )
  }

  // Para extraer información sobre la configuración:
  getConfigFieldData(): Observable<any> {
    return this.http.get<any>('/assets/data/fieldDataConfig.json');

  }


}
