import { Injectable } from '@angular/core';
import { FactoryModel } from '../../models/FactoryModel';
import { Observable, BehaviorSubject } from 'rxjs';
import { PureQueryOptions } from 'apollo-client';
import { FactoryGraphQLBuilder } from 'src/app/models/graphQL/factory-graphQL.request';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import { FactoryCollection as FactoryCollection } from 'src/app/models/factory/factory.collection.model';
import { FactoryGraphQLResponse } from 'src/app/models/graphQL/factory-graphQL.respponse';
import * as lodash from 'lodash';

export interface FactoryChanged {
  uuid: string;
  name: string;
}
@Injectable({
  providedIn: 'root'
})

export class FactoriesService {

  private factorySelectedBS: BehaviorSubject<FactoryCollection>;
  public factorySelectedObservable: Observable<FactoryCollection>;
  emptyFactory: FactoryChanged = {
    name: '',
    uuid: ''
  };
  public factoryChangedBS: BehaviorSubject<FactoryChanged> = new BehaviorSubject<FactoryChanged>(this.emptyFactory);
  public factoryChangedObservable: Observable<FactoryChanged>;


  constructor(private apolloService: ApolloGraphQlService) {
    this.factorySelectedBS = new BehaviorSubject<FactoryCollection>(new FactoryCollection());
    this.factorySelectedObservable = this.factorySelectedBS.asObservable();
  }

  public removeAllFactories() {
    const aux: FactoryCollection = new FactoryCollection();
    this.factorySelectedBS.next(aux);
  }

  public getFactories(usuarioFilter: string): Observable<FactoryCollection> {
    const queryOptions: PureQueryOptions = new FactoryGraphQLBuilder()
    .withUserParam(usuarioFilter)
    .build();
    const responseFactories =
    this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toFactoryModelCollection);
    if (!lodash.isEmpty(responseFactories)) {
      return responseFactories;
    }
  }

  public newSelectFactoryCollection(factorySelected: FactoryCollection) {
    if (!lodash.isEmpty(factorySelected)) {
      this.factorySelectedBS.next(factorySelected);
    }
  }

  private toFactoryModelCollection(result: FactoryGraphQLResponse): FactoryCollection {
    const factoryModelCollection: FactoryCollection = new FactoryCollection();
    const map = result.dispositivosByUsuario.map(factoryItem => new FactoryModel(factoryItem));
    if (!lodash.isEmpty(map)) {
      factoryModelCollection.addRangeItem(map);
      return factoryModelCollection;
    }
  }

  setFactoryChanged(factoryChanged: FactoryChanged) {
    this.factoryChangedBS.next(factoryChanged);
  }

}
