import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { from, interval, Subscription, Observable, concat, forkJoin } from 'rxjs';
import { flatMap, map, filter, mergeAll, tap } from 'rxjs/operators';
import { Device } from 'src/app/models/device.model';

import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import { QueryAllDevices, QueryAllDevicesUltimasMediciones, QueryAllGeoPos, QueryFindLastMeasuresWithDescendancy, QueryLastDataDispositivo, QueryTiposDatos } from 'src/app/components/informes/consultas/interfaces.consultas';
import { StringNullableChain } from 'lodash';

export interface EntidadMapa {
  id: string,
  name: string,
  position: any
}

export interface EntidadGeoPOS {
  uuid: string,
  nombre?: string,
  longitud: number,
  latitud: number,
  labels: string[]
  mediciones?: LastDataDevice[];
}

export interface LastDataDevice {
  uuid: string
  nombre: string
  labels: string[]
  tiene: DataDevice[]
  estructura: Estructura[]
}

export interface tipoEstructura {
  nombre: string
  uuid: string
}

export interface estructuraTiene {
  to: EstructuraTo;

}

export interface EstructuraTo {
  uuid: string;
  stats_count: string;
  lastEntrada?: {
    lastFecha: string;
    lastValor: string;
  };
  lastFecha: string; // new
  lastValor: string; // new
  es: estructuraEs
}

export interface estructuraEs {
  uuid: string;
  nombre: string;
  codigo: string;
  nombreCode: string;
  unidad: string;
  descripcion: string;
}
export interface Estructura {
  uuid: string;
  nombre: string;
  nombreTecnico: string;
  descripcion: string;
  tipo: tipoEstructura;
  estructuraCantidad: string;
  tiene: estructuraTiene[];
}

export interface DataDevice {
  to: {
    stats_last: number
    es: {
      uuid: string
      nombre: string
      unidad: string
      codigo: string
      nombreCode: string;
      descripcion: string;
      agrupacion: Agrupacion[];
    }
    lastEntrada: {
      lastFecha: string;
      lastValor: string
    }
    lastFecha?: string; // new
    lastValor?: string; // new


  }
}
// export interface Agrupacion {
//   uuid: string;
//   nombre: string;
//   nombreCode: string;
// }

export interface Agrupacion {
  nombre: string;
  nombre_es: string;
}

export interface TipoDato {

  uuid: string
  nombre: string;
  codigo: string;
  nombreCode: string;
  descripcion: string;
  factorDecimal: string;
  unidad: string;
  displayField: string;
  agrupacion?: Agrupacion[];
}

export interface Agrupacion {
  nombre: string;
  nombreCode: string;
}

@Injectable({
  providedIn: 'root'
})

export class GeoDevicesService {

  dataGatewayRaiz: any;
  constructor(
    // private dataService: FieldDataService,
    // private gF: GraphFunciones
    private apollo: Apollo,
    private http: HttpClient,
    private apolloService: ApolloGraphQlService,
  ) {
  }

  entidadesGeoPos: EntidadGeoPOS[] = [];
  subStream: any;
  fabricas: any[] = [];
  geoPos: any;
  getGeoPos$: Observable<any>[];

  getAllGeoPos(): Observable<EntidadGeoPOS[]> {
    return this.apollo.watchQuery<any>({
      query: QueryAllGeoPos,
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data = response.data.geoPos))
  }

  getLastDataDispositivo(uuid: string): Observable<LastDataDevice> {
    return this.apollo.watchQuery<any>({
      query: QueryLastDataDispositivo,
      variables: {
        uuid: uuid,
      },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(
        map(response => {
          return response.data = response.data ? response.data.findEstructura : null
        }),
      )
  }

  findLastMeasuresWithDescendancy(uuidDevice: string): Observable<LastDataDevice[]> {
    return this.apollo.watchQuery<any>({
      query: QueryFindLastMeasuresWithDescendancy,
      variables: {
        uuidDevice: uuidDevice,
      },
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(
        map(response => response.data = response.data.findLastMeasuresWithDescendancy),
      )
  }

  getTiposDatos(): Observable<TipoDato[]> {
    return this.apollo.watchQuery<any>({
      query: QueryTiposDatos,
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(
        map(response => response.data = response.data.tipoDatos),
      )
  }

  getAllDevices(): Observable<LastDataDevice[]> {
    return this.apollo.watchQuery<any>({
      query: QueryAllDevices,
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data = response.data.dispositivosByUsuario))
  }

  getAllDevicesUltimasMediciones(): Observable<LastDataDevice[]> {
    return this.apollo.watchQuery<any>({
      query: QueryAllDevicesUltimasMediciones,
      fetchPolicy: 'network-only'
    }).valueChanges
      .pipe(map(response => response.data = response.data.dispositivos))
  }

}