import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap, switchMap, flatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { FactoriesService } from 'src/app/services/factories/factories.service';
import { Device } from 'src/app/models/device.model';
import { FactoryCollection } from 'src/app/models/factory/factory.collection.model';

@Component({
  selector: 'app-menu-dashboard',
  templateUrl: './menu-dashboard.component.html',
  styleUrls: ['./menu-dashboard.component.scss']
})

export class MenuDashboardComponent implements OnInit {

  searchField: FormControl;
  termSelected: string;
  typeFieldSelected: string;
  factoriasSelected: FactoryCollection;
  results: Observable<Array<Device>>;

  addedDevices: {device: string, type: string} [] = [];

  @Output() add: EventEmitter<{device: string, type: string} []> = new EventEmitter<{device: string, type: string} []>();

  onEnter(value: string) {
    this.filterDevice(value);
  }

  constructor(private dispositivoService: DispositivoService,
    private factoriesService: FactoriesService) { }

  ngOnInit() {
    this.searchField = new FormControl();
    this.factoriesService
      .factorySelectedObservable.pipe(
      ).subscribe(factorySelectedCollection => this.factoriasSelected = factorySelectedCollection);
    this.searchDispositvo();
    this.results = this.dispositivoService.deviceCollectionObservable;
    this.filterDevice('');
  }

  searchDispositvo() {
    this.searchField.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()).subscribe(term => this.filterDevice(term));
  }

  filterDevice(term: string) {
    this.termSelected = term;
    if (this.factoriasSelected.isEmpty()) {
      return;
    }
    let factoryIdCollection: Array<string> = this.factoriasSelected.collection.map(factory => factory.uuid);
    //this.dispositivoService.getDevicesByNameAndFactoryCollection(term, factoryIdCollection);

  }

  addDevice() {
     if ( this.termSelected && this.typeFieldSelected) {

      const newDevice = {
        device: this.termSelected,
        type: this.typeFieldSelected
      };

      const newList = [];
      newList.push(newDevice);

      this.addedDevices = [...newList];

      this.add.emit(this.addedDevices);
    }
  }
  onTypeDataChange(event) {

    if (event === '1') {
      this.typeFieldSelected = 'tipo dato 1';
    } else if (event === '2') {
      this.typeFieldSelected = 'tipo dato 2';
    } else {
      this.typeFieldSelected = 'tipo dato 3';
    }

  }
}
