import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {



  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialogRef.disableClose = true;
     }

  ngOnInit() {
    if(!this.data.existingData) {
      this.data.message = 'CONFIRM_MODAL.MESSAGE.NO_GRAPHIC';
    }
  }

  closeDialog(result) {
    this.dialogRef.close(result);
    return result;
  }



}
