import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface ChangePasswordModel {
   usuario: string;
   clave: string;
   nuevaClave: string;
}

export class ChangeUserPasswordBuilder {
    private _USER: string;
    private _OLDPASSWORD: string;
    private _NEWPASSWORD: string;

    private get _mutation() {
        return gql`mutation changePassword($usuario:String,$clave:String,$nuevaClave:String){
            changePassword(usuario:$usuario, clave:$clave, nuevaClave:$nuevaClave){
              nombre
            }
          }`;
    }

    public withUser(user: string): this {
        this._USER = user;
        return this;
    }

    public withOldPassword(oldPassword: string): this {
        this._OLDPASSWORD = oldPassword;
        return this;
    }

    public withNewPassword(newPassword: string): this {
        this._NEWPASSWORD = newPassword;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: ChangePasswordModel  = { usuario: this._USER, clave: this._OLDPASSWORD, nuevaClave: this._NEWPASSWORD };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
