import { LoginService } from 'src/app/services/login/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardGuard implements CanActivate {
  userRole: string[];
  hasAccess: boolean = false;
  expectedRole: string[];
  constructor(private loginService: LoginService,
    private _router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.expectedRole = next.data.expectedRole;
    this.userRole = (this.loginService.currentUserValue ? this.loginService.currentUserValue.rol: ['']);
    this.hasAccess = this.userRole.some(r=> this.expectedRole.indexOf(r) >= 0);
    if (this.hasAccess === true) {
      return true
    }
    else {
      this._router.navigate(['/pageNotFound']);
      return false
    }

  }



}
