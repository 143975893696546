import * as lodash from 'lodash';
import { DeviceType } from '../deviceType.model';
export interface DeviceGraphQLResponse {
  findEstructura: DeviceDetailGraphQLResponse
}

export class DeviceDetailGraphQLResponse {
  uuid: string;
  nombre: string;
  tiene:DeviceDetailDataGQLResponse[];
  tipo?:DeviceType;
  constructor(partial: Partial<DeviceDetailGraphQLResponse>){
    Object.assign(this,partial);
    if(!lodash.isEmpty(this.tiene)){
      this.tiene = this.tiene.map(data => new DeviceDetailDataGQLResponse(data));
    }
  }
  public findDataByCategory(dataCategory:DataCategory):DeviceDetailDataGQLResponse[]{
    if(dataCategory === null || dataCategory === undefined || lodash.isEmpty(this.tiene)){
      return [];
    }   
    return this.tiene.filter(item => item.isDataType(dataCategory));
  }
}

export class DeviceDetailDataGQLResponse {
  Dato: {
    uuid: string;
    stats_count: number;
    es: DeviceDetailDataTypeGQLResponse;
  }
  constructor(partial: Partial<DeviceDetailDataGQLResponse>){
    Object.assign(this,partial);
  }
  isInterval(dataIterval:DataInterval):boolean{
    if(dataIterval === null || dataIterval === undefined || !this.hasDataType()){
      return false;
      }
      return !lodash.isEmpty(this.Dato.es.nombre.match(dataIterval));
  }
  hasValue():boolean{
    return !(this.Dato === null || this.Dato === undefined) ;
  }
  hasDataType():boolean{
    return this.hasValue() && !(this.Dato.es === null || this.Dato.es === undefined) ;
  }
  isDataType(dataCategory:DataCategory):boolean{
    if(dataCategory === null || dataCategory === undefined || !this.hasDataType()){
    return false;
    }
    return !lodash.isEmpty(this.Dato.es.nombre.match(dataCategory));
  }
}

export interface DeviceDetailDataTypeGQLResponse  {
  uuid: string;
  nombre: string;
  unidad: string;
  codigo: string;
  factorDecimal: string;
}

export enum DataCategory{
  ELECTRYCITY ="^.*Electricidad.*$",
  GAS="^.*(Gas|gas|GN).*$",
  WATER="^.*(Agua|agua).*$",
  PRODUCCTION="^.*(Produccion|produccion).*$"
}

export enum DataInterval{
  MONTHLY ="^.*Mes.*$",
  YEARLY="^.*Año.*$",
  ACCUMULATED="^.*Acumulado.*$",
}