import { Component, OnInit, Input, HostListener, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {
  PlotlyData, ChartOrientationType, PlotlyChartType, PlotlyGraph,
  LayoutGraphData, ConfigGraphData
} from 'src/app/models/plotly/plotly-chart.model';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
// import * as moment from 'moment'
import moment from 'moment-timezone';
import { TimezoneService } from 'src/app/services/timezone.service'
import { LoginService } from 'src/app/services/login/login.service';


export interface DataPie {
  values: number[];
  labels: string[];
  type: PlotlyChartType;
  automargin: boolean;
  textinfo: string;
  textposition: string;
}

@Component({
  selector: 'app-plotly',
  templateUrl: './plotly.component.html',
  styleUrls: ['./plotly.component.scss']
})

export class PlotlyComponent implements OnInit, OnChanges {
  @Input() plotlyChartCollection: PlotlyData[] | PlotlyData;
  @Input() graphCounter: number;
  @Input() title: string;
  @Input() width: number;
  @Input() height: number;
  @Input() isDashboard: boolean;
  @Input() isDeviceSection: boolean;
  @Input() showModeBar: boolean;
  @Input() unity: string;
  @Input() test: number;
  @Input() uuid: string;
  @Output() readyRender: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('plotly', { static: false }) plotly;

  timeZone: string;
  public graph: PlotlyGraph;
  public data: any[] = []; // mapear
  public layout: LayoutGraphData;
  public innerWidth: number;
  public loading = false;
  private yAxisPosition = -0.25;
  plotlyChart: PlotlyData = new PlotlyData();
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.truncateNameCollectionLegend();
    this.positionYAxis();
  }


  constructor(private timeZoneService: TimezoneService,
    private loginService: LoginService,
    translate: TranslateService) { }

  ngOnInit() {
    this.timeZone = this.loginService.currentUserValue.timeZone;
    this.buildDataChart();
    this.truncateNameCollectionLegend();
    this.assignDataFiller();

  }
  buildDataChart() {
    if (!_.isEmpty(this.plotlyChartCollection)) {
      if (this.plotlyChartCollection instanceof Array) {
        this.plotlyChart.type = this.plotlyChartCollection[0].type;
      } else {
        this.plotlyChart.type = this.plotlyChartCollection.type;
      }
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (this.graph !== undefined) {
      this.graph.layout.width = this.width;
      this.graph.layout.height = this.height;
    }
    this.timeZone = this.loginService.currentUserValue.timeZone;
  }


  animated(event) {
    this.readyRender.emit(this.uuid);
  }
  private truncateNameCollectionLegend() {
    // let truncatedName = '';
    const nameLength = this.assignLengthDependingOnInnerWidth();
    // MANU
    /* if (this.plotlyChart.name !== undefined) {
      for (let i = 0; i < this.plotlyChart.name.length; i++) {
        if (this.plotlyChart.name[i].length > nameLength) {
          truncatedName = this.plotlyChart.name[i].slice(0, nameLength);
          this.plotlyChart.name.splice(i, 1, truncatedName);
        }
      }
    } */
  }

  private assignLengthDependingOnInnerWidth(): number {
    switch (this.innerWidth) {
      case 1440:
        return 28;
      case 768:
        return 22;
      default:
        return 33;
    }
  }

  private assignDataFiller() {
    this.positionYAxis();
    switch (this.plotlyChart.type) {

      case PlotlyChartType.PIE: {
        this.fillPieData();
        break;
      }
      case PlotlyChartType.BAR: {
        this.fillBarData();
        break;
      }
      case PlotlyChartType.SCATTER: {
        this.fillScatterData();
        break;
      }
      case PlotlyChartType.GAUGE: {
        this.fillGaugeData();
        break;
      }
      case PlotlyChartType.TABLE: {
        this.fillTableData();
        break;
      }
      case PlotlyChartType.DIGITAL: {
        this.fillDigitalData();
      }
    }
    this.fillGraph();
  }

  private fillGraph() {
    // Cambiamos al formato label una vez que vamos a rellenar el gráfico y no antes!
    // Además le aplicamos la zona horaria que tiene seleccionada el usuario:
    // Si es gauge no hay que aplicarle el cambio de zona horaria porque no tiene eje X (timestamp) y peta
    //Se agregó comprobación de datos para evitar error en this.data = 'undefined
    if (this.data[0]) {
      if (!("gauge" in this.data[0])) {
        for (let i = 0; i < this.data.length; i++) {
          for (let j = 0; j < this.data[i].x.length; j++) {
            let t = moment.unix(Number(this.data[i].x[j]));
            t.tz(this.timeZone);
            this.data[i].x[j] = t.format("YYYY-MM-DD HH:mm:ss");
          }
        }
      }
      if(this.data[0].type == "bar" && this.data[0].orientation == "h") {
        for (let i = 0; i < this.data.length; i++) {
          let yAxis = this.data[i].y;
          let xAxis = this.data[i].x;
          this.data[i].x = yAxis;
          this.data[i].y = xAxis;
        }
          
      }   
    }

    this.graph = {
      data: this.data,
      layout: this.fillLayout(),
      config: this.getShowModeBar(),
    };
  }


  private fillBarData() {
    if (this.plotlyChartCollection instanceof Array) {
      for (const plotlyChart of this.plotlyChartCollection) {
        if (plotlyChart.orientation === ChartOrientationType.HORIZONTAL) {
          this.fillHorizontalBarData(plotlyChart);
        } else {
          this.fillVerticalBarData(plotlyChart);
        }
      }
    }
  }

  private fillHorizontalBarData(plotlyChart: PlotlyData) {
    this.data.push({
      x: plotlyChart.x,
      y: plotlyChart.y,
      type: plotlyChart.type,
      orientation: ChartOrientationType.HORIZONTAL,
      name: plotlyChart.name
    });
  }

  private fillVerticalBarData(plotlyChart: PlotlyData) {
    this.data.push({
      x: plotlyChart.x,
      y: plotlyChart.y,
      type: plotlyChart.type,
      orientation: ChartOrientationType.VERTICAL,
      name: plotlyChart.name
    });
  }

  private fillScatterData() {
    if (this.plotlyChartCollection instanceof Array) {
      for (const plotlyChart of this.plotlyChartCollection) {
        this.data.push({
          x: plotlyChart.x,
          y: plotlyChart.y,
          type: plotlyChart.type,
          orientation: ChartOrientationType.VERTICAL,
          name: plotlyChart.name,
          mode: 'lines+markers'
        });
      }
    } else {
      this.data.push({
        x: this.plotlyChartCollection.x,
        y: this.plotlyChartCollection.y,
        type: this.plotlyChart.type,
        orientation: ChartOrientationType.VERTICAL,
        name: this.plotlyChartCollection.name
      });
    }
  }

  private fillPieData() {
    const auxValues: number[] = [];
    const auxLabels: string[] = [];
    if (this.plotlyChartCollection instanceof Array) {
      for (const plotlyChart of this.plotlyChartCollection) {
        auxValues.push(plotlyChart.y[0]);
        auxLabels.push(plotlyChart.name);
      }
      this.data.push({
        values: auxValues,
        labels: auxLabels,
        type: this.plotlyChart.type,
        automargin: true,
        textinfo: 'label+percent',
        textposition: 'inside'
      });
    } else {
      this.data.push({
        values: this.plotlyChartCollection.y,
        labels: [this.plotlyChart.name],
        type: this.plotlyChart.type,
        automargin: true,
        textinfo: 'label+percent',
        textposition: 'inside',
      });
    }
  }

  private fillGaugeData() {
    this.data.push(this.plotlyChartCollection);
  }

  private fillTableData() {
    this.data.push(this.plotlyChartCollection);
  }

  private fillDigitalData() {
    this.data.push(this.plotlyChartCollection);
  }

  private getShowModeBar(): ConfigGraphData {
    if (this.showModeBar !== undefined) {
      return { displayModeBar: false, responsive: true, scrollZoom: true, displaylogo: false };
    }
    return { responsive: true, scrollZoom: true, displaylogo: false };
  }

  private fillLayout(): LayoutGraphData {
    this.layout = {
      title: this.title,
      width: this.width,
      height: this.height,
      showlegend: true
    };
    if (this.isDeviceSection) {
      this.layout.legend = {
        orientation: ChartOrientationType.VERTICAL
      };
    } else {
      this.layout.legend = {
        orientation: ChartOrientationType.HORIZONTAL
      };
    }

    if (!this.isDeviceSection || this.isDashboard) {
      this.layout.legend.x = 0;
      this.layout.legend.y = this.yAxisPosition;
      if (this.plotlyChart.x !== undefined) {
        if (this.plotlyChart.x.length > 5) {
          this.layout.xaxis = { tickangle: -90 };
        }
      }
    }

    if (this.isDeviceSection || this.isDashboard && this.plotlyChart.type !== PlotlyChartType.TABLE) {
      this.layout.annotations = [{
        xref: 'paper',
        yref: 'paper',
        x: 0,
        xanchor: 'left',
        y: 1.05,
        yanchor: 'top',
        text: this.unity,
        font: {
          family: 'sans serif',
          size: 12
        },
        showarrow: true ? this.plotlyChart.type !== PlotlyChartType.PIE : false// yAxis arrow to indicate unity
      }];
    }

    this.layout.margin = {
      l: 60,
      r: this.isDashboard ? 0 : 30,
      b: this.isDeviceSection ? 50 : 0,
      t: 50,
      pad: 0
    };
    return this.layout;
  }

  private positionYAxis() {
    let distance = 0;
    if (this.graphCounter === 1) {
      distance = 0.01;
    } else {
      distance = 0.05;
    }
    if (this.plotlyChart.name !== undefined) {
      this.yAxisPosition -= this.plotlyChart.name.length * distance;
    }
  }

}
