import gql from 'graphql-tag';


export const IRIDIUM_EVENTS_SUBSCRIPTION = gql`
subscription iridiumEvent {
  iridiumEvent{
    _id
    dateCreated
    msg {
      header {
        timestamp
        ieid
        ielongi
        cdr
        imei
        findDispositivoByImei {
          uuid
          nombre
        }
        momsn
        mtmsn
      }
      status {
        ieid
        ielongi
        valores {
          tag
          valor
          findTipoDatoByTag {
            uuid
            nombre
            unidad
          }
        }
        alarmas {
          tipo
          lluvia
          altlm
        }
      }
    }
  }
  }`