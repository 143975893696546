import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unityFormat'
})
export class UnityFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (value === 'dispositivos') {
      return '';
    } else {
      return '(' + value + ')';
    }
  }

}
