import {
  Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef,
  ChangeDetectorRef, AfterViewChecked, Type
} from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { LoginService } from 'src/app/services/login/login.service';
import { AlarmaEvento, AlarmaModel, DatoModel, FieldModel, InformaModel, NewDependeModel, NewInformaModel, NewParametroModel, ParametrosAlarmaModel, TipoAlarmaModel, UpdateParametroModel, VariablesModel } from './../models/alarma-models'
import { AlarmasService } from '../../../services/alarmas/alarmas.service';


import { BehaviorSubject, of } from 'rxjs';
import { Observable }      from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDatosModalComponent } from 'src/app/components/device-datos-modal/device-datos-modal.component';
import { AlarmaShareComponent } from 'src/app/components/alarma-share/alarma-share.component';
import { DataTypeService } from 'src/app/services/datatype/data-type.service';
import { DatatypeTreeHierarchi } from 'src/app/models/datatype/datatype-hierarchi.model';
import { TranslateService } from '@ngx-translate/core';
import { TimezoneService } from 'src/app/services/timezone.service';
import { DataType } from 'src/app/models/data-type.model';
import { Options } from 'ng5-slider';
import { UserDashboardModel, UserModel } from 'src/app/models/userModel';
import { ToastService } from 'src/app/services/toast/toast.service';
import { tap } from 'rxjs/operators';


@Component({
  selector: 'app-alarma-form',
  templateUrl: './alarma-form.component.html',
  styleUrls: ['./alarma-form.component.scss'],
})
export class AlarmaFormComponent implements OnInit {

  @Input() mobileMode: boolean;
  @Input() formMode: string;
  @Input() alarmaToEdit: AlarmaModel;
  @Output() cancelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshAlarmasEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() editEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();

  timezoneBS: BehaviorSubject<string> = new BehaviorSubject<string>('');

  dataTypeTree: DatatypeTreeHierarchi[];
  AlarmaForm:FormGroup;
  newAlarma: AlarmaModel = new AlarmaModel();
  newAlarmaParametros: NewParametroModel[]= [];
  alarmaParametros: UpdateParametroModel[]= [];
  newAlarmaDepende: NewDependeModel[]=[];
  newAlarmaInforma: NewInformaModel[]=[];
  informaUsers: string;

  AlarmaTypeCombo: TipoAlarmaModel[] = [];
  SelectedAlarmaType: TipoAlarmaModel;

  hide: boolean = true;

  //dynamic form
  FieldsTipoAlarmaData:any[]=[];
  FieldsTipoAlarmaForm: FormGroup;
  tipoAlarmaFields: FieldModel[]=[];
  tipoAlarmaFields2: FieldModel[]=[];
  selectedUsers: UserModel[]=[];
  eventosAlarma$: Observable<AlarmaEvento[]>;
  eventosAlarma: AlarmaEvento[];

  constructor(
    private alarmasService: AlarmasService,
    private loginService: LoginService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private datatypeService: DataTypeService,
    private translate: TranslateService,
    private timezone: TimezoneService,
    private toastr: ToastService,
  ) {
      
   
  }

  createAlarmaForm(){
      this.AlarmaForm = this.fb.group({
        AlarmaNameControl : ['', Validators.required],
        AlarmaDescriptionControl : [''],
        AlarmaTypeControl : ['', Validators.required],
        AlarmaUsersControl: [''],
        AlarmaFieldControls: this.fb.array([]),
        AlarmaDependeControls: this.fb.array([]),
        AlarmaParametrosControls: this.fb.array([]),
        AlarmaTimeoutControl: ['']
      })

    
  }

  ngOnInit() {

    this.createAlarmaForm();
    
    switch (this.formMode){
      case 'create':
        this.initializeCreate();
        break;
      case 'edit':
        this.loadAlarma();
        this.setFormAsTouched();   
        break;
      case 'detail':
      this.loadAlarma();
      break;
      default: break;
    }
    this.detectChanges();
  }

  setFormAsTouched(){
    if (this.formMode=='edit'){
      if (!_.isEmpty(this.alarmaToEdit.tipo.nombre)){
        this.AlarmaForm.controls["AlarmaTypeControl"].setValue(this.alarmaToEdit.tipo.nombre);
      }else{
        this.AlarmaForm.controls["AlarmaTypeControl"].setValue("No description");
      }
      
      this.AlarmaForm.markAllAsTouched(); 
    }
  }

  initializeCreate(){
    this.newAlarma = new AlarmaModel();
    this.alarmasService.getTiposAlarma().subscribe(data=>{
      this.AlarmaTypeCombo = data.tipoAlarmas;
    });
    this.datatypeService.getDataType().subscribe(data => {
      this.dataTypeTree = _.sortBy(data, ['name'], ['asc']);
    });
  }

  loadAlarma(){

    let control = this.AlarmaForm.controls["AlarmaNameControl"] as FormControl;
    control.setValue(this.alarmaToEdit.nombre);
    control = this.AlarmaForm.controls["AlarmaDescriptionControl"] as FormControl;
    control.setValue(this.alarmaToEdit.descripcion);
    control = this.AlarmaForm.controls["AlarmaTimeoutControl"] as FormControl;
    let timeoutAlarma = 0;
    if(this.alarmaToEdit.timeout > 0){
      timeoutAlarma = this.alarmaToEdit.timeout / 60000
    }
    control.setValue(timeoutAlarma);
    control = this.AlarmaForm.controls["AlarmaUsersControl"] as FormControl;

    let users:string[] = this.alarmaToEdit.informa.map(user=>{
      return user.to.usuario;
    })
    control.setValue(users);

    this.alarmaToEdit.depende.forEach(depende=>{
      let translatedDataType = '';
      this.translate.get('DATA_TYPES_AND_AGRUPATIONS.'.concat(depende.to.es.nombre)).subscribe(value => translatedDataType = value);
      this.GetAlarmaDependeControls.push(this.fb.control(
        `${depende.to.tiene.nombre} --> ${translatedDataType} `
        ,Validators.required
        ));
    })
    this.alarmaToEdit.parametros.forEach((parametro,index)=>{
      this.alarmaParametros.push({
        uuidAlarma: this.alarmaToEdit.uuid,
        idFormControl: index.toString(),
        clave: parametro.clave,
        valor: parametro.valor,
        uuid: parametro.uuid
      });

      this.GetAlarmaParametrosControls.push(this.fb.control(
        parametro.valor
        ,Validators.required
        ));
    })
    
    this.datatypeService.getDataType().subscribe(data => {
      this.dataTypeTree = _.sortBy(data, ['name'], ['asc']);
    });

    this.loadEventosAlarma(this.alarmaToEdit.uuid);

    this.setReadOnlyControls();
   
  }

  loadEventosAlarma(uuid: string){
    this.eventosAlarma$ = this.alarmasService.getEventosAlarma(uuid).pipe(
      tap(res=> {
        console.log ();
      })
    )
  }

  setReadOnlyControls(){
    if (this.formMode === 'detail'){
      this.AlarmaForm.controls.AlarmaNameControl.disable();
      this.AlarmaForm.controls.AlarmaDescriptionControl.disable();
      this.AlarmaForm.controls.AlarmaTimeoutControl.disable();
      this.alarmaParametros.forEach(parametro =>{
        var formControl = this.GetAlarmaParametrosControls.controls[parametro.idFormControl] as FormControl;
        formControl.disable();
      });
    }

  }

  detectChanges() {
    this.timezone.currentTimeZone$.subscribe((data: string) => {
      this.timezoneBS.next(data);
    });
  }

  showModalDatos(controlName:string){
    this.openDialog(controlName);
  }

  openDialog(controlName:string) {
        
    var formControl = this.GetTipoAlarmaFieldControls.controls[controlName] as FormControl;
    const dialogRef = this.dialog.open(DeviceDatosModalComponent, {
      data: { "datos":"datos",
              "dataTypeTree": this.dataTypeTree
            },
      minWidth: 600
    });
    dialogRef.afterClosed().subscribe(selectedData => {
      let deviceTree = selectedData["deviceTree"] as string[];
      let datoPrefix = deviceTree.join(' --> ');
      let translatedDataType = '';
      this.translate.get('DATA_TYPES_AND_AGRUPATIONS.'.concat(selectedData.dato.es.nombre)).subscribe(value => translatedDataType = value);

        if(!_.isEmpty(selectedData) && (!selectedData["dato"]["es"]["codigo"] || selectedData["dato"]["es"]["codigo"] == null)){
          let data:DatoModel = selectedData["dato"] as DatoModel;
          formControl.setValue(`${datoPrefix} --> ${data.tiene.nombre} --> ${translatedDataType} ` );
          this.newAlarmaDepende.where(item=> item.idFormControl==controlName)[0].dato= data.uuid;
        } 
        else {
          let data:DatoModel = selectedData["dato"] as DatoModel;
          formControl.setValue(`${datoPrefix} --> ${data.tiene.nombre} --> ${translatedDataType} ` );
          this.newAlarmaDepende.where(item=> item.idFormControl==controlName)[0].dispositivo= data.tiene.uuid;
          this.newAlarmaDepende.where(item=> item.idFormControl==controlName)[0].codigo= data.es.codigo;
          this.newAlarmaDepende.where(item=> item.idFormControl==controlName)[0].dato= null;
        }
    });
  }

  showModalUsuarios(){
    this.openUsuariosDialog();
  }

  openUsuariosDialog() {
        
    var formControl = this.AlarmaForm.controls["AlarmaUsersControl"] as FormControl;
    let informa: InformaModel[] = this.alarmaToEdit!==undefined?  this.alarmaToEdit.informa : [] ;
    let width:number = 600;
    if (this.mobileMode) width= 200;
    const dialogRef = this.dialog.open(AlarmaShareComponent, {
      data: {
        selectedInforma:informa,
        selectedUsers:this.selectedUsers,
        formMode:this.formMode
      },
      minWidth: width
    });
    dialogRef.afterClosed().subscribe(selectedData => {
        if(!_.isEmpty(selectedData)){
          this.selectedUsers = selectedData;
          this.informaUsers = selectedData.map((data)=>{
            return data.uuid;
          }).toString();
          let users:string;
          users = selectedData.map((data)=>{
            return data.user;
          }).toString();
          formControl.setValue(users);

        }
    });
  }

  get getTipoAlarma(){
    if (this.alarmaToEdit){
      if (_.isEmpty(this.alarmaToEdit.tipo.nombre)){
        return "No description";
      }
      else{
        return this.alarmaToEdit.tipo.nombre
      }
    }    
  }


  get TipoAlarmaFieldControls(){
    return of (this.GetTipoAlarmaFieldControls.controls)
  }

  get GetTipoAlarmaFieldControls () {
    return this.AlarmaForm.get("AlarmaFieldControls") as FormArray;
  }

  get GetAlarmaDependeControls () {
    return this.AlarmaForm.get("AlarmaDependeControls") as FormArray;
  }

  get GetAlarmaParametrosControls () {
    return this.AlarmaForm.get("AlarmaParametrosControls") as FormArray;
  }

  selectionChangeAlarmaType() {
    let formArray :FormArray =this.AlarmaForm.get("AlarmaFieldControls") as FormArray;
    this.newAlarmaDepende = [];
    this.newAlarmaParametros = [];
    formArray.controls = [];
    if (this.SelectedAlarmaType.fields !== null){
      this.tipoAlarmaFields = JSON.parse(this.SelectedAlarmaType.fields) ;
      this.tipoAlarmaFields.forEach((field,i) => {
        this.GetTipoAlarmaFieldControls.push(this.fb.control(
          ''
          ,Validators.required
          ));
          this.createFieldAlarma(field,i.toString());
      });
    }
    
  }

  createFieldAlarma(field:FieldModel, id:string){
    if (field.tipo=="variable"){
      this.newAlarmaDepende.push({
        uuidAlarma:"",
        idFormControl: id,
        variable : field.clave,
        dato: ""
      })
    }else if(field.tipo=="parametro"){
      this.newAlarmaParametros.push({
        uuidAlarma:"",
        idFormControl:id,
        clave:field.clave,
        valor:""
      })
    }
    
  }

  addDatoAlarma(fields: FieldModel[]){
    fields.forEach(field=>{
      let depende:FieldModel= {
        clave: field.clave,
        descripcion:field.descripcion,
        tipo: field.tipo
      }
      this.newAlarma.depende.push()
    });
  }
 
  cancelForm() {
    //this.userForm.reset();
    this.cancelEmitter.emit(true);
  }

  refreshAlarmas() {
    //this.userForm.reset();
    this.refreshAlarmasEmitter.emit(true);
  }

  isAdminUser(): boolean {
    if (this.loginService.currentUserValue.rol.includes('admin')) {
      return true;
    } else {
      return false;
    }
  }

  isValid() {
    return this.AlarmaForm.valid;
  }

  updateInformaUsers(){
    let currentInformaUsers = this.alarmaToEdit.informa.map(informa=>{
      return informa.to.uuid;
    }).filter(user=>{
      return user!=this.alarmaToEdit.usuario;
    });

    let newInformaUsers;
    if(this.informaUsers){
      newInformaUsers = this.informaUsers.split(",");
    }
    let unsubscribeUsers = _.difference(currentInformaUsers,newInformaUsers)
    let subscribeUsers = _.difference(newInformaUsers,currentInformaUsers);

    subscribeUsers.forEach(user=>{
      this.alarmasService.informarAlarma(
        this.alarmaToEdit.uuid,
        user
      );
    })

    unsubscribeUsers.forEach(user=>{
      this.alarmaToEdit.informa.forEach(informa=>{
        if (informa.to.uuid==user){
          this.alarmasService.unsubscribeUserAlarma(
            informa.uuid
          ).subscribe(response=>{
            response;
          })
        }
      })
    })
  }

  updateParameters(){
    this.alarmaParametros.forEach(parametro=>{
      var formControl = this.GetAlarmaParametrosControls.controls[parametro.idFormControl] as FormControl;
      parametro.valor = formControl.value.toString();
      this.alarmasService.updateParameterAlarma(
        parametro.uuid,
        parametro.clave,
        parametro.valor
      ).subscribe(response=>{
        response;
      })
    });
  }

  onSubmit(){
    if (this.formMode=='create'){
      this.buildAlarmaObject();
      this.saveAlarma();
    }else if (this.formMode=='edit'){
      this.updateAlarma();
    }
    
  }

  updateAlarma(){
    let auxMessage = '';
    let auxMessage2 = '';
    let timeoutAlarma = 0;
    if (_.isNumber(this.AlarmaForm.get("AlarmaTimeoutControl").value)){
      timeoutAlarma = Number(this.AlarmaForm.get("AlarmaTimeoutControl").value) * 60000;
    }
    this.alarmasService.updateAlarma(
      this.alarmaToEdit.uuid,
      this.AlarmaForm.get("AlarmaNameControl").value,
      this.AlarmaForm.get("AlarmaDescriptionControl").value,
      timeoutAlarma
    ).subscribe(response=>{

      this.updateInformaUsers();
      this.updateParameters();
      if(response){
        this.translate.get('ALARMAS.ALARMAS_EDIT_OK').subscribe(value => auxMessage = value);
        this.translate.get('ALARMAS.SUCCESS').subscribe(value => auxMessage2 = value);        
        this.toastr.showSuccess(auxMessage, auxMessage2);
        this.refreshAlarmas();
          
      }else{
        this.translate.get('ALARMAS.ALARMAS_EDIT_ERROR').subscribe(value => auxMessage = value);
        this.translate.get('ALARMAS.ERROR').subscribe(value => auxMessage2 = value);
        this.toastr.showError(auxMessage, auxMessage2);
      }
    })
    this.cancelForm();
  }

  buildAlarmaObject(){
    this.newAlarma.usuario = this.loginService.currentUserValue.uuid;
    this.newAlarma.nombre = this.AlarmaForm.get("AlarmaNameControl").value;
    this.newAlarma.descripcion = this.AlarmaForm.get("AlarmaDescriptionControl").value;
    this.newAlarma.tipo = this.AlarmaForm.get("AlarmaTypeControl").value;
    if (_.isNumber(this.AlarmaForm.get("AlarmaTimeoutControl").value)){
      this.newAlarma.timeout = Number(this.AlarmaForm.get("AlarmaTimeoutControl").value) * 60000;
    }
    
    this.newAlarmaParametros.forEach(parametro=>{
      var formControl = this.GetTipoAlarmaFieldControls.controls[parametro.idFormControl] as FormControl;
      parametro.valor = formControl.value;
    });

  }

  saveAlarma(){
    let auxMessage = '';
    let auxMessage2 = '';


/// AQUÍ HAY QUE VOLVER A DISCRIMINAR SI TIENE CÓDIGO O NO EL TIPO DE DATO, PARA PASARLE A LA FUNCIÓN DE ALARMA DE UUID DATO O CODIGO DE TD + DISP

    this.alarmasService.createAlarma(
      this.newAlarma.nombre,
      this.newAlarma.descripcion,
      this.newAlarma.tipo.uuid,
      this.newAlarma.usuario
      //"d282603a-a328-4b9d-bf77-1885e571ad78" //iker
      //"192f8cc2-570c-4855-a2fa-d84cdfaab7d4" // aitor
    ).subscribe(data=>{
      if (data["uuid"]){
        this.newAlarmaParametros.forEach(parametro=>{
          this.alarmasService.addAlarmaParametro(
            data["uuid"],
            parametro.clave,
            parametro.valor.toString()
            )  
        });
        this.newAlarmaDepende.forEach(depende=>{
          if((!depende.codigo || depende.codigo != null) && (depende.dato && depende.dato != null)) {
            this.alarmasService.addAlarmaDepende(
              data["uuid"],
              depende.variable,
              depende.dato
              ) 
          } else {
            this.alarmasService.addAlarmaDependeDispYCodigo(
              data["uuid"],
              depende.dispositivo,
              depende.codigo,
              depende.variable)
            
          } 
          
 
        });
        if(this.informaUsers){
          this.informaUsers.split(",").forEach(user=>{
            if (user!==this.newAlarma.usuario){
              this.alarmasService.informarAlarma(
                data["uuid"],
                user
              )
            }
          })
        }
        this.alarmasService.updateAlarma(
          data["uuid"],
          null,
          null,
          this.newAlarma.timeout
        )

        this.translate.get('ALARMAS.ALARMAS_CREATE_OK').subscribe(value => auxMessage = value);
        this.translate.get('ALARMAS.SUCCESS').subscribe(value => auxMessage2 = value);        
        this.toastr.showSuccess(auxMessage, auxMessage2);
        this.refreshAlarmas();
      }else{
        this.translate.get('ALARMAS.ALARMAS_CREATE_ERROR').subscribe(value => auxMessage = value);
        this.translate.get('ALARMAS.ERROR').subscribe(value => auxMessage2 = value);
        this.toastr.showError(auxMessage, auxMessage2);
      }
      this.cancelForm();
    })

  }

}
