import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapaComponent } from 'src/app/sections/mapa/mapa.component';
import { AngularCesiumModule } from 'angular-cesium';
import { BillboardLayerDevicesComponent } from '../../components/mapa/billboard-layer-devices/billboard-layer-devices.component';
import { HtmlLayerDevicesComponent } from '../../components/mapa/html-layer-devices/html-layer-devices.component';

import { SharedModuleModule } from '../shared-module/shared-module.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material/material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapElementItemComponent } from '../../sections/mapa/map-element-item/map-element-item.component';
import { GetDatePipe } from '../../pipes/pipes/pipe-date.pipe';
import { HtmlLayerDeviceComponent } from '../../components/mapa/html-layer-devices/html-layer-device/html-layer-device.component';
import { HtmlLayerDeviceDetailsComponent } from '../../components/mapa/html-layer-devices/html-layer-device-details/html-layer-device-details.component';




@NgModule({
  declarations: [
    MapaComponent,
    MapElementItemComponent,
    BillboardLayerDevicesComponent,
    HtmlLayerDevicesComponent,
    HtmlLayerDeviceComponent,
    HtmlLayerDeviceDetailsComponent,

  ],
  imports: [
    CommonModule,
    SharedModuleModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularCesiumModule.forRoot({ fixEntitiesShadows: false, customPipes: [] }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  exports: [
    MapaComponent,
    MapElementItemComponent,
    BillboardLayerDevicesComponent,
    HtmlLayerDevicesComponent,
  ],
  providers: [GetDatePipe]
})
export class MapaModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
