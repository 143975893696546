import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { UserDashboardModel, UserModel } from 'src/app/models/userModel';
import { MAT_DIALOG_DATA, MatSelectChange, MatAutocompleteSelectedEvent, MatAutocomplete, MatChipInputEvent } from '@angular/material';
import { Dashboard } from 'src/app/models/widgets/widgets.model';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { LoginService } from 'src/app/services/login/login.service';
import { AlarmaModel, InformaModel, UsuarioModel } from 'src/app/sections/alarmas/models/alarma-models';
import { Observable } from 'rxjs';
import { Options } from 'ng5-slider';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-alarma-share',
  templateUrl: './alarma-share.component.html',
  styleUrls: ['./alarma-share.component.scss']
})
export class AlarmaShareComponent implements OnInit {

  userCollection: UserModel[] = [];
  usersSelected: UserModel[] = [];
  selectUsers = new FormControl();

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  userCtrl = new FormControl();
  filteredUsers: Observable<string[]>;
  users: string[] = [];
  allUsers: string[] = [];

  @ViewChild('userInput', {read:false,static:false}) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto',{read:false,static:false}) matAutocomplete: MatAutocomplete;

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedInforma: InformaModel[],
      selectedUsers: UserModel[],
      formMode:String
    }
    ) { 
      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((user: string | null) => user ? this._filter(user) : this.allUsers.slice()));
     
    }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    //Obtener lista general de usuarios
    this.userService.getUserCollectionReal().subscribe(result => {
      
      const auxUserArray: UserModel[] = this.removeUserNull(result);
      
      // Eliminamos el usuario logueado por que ya automaticamente se agrega a informa alarma cuando la alarma se crea
      auxUserArray.removeBy(item => item.uuid === this.loginService.currentUserValue.uuid);
      // Auto seleccionamos a los usuarios que ya esten en informa Alarma 
      this.checkUserHasInforma(auxUserArray)
      this.userCollection = _.sortBy(this.checkUserHasInforma(auxUserArray), ['name'], ['asc']);
      this.allUsers = this.userCollection.map(user=>{
        return user.user
      })

      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((user: string | null) => user ? this._filter(user) : this.allUsers.slice()));
      
    });
  }

  checkUserHasInforma(userCollection: UserModel[]): UserModel[] {
    const userCollectionWithInformaSelected: UserModel[] = [];

    //agregar estos usuarios a los usuarios seleccionados
    let selectedUsers : UserModel[]=[];
    if (this.data.formMode=="edit" && this.data.selectedInforma){
      this.data.selectedInforma.forEach(informa=>{
        userCollection.forEach(user=>{
          if (informa.to.uuid === user.uuid) {
            selectedUsers.push(user);
          }
        });
      })
    }

    else if (this.data.selectedUsers){
      this.data.selectedUsers.forEach(selectedUser=>{
        userCollection.forEach(user=>{
          if (selectedUser.uuid === user.uuid) {
            selectedUsers.push(user);
          }
        });
      })
    }
    
    this.selectUsers.setValue(selectedUsers);
    this.usersSelected = selectedUsers;

    this.users = selectedUsers.map(user=>{
      return user.user
    });
    
    return userCollection; //_.differenceBy(userCollection, userCollectionWithInformaSelected, 'uuid');
  }

  removeUserNull(userCollectionFromService: UserModel[]): UserModel[] {
    const auxUserArray: UserModel[] = [];
    for (const user of userCollectionFromService) {
      if (user.user === null) {
        auxUserArray.push(user);
      }
    }
    return userCollectionFromService = _.difference(userCollectionFromService, auxUserArray);
  }
  
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    //console.log(value);
    // Add our user
    if ((value || '').trim()) {
      this.users.push(value.trim());
      
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.userCtrl.setValue(null);
  }

  remove(user: string): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
      this.usersSelected.splice(index,1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    if (!this.users.includes(event.option.viewValue)){
      //console.log(event.option.viewValue);
      this.users.push(event.option.viewValue);
    }

    this.usersSelected= [];
    this.users.forEach(chipuser=>{
      let userModel = this.userCollection.filter(user=>{
        return (user.user==chipuser)
      })
      .firstOrDefault();
      this.usersSelected.push(userModel);
    })

    
    this.userCtrl.setValue('');
    this.userCtrl.reset();
    this.userCtrl.setValue(null);
    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allUsers.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

}
