import { AlarmType } from '../alarmType.model';
import { DeviceGraphItem } from '../device-graph.model';
import { AllUserGraphQLResponse } from '../graphQL/all-user-graphQL.response';

export class Alarm {
    uuid: string;
    name: string;
    subject: string;
    body?: string;
    description?: string;
    type: AlarmType;
    state: AlarmState;
    param: AlarmParams; // campo valor del parámetro
    // alarms: string[]; ver cómo se puede representar
    devices?: DeviceGraphItem[];
    supervisa: string[];
}

export class AlarmParams {
    uuid: string;
    clave: string;
    valor: string;
}

export class AlarmInforma {
    uuid: string;
    disabled: string;
    Usuario: AllUserGraphQLResponse;
}

export class AlarmSupervisa {
    uuid: string;
    state: string;
    disabled: string;
    Dato: AlarmDato;
}

export interface AlarmDato {
    uuid: string;
    tiene: {
        uuid: string;
        nombre: string;
    };
    es: {
        uuid: string;
        nombre: string;
        unidad: string;
    };
}

export class AlarmTypeResponse {
    uuid: string;
    codigo: string;
    nombre: string;
}

export enum AlarmState {
    activated = 'Activada',
    desactivated = 'Desactivada',
    alarmed = 'Alarmada'
}
