import {
  Component, OnInit, Input, OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AcNotification, ActionType, ViewerConfiguration } from 'angular-cesium';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeviceDatosComponent, LastDataModel } from 'src/app/components/device-datos/device-datos.component';
import { DataTypeItem, DatatypeTreeHierarchi } from 'src/app/models/datatype/datatype-hierarchi.model';
import { TimezoneService } from 'src/app/services/timezone.service';
import { SeleccionTiposDatosModalComponent } from '../../components/seleccion-tipos-datos-modal/seleccion-tipos-datos-modal.component';
import { DataDevice, GeoDevicesService, LastDataDevice } from '../../services/mapa/geodevices.service';
import { MapaServiceService } from './mapa-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
  providers: [ViewerConfiguration]
})
export class MapaComponent implements OnInit, OnDestroy {

  @Input() name: string;
  dataTypeTree: DatatypeTreeHierarchi[];
  timezoneBS: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedDataTypes: DataTypeItem[] = [];
  modalDataTypesSuscription: Subscription;

  entities$: Subject<{
    entityMap: AcNotification,
    lastDataDevice: LastDataDevice, selected: boolean
  }[]> = new Subject();
  // filteredEntities$: Observable<LastDataModel[]>;

  form: FormGroup;

  constructor(
    private viewerConf: ViewerConfiguration,
    private dialog: MatDialog,
    private timezone: TimezoneService,
    private geoDevicesService: GeoDevicesService,
    private mapService: MapaServiceService,
    private fb: FormBuilder

  ) {
    this.viewerConf.viewerOptions = {
      selectionIndicator: true,
      timeline: false,
      infoBox: true,
      fullscreenButton: true,
      baseLayerPicker: false,
      animation: false,
      shouldAnimate: false,
      homeButton: true,
      geocoder: false,
      navigationHelpButton: false,
      navigationInstructionsInitiallyVisible: false,
      mapMode2D: Cesium.MapMode2D.ROTATE,
      sceneMode: Cesium.SceneMode.SCENE2D
    };

    // Will be called on viewer initialistion   
    viewerConf.viewerModifier = (viewer: any) => {
      // Remove default double click zoom behaviour  
      //viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(environment.map.initial_view.x, environment.map.initial_view.y, environment.map.initial_view.zoom)
      });
    };
    this.mapService.mapElementsFiltered$
      .subscribe(obj => {
        this.entities$.next(obj)
      })

    this.form = this.fb.group({
      search: ['']
    })
    this.form.controls.search.valueChanges.subscribe(value => this.mapService.mapFilterText$.next(value))
  }
  ngOnDestroy(): void {
    if (this.modalDataTypesSuscription) {
      this.modalDataTypesSuscription.unsubscribe();
    }

  }

  ngOnInit() {
    this.detectChanges();
    this.loadGeoPos();

  }



  detectChanges() {
    this.timezone.currentTimeZone$.subscribe((data: string) => {
      this.timezoneBS.next(data);
    });
  }


  openDatosModal(deviceId: string) {
    this.openDialogDatos(deviceId);
  }

  openDialogDatos(deviceId: string) {
    let selectedDataTypes: DataTypeItem[] = this.selectedDataTypes;
    if (sessionStorage.getItem('selectedDataTypes')) {
      selectedDataTypes = JSON.parse(sessionStorage.getItem('selectedDataTypes'));
    } else {
      selectedDataTypes = [];
    }

    const dialogRef = this.dialog.open(DeviceDatosComponent, {
      data: {
        "device": deviceId,
        "selectedDataTypes": selectedDataTypes.map(dataType => dataType.uuid)

      },
      minWidth: 600
    });
    dialogRef.afterClosed();//subscribe

  }


  getDeviceData(deviceId): Observable<LastDataDevice> {
    return this.geoDevicesService.getLastDataDispositivo(deviceId)
  }


  loadGeoPos() {
    this.geoDevicesService.getAllGeoPos()
      .pipe(
        map(data => {
          return data.map(geopos => {
            const randomSign = () => Math.round(Math.random()) * 2 - 1;
            const lat = (geopos.latitud ? geopos.latitud : 70 * Math.random() * randomSign());
            const long = (geopos.longitud ? geopos.longitud : 180 * Math.random() * randomSign());
            let entityMap: AcNotification;
            entityMap = {
              id: geopos.uuid,
              actionType: ActionType.ADD_UPDATE,
              entity: {
                id: geopos.uuid,
                name: `${geopos.nombre}`,
                position: Cesium.Cartesian3.fromDegrees(long, lat, 0.00),
                show: true,
                color: Cesium.Color.RED
              }
            }
            return entityMap;
          })
        }
        ),
        map(entityMap => {
          return entityMap.map(deviceData => {
            return this.getDeviceData(deviceData.id).pipe(
              switchMap(r => {
                return this.geoDevicesService.getLastDataDispositivo(deviceData.id).pipe(
                  map(lastDataDevice => {
                    if (!lastDataDevice) {
                      return lastDataDevice;
                    }
                    const DevicesNoDuplicates: DataDevice[] = [];
                    lastDataDevice.tiene.forEach(detail => {
                      if (DevicesNoDuplicates.some((data) => data.to.es.codigo === detail.to.es.codigo)) {
                        const filterDuplicate = lastDataDevice.tiene.filter(data => data.to.es.codigo === detail.to.es.codigo);
                        const lastDuplicate = filterDuplicate.sort((a, b) => Number(b.to.lastFecha) - Number(a.to.lastFecha));
                        DevicesNoDuplicates.splice(
                          DevicesNoDuplicates.findIndex(data => data.to.es.codigo === detail.to.es.codigo),
                          1,
                          lastDuplicate[0]
                        )
                      } else {
                        DevicesNoDuplicates.push(detail);
                      }
                    })

                    lastDataDevice.tiene = DevicesNoDuplicates;
                    return lastDataDevice;
                  }))
              }),
              map(lastDataDevice => {
                const data: {
                  entityMap: AcNotification,
                  lastDataDevice: LastDataDevice, selected: boolean
                } = { entityMap: deviceData, lastDataDevice, selected: false }
                return data
              }))

          })
        }),

      )

      .pipe(switchMap(result => combineLatest(result)))
      .subscribe({
        next: r => {
          this.mapService.addAllElements(r);
        },
        error: e => {
          console.error(e)
        },
        complete: () => console.log('')
      })

  }

  openTipoDatosModal() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(SeleccionTiposDatosModalComponent, {
      data: {
        "datos": "datos",
        "dataTypeTree": this.dataTypeTree
      },
      minWidth: 600
    });
  }



}


