import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: string|number): string|number {
    if (value === undefined || value === '-') {
      return 'NO DATA';
    }

    if (typeof value === 'number') {
      return (Math.round((value + Number.EPSILON) * 1000) / 1000);
    } else {
      return value;
    }
  }

}
