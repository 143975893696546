// MODULES
import { ChartsModule } from 'ng2-charts';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';


// TRANSLATE
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// COMPONENTS
import { MenuDispositivosComponent } from 'src/app/components/menu-dispositivos/menu-dispositivos.component';
import { NgChartComponent } from 'src/app/components/charts/ng-chart/ng-chart.component';
import { ChartsOptionsComponent } from 'src/app/components/charts-options/charts-options.component';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { PeriodSelectorComponent } from 'src/app/components/period-selector/period-selector.component';
import { SvgDeviceComponent } from 'src/app/components/svg/svg-device/svg-device.component';
import { BlueIconComponent } from 'src/app/components/blue-icon/blue-icon.component';
import { MenuDashboardComponent } from 'src/app/components/menu-dashboard/menu-dashboard.component';
import { DeviceDetailComponent } from './../../components/device-detail/device-detail.component';
import { BottomSheetComponent } from './../../components/bottom-sheet/bottom-sheet.component';
import { DateRangeComponent } from './../../components/date-range/date-range.component';
import { DeviceInfoItemComponent } from 'src/app/components/device-info-item/device-info-item.component';
import { TabContentDeviceComponent } from 'src/app/components/tab-content-device/tab-content-device.component';
import { TabContentLastStatsDeviceComponent } from 'src/app/components/tab-content-last-stats-device/tab-content-last-stats-device.component';
import { DevicesTabComponent } from 'src/app/components/devices-tab/devices-tab.component';
import { AlarmaDevicesTabComponent } from 'src/app/components/alarma-devices-tab/alarma-devices-tab.component';
import { MapaDevicesTabComponent } from 'src/app/components/mapa-devices-tab/mapa-devices-tab.component';
import { BottomSheetDeviceComponent } from 'src/app/components/bottom-sheet-device/bottom-sheet-device.component';
import { GraphicAddDevicesComponent } from 'src/app/components/graphic-add-devices/graphic-add-devices.component';
import { GraphFactoryDeepListComponent } from 'src/app/components/card-list-item/graph-factory-deep-list/graph-factory-deep-list.component';
import { HomeComponent } from 'src/app/sections/home/home.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DeviceLabelsModalComponent } from './../../components/device-labels-modal/device-labels-modal.component';
import { DynamicFormFieldComponent } from './../../components/dynamic-form-field/dynamic-form-field.component';
import { DynamicFormComponent } from './../../components/dynamic-form/dynamic-form.component';
import { AlarmaBadgeComponent } from 'src/app/components/alarma-badge/alarma-badge.component';
// PIPE
import { UniquePipe } from 'src/app/pipes/pipes/unique-values.pipe';
import { NullFormatPipe } from 'src/app/pipes/pipes/null-format.pipe';
import { DeviceDetailListComponent } from 'src/app/components/card-list-item/device-detail-list/device-detail-list.component';
import { DeviceLastStatsListComponent } from 'src/app/components/card-list-item/device-last-stats-list/device-last-stats-list.component';
import { LoadingComponent } from 'src/app/components/shared/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FabButtonCloseComponent } from 'src/app/components/fab-button-close/fab-button-close.component';
import { GraphisDevicesHierarchiComponent } from 'src/app/components/graphis-devices-hierarchi/graphis-devices-hierarchi.component';
import { AlarmaDevicesHierarchiComponent } from 'src/app/components/alarma-devices-hierarchi/alarma-devices-hierarchi.component';
import { MapaDevicesHierarchiComponent } from 'src/app/components/mapa-devices-hierarchi/mapa-devices-hierarchi.component';
import { NoGraphicDataComponent } from 'src/app/components/shared/no-graphic-data/no-graphic-data.component';
import { DatatypeNavigateComponent } from 'src/app/components/datatype-navigate/datatype-navigate.component';
import { DatatypeTabComponent } from 'src/app/components/datatype-tab/datatype-tab.component';
import { AlarmaDatatypeNavigateComponent } from 'src/app/components/alarma-datatype-navigate/alarma-datatype-navigate.component';
import { MapaDatatypeNavigateComponent } from 'src/app/components/mapa-datatype-navigate/mapa-datatype-navigate.component';
import { AlarmaDatatypeTabComponent } from 'src/app/components/alarma-datatype-tab/alarma-datatype-tab.component';
import { MapaDatatypeTabComponent } from 'src/app/components/mapa-datatype-tab/mapa-datatype-tab.component';
import { DevicesSearchCardsComponent } from 'src/app/components/devices-search-cards/devices-search-cards.component';
import { NavbarComponent } from 'src/app/components/shared/navbar/navbar.component';
import { SectionTitleComponent } from 'src/app/components/shared/section-title/section-title.component';
import { UserAccountComponent } from 'src/app/components/shared/user-account/user-account.component';
import * as PlotlyJS from 'src/assets/script/plotly/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
import { PlotlyComponent } from 'src/app/components/charts/plotly/plotly.component';
import { TimezoneComponent } from 'src/app/components/shared/timezone/timezone.component';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { DashboardFormComponent } from 'src/app/sections/dashboard/dashboard-form/dashboard-form.component';
import { DashboardLayoutComponent } from 'src/app/sections/dashboard/dashboard-layout/dashboard-layout.component';
import { EditFormComponent } from 'src/app/components/shared/edit-form/edit-form.component';
import { WidgetFormComponent } from 'src/app/sections/dashboard/widget-form/widget-form.component';
import { TabStepperComponent } from 'src/app/components/tab-stepper/tab-stepper.component';
import { TabStepperModalComponent } from 'src/app/components/tab-stepper-modal/tab-stepper-modal.component';
import { TabStepperMapaModalComponent } from 'src/app/components/tab-stepper-mapa-modal/tab-stepper-mapa-modal.component';
import { IdiomaComponent } from 'src/app/components/shared/idioma/idioma.component';
import { ListDashboardComponent } from 'src/app/components/list-dashboard/list-dashboard.component';
import { MatTableComponent } from 'src/app/components/mat-table/mat-table.component';
import { NumberFormatPipe } from 'src/app/pipes/pipes/number-format.pipe';
import { UnityFormatPipe } from 'src/app/pipes/pipes/unity-format.pipe';
import { DigitalWidgetComponent } from 'src/app/components/digital-widget/digital-widget.component';
import { ChangeUserPasswordFormComponent } from 'src/app/components/change-user-password-form/change-user-password-form.component';
import { UserDetailComponent } from 'src/app/components/user-detail/user-detail.component';
import { UserFormComponent } from 'src/app/components/user-form/user-form.component';
import { ShareDashboardComponent } from 'src/app/components/share-dashboard/share-dashboard.component';
import { AlarmaShareComponent } from 'src/app/components/alarma-share/alarma-share.component';
import { DeviceDatosModalComponent } from './../../components/device-datos-modal/device-datos-modal.component';
import { AlarmaEventosComponent } from 'src/app/components/alarma-eventos/alarma-eventos.component';
import { DeviceDatosMapaModalComponent } from 'src/app/components/device-datos-mapa-modal/device-datos-mapa-modal.component';
import { DeviceDatosComponent } from 'src/app/components/device-datos/device-datos.component';
import { SeleccionTiposDatosModalComponent } from 'src/app/components/seleccion-tipos-datos-modal/seleccion-tipos-datos-modal.component';
import { GetDatePipe } from '../../pipes/pipes/pipe-date.pipe';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    GetDatePipe,
    UniquePipe,
    MenuDashboardComponent,
    GraphFactoryDeepListComponent,
    MenuDispositivosComponent,
    DevicesTabComponent,
    AlarmaDevicesTabComponent,
    AlarmaEventosComponent,
    MapaDevicesTabComponent,
    TabContentDeviceComponent,
    TabContentLastStatsDeviceComponent,
    DateRangeComponent,
    ChartsOptionsComponent,
    SvgDeviceComponent,
    BlueIconComponent,
    DeviceDetailComponent,
    NgChartComponent,
    DeviceInfoItemComponent,
    BottomSheetComponent,
    CalendarComponent,
    PeriodSelectorComponent,
    BottomSheetDeviceComponent,
    GraphicAddDevicesComponent,
    NullFormatPipe,
    NumberFormatPipe,
    UnityFormatPipe,
    DeviceDetailListComponent,
    DeviceLastStatsListComponent,
    GraphisDevicesHierarchiComponent,
    AlarmaDevicesHierarchiComponent,
    MapaDevicesHierarchiComponent,
    FabButtonCloseComponent,
    DatatypeNavigateComponent,
    DatatypeTabComponent,
    AlarmaDatatypeNavigateComponent,
    MapaDatatypeNavigateComponent,
    AlarmaDatatypeTabComponent,
    MapaDatatypeTabComponent,
    NoGraphicDataComponent,
    LoadingComponent,
    DevicesSearchCardsComponent,
    NavbarComponent,
    SectionTitleComponent,
    UserAccountComponent,
    TimezoneComponent,
    PlotlyComponent,
    MatTableComponent,
    DigitalWidgetComponent,
    ConfirmModalComponent,
    DashboardFormComponent,
    ChangeUserPasswordFormComponent,
    DashboardLayoutComponent,
    EditFormComponent,
    DeviceLabelsModalComponent,
    DynamicFormFieldComponent,
    DynamicFormComponent,
    WidgetFormComponent,
    TabStepperComponent,
    TabStepperModalComponent,
    TabStepperMapaModalComponent,
    IdiomaComponent,
    ListDashboardComponent,
    UserDetailComponent,
    UserFormComponent,
    ShareDashboardComponent,
    AlarmaShareComponent,
    DeviceDatosModalComponent,
    DeviceDatosMapaModalComponent,
    DeviceDatosComponent,
    AlarmaBadgeComponent,
    SeleccionTiposDatosModalComponent
  ],
  entryComponents: [
    DateRangeComponent,
    ChartsOptionsComponent,
    BottomSheetComponent,
    CalendarComponent,
    PeriodSelectorComponent,
    BottomSheetDeviceComponent,
    GraphicAddDevicesComponent,
    NgChartComponent,
    FabButtonCloseComponent,
    DeviceDetailListComponent,
    DeviceLastStatsListComponent,
    GraphisDevicesHierarchiComponent,
    AlarmaDevicesHierarchiComponent,
    MapaDevicesHierarchiComponent,
    LoadingComponent,
    NoGraphicDataComponent,
    DatatypeNavigateComponent,
    DatatypeTabComponent,
    AlarmaDatatypeNavigateComponent,
    MapaDatatypeNavigateComponent,
    AlarmaDatatypeTabComponent,
    MapaDatatypeTabComponent,
    DevicesSearchCardsComponent,
    NavbarComponent,
    SectionTitleComponent,
    UserAccountComponent,
    TimezoneComponent,
    PlotlyComponent,
    MatTableComponent,
    DigitalWidgetComponent,
    ConfirmModalComponent,
    DashboardFormComponent,
    ChangeUserPasswordFormComponent,
    DashboardLayoutComponent,
    EditFormComponent,
    DeviceLabelsModalComponent,
    DynamicFormFieldComponent,
    DynamicFormComponent,
    WidgetFormComponent,
    TabStepperComponent,
    TabStepperModalComponent,
    TabStepperMapaModalComponent,
    IdiomaComponent,
    ListDashboardComponent,
    UserDetailComponent,
    UserFormComponent,
    ShareDashboardComponent,
    AlarmaShareComponent,
    DeviceDatosModalComponent,
    DeviceDatosMapaModalComponent,
    DeviceDatosComponent,
    AlarmaBadgeComponent,
    SeleccionTiposDatosModalComponent
  ],

  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    PlotlyModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    ScrollingModule
  ],
  providers: [
    NavbarComponent,
    SectionTitleComponent,
    UserAccountComponent,
    TimezoneComponent,
    MenuDashboardComponent,
    TabContentDeviceComponent,
    TabContentLastStatsDeviceComponent,
    DevicesTabComponent,
    AlarmaDevicesTabComponent,
    AlarmaEventosComponent,
    MapaDevicesTabComponent,
    MenuDispositivosComponent,
    SvgDeviceComponent,
    BlueIconComponent,
    HomeComponent,
    IdiomaComponent,
    TabStepperComponent,
    TabStepperModalComponent,
    TabStepperMapaModalComponent,
  ],
  exports: [
    GetDatePipe,
    MenuDashboardComponent,
    GraphFactoryDeepListComponent,
    MenuDispositivosComponent,
    DevicesTabComponent,
    AlarmaDevicesTabComponent,
    AlarmaEventosComponent,
    MapaDevicesTabComponent,
    TabContentDeviceComponent,
    TabContentLastStatsDeviceComponent,
    SvgDeviceComponent,
    BlueIconComponent,
    DateRangeComponent,
    DeviceDetailComponent,
    NgChartComponent,
    DeviceInfoItemComponent,
    ChartsOptionsComponent,
    BottomSheetComponent,
    CalendarComponent,
    PeriodSelectorComponent,
    BottomSheetDeviceComponent,
    GraphicAddDevicesComponent,
    NullFormatPipe,
    NumberFormatPipe,
    UnityFormatPipe,
    FabButtonCloseComponent,
    GraphisDevicesHierarchiComponent,
    AlarmaDevicesHierarchiComponent,
    MapaDevicesHierarchiComponent,
    DeviceDetailListComponent,
    DeviceLastStatsListComponent,
    LoadingComponent,
    NoGraphicDataComponent,
    DatatypeNavigateComponent,
    DatatypeTabComponent,
    AlarmaDatatypeNavigateComponent,
    MapaDatatypeNavigateComponent,
    AlarmaDatatypeTabComponent,
    MapaDatatypeTabComponent,
    DevicesSearchCardsComponent,
    NavbarComponent,
    SectionTitleComponent,
    UserAccountComponent,
    TimezoneComponent,
    PlotlyComponent,
    MatTableComponent,
    DigitalWidgetComponent,
    ConfirmModalComponent,
    DashboardFormComponent,
    ChangeUserPasswordFormComponent,
    DashboardLayoutComponent,
    EditFormComponent,
    DeviceLabelsModalComponent,
    DynamicFormFieldComponent,
    DynamicFormComponent,
    WidgetFormComponent,
    TabStepperComponent,
    TabStepperModalComponent,
    TabStepperMapaModalComponent,
    IdiomaComponent,
    ListDashboardComponent,
    UserDetailComponent,
    UserFormComponent,
    ShareDashboardComponent,
    AlarmaShareComponent,
    DeviceDatosModalComponent,
    DeviceDatosMapaModalComponent,
    DeviceDatosComponent,
    AlarmaBadgeComponent,
    SeleccionTiposDatosModalComponent
  ]
})
export class SharedModuleModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
