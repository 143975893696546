
import { Widget, WidgetBasicParam } from './widget-interfaces.model';

export class Dashboard {
    uuid: string;
    name: string;
    widgets: Widget<WidgetBasicParam>[];
    layout?: number;

    constructor(uuid: string, name: string, widgets?: Widget<WidgetBasicParam>[]) {
        this.uuid = uuid;
        this.name = name;
        this.widgets = widgets;
    }
}
