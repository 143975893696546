import { Component, Input, OnInit } from '@angular/core';
import { DataDevice } from '../../../../services/mapa/geodevices.service';

interface DataDeviceWithFecha extends DataDevice {
  fecha: string;
}

@Component({
  selector: 'app-html-layer-device-details',
  templateUrl: './html-layer-device-details.component.html',
  styleUrls: ['./html-layer-device-details.component.scss']
})
export class HtmlLayerDeviceDetailsComponent implements OnInit {

  @Input() detail: DataDeviceWithFecha;

  constructor() { }

  ngOnInit() {
  }

}
