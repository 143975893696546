import { Moment } from 'moment';
import * as moment from 'moment';

export class DateRange {
    from: Moment;
    to: Moment;
    constructor(from: Moment, to: Moment) {
        this.from = from;
        this.to = to;
    }

    public getRange(): string[] {
        const range: string[] = [];
        for (const f = moment(this.from); f.diff(this.to, 'days') <= 0; f.add(1, 'days')) {
            range.push(f.format('DD-MM'));
        }
        return range;
    }
}
