import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface AlarmToDelete {
    alarmUUID: string;
}

export class DeleteAlarmBuilder {
    public _ALARMUUID: string;

    private get _mutation() {
        return gql `mutation deleteAlarm($alarmUUID: ID!) {
            DeleteAlarma(uuid: $alarmUUID) {
              uuid
            }
          }`;
    }

    public withAlarmUUID(alarmToDelete: string): this {
        this._ALARMUUID = alarmToDelete;
        return this;
    }

    public build() {
        const mutationParams: AlarmToDelete  = { alarmUUID: this._ALARMUUID };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
