export const environment = {
   production: 'false',
   influxDbHost: '/tsdata/',
   influxDb: 'lynx',
   influxUser: 'lynx',
   influxPassword: 'lynx',
   graphQlHost: '/back/',
   auth: '/auth/',
   iridium: 'true',
   features: '[iridium]',
   ws_graphql: 'graphql',
   roles: 'devices,alarms,iridium,map,last_measures,user_manager',
   map: {
      initial_view: {
         x: -70.6482700,
         y: -35.4569400,
         zoom: 7500000.0
      }
   }
};

