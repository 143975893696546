import { MatDialogModule } from '@angular/material/dialog';
import { ChartsModule } from 'ng2-charts';
import { MaterialModule } from './../material/material.module';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispositivosRoutingModule } from './dispositivos-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceInfoModalComponent } from 'src/app/components/device-info-modal/device-info-modal.component';
import { LoginModule } from '../login/login.module';

@NgModule({
  declarations: [
    DeviceInfoModalComponent
  ],
  imports: [
    CommonModule,
    LoginModule,
    DispositivosRoutingModule,
    BrowserAnimationsModule,
    DashboardModule,
    SharedModuleModule,
    TranslateModule,
    MaterialModule,
    ChartsModule,
    MatDialogModule,

  ],
  entryComponents: [
    DeviceInfoModalComponent
  ]
})
export class DispositivosModule { }
