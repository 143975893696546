import { DeviceTab } from './device-tab.model';
import { ListItem } from '../../contract/list-item.model';
import { LynxCollection } from '../../contract/collection.model';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';

export class DeviceTabCollection extends LynxCollection<DeviceTab> {

    constructor(private deviceService: DispositivoService) {
        super();
    }
    public buildItem(newItem: ListItem): DeviceTab {
        if (newItem === null || newItem === undefined) {
            return null;
        }
        const newDeviceTabInfo: DeviceTab = new DeviceTab(newItem);
        return newDeviceTabInfo;
    }

}