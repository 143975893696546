import { ListItemSelectable, ListItem, State } from './contract/list-item.model';
import { FactoryDetailGraphQLResponse } from './graphQL/factory-graphQL.respponse';
import { DeviceTab } from './device/tab/device-tab.model';

export class FactoryModel implements ListItemSelectable {
  public selected: boolean;
  public selectedState: State;
  public uuid: string;
  public name: string;
  public descripcion: string;
  public showTab: boolean;

  
  constructor(data?:FactoryDetailGraphQLResponse) {
    this.selectedState = State.selectable;
    this.uuid = data.uuid;
    this.name = data.nombre;
    this.descripcion = data.descripcion;
    this.selected = false;
    this.showTab = false;
  }

  public fromDeviceTab(item: DeviceTab) {
    this.selectedState = State.selected;
    this.uuid = item.uuid;
    this.name = item.name;
    this.descripcion = item.name;
  }
  
  public fromListItem(item: ListItem){
    this.uuid = item.uuid;
    this.name = item.name;
    this.selectedState = State.selectable;
    this.descripcion = item.name;
}

}
