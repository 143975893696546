import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { TimezoneService, Timezone } from 'src/app/services/timezone.service';
import { UserService } from 'src/app/services/user/user.service';
import { FactorySimpleModel } from 'src/app/models/FactorySimple.model';
import { UserModel } from 'src/app/models/userModel';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnChanges {

  @Input() formMode: string;
  @Input() userToEdit: UserModel;
  @Output() cancelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitEmitter: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Output() editEmitter: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Output() factoriesRemoveEmitter: EventEmitter<FactorySimpleModel[]> = new EventEmitter<FactorySimpleModel[]>();
  @Output() factoriesAddEmitter: EventEmitter<FactorySimpleModel[]> = new EventEmitter<FactorySimpleModel[]>();
  visualizations: any
  timezoneCollection: string[] = [];
  userForm: FormGroup;
  selectedOption = 'UTC';
  public user: UserModel = {
    uuid: '',
    user: '',
    name: '',
    firstName: '',
    timeZone: '',
    language: '',
    state: '',
    email: '',
    // pwd: '',
    rol: [],
    factories: []
  };
  factoryCollection: FactorySimpleModel[] = [];
  oldFactoryCollection: FactorySimpleModel[] = [];
  checkboxAdminValue = false;
  checkboxUserManagerValue = false;
  checkedAdmin = false;
  checkedUserManager = false;
  hide: boolean = true;
  originalRoles: string[];
  addedRoles: string[];

  constructor(private formBuilder: FormBuilder,
    private timezoneService: TimezoneService,
    private userService: UserService,
    private loginService: LoginService,
  ) {
    this.userForm = this.formBuilder.group({
      uuid: '0',
      user: '',
      name: '',
      firstName: '',
      timeZone: 'UTC',
      language: 'es',
      state: 'Activado',
      email: '',
      rol: [],
      // pwd: '',
      factories: []
    }),
      this.isAdminUser()

  }

  ngOnChanges() {
    this.visualizations = environment.roles.trim().replace(/\s/g, "").split(',');
    this.getTimeZoneCollection();
    this.getFactoryCollection();
    if (this.formMode === 'edit') {
      this.addedRoles = Object.assign([], this.userToEdit.rol)
      this.userForm = this.formBuilder.group({
        uuid: this.userToEdit.uuid,
        user: this.userToEdit.user,
        name: this.userToEdit.name,
        firstName: this.userToEdit.firstName,
        timeZone: this.userToEdit.timeZone,
        language: this.userToEdit.language,
        state: this.userToEdit.state,
        email: this.userToEdit.email,
        // pwd: this.userToEdit.pwd,
        rol: [this.userToEdit.rol],
        factories: this.userToEdit.factories
      });
      this.selectedOption = this.userToEdit.timeZone;
      if (this.userToEdit.rol.includes('admin')) {
        this.checkedAdmin = true;
        this.setAdminValue(this.checkboxAdminValue);
      }
      if (this.userToEdit.rol.includes('user_manager')) {
        this.checkedUserManager = true;
        this.setUserManagerValue(this.checkboxUserManagerValue);
      }
    }
  }

  getFactoryCollection() {
    this.userService.getAllDispositivosRaiz().subscribe((data: FactorySimpleModel[]) => {
      this.factoryCollection = this.checkedFactoriesSelectedWhenEdit(data);
    });
  }

  checkedFactoriesSelectedWhenEdit(factoryCollection: FactorySimpleModel[]): FactorySimpleModel[] {
    if (this.formMode === 'edit') {
      this.oldFactoryCollection = this.userToEdit.factories;
      for (const factory of factoryCollection) {
        for (const userFactory of this.userToEdit.factories) {
          if (factory.uuid === userFactory.uuid) {
            this.factorySelected(factory);
          }
        }
      }
    }
    return factoryCollection;
  }

  getTimeZoneCollection() {
    this.timezoneService.getTimezoneCollection().subscribe((data: Timezone[]) => {
      for (const timezone of data) {
        for (const utc of timezone.utc) {
          this.timezoneCollection.push(utc);
        }
      }
      this.timezoneCollection = _.sortBy(this.timezoneCollection);
    });
  }

  cancelForm() {
    this.userForm.reset();
    this.cancelEmitter.emit(true);
  }

  isDataEmpty() {
    if (_.isEmpty(this.userForm.value.user) || _.isEmpty(this.userForm.value.name) || _.isEmpty(this.userForm.value.firstName) ||
      _.isEmpty(this.userForm.value.email) || _.isEmpty(this.user.factories)) {
      return true;
    } else {
      return false;
    }
  }

  onSubmitForm(formValue: UserModel) {
    if (this.formMode === 'create') {
      formValue.rol = [];
      let auxFactoryCollection: FactorySimpleModel[] = [];
      let auxRoleCollection: string[] = [];
      auxFactoryCollection = this.user.factories;
      auxRoleCollection = this.user.rol;
      this.user = formValue;
      this.user.user = this.user.user.toLowerCase(); // Lo pasamos a minúsculas para que no haya problemas de duplicados
      this.user.factories = auxFactoryCollection;
      if (this.checkboxAdminValue) {
        formValue.rol.push('admin');
      }
      this.user.rol = auxRoleCollection;
      this.user.timeZone = this.selectedOption;
      this.submitEmitter.emit(this.user);
      this.userForm.reset();
    } else {
      this.user.uuid = formValue.uuid;
      this.user.user = formValue.user;
      this.user.name = formValue.name;
      this.user.firstName = formValue.firstName;
      this.user.language = formValue.language;
      this.user.state = formValue.state;
      this.user.email = formValue.email;
      // this.user.pwd = formValue.pwd;
      this.modifyAdminRole();
      this.user.rol = this.addedRoles;
      this.user.timeZone = this.selectedOption;
      const factoriesToDelete: FactorySimpleModel[] = _.differenceBy(this.oldFactoryCollection, this.user.factories, 'uuid');
      const factoriesToAdd: FactorySimpleModel[] = _.differenceBy(this.user.factories, this.oldFactoryCollection, 'uuid');
      this.editEmitter.emit(this.user);
      this.factoriesRemoveEmitter.emit(factoriesToDelete);
      this.factoriesAddEmitter.emit(factoriesToAdd);
      this.userForm.reset();
    }
  }

  factorySelected(factory: FactorySimpleModel) {
    if (factory.selected === false) {
      this.user.factories.add(factory);
    } else {
      this.user.factories.removeBy(item => item.uuid === factory.uuid);
    }
    factory.selected = !factory.selected;
  }


  rolesSelected(event, value) {
    if (this.formMode === 'edit') {
      if (event.checked == true && !this.userToEdit.rol.includes(value)) {
        this.addedRoles.push(value)
      } else if (event.checked == false && this.userToEdit.rol.includes(value)) {
        this.addedRoles = this.addedRoles.filter(e => e !== value)
      }
    } else {
      if (event.checked == true && !this.user.rol.includes(value)) {
        this.user.rol.push(value)
      } else if (event.checked == false && this.user.rol.includes(value)) {
        this.user.rol = this.user.rol.filter(e => e !== value)
      }
    }


  }

  setAdminValue(isAdmin) {
    this.checkboxAdminValue = !isAdmin;
  }

  setUserManagerValue(isUserManager) {
    this.checkboxUserManagerValue = !isUserManager;
  }


  public changeTimeZone(event) {
    this.selectedOption = event.value;
  }


  isAdminUser(): boolean {
    if (this.loginService.currentUserValue.rol.includes('admin')) {
      return true;
    } else {
      return false;
    }
  }

  modifyAdminRole() {
    if (this.checkboxAdminValue && !this.userToEdit.rol.includes('admin')) {
      this.userToEdit.rol.push('admin');
    }
    else if (!this.checkboxAdminValue && this.userToEdit.rol.includes('admin')) {
      this.userToEdit.rol = this.userToEdit.rol.filter(e => e !== 'admin')
    }
  }

}
