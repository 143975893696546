import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ToolsService {

    constructor() { }

    generateRandomUUIDs(): string {
        const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
        const max = characters.length - 1;
        let cadena = '';

        for (let i = 0; i < 36; i++) {
            cadena += characters[Math.floor(Math.random() * (max + 1))];
        }
        return cadena;
    }

    generateRandomChartId(): number {
        const randomId: number = Math.floor(Math.random() * 10000);
        return randomId;
      }
}
