import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-charts-options',
  templateUrl: './charts-options.component.html',
  styleUrls: ['./charts-options.component.scss']
})
export class ChartsOptionsComponent implements OnInit {
  @Output() changeChartTypeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  switch: boolean = false;
  constructor() { }

  ngOnInit() {
  }
  changeChartType(){
    this.switch = !this.switch;  
    this.changeChartTypeEmitter.emit(this.switch)
  }
}
