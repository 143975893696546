import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { iridiumEventsAlarmsRequest, requestDataSDB } from './../../../models/graphQL/iridium-events-request-alarms.request';
import { ResponseIridiumEventsAlarms, ResponseIridiumEventsAlarmsPayload } from './../../../models/graphQL/iridium-events-request-alarms.response';
import { timeDays } from 'd3';

@Component({
  selector: 'app-iridium-request-modal',
  templateUrl: './iridium-request-modal.component.html',
  styleUrls: ["./iridium-request-modal.component.scss"],
})

export class IridiumeRequestModalComponent implements OnInit, OnDestroy {

  iridiumDispsSub: Subscription;
  iridiumDisp: ResponseIridiumEventsAlarmsPayload[];
  responseSBD: Subscription;
  selectableDataTypes: any;
  dataToSend = { uuidDisp: '', uuidDT: '' };
  @Output() toastRequest = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<IridiumeRequestModalComponent>,
    public apollo: Apollo) { }

  ngOnInit() {
    this.getIridiumDisp();
  }

  getIridiumDisp() {
    this.dataToSend.uuidDT = null;
    this.iridiumDispsSub = this.apollo.query<ResponseIridiumEventsAlarms>({
      query: iridiumEventsAlarmsRequest,
      fetchPolicy: 'network-only'
    }).subscribe(({ data }) => {
      this.iridiumDisp = data.dispositivoIridiums;
    });
  }

  setDispUuidAndFilterDataTypes(event) {
    this.dataToSend.uuidDisp = event.value
    this.selectableDataTypes = this.iridiumDisp.filter(function (disp) {
      return disp.uuid == event.value;
    })[0].tipoDatoIridium.filter(function (td) {
      return td.iridium && td.iridium !== null && td.nombre != "altlm" && td.nombre != "lluvia" && !td.iridium.includes(' CLB')
    });
    this.selectableDataTypes = this.selectableDataTypes.sort((a, b) => (a.unidad < b.unidad ? 1 : -1))
  }

  setDTuuid(event) {
    this.dataToSend.uuidDT = event.value;
  }

  requestData() {
    this.responseSBD = this.apollo.mutate({
      mutation: requestDataSDB,
      fetchPolicy: 'no-cache',
      variables: {
        dispositivoIridium: this.dataToSend.uuidDisp,
        tipoDatoIridium: this.dataToSend.uuidDT
      }
    }).subscribe(({ data }) => {
      this.toastRequest.next(data);
    })

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.iridiumDispsSub.unsubscribe();
  }

}
