import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate, CanActivateChild {

    expectedFeature: string[];
    hasAccess: boolean = false;

  constructor(private _router: Router
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.expectedFeature = next.data.expectedFeature;
        this.hasAccess = this.expectedFeature.some(r=> environment.features.indexOf(r) >= 0)
        if (this.hasAccess === true) {
            return true
          }
          else {
            this._router.navigate(['/pageNotFound']);
            return false
          }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }


}
