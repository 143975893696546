import { Device } from 'src/app/models/device.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-device-info-modal',
  templateUrl: './device-info-modal.component.html',
  styleUrls: ['./device-info-modal.component.scss']
})
export class DeviceInfoModalComponent implements OnInit {

  device: Device;
  title: string;
  constructor(
    private dialog: MatDialogRef<DeviceInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.device =  {...data};

  }
  ngOnInit() { 
    this.title = this.device.name;
  }
  onClose() {
    this.dialog.close();
  }
}
