import gql from 'graphql-tag';
import { QueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';
import { DocumentNode } from 'graphql';

export interface WidgetsByDashboardUUIDRequest {
    dashboardUUID: string;
}

export class WidgetsByDashboardUUIDBuilder {
    private _DASHBOARDUUID: string;

    private get _query(): DocumentNode {
        return gql `query findWidgetsByDashboardUUID($dashboardUUID: ID){
            Dashboard(uuid: $dashboardUUID) {
              widgets {
                uuid
                x
                y
                width
                height
                Widget {
                  uuid
                  nombre
                  widgetType {
                    uuid
                    descripcion
                    id_interno
                  }
                  parametros {
                    clave
                    valor
                    dispositivos {
                      uuid
                      nombre
                    }
                    tipodatos {
                      uuid
                      nombre
                      unidad
                    }
                  }
                }
              }
            }
          }`;
    }

    public withDashboardUUID(newDashboardUUID: string): this {
        this._DASHBOARDUUID = newDashboardUUID;
        return this;
    }

    public build(): QueryOptions {
        const queryParams: WidgetsByDashboardUUIDRequest = { dashboardUUID: this._DASHBOARDUUID };
        const graphQLBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQLBuilder.withParams(queryParams)
            .withQuery(this._query)
            .build();
    }
}
