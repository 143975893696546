import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.scss']
})
export class WidgetFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
