import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewAlarmSupervisaParam {
    uuidAlarma: string;
    uuidDato: string;
}

export class AlarmaSupervisaBuilder {
    private _ALARMUUID: string;
    private _DATOUUID: string;

    private get _mutation() {
        return gql`mutation addAlarmaSupervisa($uuidAlarma: String, $uuidDato: String) {
            addAlarmaSupervisa(alarma: $uuidAlarma, dato: $uuidDato) {
              uuid
            }
          }`;
    }

    public withAlarmUuid(newAlarmUUID: string): this {
        this._ALARMUUID = newAlarmUUID;
        return this;
    }

    public withDatoUuid(newDatoUuid: string): this {
        this._DATOUUID = newDatoUuid;
        return this;
    }

    public build() {
        let mutationParams: NewAlarmSupervisaParam;
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = {
            uuidAlarma: this._ALARMUUID,
            uuidDato: this._DATOUUID
        };
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
