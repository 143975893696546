import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PureQueryOptions } from 'apollo-client';
import { DataTypeGraphQlBuilder } from 'src/app/models/graphQL/data-type-graphQL.request';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import { DataTypeGraphQlResponse } from 'src/app/models/graphQL/data-type-graphQl.response';
import { DataType } from 'src/app/models/data-type.model';
import { DataTypeAggregate } from 'src/app/models/data-type-aggregate.model';
import { DataTypeAggregateGraphQlResponse, DataTypeGroupGraphQlResponse } from 'src/app/models/graphQL/data-type-aggegate-GQL.response';
import { DatatypeTreeHierarchi, DataTypeItem } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DataGroup } from "src/app/models/datatype/DataGroup";
import * as lodash from 'lodash';
import { DataTypeMapper } from 'src/app/models/datatype/data-type.mapper';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConsumptionDataType } from 'src/app/models/datatype/consumptionDataType.model';
import { DataTypeUnityGraphQLBuilder } from 'src/app/models/graphQL/data-type-unity-graphQL.request';
import { DataTypeUnityGraphQlResponse } from 'src/app/models/graphQL/data-type-unity-graphQL.response';

@Injectable({
  providedIn: 'root'
})
export class DataTypeService {

  newDataTypeCollection: DatatypeTreeHierarchi = new DatatypeTreeHierarchi();

  constructor(private apolloService: ApolloGraphQlService, private http: HttpClient) { }

  public findAll(): Observable<Array<DataType>> {
    const queryOptions: PureQueryOptions = new DataTypeGraphQlBuilder()
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDataTypeCollection);
  }

  public findAllAggregated(): Observable<Array<DataTypeAggregate>> {
    const queryOptions: PureQueryOptions = new DataTypeGraphQlBuilder()
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDataTypeAggregateCollection);
  }

  private toDataTypeCollection(dataTypeResponse: DataTypeGraphQlResponse): DataType[] {

    if (dataTypeResponse === undefined || dataTypeResponse === null) {
      return [];
    }
    return dataTypeResponse.tipoDatos.map(dataType => new DataType(dataType));
  }

  private toDataTypeAggregateCollection(dataTypeAggregateResponse: DataTypeAggregateGraphQlResponse): DataTypeAggregate[] {
    if (dataTypeAggregateResponse === undefined || dataTypeAggregateResponse === null) {
      return [];
    }
    return dataTypeAggregateResponse.tipoDatos.map(dataTypeAggregate => new DataTypeAggregate(dataTypeAggregate));

  }

  public getDataType(): Observable<DatatypeTreeHierarchi[]> {
    const queryOptions: PureQueryOptions = new DataTypeGraphQlBuilder().build();
    const responseDataType = this.apolloService.executeQuery(queryOptions);

    if (!lodash.isEmpty(responseDataType)) {
      return responseDataType.pipe(
        map((item: DataTypeAggregateGraphQlResponse) => {
          const allDataTypeReversedCollection = item.tipoDatos.map(item => this.findRoot(item.agrupacion[0], DataTypeMapper.dataTypeDetailAggregateGQlResponseToDataTypeItem(item)));
          const allDataTypeGruped: DatatypeTreeHierarchi[] = []
          allDataTypeReversedCollection.forEach(rootItem => {
            const rootGrouped = allDataTypeGruped.firstOrDefault(item => item.root.uuid === rootItem.uuid);
            if (lodash.isEmpty(rootGrouped)) {
              const newRoot: DatatypeTreeHierarchi = new DatatypeTreeHierarchi();
              newRoot.root = rootItem;
              allDataTypeGruped.push(newRoot);
            } else {
              rootGrouped.addChildren(rootItem.children);
            }
          });
          return allDataTypeGruped.filter(function (t) {
            return t.root.name != "otros";
          });
        }));
    }
  }

  findRoot(arr: DataTypeGroupGraphQlResponse, children: DataGroup | DataTypeItem): DataGroup {
    if (arr === null || arr === undefined) {
      const orphan = Object.assign(new DataGroup(), { uuid: "-99", name: "tipo de dato huérfano" });
      orphan.addChild(children);
      return orphan;
    }
    const newDataType = DataTypeMapper.dataTypeAggregateGQLResToDataGroup(arr);
    if (!lodash.isEmpty(arr.agrupacion)) {
      const parent: DataGroup = this.findRoot(arr.agrupacion[0], newDataType);
      newDataType.addChild(children);
      return parent;
    }
    newDataType.addChild(children);
    return newDataType;
  }

  getDataTypeCollection(): Observable<ConsumptionDataType[]> {
    return this.http.get<ConsumptionDataType[]>('/assets/data/dataDeviceConsumptionDetail.json');
  }

  getDataTypeUnity(dataTypeUUID) {
    const queryOptions: PureQueryOptions = new DataTypeUnityGraphQLBuilder()
      .withDataTypeUUID(dataTypeUUID)
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDataTypeUnityInterface);
  }

  toDataTypeUnityInterface(dataTypeUUIDResponse: DataTypeUnityGraphQlResponse): string {
    if (dataTypeUUIDResponse !== undefined && dataTypeUUIDResponse.findTipoDato != null) {
      return dataTypeUUIDResponse.findTipoDato.unidad;
    }
  }

}
