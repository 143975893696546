import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { MatDialog } from '@angular/material';
import { ChangeUserPasswordFormComponent } from '../../change-user-password-form/change-user-password-form.component';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { local } from 'd3';

interface ChangeUserPassModel {
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})


export class UserAccountComponent implements OnInit {

  user: string = this.loginService.currentUserValue.user;
  name: string = this.loginService.currentUserValue.name;
  firstName: string = this.loginService.currentUserValue.firstName;
  constructor(private router: Router,
              public dialog: MatDialog,
              private toastr: ToastService,
              private translate: TranslateService,
              private userService: UserService,
              private loginService: LoginService) { }

  ngOnInit() {
  }

  logout() {
    localStorage.clear();
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  openModalToChangePassword() {
    const dataChangePassword: ChangeUserPassModel = {
      currentPassword: '',
      newPassword: ''
    };
    const dialogRef = this.dialog.open(ChangeUserPasswordFormComponent, {
      data : dataChangePassword
    });
    dialogRef.afterClosed().subscribe((result: ChangeUserPassModel) => {
      if (result !== undefined) {
        this.userService.changePasswordUser(this.user, result.currentPassword, result.newPassword).subscribe((res: any)  => {
          let auxMessage = '';
          let auxMessage2 = '';
          if (res.changePassword !== null) {
            this.translate.get('USER.CHANGE_OK').subscribe(value => auxMessage = value);
            this.translate.get('USER.SUCCESS').subscribe(value => auxMessage2 = value);
            this.toastr.showSuccess(auxMessage, auxMessage2);
          } else {
            this.translate.get('USER.NO_CHANGE').subscribe(value => auxMessage = value);
            this.translate.get('USER.FAILURE').subscribe(value => auxMessage2 = value);
            this.toastr.showError(auxMessage, auxMessage2);
          }
        });
      }
    });
  }

}
