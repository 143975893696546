import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { UserDashboardModel } from 'src/app/models/userModel';
import { MAT_DIALOG_DATA, MatSelectChange } from '@angular/material';
import { Dashboard } from 'src/app/models/widgets/widgets.model';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-share-dashboard',
  templateUrl: './share-dashboard.component.html',
  styleUrls: ['./share-dashboard.component.scss']
})
export class ShareDashboardComponent implements OnInit {

  userCollection: UserDashboardModel[] = [];
  usersSelected: string[] = [];
  selectUsers = new FormControl();

  constructor(
    private userService: UserService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: Dashboard) { }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    this.userService.getUserCollectionWithDashboard().subscribe(result => {
      const auxUserArray: UserDashboardModel[] = this.removeUserNull(result);
      // Eliminamos el usuario logueado para no poder asignarle sus propios dashboards
      auxUserArray.removeBy(item => item.uuid === this.loginService.currentUserValue.uuid);
      // Eliminamos tmb los que ya tengan el dashboard seleccionado para que no dé error
      this.userCollection = _.sortBy(this.checkUserHasDashboard(auxUserArray), ['name'], ['asc']);
    });
  }

  checkUserHasDashboard(userCollection: UserDashboardModel[]): UserDashboardModel[] {
    const userCollectionWithDashboardSelected: UserDashboardModel[] = [];

    for (const user of userCollection) {
      for (const dash of user.dashboards) {
        if (dash.to.uuid === this.data.uuid) {
          userCollectionWithDashboardSelected.push(user);
        }
      }
    }
    return _.differenceBy(userCollection, userCollectionWithDashboardSelected, 'uuid');
  }

  removeUserNull(userCollectionFromService: UserDashboardModel[]): UserDashboardModel[] {
    const auxUserArray: UserDashboardModel[] = [];
    for (const user of userCollectionFromService) {
      if (user.user === null) {
        auxUserArray.push(user);
      }
    }
    return userCollectionFromService = _.difference(userCollectionFromService, auxUserArray);
  }

  selectChange($event: MatSelectChange) {
    this.usersSelected = [];
    this.usersSelected = $event.value;
  }


}
