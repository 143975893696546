import {
  Component, OnInit, Output, Input, EventEmitter, ViewChild, ChangeDetectionStrategy,
  ChangeDetectorRef, ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { DateRange } from 'src/app/models/dateRange.model';
import { FactoriesService } from 'src/app/services/factories/factories.service';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { TabStepperData } from '../side-graphs/side-graphs.component';
import { FactoryCollection } from 'src/app/models/factory/factory.collection.model';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import { LoginService } from 'src/app/services/login/login.service';
import { User } from 'src/app/models/user.model';
import { State, ListItem } from 'src/app/models/contract/list-item.model';
import * as lodash from 'lodash';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { DatatypeTreeHierarchi, DataTypeItem } from 'src/app/models/datatype/datatype-hierarchi.model';
import { Subject } from 'rxjs';
import { MatTabChangeEvent, MatRadioChange } from '@angular/material';
import { PlotlyChartType, ChartOrientationType } from 'src/app/models/plotly/plotly-chart.model';
import { TranslateService } from '@ngx-translate/core';
import { ToolsService } from 'src/app/services/tools/tools.service';
import { FactoryModel } from 'src/app/models/FactoryModel';
import { Dashboard } from 'src/app/models/widgets/widgets.model';
import * as moment from 'moment';
import { TimezoneService } from 'src/app/services/timezone.service';


export interface Graphic {
  name: string;
  type: PlotlyChartType;
  icon: string;
  color: string;
  orientation: ChartOrientationType;
}

export interface WidgetParameter {
  clave: string;
  valor: string;
}

@Component({
  selector: 'app-tab-stepper',
  templateUrl: './tab-stepper.component.html',
  styleUrls: ['./tab-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabStepperComponent implements OnInit {

  @Input() isDashboard: boolean;
  @Input() dashboard: Dashboard;
  @Input() dataTypeTree: DatatypeTreeHierarchi[];
  @Output() generatedChart: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataWidgetFromTabStepper: EventEmitter<any> = new EventEmitter<any>();
  @Output() choosePeriod: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() periodEmitter: EventEmitter<WidgetParameter> = new EventEmitter<WidgetParameter>();
  @ViewChild('inputChartTitle', { static: false }) input: ElementRef;

  // Formcontrols
  selected = new FormControl(0);
  selectedFactory = new FormControl(0);
  tipoDatosControl = new FormControl();
  minFormControl = new FormControl(0, [
    Validators.required
  ]);
  maxFormControl = new FormControl(0, [
    Validators.required,
  ]);
  limitFormControl = new FormControl(0, [
    Validators.required
  ]);
  // Control de stepper
  finishOne = false;
  finishTwo = false;
  finishThree = false;
  validateFirst = false;
  availableFirst = true;
  availableSecond = false;
  availableThird = false;
  // Resume variables
  fromDate: Moment;
  toDate: Moment;
  reset = false;
  factoriesSelected: FactoryCollection = new FactoryCollection();
  selectedDeviceList: DeviceGraphItem[] = [];
  chartType: PlotlyChartType;
  tipoDatos: DeviceGraphDataType[] = [];
  tipoDatoSelected: DeviceGraphDataType = null;
  graphics: Graphic[];
  selectedChart: Graphic = null;
  factoryCollection: FactoryCollection = new FactoryCollection();
  chartTitle = '';
  enableTitleEdition = false;
  childrens: Array<DeviceGraphItem[]> = [];
  deviceSettings: DeviceListSettings = {
    titleType: 'factory',
    actionIcon: 'add-circle',
    selectable: State.selected
  };
  allDeviceCollection: DeviceGraphItem[] = [];
  dataTypeSelected: DataTypeItem[] = [];
  auxDataTypes: DataTypeItem[] = [];
  devicesSelectedSubject = new Subject();
  public title: Array<string> = [];
  public loading: Array<boolean> = [];
  public parents: Array<Array<ListItem>> = [[]];
  public isRoot: Array<boolean> = [];
  factoriesSelectedAux: FactoryCollection;
  viewActive = true;
  dateRangeType: string; // variable que controla que componente de fecha se muestra en el paso 2
  comboTimeRangeValueSelected: string;
  comboPeriodValueSelected: string;
  chartTitleAux: string;
  isAGauge: boolean;
  isATable: boolean;
  isADigital: boolean;
  orientation: ChartOrientationType;
  validateSelectDateRange = true;
  tituloGrafico: string[];
  timeZone: string;
  uniqueDataTypes: DataTypeItem[] = [];
  result: any;

  constructor(
    private factoriesService: FactoriesService,
    private loginService: LoginService,
    private cdRef: ChangeDetectorRef,
    private dispositivoService: DispositivoService,
    private translateService: TranslateService,
    private toolsService: ToolsService,
    private translate: TranslateService,
    private timeZoneService: TimezoneService
  ) {
    this.initSelect();
    this.timeZone = this.loginService.currentUserValue.timeZone;

  }

  ngOnDestroy() {
    this.viewActive = false;
  }

  /* ngAfterViewInit() {
    this.cdRef.detectChanges();
  } */

  ngOnInit() {
    this.initTitle();
    this.resetFromData();
    this.isDashboard ? this.dateRangeType = 'period' : this.dateRangeType = null;
    this.loadStep3();
    this.getFactoriesSelectedOfGlobalSelection();
    this.timeZoneService.currentTimeZone$.subscribe(
      result => {
        this.timeZone = result;
      }
    )

  }

  private initTitle() {
    if (this.isDashboard) {
      this.chartTitleAux = 'TAB_STEPPER.NEW_WIDGET';
    } else {
      this.chartTitleAux = 'TAB_STEPPER.NEW_GRAPH';
    }
  }

  private getFactoriesSelectedOfGlobalSelection() {
    this.factoriesService.factorySelectedObservable.subscribe(factorySelectedCollection => {
      this.factoriesSelectedAux = factorySelectedCollection;
      this.factoryCollection.collection = [];
      this.factoriesSelected.collection = [];
      this.title = [];
      this.childrens = [];
      this.loading = [];
      this.isRoot = [];
      this.parents = [];
      const result = this.factoriesSelectedAux.collection.length - this.factoriesSelected.collection.length;
      const factoryDiffToAdd = lodash.difference(this.factoriesSelectedAux.collection, this.factoriesSelected.collection);
      const factoryDiffToRemove = lodash.difference(this.factoriesSelected.collection, this.factoriesSelectedAux.collection);
      // COMPARAMOS EL ARRAY DE FACTORIAS SELECCIONADAS DEL SERVICIO CON LAS SELECCIONADAS DEL COMPONENTE Y VEMOS QUE SE HA AÑADIDO UNA
      if (result === 1) {
        // AÑADIR
        this.addFactoryToFactoriesSelected(factoryDiffToAdd[0]);
        // CHECKED A TRUE
        this.factoriesService.getFactories(this.loginService.currentUserValue.user).pipe(
        ).subscribe((data: FactoryCollection) => {
          this.factoryCollection = data;
          for (const factory of this.factoryCollection.collection) {
            if (factory.uuid === factoryDiffToAdd[0].uuid) {
              factory.selected = true;
              factory.showTab = true;
            }
          }
        });
        // SI LA COMPARACION SALE NEGATIVA ES PORQUE SE HA QUITADO UNA DE LA GLOBAL
      } else if (result === -1) {
        // ELIMINAR
        let factoryToRemove: FactoryModel;
        factoryToRemove = factoryDiffToRemove[0];
        factoryToRemove.showTab = false;
        this.removeDataOfFactoryUnselected(factoryToRemove);
        this.factoriesSelected.collection.remove(factoryToRemove);
        this.factoriesSelected = this.factoriesSelected;
        if (this.viewActive) {
          this.cdRef.detectChanges();
        }
        // CHECKED A FALSE
        for (const factory of this.factoryCollection.collection) {
          if (factory.uuid === factoryToRemove.uuid) {
            factory.selected = false;
            factory.showTab = false;
          }
        }

        // SI LA COMPARACION ES MAYOR QUE UNO ES PORQUE HEMOS CAMBIADO DE PANTALLA Y HAY QUE INICIALIZAR LAS FACTORIAS SELECCIONADAS
      } else if (result > 1) {
        // AÑADIR
        for (const facToAdd of factoryDiffToAdd) {
          this.addFactoryToFactoriesSelected(facToAdd);
        }
        // CHECKED A TRUE
        this.factoriesService.getFactories(this.loginService.currentUserValue.user).pipe(
        ).subscribe((data: FactoryCollection) => {
          this.factoryCollection = data;
          for (const factory of this.factoryCollection.collection) {
            for (const facDiff of factoryDiffToAdd) {
              if (factory.uuid === facDiff.uuid) {
                factory.selected = true;
                factory.showTab = true;
              }
            }
          }
        });
        // SI LA COMPARACION ES 0 HAY QUE INICIALIZAR LOS ARRAYS PARA QUE NO SE QUEDEN VACIOS
      } else if (result === 0) {
        this.initSelect();
        if (this.viewActive) {
          this.cdRef.detectChanges();
        }
      }
    });
  }

  editTitle() {
    setTimeout(() => {
      if (this.input !== undefined) {
        this.input.nativeElement.focus();
        if (this.isTitleGenerated()) {
          this.translateService.get(this.chartTitle).subscribe((data: string) => {
            this.input.nativeElement.value = data;
          });
        } else {
          this.translateService.get(this.chartTitleAux).subscribe((data: string) => {
            this.input.nativeElement.value = data;
          });
        }
      }
    }, 20);
    this.enableTitleEdition = !this.enableTitleEdition;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    tabChangeEvent.index < 1 ? this.availableFirst = true : this.availableFirst = false;
    tabChangeEvent.index === 1 ? this.availableSecond = true : this.availableSecond = false;
    tabChangeEvent.index === 2 ? this.availableThird = true : this.availableThird = false;
    this.next(tabChangeEvent.index);
  }

  public get hasDataType(): FormControl {
    return new FormControl(this.tipoDatos.length > 0);
  }

  private loadDeviceSelectedDataType() {
    const deviceSelectedDataTypeCollection: DeviceGraphDataType[][] = this.getDeviceSelectedDataTypeCollection();
    if (deviceSelectedDataTypeCollection !== null &&
      deviceSelectedDataTypeCollection !== undefined && deviceSelectedDataTypeCollection.length > 0) {
      this.tipoDatos = deviceSelectedDataTypeCollection.reduce(this.buildDeviceSelectedDataType);
    } else {
      this.tipoDatos = [];
    }
  }

  getDeviceSelectedDataTypeCollection(): DeviceGraphDataType[][] {
    return this.selectedDeviceList.map(item => item.dataType);
  }

  private buildDeviceSelectedDataType(prev: DeviceGraphDataType[], current: DeviceGraphDataType[]): DeviceGraphDataType[] {
    if (current === null || current === undefined) {
      return prev;
    }
    return prev.intersect(current, item => item.uuid);
  }

  getClassByValue(id: string): string {
    if (this.selectedDeviceList.any(item => item.uuid === id)) {
      return 'selectedDevice';
    } else {
      return 'unselectedDevice';
    }
  }

  initSelect() {
    const usuario: User = this.loginService.currentUserValue;
    if (usuario !== null && usuario !== undefined) {
      this.factoriesService.getFactories(usuario.user).pipe(
      ).subscribe((data: FactoryCollection) => this.factoryCollection = data);
    }
  }

  selectDatoEvent(datoSelected: DeviceGraphDataType) {
    this.tipoDatoSelected = {
      uuid: datoSelected.uuid,
      name: datoSelected.name,
      unity: datoSelected.unity,
      codigo: datoSelected.codigo
    };
    this.validateFirstTabStep();
  }

  loadStep3() {
    this.graphics = [
      {
        name: 'TAB_STEPPER.LINEAL',
        type: PlotlyChartType.SCATTER,
        icon: 'trending_up',
        color: '',
        orientation: ChartOrientationType.NONE
      },
      {
        name: 'TAB_STEPPER.BAR',
        type: PlotlyChartType.BAR,
        icon: 'bar_chart',
        color: '',
        orientation: ChartOrientationType.VERTICAL
      },
      {
        name: 'TAB_STEPPER.HBAR',
        type: PlotlyChartType.BAR,
        icon: 'subject',
        color: '',
        orientation: ChartOrientationType.HORIZONTAL
      }
    ];
    if (this.isDashboard) {
      this.graphics.push({
        name: 'TAB_STEPPER.DIGITAL',
        type: PlotlyChartType.DIGITAL,
        icon: 'short_text',
        color: '',
        orientation: ChartOrientationType.NONE
      },
        {
          name: 'TAB_STEPPER.TABLE',
          type: PlotlyChartType.TABLE,
          icon: 'table_chart',
          color: '',
          orientation: ChartOrientationType.NONE
        },
        {
          name: 'TAB_STEPPER.SPEEDOMETER',
          type: PlotlyChartType.GAUGE,
          icon: 'speed',
          color: '',
          orientation: ChartOrientationType.NONE
        });
    }
  }

  private addFactoryToFactoriesSelected(factory: FactoryModel) {
    let factoryToAdd: FactoryModel;
    factoryToAdd = factory;
    factoryToAdd.showTab = true;
    this.factoriesSelected.collection.add(factoryToAdd);
    this.getChildrenFactory(factoryToAdd.uuid, this.factoriesSelected.collection.length - 1);
    this.getTitleForCard(factoryToAdd.name, this.factoriesSelected.collection.length - 1);
    this.addParent(factoryToAdd.uuid, factoryToAdd.name, this.factoriesSelected.collection.length - 1);
  }

  selectFactoryEvent(selectedFactory: FactoryModel) {
    if (selectedFactory.showTab === false) {
      this.addFactoryToFactoriesSelected(selectedFactory);
    } else if (selectedFactory.showTab === true) {
      let factoryToRemove: FactoryModel;
      factoryToRemove = selectedFactory;
      factoryToRemove.showTab = false;
      this.removeDataOfFactoryUnselected(factoryToRemove);
      this.factoriesSelected.collection.removeBy(item => item.uuid === factoryToRemove.uuid);
    }
  }

  private removeDataOfFactoryUnselected(factoryDiff: FactoryModel) {
    const index = lodash.findIndex(this.factoriesSelected.collection, { uuid: factoryDiff.uuid });
    this.title.remove(this.title[index]);
    this.childrens.remove(this.childrens[index]);
    this.loading.remove(this.loading[index]);
    this.isRoot.remove(this.isRoot[index]);
    this.parents.remove(this.parents[index]);
  }

  addParent(parentUuid, parentName, i) {
    if (this.parents[i] === undefined) {
      this.parents[i] = [];
    }
    const item: ListItem = { uuid: parentUuid, name: parentName };
    this.parents[i].add(item);
  }

  getTitleForCard(name, i) {
    this.title[i] = '';
    this.title[i] = name;
  }

  getChildrenFactory(uuid, i) {
    this.childrens[i] = [];
    this.loading[i] = true;
    this.dispositivoService.getDeviceChildrenAndDataTypesGraphQl(uuid)
      .subscribe(
        next => {
          this.childrens[i] = this.checkDeviceSelectedCollection(next);
          this.childrens[i] = lodash.sortBy(this.childrens[i], ['name'], ['asc']);
          this.loading[i] = false;
          if (this.viewActive) {
            this.cdRef.detectChanges();
          }
        }
      );
  }

  private checkDeviceSelectedCollection(deviceSelected: DeviceGraphItem[]): DeviceGraphItem[] {
    let auxDevices: DeviceGraphItem[];
    auxDevices = lodash.intersectionBy(deviceSelected, this.selectedDeviceList, 'uuid');
    for (const aux of auxDevices) {
      aux.selectedState = State.selected;
    }
    return deviceSelected;
  }

  navigate(selected: DeviceGraphItem, i: number) {
    if (lodash.isEmpty(this.parents[i])) {
      this.isRoot[i] = true;
    } else {
      this.isRoot[i] = false;
    }
    this.getTitleForCard(selected.name, i);
    this.getChildrenFactory(selected.uuid, i);
    this.addParent(selected.uuid, selected.name, i);
  }

  goBack(i) {
    const uuidParent = this.parents[i][this.parents[i].length - 2].uuid;
    const nameParent = this.parents[i][this.parents[i].length - 2].name;

    this.parents[i].remove(this.parents[i][this.parents[i].length - 2]);
    this.parents[i].remove(this.parents[i][this.parents[i].length - 1]);

    if (lodash.isEmpty(this.parents[i])) {
      this.isRoot[i] = true;
    } else {
      this.isRoot[i] = false;
    }

    const aux: DeviceGraphItem = new DeviceGraphItem();
    aux.uuid = uuidParent;
    aux.name = nameParent;
    this.navigate(aux, i);
  }

  checkFactoryCollectionLength(factoryCollection: FactoryCollection) {
    if (factoryCollection.collection.length === 0) {
      this.selectedDeviceList = [];
      this.tipoDatos = [];
    }
  }

  activeTab() {
    this.finishOne = false;
  }

  isTitleGenerated(): boolean {
    if (lodash.isEmpty(this.chartTitle)) {
      return false;
    }
    return true;
  }

  next(step: number) {
    switch (step) {
      case 1:
        this.finishOne = true;
        this.selected.setValue(1);
        // if (!lodash.isEmpty(this.dataTypeSelected) && !lodash.isEmpty(this.selectedDeviceList)) {
        //   if (this.dataTypeSelected.length > 1 || this.selectedDeviceList.length > 1) {
        //     this.resetForAGaugeOrDigital();
        //   }
        // }
        break;
      case 2:
        this.chartTitle = '';
        this.generateChartTitle();
        this.finishTwo = true;
        this.selected.setValue(2);
        // if (!lodash.isEmpty(this.dataTypeSelected) && !lodash.isEmpty(this.selectedDeviceList)) {
        //   if (this.dataTypeSelected.length > 1 || this.selectedDeviceList.length > 1) {
        //     //this.resetForAGaugeOrDigital();
        //   }
        // }
        break;
    }
  }

  private resetForAGaugeOrDigital() {
    if (this.isAGauge || this.isADigital) {
      this.dataTypeSelected = [];
      for (const device of this.selectedDeviceList) {
        device.selectedState = State.selectable;
      }
      this.selectedDeviceList = [];
      this.devicesSelectedSubject.next(this.selectedDeviceList);
    }
  }

  selectChart(chart: Graphic) {
    this.selectedChart = chart;
    this.chartType = chart.type;
    this.orientation = chart.orientation;
    this.firstChartTilte(chart.name);

    const gauge: PlotlyChartType = PlotlyChartType.GAUGE;
    const table: PlotlyChartType = PlotlyChartType.TABLE;
    const digital: PlotlyChartType = PlotlyChartType.DIGITAL;

    switch (this.chartType) {
      case gauge:
        this.isAGauge = true;
        this.isATable = false;
        this.isADigital = false;
        break;
      case table:
        this.isATable = true;
        this.isAGauge = false;
        this.isADigital = false;
        break;
      case digital:
        this.isADigital = true;
        this.isAGauge = false;
        this.isATable = false;
        break;
      default:
        this.isAGauge = false;
        this.isATable = false;
        this.isADigital = false;
        break;
    }
  }

  private firstChartTilte(typeGraph) {
    if (this.isDashboard) {
      this.translate.get('TAB_STEPPER.NEW_WIDGET_OF').subscribe((text: string) => this.chartTitle = text);
      this.translate.get(typeGraph).subscribe((text: string) => this.chartTitle += text);
    } else {
      this.translate.get('TAB_STEPPER.NEW_GRAPH_OF').subscribe((text: string) => this.chartTitle = text);
      this.translate.get(typeGraph).subscribe((text: string) => this.chartTitle += text);
    }
  }

  getDateFrom(event: Moment) {
    if (this.fromDate !== null && this.comboPeriodValueSelected !== null) {
      this.validateSelectDateRange = false;
    }
    this.fromDate = event;
  }

  getDateTo(event: Moment) {
    if (this.fromDate !== null && this.comboPeriodValueSelected !== null) {
      this.validateSelectDateRange = false;
    }
    this.toDate = event;
  }

  getSelectionTimeRangeValue($event: string) {
    this.validateSelectDateRange = false;
    this.comboTimeRangeValueSelected = $event;
  }

  getSelectionPeriodValue($event: string) {
    this.comboPeriodValueSelected = $event;
  }

  resetFromData() {
    this.enableTitleEdition = false;
    this.finishOne = false;
    this.finishTwo = false;
    this.finishThree = false;
    this.selected.setValue(0);
    this.selectedDeviceList = [];
    this.getFactoriesSelectedOfGlobalSelection();
    this.reset = true;
    this.tipoDatosControl.setValue('');
    this.chartTitle = '';
    this.tipoDatos = [];
    this.chartType = null;
    this.selectedChart = null;
    this.dataTypeSelected = null;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
    this.title = [];
    this.childrens = [];
    this.loading = [];
    this.isRoot = [];
    this.resetDateRange();
  }

  generateChartElement() {
    if (this.isATable || this.isADigital) {
      this.chartTitle = '';
      this.generateChartTitle();
    }
    const oData: TabStepperData = {
      chartId: this.toolsService.generateRandomChartId(),
      chartTitle: this.chartTitle,
      devices: this.selectedDeviceList,
      tipoDato: lodash.uniqBy(this.dataTypeSelected, 'uuid'),
      unidad: this.dataTypeSelected[0].unity,
      period: {
        clave: this.comboPeriodValueSelected,
        valor: this.comboTimeRangeValueSelected
      },
      rangeDate: this.createDateRange(),
      chartType: this.chartType,
      orientation: this.orientation,
      min: this.minFormControl.value,
      max: this.maxFormControl.value,
      limit: this.limitFormControl.value,
      timeZone: this.timeZone
    };
    this.generatedChart.emit(oData);
    if (this.isDashboard) {
      this.dataWidgetFromTabStepper.emit(oData);
    }
    this.resetFromData();
  }

  createDateRange(): DateRange {
    let newDateRange: DateRange;
    if (this.fromDate && this.toDate) {
      newDateRange = new DateRange(this.fromDate, this.toDate);
    } else if (this.comboPeriodValueSelected && this.comboTimeRangeValueSelected) {
      const periodObjectForMutation: WidgetParameter = {
        clave: this.comboPeriodValueSelected,
        valor: this.comboTimeRangeValueSelected
      };
      this.periodEmitter.emit(periodObjectForMutation);
      newDateRange = this.convertPeriodToDateRange();
    }
    return newDateRange;
  }

  convertPeriodToDateRange(): DateRange {
    let dateRangeConverted: DateRange;
    switch (this.comboPeriodValueSelected) {
      case 'Hour':
        // tslint:disable-next-line: radix
        dateRangeConverted = new DateRange(moment().subtract(parseInt(this.comboTimeRangeValueSelected), 'hours'), moment());
        break;
      case 'Day':
        // tslint:disable-next-line: radix
        dateRangeConverted = new DateRange(moment().subtract(parseInt(this.comboTimeRangeValueSelected), 'days')
          .startOf('day'), moment().endOf('day'));
        break;
      case 'Week':
        // tslint:disable-next-line: radix
        dateRangeConverted = new DateRange(moment().subtract(parseInt(this.comboTimeRangeValueSelected), 'weeks')
          .startOf('day'), moment().endOf('day'));
        break;
      case 'Month':
        // tslint:disable-next-line: radix
        dateRangeConverted = new DateRange(moment().subtract(parseInt(this.comboTimeRangeValueSelected), 'months')
          .startOf('day'), moment().endOf('day'));
        break;
    }
    return dateRangeConverted;
  }

  generateChartTitle() {
    this.addDataTypesToChartTitle();
    this.addDevicesToChartTitle();
  }

  addDataTypesToChartTitle() {
    let concatWord: string;
    this.tituloGrafico = [];
    this.translateService.get('OTHERS.OF_DEVICES').subscribe((result: string) => {
      concatWord = result
    });
    this.auxDataTypes = this.dataTypeSelected.reduce((td, x) => td.concat(td.find(y => y.codigo === x.codigo) ? [] : [x]), []);
    for (let i = 0; i < this.auxDataTypes.length; i++) {
      this.tituloGrafico.push(this.auxDataTypes[i].name)
      if (i === this.auxDataTypes.length - 1) {
        this.chartTitle += this.auxDataTypes[i].name + ' ' + concatWord + ' ';
      } else {
        this.chartTitle += this.auxDataTypes[i].name + ', ';
      }
    }
    this.tituloGrafico = [...new Set(this.tituloGrafico)];
   
  }

  filterDevices() {
    var tp = [];
    for(let elem of this.auxDataTypes) {
      if (elem.codigo) {
        tp.push(elem.codigo)
      } else {
        elem.codigo = elem.uuid;
        tp.push(elem.codigo)
      }
    }
    this.selectedDeviceList = this.selectedDeviceList.filter(({ dataType }) =>
      dataType.some(({ codigo }) => tp.includes(codigo)   )
    );
  }

  addDevicesToChartTitle() {
    this.filterDevices();
    for (let i = 0; i < this.selectedDeviceList.length; i++) {
      if (i === this.selectedDeviceList.length - 1) {
        this.chartTitle += this.selectedDeviceList[i].name;
      } else {
        this.chartTitle += this.selectedDeviceList[i].name + ', ';
      }
    }
  }

  changeDateRangeTypeOption($event: MatRadioChange) {
    this.resetDateRange();
    this.validateSelectDateRange = true;
    this.dateRangeType = $event.value;
    this.dateRangeType === 'period' ? this.choosePeriod.emit(true) : this.choosePeriod.emit(false);
  }

  resetDateRange() {
    this.dateRangeType = '';
    this.fromDate = null;
    this.toDate = null;
    this.comboTimeRangeValueSelected = '';
    this.comboPeriodValueSelected = '';
    this.choosePeriod.emit(false);
    this.validateSelectDateRange = true;
  }

  validateFirstTabStep() {
    if (this.selectedDeviceList.length === 0 || this.chartTitle === ''
      || this.chartTitle === undefined || this.tipoDatoSelected.uuid === '') {
      this.validateFirst = false;
    } else {
      this.validateFirst = true;
    }
  }

  validateSecondTabStep(isAGauge: boolean): boolean {
    let disabled = true;
    if (!isAGauge) {
      return disabled = this.validatePeriod();
    } else {
      return disabled = this.validateGaugeParams();
    }
  }

  validatePeriod(): boolean {
    let disabled = true;
    if (this.dateRangeType === 'custom') {
      if ((this.fromDate && this.toDate) && (this.fromDate < this.toDate)) {
        disabled = false;
      }
    } else if (this.comboTimeRangeValueSelected !== undefined) {
      disabled = false;
      this.choosePeriod.emit(true);
    }
    return disabled;
  }

  validateGaugeParams(): boolean {
    let disabled = true;
    if (this.minFormControl.value > this.maxFormControl.value || this.minFormControl.value > this.limitFormControl.value) {
      this.minFormControl.setErrors({ overmin: true });
    }
    if (this.maxFormControl.value < this.minFormControl.value && this.maxFormControl.value < this.limitFormControl.value) {
      this.maxFormControl.setErrors({ undermax: true });
    }
    if (this.limitFormControl.value <= this.minFormControl.value || this.limitFormControl.value >= this.maxFormControl.value) {
      this.limitFormControl.setErrors({ overlimit: true });
    } else {
      disabled = false;
    }
    return disabled;
  }

  public selectedDeviceGraphItem(item: DeviceGraphItem) {
    if (this.isAGauge || this.isADigital) {
      this.deleteOtherDevicesForAGauge();
      this.selectedDeviceList.add(item);
      this.loadDeviceSelectedDataType();
      this.checkDataTypeSelectedCollection();
      item.selectedState = State.selected;
      this.devicesSelectedSubject.next(this.selectedDeviceList);
    } else {
      this.selectedDeviceList.add(item);
      this.loadDeviceSelectedDataType();
      this.checkDataTypeSelectedCollection();
      item.selectedState = State.selected;
      this.devicesSelectedSubject.next(this.selectedDeviceList);
    }
  }

  private deleteOtherDevicesForAGauge() {
    if (!lodash.isEmpty(this.selectedDeviceList)) {
      for (const deviceToDelete of this.selectedDeviceList) {
        this.unSelectedDeviceGraphItem(deviceToDelete);
      }
    }
  }

  private checkDataTypeSelectedCollection() {
    const aux: DataTypeItem[] = [];
    if (!lodash.isEmpty(this.dataTypeSelected)) {
      for (const dataTypeSelected of this.dataTypeSelected) {
        for (const tipoDato of this.tipoDatos) {
          if (tipoDato.uuid === dataTypeSelected.uuid) {
            aux.add(dataTypeSelected);
          }
        }
      }
    }
    this.dataTypeSelected = aux;
  }

  removeChipset(item: DeviceGraphItem) {
    item.selectedState = State.selectable;
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    for (const childCollection of this.childrens) {
      for (const child of childCollection) {
        if (child.uuid === item.uuid) {
          child.selectedState = State.selectable;
        }
      }
    }

    if (lodash.isEmpty(this.selectedDeviceList)) {
      this.dataTypeSelected = [];
    }
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    this.loadDeviceSelectedDataType();
    item.selectedState = State.selectable;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  public unSelectedDeviceGraphItem(item: DeviceGraphItem) {
    if (this.selectedDeviceList.length < 2) {
      this.dataTypeSelected = [];
    }
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    this.loadDeviceSelectedDataType();
    item.selectedState = State.selectable;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  unSelectedDatatype(unselected: DataTypeItem) {
    this.dataTypeSelected.removeBy(item => item.uuid === unselected.uuid);
    this.cdRef.detectChanges();
  }

  selectedDatatype(selected: DataTypeItem) {
    if (this.isAGauge || this.isADigital) {
      // this.deleteOtherDataTypesForAGauge();
      this.dataTypeSelected.add(selected);
      this.cdRef.detectChanges();
    } else {
      this.dataTypeSelected.add(selected);
      this.cdRef.detectChanges();
    }
  }

  private deleteOtherDataTypesForAGauge() {
    if (!lodash.isEmpty(this.dataTypeSelected)) {
      for (const dataTypeToDelete of this.dataTypeSelected) {
        dataTypeToDelete.selectedState = State.selectable;
        this.unSelectedDatatype(dataTypeToDelete);
      }
    }
  }

  getAllDeviceCollection(items: DeviceGraphItem[]): DeviceGraphDataType[][] {
    this.allDeviceCollection = items;
    return this.allDeviceCollection.map(item => item.dataType);
  }

  public checkDataType(): FormControl {
    if (this.tipoDatos === null || this.tipoDatos === undefined || this.tipoDatos.length === 0) {
      return new FormControl(true);
    } else {
      return new FormControl(false);
    }
  }
  getWidgetType(graphType?: Graphic, selectedType?: Graphic): boolean {
    if (graphType !== null && selectedType !== null) {
      if (graphType.type === selectedType.type) {
        if (graphType.orientation === selectedType.orientation) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }

  }
  validateKey($event) {
    if ($event.keyCode === 13) {
      this.editTitle();
    }
  }
}
