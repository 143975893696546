import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { stringify } from 'querystring';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  graphics: {name: string, type: string, icon: string, color: string} [] = [];
  selectedChart = '';

  devicesSelected = [];
  

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      from: ['', Validators.required],
      to: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.loadStep3();
  }
  getDate(eventSide: {from: string , to: string}) {
    this.secondFormGroup.get('from').setValue(eventSide.from);
    this.secondFormGroup.get('to').setValue(eventSide.to);
  }

  onAddDevice(devices: {name: string, type: string, icon: string, color: string} []) {
    this.devicesSelected = [...devices];
  }

  createChart() {
    
  }

  loadStep3() {
    this.graphics = [
      {
        name: 'Tipo 1',
        type: 'tipo 1',
        icon: '',
        color: ''
      },
      {
        name: 'Tipo 2',
        type: 'tipo 2',
        icon: '',
        color: ''
      },
      {
        name: 'Tipo 3',
        type: 'tipo 3',
        icon: '',
        color: ''
      },
      {
        name: 'Tipo 4',
        type: 'tipo 4',
        icon: '',
        color: ''
      },
      {
        name: 'Tipo 5',
        type: 'tipo 5',
        icon: '',
        color: ''
      },
      {
        name: 'Tipo 6',
        type: 'tipo 6',
        icon: '',
        color: ''
      }
    ];
  }

  selectChart(chart: {name: string, type: string, icon: string, color: string}) {
    this.thirdFormGroup.get('thirdCtrl').setValue(chart.type);
    this.selectedChart = chart.type;
  }
}
