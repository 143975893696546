import { DeviceDescentDetailGQLResponse, DeviceDescentDataTypeGQLResponse } from './graphQL/device-descent-GQL.response';
import { NgxChartElementColor } from './ngx-chart.model';
import { State, ListItemSelectable } from './contract/list-item.model';




export class DeviceGraphItem implements ListItemSelectable {

    uuid: string;
    name: string;
    color?:NgxChartElementColor;
    dataType?: DeviceGraphDataType[] = [];
    selectedState?:State = State.selectable;
    childrenNumber?: number;

    constructor(deviceItemDetail?: DeviceDescentDetailGQLResponse) {
        if (deviceItemDetail === null || deviceItemDetail === undefined) {
            return;
        }
        this.uuid = deviceItemDetail.uuid;
        this.name = deviceItemDetail.nombre;
        this.dataType = deviceItemDetail.tiene
            .filter(data => (data.Dato !== undefined && data.Dato !== null)
                && (data.Dato.es !== undefined && data.Dato.es !== null)
            )
            .map(data => new DeviceGraphDataType(data.Dato.es));
    }
}

export class DeviceGraphDataType {
    uuid: string;
    name: string;
    unity?: string;
    codigo?: string;
    displayField?: string;
    constructor(deviceDataTypeDetail?: DeviceDescentDataTypeGQLResponse) {
        if (deviceDataTypeDetail === null || deviceDataTypeDetail === undefined) {
            return;
        }
        this.uuid = deviceDataTypeDetail.uuid;
        this.name = deviceDataTypeDetail.nombre;
        this.codigo = deviceDataTypeDetail.codigo;
        // Si no hay codigo, le establecemos el uuid del tipo de dato
        if(this.codigo === null) {this.codigo = this.uuid};
        this.unity = deviceDataTypeDetail.unidad;
    }
}

