import { DocumentNode } from 'apollo-link';

import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';
interface DeviceDescentGraphQL {
    userId: string;
    rootDeviceId: string;
  }
export class DeviceDescentGQlBuilder {
    private _userId: string;
    private _rootDeviceId: string;
    private get _query(): DocumentNode {

        return gql`query findAllDeviceDescencyByUserAndDeviceRoot($userId:String,$rootDeviceId:String){
            findDispositivosAscendancy(usuario:$userId, nombre:"",ascendancy:$rootDeviceId){
              uuid
              nombre
              tiene{
                Dato{
                  uuid
                  es{
                    uuid
                    nombre
                    unidad
                    codigo
                  }
                }
              }
            }
          }`;
    }

    public withUser(userId: string): this {
        this._userId = userId;
        return this;
    }
    public withRootDevice(rootDeviceId: string): this {
        this._rootDeviceId = rootDeviceId;
        return this;
    }
    public build(): PureQueryOptions {
        let queryParams: DeviceDescentGraphQL;
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        queryParams = { userId: this._userId, rootDeviceId: this._rootDeviceId };
        return graphQlBuilder.withQuery(this._query)
          .withParams(queryParams)
          .build();
      }
}

