import { Component, OnInit, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

export interface datos {
  dispositivo: string;
  estado: string;
  estadoTiempo: string;
  estadoInyectora: string;
  marchaParo: string;
}

const ELEMENT_DATA: datos[] = [
  {dispositivo: 'D-1', estado: 'B', estadoTiempo: 'M', estadoInyectora: ' ',marchaParo: 'marcha' },
  {dispositivo: 'D-2', estado: 'M', estadoTiempo: 'B', estadoInyectora: ' ',marchaParo: 'paro' },
  {dispositivo: 'D-3', estado: 'B', estadoTiempo: 'M', estadoInyectora: ' ',marchaParo: 'marcha' },
  {dispositivo: 'D-4', estado: 'M', estadoTiempo: 'B', estadoInyectora: ' ',marchaParo: 'paro' },
  {dispositivo: 'D-5', estado: 'B', estadoTiempo: 'M', estadoInyectora: ' ',marchaParo: 'marcha' },
  {dispositivo: 'D-6', estado: 'M', estadoTiempo: 'B', estadoInyectora: ' ',marchaParo: 'paro' },
  {dispositivo: 'D-7', estado: 'B', estadoTiempo: 'M', estadoInyectora: ' ',marchaParo: 'marcha' },
  {dispositivo: 'D-8', estado: 'M', estadoTiempo: 'B', estadoInyectora: ' ',marchaParo: 'paro' },
  {dispositivo: 'D-9', estado: 'B', estadoTiempo: 'M', estadoInyectora: ' ',marchaParo: 'marcha' },
  {dispositivo: 'D-10', estado: 'M', estadoTiempo: 'B', estadoInyectora: ' ',marchaParo: 'paro' },
];

@Component({
  selector: 'app-last-states',
  templateUrl: './last-states.component.html',
  styleUrls: ['./last-states.component.scss']
})
export class LastStatesComponent implements OnInit {

  displayedColumns: string[] = ['dispositivo', 'estado', 'estadoTiempo', 'estadoInyectora', 'marchaParo'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
