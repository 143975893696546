import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewAssignmentToDashboard {
    dashboardUUID: string;
    usuarioUUID: string;
}

export class AssignDashboardToUserBuilder {

    private _DASHBOARDUUID: string;
    private _USERUUID: string;

    private get _mutation() {
        return gql`mutation shareDashboard($dashboardUUID: String, $usuarioUUID: String){
            compartirDashboard(dashboard: $dashboardUUID, usuario: $usuarioUUID) {
              uuid
            }
          }`;

    }

    public withUserUUID(newUserUUID: string): this {
        this._USERUUID = newUserUUID;
        return this;
    }

    public withDashboardUUID(newDashboardUUID: string): this {
        this._DASHBOARDUUID = newDashboardUUID;
        return this;
    }

    public build() {
        let mutationParams: NewAssignmentToDashboard;
        const graphqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = {
            dashboardUUID: this._DASHBOARDUUID,
            usuarioUUID: this._USERUUID
        };
        return graphqlMutationBuilder
            .withMutation(this._mutation)
            .withParams(mutationParams)
            .build();
    }
}
