import * as _ from 'lodash';
import { DataSize } from './widgets/widget-interfaces.model';
import { DigitalWidget, DataDigitalWidget } from './digital-widget.model';
import * as moment from 'moment';


export class DigitalWidgetBuilder {

    private _DIGITALUUID: string;
    private _DIGITALNAME: string;
    private _DATASIZE: DataSize;
    private _X: number;
    private _Y: number;
    private _ROW: number;
    private _COL: number;
    private _RELATIONUUID: string;
    private _UNITY: string;
    private _VALUE: number;
    private _DATE: string;

    public withDigitalUUID(newDigitalUUID: string): DigitalWidgetBuilder {
        this._DIGITALUUID = newDigitalUUID;
        return this;
    }

    public withDigitalValue(newDigitalValue: number): DigitalWidgetBuilder {
        this._VALUE = newDigitalValue;
        return this;
    }

    public withDigitalDate(newDigitalDate: string): DigitalWidgetBuilder {
        this._DATE = newDigitalDate;
        return this;
    }

    public withDigitalName(newDigitalName: string): DigitalWidgetBuilder {
        this._DIGITALNAME = newDigitalName;
        return this;
    }

    public withUnity(newUnity: string): DigitalWidgetBuilder {
        this._UNITY = newUnity;
        return this;
    }

    public withDataSize(newDataSize: DataSize): DigitalWidgetBuilder {
        this._DATASIZE = newDataSize;
        return this;
    }

    public withXPos(newPos: number): DigitalWidgetBuilder {
        this._X = newPos;
        return this;
    }
    public withYPos(newPos: number): DigitalWidgetBuilder {
        this._Y = newPos;
        return this;
    }
    public withCol(newCol: number): DigitalWidgetBuilder {
        this._COL = newCol;
        return this;
    }
    public withRow(newRow: number): DigitalWidgetBuilder {
        this._ROW = newRow;
        return this;
    }
    public withRelationUUID(newRelation: string): DigitalWidgetBuilder {
        this._RELATIONUUID = newRelation;
        return this;
    }

    public build(): DigitalWidget {
        const auxData: DataDigitalWidget = {
            value: this._VALUE,
            unity: this._UNITY,
            date: moment(this._DATE).format('LLL')
        };

        const auxDigitalWidget: DigitalWidget = {
            uuid: this._DIGITALUUID,
            name: this._DIGITALNAME,
            dataSize: this._DATASIZE,
            digitalData: auxData,
            x: this._X,
            y: this._Y,
            cols: this._COL,
            rows: this._ROW,
            relationUUID: this._RELATIONUUID
        };
        return auxDigitalWidget;
    }
}
