import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { AlarmaModel } from 'src/app/sections/alarmas/models/alarma-models';
import { AlarmasService } from 'src/app/services/alarmas/alarmas.service';
import { LoginService } from 'src/app/services/login/login.service';


@Component({
  selector: 'app-alarma-badge',
  templateUrl: './alarma-badge.component.html',
  styleUrls: ['./alarma-badge.component.scss']
})
export class AlarmaBadgeComponent implements OnInit {

  elementData: AlarmaModel[] = [];
  elementDataAux: AlarmaModel[] = [];
  userId: string;

  constructor(
    private alarmasService: AlarmasService,
    private loginService: LoginService,
    private router: Router,
  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
} 
  }

  ngOnInit() {


    //this.userId = 'd282603a-a328-4b9d-bf77-1885e571ad78';
    this.userId = this.loginService.currentUserValue.uuid;
    this.getAlarmasCollectionByUser(this.userId);  
    const getAlarmas = interval(10000);
    getAlarmas.subscribe(subscription=>{
      this.getAlarmasCollectionByUser(this.userId);  
    });
    
    
  }

  getAlarmasCollectionByUser(uuidUser: string){
    
    this.alarmasService.getAlarmasByOwner(uuidUser)
    .subscribe(alarmasUser=>{
      //get Alarmas by owner
      
      if(alarmasUser["miUsuario"]["alarmas"]){
        this.elementData = alarmasUser["miUsuario"]["alarmas"].map(alarma =>{
          return alarma.to as AlarmaModel;
        }).filter((alarma:AlarmaModel)=>{
          return alarma.deleted==null;
        });
      }
      //setear el usuario logeado como el owner en el arreglo de Alarmas
      this.elementData.forEach(data=>{
         data.usuario = uuidUser
      })


      //get subscribedAlarmas
      this.alarmasService.getAlarmasByUser(uuidUser)
      .subscribe(alarmas=>{
        let alarmasGeneral:AlarmaModel[];
        let subsribedAlarmas:AlarmaModel[]=[];
        alarmasGeneral= alarmas["listadoAlarmas"] as AlarmaModel[];
        
        alarmasGeneral.filter((alarma:AlarmaModel)=>{
          return alarma.deleted==null;
        })        
        .forEach(alarma=>{
            alarma.informa.forEach(informa=>{
              if(informa.to.uuid==uuidUser){
                if (!_.find(this.elementData, ['uuid', alarma.uuid])  && alarma.disabled==null){ //agregarla si no es owner de la alarma
                  this.elementData.push({...alarma , usuario:""});
                }
              }
            })
        });
                
        this.elementDataAux = this.elementData

      })
    })
  }

  get AlarmedCounter(){
    this.elementDataAux = this.elementData.filter(alarma=>{
      let informa:any[];
      informa = alarma.informa.filter(informa =>{
        return (informa.to.uuid === this.userId && _.isNumber(informa.alarmado));
      });
      return (informa.length > 0 )
    })
    
    return this.elementDataAux.length
  }

  get SubscribedCounter(){
    this.elementDataAux = this.elementData.filter(alarma=>{
      let informa:any[];
      informa = alarma.informa.filter(informa =>{
        return (informa.to.uuid === this.userId && _.isNumber(informa.alarmado));
      });
      return (informa.length === 0 )
    })
    return this.elementDataAux.length
  }

  get ActiveCounter(){
    this.elementDataAux = this.elementData.filter(alarma=>{
      return (alarma.disabled===null && alarma.deleted===null);
    })
    return this.elementDataAux.length
  }

  goAlarmed(){
    this.router.navigate(['/alarmas/alarmed'],{
      replaceUrl: true
    });
  }
  goSubscribed(){
    this.router.navigate(['/alarmas/subscribed'],{
      replaceUrl: true
    });
  }
  goActive(){
    this.router.navigate(['/alarmas/active'],{
      replaceUrl: true
    });
  }
 
}
