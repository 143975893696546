import { Component, ViewEncapsulation, ViewChild, AfterViewInit, Renderer2, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Moment } from 'moment';
import * as moment from 'moment';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { MatCalendar } from '@angular/material';
import { DateRange } from 'src/app/models/dateRange.model';

export interface RangeMoment {
  from: Moment;
  to: Moment;
}

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeComponent implements OnInit, AfterViewInit {

  @ViewChild('calendarFrom', { static: false }) calendar: MatCalendar<Moment>;
  @ViewChild('calendarTo', { static: false }) calendar1: MatCalendar<Moment>;

  selectedDateFrom: Moment;
  selectedDateTo: Moment;
  enableApplyButton = false;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DateRange,
    private renderer: Renderer2) { }

  ngOnInit() {
    setTimeout(() => {
      this.selectedDateFrom = this.data[0].from;
      this.selectedDateTo = this.data[0].to;
      this.calendar._goToDateInView(this.data[0].from, 'month');
      this.calendar1._goToDateInView(this.data[0].to, 'month');
    }, 200);

  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  toggleApplyButton() {
    if (this.enableApplyButton === false) {
      this.enableApplyButton = true;
    }
  }

  directDate(date) {
    this.enableApplyButton = true;
    switch (date) {
      case 'today':
        this.selectedDateFrom = moment().subtract(1, 'days').endOf('day').add(1, 'second');
        this.selectedDateTo = moment().endOf('day');
        this.calendar._goToDateInView(moment(), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        break;
      case 'yesterday':
        this.selectedDateFrom = moment().subtract(2, 'days').endOf('day').add(1, 'second');
        this.selectedDateTo = moment().endOf('day');
        this.calendar._goToDateInView(moment().subtract(1, 'days'), 'month');
        this.calendar1._goToDateInView(moment().subtract(1, 'days'), 'month');
        break;
      case 'lastWeek':
        this.selectedDateFrom = moment().subtract(8, 'days').endOf('day').add(1, 'seconds');
        this.selectedDateTo = moment().endOf('day');
        this.calendar._goToDateInView(moment().subtract(7, 'days'), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        break;
      case 'lastMonth':
        this.selectedDateFrom = moment().subtract(31, 'days').endOf('day').add(1, 'seconds');
        this.selectedDateTo = moment().endOf('day');
        this.calendar._goToDateInView(moment().subtract(30, 'days'), 'month');
        this.calendar1._goToDateInView(moment(), 'month');
        break;
    }

  }
  public saveDate() {
    const range: RangeMoment = {
      from: moment(this.selectedDateFrom),
      to: moment(this.selectedDateTo)
    };

    this._bottomSheetRef.dismiss();
    return range;


  }
  ngAfterViewInit() {
    // Find all arrow buttons in the calendar
    const buttons = document.querySelectorAll('mat-calendar mat-calendar-header button');
    if (buttons) {
      // Listen for click event
      Array.from(buttons).forEach(button => {
        this.renderer.listen(button, 'click', () => {
        });
      });
    }
  }

}
