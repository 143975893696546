import { Component, OnInit, Input } from '@angular/core';
import { NgxChart } from 'src/app/models/ngx-chart.model';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
@Component({
  selector: 'app-widget-pie-chart',
  templateUrl: './widget-pie-chart.component.html',
  styleUrls: ['./widget-pie-chart.component.scss']
})
export class WidgetPieChartComponent implements OnInit {
  @Input() ngxChart: NgxChart;
  public pieChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartColors = [
    {
      backgroundColor: ['#9C27B0', '#673AB7', '#3F51B5','#5677FC','#03A9F4','#18FFFF','#00BCD4','#009688','#056F00']
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
