// MODULES
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './module/home/home.module';
import { DashboardModule } from './module/dashboard/dashboard.module';
import { AlarmModule } from './module/alarm/alarm.module';
import { AlarmaModule } from './module/alarma/alarma.module';
import { MapaModule } from './module/mapa/mapa.module';
import { LoginModule } from './module/login/login.module';
import { MaterialModule } from './module/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeviceStatusModule } from './module/device-status/device-status.module';
import { LatestMeasurementsModule } from './module/latest-measurements/latest-measurements.module';
import { LastStatesModule } from './module/last-states/last-states.module';
import { ComparativeGraphsModule } from './module/comparative-graphs/comparative-graphs.module';
import { CheckMeasurementsProductsModule } from './module/check-measurements-products/check-measurements-products.module';
import { GraphicsModule } from './module/graphics/graphics.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StorageModule } from '@ngx-pwa/local-storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DispositivosModule } from './module/dispositivos/dispositivos.module';
import { SharedModuleModule } from './module/shared-module/shared-module.module';
import { ToastrModule } from 'ngx-toastr';
import './models/array-prototype.model';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule } from '@angular/material';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClipboardModule } from 'ngx-clipboard';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule, MatExpansionModule } from '@angular/material';
import { MatFormFieldModule, MatButtonModule } from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';


// TRANSLATE
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';


// GRAPHQL
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { GraphQLModule } from './graphql.module';

// COMPONENTS
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { BbddComponent } from './sections/bbdd/bbdd.component';

import { PagenotfoundComponent } from './components/shared/pagenotfound/pagenotfound.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GraphicsComponent } from './sections/visualizacionFolder/graphics/graphics.component';
import { DeviceStatusComponent } from './sections/visualizacionFolder/device-status/device-status.component';
import { LatestMeasurementsComponent } from './sections/visualizacionFolder/latest-measurements/latest-measurements.component';
import { LastStatesComponent } from './sections/visualizacionFolder/last-states/last-states.component';
import { ComparativeGraphsComponent } from './sections/visualizacionFolder/comparative-graphs/comparative-graphs.component';
import { CheckMeasurementsProductsComponent } from './sections/visualizacionFolder/check-measurements-products/check-measurements-products.component';
import { DispositivosComponent } from './sections/dispositivos/dispositivos.component';
import { InformesComponent } from './components/informes/informes.component';
import { InformeFabricaComponent } from './components/informes/informe-fabrica.component';
import { InformeMaeComponent } from './components/informes/informe-mae.component';
import { PopUpComponent } from './components/utilidades/ventana-dsp/pop-up.component';
import { ReportParamComponent } from './components/informes/ventanas/fabrica/report-param/report-param.component';
import { ReportRowComponent } from './components/informes/ventanas/fabrica/report-row/report-row.component';
import { NavegacionDependenciasComponent } from './components/navegacion-dependencias/navegacion-dependencias.component';
import { EstructuraCampoComponent } from './sections/estructuraCampo/estructuraCampo.component';
import { EstructuraCampoNavComponent } from './components/estructuraCampoNav/estructuraCampoNav.component';
import { ReportTableComponent } from './components/informes/ventanas/fabrica/report-table/report-table.component';
import { GraphFunciones } from './components/informes/consultas/consultas-graphql.service';
import { GraphFuncionesFabrica } from './components/informes/consultas/fabrica/consultas-graphql.service';
import { GraphFuncionesMae } from './components/informes/consultas/mae/consultas-graphql.service';
import { IridiumeRequestModalComponent } from './components/iridium-events/iridium-request-modal/iridium-request-modal.component';

// PIPES
import { SelectPipeBBDD } from 'src/app/pipes/pipes/pipe-iker-map.pipe';
import { GetUtcDatePipe } from 'src/app/pipes/pipes/pipe-utcDate.pipe';
import { ToFixedPipe } from 'src/app/pipes/tofixed.pipe';
import { GetAlarmaCampoPipe } from 'src/app/pipes/alarmaCampo.pipe';
import { AlarmsComponent } from './sections/alarms/alarms.component';
import { AlarmasComponent } from './sections/alarmas/alarmas.component';

// SERVICES
import { LocalstorageService } from './services/localstorage/localstorage.service';
import { ApolloGraphQlService } from './services/apollo/apollo-graphql.service';
import { LoginComponent } from './sections/login/login.component';
import { HomeComponent } from './sections/home/home.component';
import { FactoriesService } from './services/factories/factories.service';
import { environment } from 'src/environments/environment';
import { arbolDispositivos } from './services/arbolDispositivos/arbolDispositivos.component';
import { arbolDatos } from './components/arbolDatos/arbolDatos.component';
import { UsersComponent } from './sections/users/users.component';
import { EntradasNavComponent } from './components/entradas-nav/entradas-nav.component';
//import { MapaComponent} from './sections/mapa/mapa.component';
import { NoCredentialsComponent } from './components/noCredentials/no-credentials.component';
import { AngularCesiumModule } from 'angular-cesium';
import { CookieService } from 'ngx-cookie-service';
//import { BillboardLayerDevicesComponent} from './components/mapa/billboard-layer-devices/billboard-layer-devices.component';


import { IridiumEventsComponent } from './components/iridium-events/iridium-events.component';
import { UltimasMedicionesModule } from './module/ultimas-mediciones/ultimas-mediciones.module';
import { InformesTablesComponent } from './components/informes/informes/informes-tables/informes-tables.component';

@NgModule({
  declarations: [
    JSON.parse(environment.iridium) === true ? IridiumEventsComponent : [],
    NoCredentialsComponent,
    EstructuraCampoNavComponent,
    EstructuraCampoComponent,
    ToFixedPipe,
    GetUtcDatePipe,
    GetAlarmaCampoPipe,
    AppComponent,
    FooterComponent,
    DashboardComponent,
    PagenotfoundComponent,
    GraphicsComponent,
    AlarmsComponent,
    AlarmasComponent,
    UsersComponent,
    LoginComponent,
    HomeComponent,
    SidebarComponent,
    DeviceStatusComponent,
    LatestMeasurementsComponent,
    LastStatesComponent,
    ComparativeGraphsComponent,
    CheckMeasurementsProductsComponent,
    // IdiomaComponent,
    DispositivosComponent,
    BbddComponent,
    arbolDispositivos,
    SelectPipeBBDD,
    arbolDatos,
    InformesComponent,
    InformeFabricaComponent,
    InformeMaeComponent,
    PopUpComponent,
    ReportParamComponent,
    ReportRowComponent,
    NavegacionDependenciasComponent,
    ReportTableComponent,
    EntradasNavComponent,
    //MapaComponent,
    //BillboardLayerDevicesComponent,
    IridiumEventsComponent,
    IridiumeRequestModalComponent,
    InformesTablesComponent,
  ],
  entryComponents: [
    PopUpComponent,
    ReportParamComponent,
    ReportRowComponent,
    ReportTableComponent,
    NavegacionDependenciasComponent,
    DispositivosComponent,
    IridiumeRequestModalComponent
  ],
  imports: [
    GraphQLModule,
    NgSelectModule,
    MatExpansionModule,
    ClipboardModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    SelectDropDownModule,
    FilterPipeModule,
    NgxPaginationModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModuleModule,
    LoginModule,
    HomeModule,
    DashboardModule,
    DispositivosModule,
    AlarmModule,
    AlarmaModule,
    MapaModule,
    AngularFontAwesomeModule,
    FontAwesomeModule,
    NgbModule,
    DeviceStatusModule,
    LatestMeasurementsModule,
    LastStatesModule,
    ComparativeGraphsModule,
    CheckMeasurementsProductsModule,
    GraphicsModule,
    NgxSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ApolloModule,
    HttpLinkModule,
    FormsModule,
    ReactiveFormsModule,
    StorageModule.forRoot({
      IDBNoWrap: true,
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    MatFormFieldModule,
    MatButtonModule,
    UltimasMedicionesModule,

  ],
  providers: [
    ApolloGraphQlService,
    LocalstorageService,
    FactoriesService,
    GraphFunciones,
    GraphFuncionesFabrica,
    GraphFuncionesMae,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


