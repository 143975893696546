import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { TiposAlarma , Alarmas, AlarmasByUser, InformarAlarma, DesinformarAlarma, createAlarma, addAlarmaParametro, addAlarmaDepende, 
  informarAlarma, deleteAlarma, disableAlarma, rearmarAlarma, AlarmasByOwner, enableAlarma, updateAlarma, updateParameterAlarma, 
  EventosAlarma, addAlarmaDependeDispYCodigo  } from './queries/queries'
import { HttpClient } from '@angular/common/http';
import { QueryOptions, MutationOptions } from 'apollo-client';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import { FindDatoFromDeviceAndDataTypeBuilder } from 'src/app/models/graphQL/datoFromDeviceAndDatatype.request';
import { AlarmaModel, InformaModel, AlarmaEvento } from 'src/app/sections/alarmas/models/alarma-models';


@Injectable({
  providedIn: 'root'
})
export class AlarmasService {

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private apolloService: ApolloGraphQlService,
  ) { 


  }

  getTiposAlarma(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: TiposAlarma,
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(map(response => response.data = response.data))
  }

  getAlarmas(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: Alarmas,
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(map(response => response.data = response.data))
  }

  getAlarmasByUser(uuidUser:string ): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: AlarmasByUser,  
      variables: {
        usuario: uuidUser,    
      },
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(map(response => response.data = response.data))
  }

  getAlarmasByOwner(uuidUser:string ): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: AlarmasByOwner,  
      variables: {
        uuid: uuidUser,    
      },
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(map(response => response.data = response.data))
  }

  subscribeUserAlarma(uuidUser:string, uuidAlarma:string ): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: InformarAlarma,  
      variables: {
        usuario: uuidUser,    
        alarma: uuidAlarma,    
      },
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(map(response => response.data = response.data))
  }

  unsubscribeUserAlarma(uuidInforma:string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: DesinformarAlarma,  
      variables: {
        informa: uuidInforma,    
      },
    }) 
    .pipe(map(response => response.data = response.data))
  }

  public getDatoUuid(deviceUuid: string, dataTypeUuid: string) {
    const queryOptions: QueryOptions = new FindDatoFromDeviceAndDataTypeBuilder()
      .withDeviceUUID(deviceUuid)
      .withDataTypeUUID(dataTypeUuid)
      .build();    
    return this.apolloService.executeQuery(queryOptions);
  }

  public createAlarma(
    nombre:string,
    descripcion:string,
    tipo:string,
    usuario:string) 
    
    {
    return this.apollo.mutate({
        mutation: createAlarma,
        variables: {
          nombre: nombre, 
          descripcion: descripcion, 
          tipo: tipo,
          usuario: usuario
        }
    })
    .pipe(map(response => response.data = response.data["createAlarma"])) 
   }

  public addAlarmaParametro(
    alarma:string,
    clave:string,
    valor:string,
    )    
    {
    this.apollo.mutate({
        mutation: addAlarmaParametro,
        variables: {
          alarma: alarma, 
          clave: clave, 
          valor: valor,
        }
    }).subscribe((data) => { 
      return data;
    });
  }

  public addAlarmaDepende(
    alarma:string,
    variable:string,
    dato:string,
    )    
    {
    this.apollo.mutate({
        mutation: addAlarmaDepende,
        variables: {
          alarma: alarma, 
          variable: variable, 
          dato: dato,
        }
    }).subscribe((data) => { 
      return data;
    });
  }

  public addAlarmaDependeDispYCodigo(
    alarma:String, 
    dispUUID:String, 
    codigoTD: String, 
    variable: String) {
      this.apollo.mutate({
        mutation: addAlarmaDependeDispYCodigo,
        variables: {
          alarma: alarma, 
          dispUUID:  dispUUID,
          codigoTD: codigoTD,
          variable: variable
        }
    }).subscribe((data) => { 
      return data;
    });
    }
  
  public informarAlarma(
    alarma:string,
    usuario:string
    )    
    {
    this.apollo.mutate({
        mutation: informarAlarma,
        variables: {
          alarma: alarma, 
          usuario: usuario
        }
    }).subscribe((data) => { 
      return data;
    });
  }

  public deleteAlarma(
    alarma:string
    )    
    {
      return this.apollo.mutate({
        mutation: deleteAlarma,
        variables: {
          uuid: alarma
        }
    })
    .pipe(map(response => response.data = response.data["deleteAlarma"])) 
  }

  public disableAlarma(
    alarma:string
    )    
    {
      return this.apollo.mutate({
        mutation: disableAlarma,
        variables: {
          uuid: alarma
        }
    })
    .pipe(map(response => response.data = response.data["disableAlarma"])) 
  }

  public enableAlarma(
    alarma:string
    )    
    {
      return this.apollo.mutate({
        mutation: enableAlarma,
        variables: {
          uuid: alarma
        }
    })
    .pipe(map(response => response.data = response.data["enableAlarma"])) 
  }

  public rearmarAlarma(
    alarma:string,
    usuario:string
    )    
    {
      return this.apollo.mutate({
        mutation: rearmarAlarma,
        variables: {
          alarma: alarma,
          usuario:usuario
        }
    })
    .pipe(map(response => response.data = response.data["rearmarAlarma"])) 
  }

  public updateAlarma(
    alarma:string,
    nombre?:string,
    descripcion?:string,
    timeout?:number,
    )    
    {
      return this.apollo.mutate({
        mutation: updateAlarma,
        variables: {
          uuid: alarma,
          nombre:nombre,
          descripcion:descripcion,
          timeout:timeout
        }
    })
    .pipe(map(response => response.data = response.data["updateAlarma"])) 
  }

  public updateParameterAlarma(
    uuid:string,
    clave:string,
    valor:string
    )    
    {
      return this.apollo.mutate({
        mutation: updateParameterAlarma,
        variables: {
          uuid: uuid,
          clave:clave,
          valor:valor
        }
    })
    .pipe(map(response => response.data = response.data["updateAlarmaParametro"])) 
  }

  
  getEventosAlarma(
    uuid:string
  ): Observable<AlarmaEvento[]> {
    return this.apollo.watchQuery<any>({
      query: EventosAlarma,
      variables: {
        alarma: uuid
      },
      fetchPolicy: 'network-only'
    }).valueChanges
    .pipe(
      tap(res =>{} ),
      map(response => {
        return response.data = response.data
      }),
      map(data => {
        return data = data.getAlarmaEventos as AlarmaEvento[];
      }),
      )
  }


  

}
