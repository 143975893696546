import { DocumentNode } from 'apollo-link';
import gql from 'graphql-tag';
import { QueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

interface FactoryGraphQL {
    usuario: string;
}
export class FactoryGraphQLBuilder {

    private _USUARIO: string;

    get _query(): DocumentNode {
        return gql`query DispositivosDeUsuario{
                    dispositivosByUsuario {
                        uuid
                        nombre
                        descripcion
                        }
                    }`;
    }

    public withUserParam(usuario: string): FactoryGraphQLBuilder {
        this._USUARIO = usuario;
        return this;
      }

      public build(): QueryOptions {
        let queryParams: FactoryGraphQL;
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        queryParams = { usuario: this._USUARIO };
        return graphQlBuilder.withQuery(this._query)
          .withParams(queryParams)
          .build();
      }
}
