import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { PureQueryOptions, MutationOptions, OperationVariables } from 'apollo-client';
import { FetchResult } from 'apollo-link';

@Injectable({
  providedIn: 'root'
})
export class ApolloGraphQlService {

  constructor(private apollo: Apollo) { }

  executeQuery<TReturn>(queryOptions: PureQueryOptions): Observable<TReturn> {
    return this.apollo
      .watchQuery<TReturn>(queryOptions)
      .valueChanges.map(result => result.data);
  }

  // tslint:disable-next-line: max-line-length
  executeQueryWithMappedFunction<TReturn, TGraphQlReturn>(queryOptions: PureQueryOptions, mapFunction: (a: TGraphQlReturn) => TReturn): Observable<TReturn> {
    return this.apollo
      .watchQuery<TGraphQlReturn>(queryOptions)
      .valueChanges.map(result => mapFunction(result.data));
  }

  executeMutation<T = { [key: string]: any; }, TVariables = OperationVariables>(mutationOptions: MutationOptions<T, TVariables>)
  : Observable<FetchResult<T>> {
    return this.apollo
      .mutate<T, TVariables>(mutationOptions).map(result => result.data);
  }

}
