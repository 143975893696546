import { Component, OnInit } from '@angular/core';
import { FakeServiceService } from 'src/app/services/fakeService/fake-service.service';
import {TranslateService} from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login/login.service';
import { User } from 'src/app/models/user.model';

export interface Idioma {
  value: string;
  class: string;
}

@Component({
  selector: 'app-idioma',
  templateUrl: './idioma.component.html',
  styleUrls: ['./idioma.component.scss']
})
export class IdiomaComponent implements OnInit {
  selected = 'es';
  opcionesIdiomas: Idioma[] = [];

  constructor(private fakeService: FakeServiceService,
              private loginService: LoginService,
              private translate: TranslateService) {
    this.selected = this.loginService.currentUserValue.language;
    this.translate.setDefaultLang(this.selected);
  }

  getData() {
    this.fakeService.getLanguagesData().subscribe((data: Idioma[]) => {
      this.opcionesIdiomas = this.removeLangSelected(data, this.selected);
    });
  }

  ngOnInit() {
    this.getData();
  }

  public changeLanguage(lang) {
    this.selected = lang;
    this.getData();
    this.translate.use(lang);
    this.updateLanguage(lang);
  }

  updateLanguage(lang) {
    let currentUser: User;
    currentUser = this.loginService.currentUserValue;
    currentUser.language = lang;
    this.loginService.updateUser(currentUser).subscribe(data => {
      if (data !== null) {
      }
    });
    this.setLocalStorage(currentUser);
  }

  private setLocalStorage(res: User) {
    localStorage.setItem('loggedUser', JSON.stringify(res));
  }

  removeLangSelected(array, element) {
    return array.filter(el => el.value !== element);
  }

}
