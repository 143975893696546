import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatTable, MatSelectChange, MatAutocompleteSelectedEvent, MatAutocomplete, MatChipInputEvent, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Options } from 'ng5-slider';
import { map, startWith, tap } from 'rxjs/operators';
import { GeoDevicesService, TipoDato } from 'src/app/services/mapa/geodevices.service';
import { DataType } from 'src/app/models/data-type.model';
import { TranslateService } from '@ngx-translate/core';
import { MapaServiceService } from '../../sections/mapa/mapa-service.service';

export interface CodigoTraduccion {
  codigo: string;
  traduccion: string;
}

@Component({
  selector: 'app-seleccion-tipos-datos-modal',
  templateUrl: './seleccion-tipos-datos-modal.component.html',
  styleUrls: ['./seleccion-tipos-datos-modal.component.scss']
})


export class SeleccionTiposDatosModalComponent implements OnInit {


  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  allTiposDatosCollection: TipoDato[];
  tiposDatosCollection: TipoDato[];
  tiposDatosCtrl = new FormControl();
  filteredTiposDatos: Observable<string[]>;
  tiposDatos: string[] = [];
  allTiposDatos: string[] = [];
  selectedData: TipoDato[] = [];

  allTiposDatosTraduccion: CodigoTraduccion[] = [];

  @ViewChild('tiposDatosInput', { read: false, static: false }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { read: false, static: false }) matAutocomplete: MatAutocomplete;


  constructor(
    private geoDevicesService: GeoDevicesService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      formMode: String
    },
    public dialogRef: MatDialogRef<SeleccionTiposDatosModalComponent>,
    private mapService: MapaServiceService
  ) {
    this.filteredTiposDatos = this.tiposDatosCtrl.valueChanges.pipe(
      map((tipoDato: string) => this.allTiposDatos.slice()));
    // this.tiposDatos = this.mapService.tipeDataSelected$.value.map(type => type.codigo);
  }

  ngOnInit() {
    //this.getTiposDatos();
    this.getAllTiposDatos();
    this.getTiposDatosAgrupados();
    const previusSelected = this.mapService.tipeDataSelected$.value;

  }

  getTiposDatosAgrupados() {
    this.geoDevicesService.getTiposDatos().pipe(
      map(tiposDatos => {
        let tiposDatosAgrupados = _.groupBy(tiposDatos.
          filter(td => td.codigo && td.codigo != 'null' && td.agrupacion.length > 0 && td.agrupacion[0].nombre != 'otros'), group => group.codigo)
        return tiposDatosAgrupados;
      }),

      map(tiposDatosAgrupados => {
        let tiposDatosToShow;
        let record: TipoDato[] = [];
        Object.entries(tiposDatosAgrupados).forEach(entry => {
          let group: TipoDato = {
            codigo: (entry[0]),
            descripcion: '',
            displayField: '',
            factorDecimal: '',
            nombre: (entry[0]),
            nombreCode: '',
            unidad: '',
            uuid: '',
            agrupacion: entry[1][0].agrupacion
          }
          record.push(group);
        });
        return tiposDatosToShow = record;
      })

    )
      .subscribe(
        res => {
          this.tiposDatosCollection = res
          this.allTiposDatos = this.tiposDatosCollection.map(dato => {
            return dato.nombre
          })

          this.filteredTiposDatos = this.tiposDatosCtrl.valueChanges.pipe(
            startWith(null),
            map((tipoDato: string) => this.allTiposDatos.sort().slice()));
        }
      )
  }



  getAllTiposDatos() {
    this.geoDevicesService.getTiposDatos().subscribe(
      res => {


        this.allTiposDatosCollection = res;
        // crear una collection con los datos traducidos
        res.forEach(tipoDato => {
          this.allTiposDatosTraduccion.push({
            codigo: tipoDato.codigo,
            traduccion: this.translate.instant(`DATA_TYPES_AND_CODES.${tipoDato.codigo}`)
          })
        })

        // Repoblate selector with previus values
        this.tiposDatos = this.allTiposDatosTraduccion.filter(typo => {
          const previsSelected = this.mapService.tipeDataSelected$.value;
          return previsSelected.find(prev => typo.codigo === prev.codigo)
        }).map(filtered => filtered.traduccion)

        this.tiposDatos = [...new Set(this.tiposDatos)]
      }
    )
  }

  removeTiposDatosNull(tiposDatosCollectionFromService: TipoDato[]): TipoDato[] {
    const auxUserArray: TipoDato[] = [];
    for (const tipoDato of tiposDatosCollectionFromService) {
      if (tipoDato.nombre === null) {
        auxUserArray.push(tipoDato);
      }
    }
    return tiposDatosCollectionFromService = _.difference(tiposDatosCollectionFromService, auxUserArray);
  }


  remove(traduccion: string): void {
    const codeTypeData = this.allTiposDatosTraduccion.find(dato => dato.traduccion === traduccion);
    this.tiposDatos.splice(this.tiposDatos.findIndex(type => type === traduccion), 1)
    this.mapService.removeTipoDato(codeTypeData.codigo);
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    if (!this.tiposDatos.includes(event.option.viewValue)) {
      this.tiposDatos.push(event.option.viewValue);
    }

    this.tiposDatos.forEach(chip => {

      //obtener el codigo traducido
      let value: string;
      value = this.allTiposDatosTraduccion.filter(codigoTraducido => codigoTraducido.traduccion === chip)
        .map(codigoTraducido => codigoTraducido.codigo).firstOrDefault();
      let tiposDatosModel = this.tiposDatosCollection.filter(tiposDatos => {
        return (tiposDatos.nombre == value)
      })
        .firstOrDefault();
      if (tiposDatosModel !== null) {
        const previusData = this.mapService.tipeDataSelected$.value;
        if (previusData.every(previus => previus.codigo !== tiposDatosModel.codigo)) {
          this.mapService.addTipoDato(tiposDatosModel);
        }
      }
      // console.log('selected',this.tiposDatosSelected);
    })

    this.tiposDatosCtrl.setValue(this.mapService.tipeDataSelected$.value.map(type => type.codigo))
  }

  // private _filter(value: string[]): string[] {
  //   return this.allTiposDatos.filter(tipoDato => value.some(v => tipoDato.toLowerCase().includes(v.toLowerCase())));
  // }

  choseData() {
    this.dialogRef.close();
  }

}
