import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { User } from './../../models/user.model';
import { LoginService } from './../../services/login/login.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { GraphFunciones } from './consultas/consultas-graphql.service';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';


// interface reportItf
interface ReportsItf {
  uuid: string;
  titulo: string;
  codigo: string;
  logo: string;
  idioma: string;
}

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.scss']
})
export class InformesComponent implements OnInit, OnDestroy {

  // Variables privadas
  private querySubscription: Subscription[] = [];

  // Variables publicas (acceso desde HTML)
  public reports: ReportsItf[] = [];
  public loading = true;
  public reportHeadRowAdmin: string[] = ['UUID', 'TITULO', 'CODIGO', 'LOGO'];
  public reportHeadRow: string[] = ['TITULO', 'CODIGO', 'LOGO'];
  public verBoton: boolean[] = [];
  public verRef: string[] = [];
  public fecha = false;
  public ano = 0;
  public mes = 0;
  user: User;
  reportCodes: any;



  constructor(private apollo: Apollo,
    private gF: GraphFunciones,
    private loginService: LoginService,
    private toastrservice: ToastrService) {
    this.getReportCodes();
    for (let i = 0; i < 2048; i++) {
      this.verBoton.push(false);
      // this.verRef.push('');
    }
  }

  ngOnInit() {
    this.getReportCodes();
    this.user = this.loginService.currentUserValue;
    this.ano = new Date().getFullYear();
    this.mes = new Date().getMonth();
  }

  editFecha() {
    this.fecha = !this.fecha;
  }

  getReportCodes() {
    this.gF.findReportCodes().subscribe( data => {
      this.reportCodes = data.data.findReportCodes;
    })
  }

  ngOnDestroy() {
    this.querySubscription.map(qs => qs.unsubscribe());
  }

}
