import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import gql from 'graphql-tag';
import { QueryReportMae,  ReportItM, DatoUuid,} from './interfaces.consultas';
import { QueryReports} from '../interfaces.consultas';
import { ConditionalExpr } from '@angular/compiler';

  /// Empieza el programa


@Injectable()
export class GraphFuncionesMae {
    private subscrip: Subscription [] = [];

    constructor(
        private apollo: Apollo ) {
    }

    //////////////////////////
    // FUNCIONES AUXILIARES //
    //////////////////////////

    getTipoDatoMae(uuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: getTipoDatoMae(uuid:"${uuid}"){
            uuid
            nombre
          }        
        }`
      });
    } 

    getDispositivoMae(uuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: getDispositivoMae(uuid:"${uuid}") {
            uuid
            nombre
          }                  
        }`
      });
    } 

    getFabricaMae(uuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: getFabricaMae(uuid:"${uuid}") {
            uuid
            nombre
          }                  
        }`
      });
    } 

    

    getDispositivosAhorroEnFabrica(uuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: cargaDispositivosAhorroEnFabrica(uuid:"${uuid}"){
            uuid
            nombre
          } 
        }`
      });
    } 

    getTipoDatosAhorroEnDispositivoMes(uuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: cargaTipoDatoAhorroEnDispositivoMes(uuid:"${uuid}"){
            uuid
            nombre
          } 
        }`
      });
    } 

    getTipoDatosAhorroEnDispositivoHora(uuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: cargaTipoDatoAhorroEnDispositivoHora(uuid:"${uuid}"){
            uuid
            nombre
          } 
        }`
      });
    } 

    ///////////////
    // MUTATIONS //
    ///////////////

    borrarReportMae(puuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql` mutation delete{
          __typename
          dato: deleteReportMae(puuid: "${puuid}")
        }`,
        refetchQueries: [{query: QueryReports}, {query: QueryReportMae, variables: {uuid: puuid} }]});
    }

    borrarReporParamtMaeDispositivo(puuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql` mutation delete{
          __typename
          dato: deleteReportParamMaeDispositivo(puuid: "${puuid}")
        }`,
        refetchQueries: [{query: QueryReports}, {query: QueryReportMae, variables: {uuid: puuid} }]});
    }
    borrarReporParamtMaeTipoDato(puuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql` mutation delete{
          __typename
          dato: deleteReportParamMaeTipoDato(puuid: "${puuid}")
        }`,
        refetchQueries: [{query: QueryReports}, {query: QueryReportMae, variables: {uuid: puuid} }]});
    }

    // Crear Relaciones

    createReportParamMaeDis(report, dispositivo, codigo): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: createReportParamMaeDis(report: "${report}",dispositivo: "${dispositivo}",codigo: "${codigo}")  
        }`,
        refetchQueries: [{query: QueryReports},{query: QueryReportMae, variables: {uuid: report} }]});
    }

    createReportParamMaeTipoDato(report, tipodato, codigo,periodo, unidadT,unidadK): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: createReportParamMaeTipoDato(report: "${report}",tipodato: "${tipodato}",codigo: "${codigo}",periodo: "${periodo}",unidadT: "${unidadT}",unidadK: "${unidadK}") 
        }`,
        refetchQueries: [{query: QueryReports},{query: QueryReportMae, variables: {uuid: report}}]});
    }

    updateReportParamMaeTipoDato(report,uuid, codigo, periodo, unidadT, unidadK): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: updateReportParamMaeTipoDato(uuid: "${uuid}",codigo: "${codigo}",periodo: "${periodo}",unidadT: "${unidadT}",unidadK: "${unidadK}")
        }`,
        refetchQueries: [{query: QueryReports},{query: QueryReportMae, variables: {uuid: report}}]});
    }


    // Crear REPORT
    crearReportMae(codigo, logo, imagen, idioma, titulo,descripcion,fechaImplantacion): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          dato: createReportMae(codigo: "${codigo}" , idioma: "${idioma}", imagen: "${imagen}", logo: "${logo}", titulo: "${titulo}",descripcion: "${descripcion}",fechaImplantacion:${fechaImplantacion}) {
            uuid
            codigo
            logo
            imagen
            idioma
            titulo
            descripcion
            fechaImplantacion
          } 
          
        }`,
        refetchQueries: [{query: QueryReports}]});
    }

    updateReportMae(uuid, titulo, descripcion, codigo, logo, idioma, imagen, fechaImplantacion) : Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          datos:   updateReportMae(uuid: "${uuid}", titulo: "${titulo}", descripcion: "${descripcion}",codigo: "${codigo}", logo: "${logo}", idioma: "${idioma}", imagen: "${imagen}" , fechaImplantacion: ${fechaImplantacion}  ) {
              uuid
          }
        }`,
      refetchQueries: [{query: QueryReports},{query: QueryReportMae, variables: {uuid: uuid}}]});
    }
    ////////////
    // QUERYS //
    ////////////


    // Cargar toda la estructura del report
    cargarReportMae(ruuid: string): Observable<any> {
      return this.apollo.watchQuery<any>({
          query: QueryReportMae,
          variables: {uuid: ruuid}}).valueChanges;
  }


    // Estrutura del Report
    // Partiendo de a consulta a grahpQl
    obtenerDatosReportMae(rp: any) {
      const tmp: ReportItM = {
        uuid: rp.uuid,
        titulo: rp.titulo,
        descripcion:rp.descripcion,
        codigo: rp.codigo,
        logo: rp.logo,
        idioma: rp.idioma,
        imagen: rp.imagen,
        fechaImplantacion: rp.fechaImplantacion,
        paramsDis:  [],
        paramsTipoDato: []
      };
      rp.paramsDis.map( p => tmp.paramsDis.push({ // REPORTPARAM DISPOSITIVOS
        uuid: p.uuid,
        codigo: p.codigo,
        Dispositivo:{
          descripcion: p.description,
          nombre:p.nombre,
          uuid:p.uuid
        } 
      }));
      rp.paramsTipoDato.map( p => tmp.paramsTipoDato.push({ // REPORTPARAM TIPO DATO
        uuid: p.uuid,
        codigo: p.codigo,
        periodo: p.periodo,
        unidadT: p.unidadT,
        unidadK: p.unidadK,
        TipoDato: {
          codigo: p.codigo,
          descripcion:p.description,
          nombre:p.nombre,
          uuid:p.uuid
        }
      }));
      return {report: tmp, loading: false};
    }


}
