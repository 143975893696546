import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AlarmaEvento } from './alarma-evento.model';
import { GetDatePipe} from '../../pipes/pipes/pipe-date.pipe';

@Component({
  selector: 'app-alarma-eventos',
  templateUrl: './alarma-eventos.component.html',
  styleUrls: ['./alarma-eventos.component.scss'],
  providers: [
    GetDatePipe
  ]
})
export class AlarmaEventosComponent implements OnInit, OnChanges {

  @Input() EventosAlarma: AlarmaEvento[]=[];
  dataSource: MatTableDataSource<AlarmaEvento>;
  columnsToDisplay = ['tipoEvento', 'formula', 'dateCreated'];

  @ViewChild('TableGatewayRaizPaginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(

    private getDatePipe: GetDatePipe,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.EventosAlarma){
      if(this.EventosAlarma!==undefined && this.EventosAlarma!==null){
        this.dataSource = new MatTableDataSource(this.EventosAlarma);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }
  }

  ngOnInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
