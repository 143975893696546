import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Device, DeviceModel } from 'src/app/models/device.model';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import { DeviceChildrenGraphQLBuilder } from 'src/app/models/graphQL/device-children-graphQL.request';
import { PureQueryOptions, MutationOptions, QueryOptions } from 'apollo-client';
import { DeviceGraphItem } from 'src/app/models/device-graph.model';
import { DeviceDescentGQlBuilder } from 'src/app/models/graphQL/device-descent-GQL.request';
import { DeviceDescentGQLResponse } from 'src/app/models/graphQL/device-descent-GQL.response';
import { DeviceChildrenGraphQLResponse,  } from 'src/app/models/graphQL/device-children-graphQL.response';
import { AllDeviceChildrenGraphQLResponse, dispositivo} from 'src/app/models/graphQL/all-device-children-graphQL.response';
import { DeviceDetailGraphQLBuilder } from 'src/app/models/graphQL/device-detail-graphQL.request';
import { DeviceGraphQLResponse, DeviceDetailGraphQLResponse } from 'src/app/models/graphQL/device-detail-graphQL.response';
import { DeviceChildrenWithDatatypes } from 'src/app/models/graphQL/device-children-datatype.request';
import { MapperDevice } from 'src/app/models/device/mapper/mapper.detail';
import * as lodash from 'lodash';
import { UpdateWidgetBuilder } from 'src/app/models/graphQL/mutations/UpdateWidget.mutation';
import { UpdateDeviceBuilder } from 'src/app/models/graphQL/mutations/updateDevice.mutation';
import { AllDeviceChildrenGraphQLBuilder } from 'src/app/models/graphQL/all-device-children-graphQL.request';

@Injectable({
  providedIn: 'root'
})

export class DispositivoService {

  private deviceCollectionBS: BehaviorSubject<Array<Device>>;
  public deviceCollectionObservable: Observable<Array<Device>>;

  constructor(private apolloService: ApolloGraphQlService) {
    this.deviceCollectionBS = new BehaviorSubject<Array<Device>>(new Array<Device>());
    this.deviceCollectionObservable = this.deviceCollectionBS.asObservable();
  }

  public clear() {
    this.deviceCollectionBS.next([]);
  }

  public getDeviceDetailGraphQl(deviceId: string): Observable<Array<Device>> {
    const queryOptions: PureQueryOptions = new DeviceDetailGraphQLBuilder()
      .withDeviceIdParam(deviceId)
      .build();
    const responseDeviceDetailGraphQl = this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDeviceInfoCollection);
    if (!lodash.isEmpty(responseDeviceDetailGraphQl)) {
      return responseDeviceDetailGraphQl;
    }
  }

  public getDeviceChildrenGraphQl(factoryId: string): Observable<Array<DeviceModel>> {
    const queryOptions: PureQueryOptions = new DeviceChildrenGraphQLBuilder()
      .withDeviceIdParam(factoryId)
      .build();
    const responseDeviceChildrenGraphQl = this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toChildrenDeviceModelCollection);
    if (!lodash.isEmpty(responseDeviceChildrenGraphQl)) {
      return responseDeviceChildrenGraphQl
    }
  }

  
  public getAllDeviceChildrenGraphQl(factoryId: string): Observable<Array<DeviceModel>> {
    const queryOptions: PureQueryOptions = new AllDeviceChildrenGraphQLBuilder()
      .withDeviceIdParam(factoryId)
      .build();
    const responseDeviceChildrenGraphQl = 
      this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toAllChildrenDeviceModelCollection);
    if (!lodash.isEmpty(responseDeviceChildrenGraphQl)) {
      return responseDeviceChildrenGraphQl
    }
  }

  public getDeviceChildrenAndDataTypesGraphQl(factoryId: string): Observable<Array<DeviceGraphItem>> {
    const queryOptions: PureQueryOptions = new DeviceChildrenWithDatatypes()
      .withDeviceIdParam(factoryId)
      .build();
    const responseDeviceChildrenAndDataTypesGraphQl =
      this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDeviceGraphItemCollection);
    if (!lodash.isEmpty(responseDeviceChildrenAndDataTypesGraphQl)) {
      return responseDeviceChildrenAndDataTypesGraphQl;
    }
  }

  public getDeviceDescentByUserAndDeviceRootWithDataType(userID: string, deviceRootId: string): Observable<Array<DeviceGraphItem>> {
    const queryOptions: PureQueryOptions = new DeviceDescentGQlBuilder()
      .withUser(userID)
      .withRootDevice(deviceRootId)
      .build();
    const responseDeviceDescentByUserAndDeviceRootWithDataType =
      this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDeviceGraphItem);
    if (!lodash.isEmpty(responseDeviceDescentByUserAndDeviceRootWithDataType)) {
      return responseDeviceDescentByUserAndDeviceRootWithDataType;
    }
  }

  public UpdateDeviceName(deviceUUID: string, newDeviceName: string) {
    const mutationOptions: MutationOptions = new UpdateDeviceBuilder()
      .withDeviceUUID(deviceUUID)
      .withNewDeviceName(newDeviceName)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  private toDeviceGraphItem(toDeviceGraphItem: DeviceDescentGQLResponse): DeviceGraphItem[] {
    return toDeviceGraphItem.findDispositivosAscendancy
      .filter(deviceResult => (deviceResult !== null && deviceResult !== undefined)
        && (deviceResult.tiene !== null && deviceResult.tiene !== undefined)
        && (deviceResult.tiene.length > 0))
      .map(deviceResult => new DeviceGraphItem(deviceResult));
  }

  toChildrenDeviceModelCollection(responseData: DeviceChildrenGraphQLResponse): Array<DeviceModel> {
    return responseData.findEstructura.estructura.map(item => new DeviceModel(item));
  }

  toAllChildrenDeviceModelCollection(responseData: AllDeviceChildrenGraphQLResponse): Array<DeviceModel> {
    let devices: DeviceModel[]=[];
    responseData.dispositivos.map(dispositivo => dispositivo.estructura.map(item=> devices.push(new DeviceModel(item))));
    return devices;
  }

  toDeviceGraphItemCollection(responseData: DeviceChildrenGraphQLResponse): Array<DeviceGraphItem> {
    return responseData.findEstructura.estructura.map(item => MapperDevice.deviceModelToDeviceGraphItem(item));
  }

  toDeviceInfoCollection(responseData: DeviceGraphQLResponse): Array<Device> {
    return [responseData.findEstructura].map(item => MapperDevice.deviceDetailGQLResponseToDevice(new DeviceDetailGraphQLResponse(item)));
  }

}

// dispositivos": [
//   {
//     "uuid": "45527f3b-df6f-4792-a3a0-866a04025f01",
//     "nombre": "Gateways",
//     "estructuraCantidad": 2,
//     "estructura": [