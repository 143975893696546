import gql from 'graphql-tag';

////////////////////////////
// definir los interfaces //
////////////////////////////
// Interfaces
export interface ReportItf {
  uuid: string;
  titulo: string;
  codigo: string;
  logo: string;
  idioma: string;
  params: any[];
  rows: any [];
  tables: any [];
}

export interface DatoUuid {
  dato: {
    uuid: string;
  };
}
export interface Nume {
  dato: {
    numero: number;
  };
}

export interface Dato {
    uuid: string;
    periodo: string;
    tiene: Dispositivo;
    es: TipoDato;
}
export interface TipoDato {
    uuid: string;
    nombre: string;
    unidad: string;
  }
export interface Dispositivo {
    uuid: string;
    nombre: string;
    tiene: Dato [];
  }
export interface Datos {
    datos: Dato [];
}
export interface IQuery {
  datos: any;
}

///////////////
// Mutations //
///////////////


/*
export const createReportFabrica =  gql `
mutation createReportFabrica($titulo: String, $codigo: String, $logo: String, $idioma: String) {
  dato: createReportFabrica(codigo: $codigo, logo: $logo, idioma: $idioma, titulo: $titulo){
    uuid
  }
}`;
*/

export const createReportFabrica =  gql `
mutation createReportFabrica($titulo: String, $codigo: String, $logo: String, $idioma: String) {
  dato: createReportFabrica(codigo: $codigo,titulo: $titulo,idioma: $idioma, logo: $logo){
      uuid
      codigo
      logo
      idioma
      titulo
  } 
}`;

export const createReportParam = gql `
mutation createReportParam($report: String, $dato: String, $codigo: String,
  $periodo: String, $unidadT: String, $unidadK: String, $tipo: String) {
  dato: createReportParam(report:$report, dato:$dato, codigo: $codigo, periodo:$periodo,
          unidadT: $unidadT, unidadK: $unidadK, tipo: $tipo)
}`;

export interface IupdateReport {
  updateReport: {
    uuid: string;
  };
}
export const updateReport = gql `
  mutation updateReport($uuid: String, $titulo: string, $codigo: string, $logo: string ) {
    updateReport(uuid:$uuid, titulo:$titulo, codigo:$codigo, logo:$logo)
}`;

export interface IupdateReportFabrica {
  updateReportFabrica: {
    uuid: string;
  };
}
export const updateReportFabrica = gql `
  mutation updateReportFabrica($uuid: String, $titulo: string, $codigo: string, $logo: string,$idioma: string ) {
    updateReportFabrica(uuid:$uuid, titulo:$titulo, codigo:$codigo, logo:$logo, idioma:$idioma)
}`;

export interface IupdateReportParam {
  updateReportParam: {
    uuid: string;
  };
}
export const updateReportParam = gql `
  mutation updateReportParam($uuid: String, $codigo: String, $periodo: String,
   $unidadT: String, $unidadK: String, $tipo: String)  {
   updateReportParam(uuid: $uuid, codigo: $codigo, periodo:$periodo,
            unidadT: $unidadT, unidadK: $unidadK, tipo: $tipo)
}`;

export interface IdeleteReportParam {
  deleteReportParam: Number;
}
export const deleteReportParam = gql `
  mutation deleteReportParam ($puuid: String)  {
     deleteReportParam (puuid: $puuid)
}`;

export const createReportRow = (titulo: string, orden: number, unidadT: string, unidadK: string ) => {
  const reto = gql `mutation {
    dato: CreateReportRow (titulo: "${titulo}", orden: ${orden}, unidadT: "${unidadT}",unidadK: "${unidadK}" ) {
    uuid
    }
  }`;
  return reto;
};

export const updateReportRow = (puuid: string, titulo: string, orden: number, unidadT: string, unidadK: string ) => {
  const reto = gql `mutation {
    dato:  UpdateReportRow (uuid: "${puuid}", titulo: "${titulo}", orden: ${orden}, unidadT: "${unidadT}",unidadK: "${unidadK}" ) {
    uuid
    }
  }`;
  return reto;
};

export const createReportRowParam = gql `
mutation  createReportRowParam ($reportRow: String, $dato: String, $periodo: String) {
  dato: createReportRowParam (reportRow: $reportRow, dato: $dato, periodo: $periodo)
}`;

export const deleteReportRowParam = gql `
mutation deleteReportRowParam ($puuid: string)  {
  deleteReportRowParam (uuid: $puuid)
}`;

export const createReportTable = (titulo: string, consumoTitulo: string, consumoK: string, itemTitulo: string,
                                  itemK: string, ratioTitulo: string, ratiomedioTitulo: string) => {
    const reto = gql `mutation {
      dato: CreateReportTable (titulo: "${titulo}", consumoTitulo: "${consumoTitulo}", consumoK: "${consumoK}", itemTitulo: "${itemTitulo}",
      itemK: "${itemK}", ratioTitulo: "${ratioTitulo}",  ratiomedioTitulo: "${ratiomedioTitulo}"){
          uuid
      }
    }`;
    return reto;
};

export const updateReportTable = (uuid: string, titulo: string, consumoTitulo: string, consumoK: string, itemTitulo: string,
                                  itemK: string, ratioTitulo: string, ratiomedioTitulo: string) =>  {
  const reto = gql `mutation {
  dato:  UpdateReportTable (uuid: "${uuid}", titulo: "${titulo}", consumoTitulo: "${consumoTitulo}", consumoK: "${consumoK}",
  itemTitulo: "${itemTitulo}", itemK: "${itemK}", ratioTitulo: "${ratioTitulo}",  ratiomedioTitulo: "${ratiomedioTitulo}"){
      uuid
  }
  }`;
  return reto;
};

/////////////
// QUERYS //
////////////


export const QueryDato =  gql`
  query QueryDato($uuid: String) {
      datos:
      findDato (uuid:$uuid) {
      uuid
      periodo
      tiene{
        uuid
        nombre
        nombreTecnico
        descripcion
      }
      es {
        uuid
        unidad
        nombre
      }
    }
}`;
  
export const QueryDatos =  gql`
  query QueryDatos($d_uuid: String) {
      datos:
      findDato (uuid:$d_uuid) {
      uuid
      periodo
      tiene{
        uuid
        nombre
        nombreTecnico
        descripcion
      }
      es {
        uuid
        unidad
        nombre
      }
    }
}`;

export const QueryEstructura = gql `
  query findEstructura($uuid: ID) {
  datos: 
  Dispositivo(filter: {uuid: $uuid}) {
    uuid
    nombre
    estructuraCantidad
    estructura {
          uuid
          nombre
    }
  }
}`;

export const QueryDispositivos = gql `
query findDispositivos {
  datos:
  Dispositivo {
    uuid
    nombre
  }
}`;

// Todos los datos asociados a un report
export const QueryReportFabrica = gql`
query QueryReportDabrica($uuid: String) {
  datos: findReportFabrica(uuid: $uuid) {
    uuid
    titulo
    logo
    idioma
    codigo
    params {
      uuid
      codigo
      periodo
      unidadT
      unidadK
      tipo
      to {
        uuid
        periodo
        tiene {
          uuid
          nombre
          __typename
        }
        es {
          uuid
          nombre
          unidad
          __typename
        }
        __typename
      }
      __typename
    }
    reportRows {
      uuid
      to {
        uuid
        titulo
        orden
        unidadK
        unidadT
        params {
          to {
            uuid
            periodo
            tiene {
              uuid
              nombre
              __typename
            }
            es {
              uuid
              nombre
              unidad
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    reportTables {
      uuid
      to {
        uuid
        titulo
        consumoTitulo
        consumoK
        itemTitulo
        itemK
        ratioTitulo
        ratiomedioTitulo
        dispositivos {
          uuid
          codigo
          to {
            uuid
            nombre
            __typename
          }
          __typename
        }
        tipoDatos {
          uuid
          codigo
          to {
            uuid
            nombre
            unidad
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}`;
