import { DateRange } from './dateRange.model';
import { LimitInfluxParams } from './limitInfluxParams.model';
import * as _ from 'lodash';

export class InfluxQueryBuilder {
    private _deviceUuid: string;
    private _dataTypeUuid: string;
    private _from: number;
    private _to: number;
    private _limitParams: LimitInfluxParams;

    constructor() { }

    public withDeviceUuid(newDeviceUuid: string): InfluxQueryBuilder {
        this._deviceUuid = newDeviceUuid;
        return this;
    }
    public withDataTypeUuid(newDataTypeUuid: string): InfluxQueryBuilder {
        this._dataTypeUuid = newDataTypeUuid;
        return this;
    }
    public withDateFrom(from: number): InfluxQueryBuilder {
        this._from = from;
        return this;
    }
    public withDateTo(to: number): InfluxQueryBuilder {
        this._to = to;
        return this;
    }

    public withLimitParams(newLimitParams: LimitInfluxParams): InfluxQueryBuilder {
        this._limitParams = newLimitParams;
        return this;
    }

    public build(): string {
        let influxQuery = '';

        influxQuery = `SELECT \"${this._deviceUuid}\" FROM \"${this._dataTypeUuid}\"` +
            ` where time >= ${this._from}s` +
            ` and time < ${this._to}s`;

        /*  if (!_.isEmpty(this._limitParams)) {
             influxQuery += ` and \"${this._deviceUuid}\" > ${this._limitParams.pbottom +
                 (this._limitParams.ptop - this._limitParams.pbottom) * 2.5} and \"${this._deviceUuid}\" < ${this._limitParams.ptop +
                 (this._limitParams.ptop - this._limitParams.pbottom) * 2.5}`;
         } */

        return influxQuery;
    }

}
