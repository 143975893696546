import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { NgxChartElementColor, NgxChart, NgxChartType } from 'src/app/models/ngx-chart.model';
import { ColorsService } from 'src/app/services/colors/colors.service';


@Component({
  selector: 'app-widget-charts',
  templateUrl: './widget-charts.component.html',
  styleUrls: ['./widget-charts.component.scss']
})
export class WidgetChartsComponent implements OnInit, OnChanges {

  @Input() ngxChart: NgxChart;
  @Input() chartType: string;
  @Input() stacked: boolean;
  @Input() legend: boolean;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  @Input() opacity: string;
  ngxMethods: NgxChartElementColor = new NgxChartElementColor();
  public lineChartColors = [];
  public alternativeLineChartColors = [{
    backgroundColor: ['']
  }];
  chartOptions;

  constructor(private colorsService: ColorsService) { }

  ngOnInit() {
    this.assignChartOptions(this.ngxChart.type);
    this.getColors();
  }

  assignChartOptions(chartType: NgxChartType) {
    if (chartType === NgxChartType.PIE) {
      this.chartOptions = {
        responsive: true,
        legend: {
          position: 'left',
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              return label;
            },
          },
        }
      };
    }
    else {
      this.chartOptions = {
        responsive: true,
        legend: {
          position: 'top',
          labels: {
            boxWidth: 30,

          },
          align: 'start',
        },

        tooltip: { intersect: false },
        scales: {
          // We use this empty structure as a placeholder for dynamic theming.
          xAxes: [{}],
          yAxes: [
            {
              id: 'y-axis-0',
              position: 'left'
            }
          ]
        }
      };
    }
  }

  getColors() {
    if (this.ngxChart.type === NgxChartType.LINE) {
      this.colorsService.getColors().subscribe((colors: any) => {
        for (const color of colors) {
          this.lineChartColors.push(this.ngxMethods.addHexColor(color.color, this.opacity));
        }
      });
    } else {
      const auxArrayColors: string[] = [];
      this.colorsService.getColors().subscribe((colors: any) => {
        for (const color of colors) {
          auxArrayColors.push(color.color);
        }
        this.alternativeLineChartColors[0].backgroundColor = auxArrayColors;
        this.lineChartColors = this.alternativeLineChartColors;
      });

    }

  }

  ngOnChanges() {
    this.getColors();
  }
}
