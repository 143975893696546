import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewDashboardWidgetParametro {
    widgetUUID: string;
    clave: string;
    valor: string;
}

export class AddDashboardWidgetParametroBuilder {
    private _WIDGETUUID: string;
    private _CLAVE: string;
    private _VALOR: string;

    private get _mutation() {
        return gql`mutation AddDashboardWidgetParametro($widgetUUID: String, $clave: String, $valor: String) {
            addWidgetParametro(widget: $widgetUUID, clave: $clave, valor: $valor) {
              uuid
            }
          }
        `;
    }

    public withWidgetUUID(newWidgetUUID: string): this {
        this._WIDGETUUID = newWidgetUUID;
        return this;
    }

    public withClave(newClave: string): this {
        this._CLAVE = newClave;
        return this;
    }

    public withValor(newValor: string): this {
        this._VALOR = newValor;
        return this;
    }

    public build() {
        const mutationParams: NewDashboardWidgetParametro = {
            widgetUUID: this._WIDGETUUID,
            clave: this._CLAVE,
            valor: this._VALOR
        };
        const graphqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphqlMutationBuilder.withMutation(this._mutation)
            .withParams(mutationParams)
            .build();
    }
}
