import { Component, QueryList, Input, ContentChildren, ElementRef, Renderer, AfterContentInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { GridStackOptions } from './../models/grid-stack-options.model';
import { GridStackItem } from './../models/grid-stack-item.model';
import { GridStackItemComponent } from './../grid-stack-item/grid-stack-item.component';
import * as lodash from 'lodash';

export interface DivMeasurement {
  width: number;
  height: number;
  uuid: string;
  columnWidth?: number
  columnHeight?: number
}

declare var jQuery: any;

@Component({
  selector: 'ngx-grid-stack',
  templateUrl: './grid-stack.component.html',
  styles: [':host { display: block; }']
})
export class GridStackComponent implements AfterContentInit {

  @Input() options: GridStackOptions = new GridStackOptions();
  @Output() divChartSize = new EventEmitter();
  @Output() updatedMeasures = new EventEmitter();
  @ContentChildren(GridStackItemComponent) items: QueryList<GridStackItemComponent>;
  private gridStack: any = null;
  private grid: any = null;
  private defaultOptions = {
    cellHeight: '60px',
    width: 12,
    height: 0,
    animate: true,
    float: true,
    resizable: true
  };

  constructor(private el: ElementRef, private renderer: Renderer, private cd: ChangeDetectorRef) { }

  public makeWidget(item: any) {
    item.jGridRef = this.grid;
    if (item.option != null && item.option.noResize != null && item.option.noResize) {
      return;
    }
    
    const divMeasurement: DivMeasurement = {
      width: item.el.nativeElement.clientWidth,
      height: item.el.nativeElement.clientHeight,
      uuid: item.el.nativeElement.id,
      columnWidth: item.option.width,
      columnHeight: item.option.height

    };
    this.cd.detectChanges();
    this.divChartSize.emit(divMeasurement);
    this.updateWidget(item);
  }

  public updateWidget(item: GridStackItemComponent) {
    this.grid.resizable(item.nativeElement, true);
    this.grid.move(item.nativeElement, item.option.x, item.option.y);
    this.grid.resize(item.nativeElement, item.option.width, item.option.height);
    this.grid.compact(); // rellena los espacios en blanco posible
  }

  public AddWidget(item: GridStackItemComponent) {
    item.jGridRef = this.grid;
    this.grid.addWidget(item.nativeElement);
    this.grid.resizable(item.nativeElement, true);
    this.grid.move(item.nativeElement, item.option.x, item.option.y);
    this.grid.resize(item.nativeElement, item.option.width, item.option.height);
    this.cd.detectChanges();
  }

  public RemoveWidget(item: GridStackItemComponent) {
    this.grid.removeWidget(item.nativeElement, false);
  }

  ngAfterContentInit(): void {
    const that = this;
    const nativeElement = this.el.nativeElement;
    if (this.options == null) {
      this.options = new GridStackOptions();
    }

    for (const key of Object.keys(this.defaultOptions)) {
      if (!this.options.hasOwnProperty(key)) {
        this.options[key] = this.defaultOptions[key];
      }
    }

    this.renderer.setElementAttribute(nativeElement, 'data-gs-width', String(this.options.width));
    this.renderer.setElementAttribute(nativeElement, 'data-gs-height', String(this.options.height));

    this.gridStack = jQuery(nativeElement).gridstack(this.options);
    this.grid = this.gridStack.data('gridstack');

    this.gridStack.on('change', (e: any, items: any) => {
      lodash.each(items, (item: any) => this.widgetChanged(item)); //Cambiada variable _ por lodash
    });
    // Initialize widgets
    this.items.forEach(item => that.makeWidget(item));
    this.cd.detectChanges();
  }

  private widgetChanged(change: GridStackItem): void {

      const jWidget = change.el;
      const divMeasurement: DivMeasurement = {
        width: change.el[0].clientWidth,
        height: change.el[0].clientHeight,
        uuid: change.el[0].id,
        columnWidth: change.width,
        columnHeight: change.height
      };

      this.divChartSize.emit(divMeasurement);
      const gridStackItem = this.items.find(item => item.jWidgetRef !== null ? item.jWidgetRef === jWidget[0] : false);
      if (!gridStackItem) {
        return;
      }

      gridStackItem.update(change.x, change.y, change.width, change.height);
      this.cd.detectChanges();
  }

}
