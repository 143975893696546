import { DocumentNode } from 'apollo-link';
import gql from 'graphql-tag';
import { QueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';
interface WidgetUserIDGraphQL {
  userId: string;
}
export class WidgetGraphQlBuilder {
  private userId: string;
  private get _query(): DocumentNode {

    return gql`query findWidgetsByUser {
      miUsuario {
        dashboards {
          uuid
          owner
          deleted
          from {
            uuid
            nombre
            usuario
          }
          to {
            nombre
            uuid
            widgets {
              uuid
              x
              y
              width
              height
              to {
                uuid
                nombre
                widgetType {
                  uuid
                  descripcion
                  id_interno
                }
                parametros {
                  clave
                  valor
                  dispositivos {
                    uuid
                    nombre
                  }
                  tipodatos {
                    uuid
                    nombre
                    unidad
                    codigo
                  }
                }
              }
            }
          }
        }
      }
    }`;
  }

  public withUserIdParam(userId: string): this {
    this.userId = userId;
    return this;
  }

  public build(): QueryOptions {
    const queryParams: WidgetUserIDGraphQL = { userId: this.userId };
    const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
    return graphQlBuilder.withQuery(this._query)
      .withParams(queryParams)
      .build();
  }
}
