import { Component, OnInit } from '@angular/core';
import { Budget } from 'src/app/models/budget.model';
import { UserService } from 'src/app/services/user/user.service';
import { UserModel } from 'src/app/models/userModel';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login/login.service';
import { FactorySimpleModel } from 'src/app/models/FactorySimple.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  budgetCollection: Budget[] = [];
  activatedStyle = false;
  isSelected = false;
  enableUserDetail = false;
  enableUserForm = false;
  userSelected: UserModel;
  elementData: UserModel[] = [];
  elementDataAux: UserModel[] = [];
  formMode = '';
  isAdmin = false;
  loadingText = 'LOADING.USERS';
  showUsers = false;

  constructor(private userService: UserService,
    private toastr: ToastService,
    private translate: TranslateService,
    private loginService: LoginService,
    public dialog: MatDialog) {
    this.isAdminUser();
  }

  isAdminUser() {
    if (this.loginService.currentUserValue.rol.includes('admin')) {
      this.isAdmin = true;
    }
  }

  ngOnInit() {
    this.getBudgetCollection();
    this.getUserCollection();
    this.isAdminUser();
  }

  private getUserCollection() {
    this.userService.getUserCollectionReal().subscribe(data => {
      this.elementData = data;
      this.elementDataAux = this.elementData;
      this.checkIfIsLoading();
    });
  }

  private checkIfIsLoading() {
    if (!_.isEmpty(this.elementData)) {
      this.showUsers = true;
    }
  }

  private getBudgetCollection() {
    this.userService.getBudgetCollection().subscribe(response => {
      this.budgetCollection = response;
    });
  }

  filterByState(newBudgetCollection: Budget[]) {
    const budgetStateCollection: string[] = [];
    for (const budget of newBudgetCollection) {
      if (budget.selected) {
        budgetStateCollection.push(this.getLiteralState(budget.name));
      }
    }
    if (budgetStateCollection.length === 0) {
      for (const budget of newBudgetCollection) {
        if (budget.name === 'USER.TOTAL') {
          budget.selected = !budget.selected;
          budgetStateCollection.push(this.getLiteralState(budget.name));
        }
      }
    }
    this.elementData = this.getAlarmCollectionByState(budgetStateCollection);
  }

  private getAlarmCollectionByState(stateCollection: string[]): UserModel[] {
    if (stateCollection[0] === 'Total') {
      return this.elementDataAux;
    } else {
      let auxAlarmCollectionByState: UserModel[] = [];
      auxAlarmCollectionByState = this.elementDataAux.filter(item => stateCollection.includes(item.state));
      return auxAlarmCollectionByState;
    }
  }

  private getLiteralState(budgetName: string): string {
    switch (budgetName) {
      case 'USER.ACTIVATED':
        return 'Activado';
      case 'USER.DEACTIVATED':
        return 'Desactivado';
      case 'USER.TOTAL':
        return 'Total';
    }
  }

  showUserForm() {
    this.toggleUserForm();
    this.formMode = 'create';
  }

  controlStateButtonStyle(newState: string) {
    newState !== 'Activada' ? this.activatedStyle = true : this.activatedStyle = false;
  }

  selectUser(user: UserModel) {
    this.userSelected = user;
    this.enableUserDetail = !this.enableUserForm;
  }

  goBackUserTable() {
    this.enableUserDetail = false;
  }

  toggleUserForm() {
    this.enableUserForm = !this.enableUserForm;
  }

  cancelForm() {
    this.toggleUserForm();
  }

  confirmChangeUserState(user: UserModel) {
    let warningMessage = 'USER.CHANGE_TO_ACTIVATED';
    if (user.state === 'Activado') {
      warningMessage = 'USER.CHANGE_TO_DEACTIVATED';
    }
    const options = {
      title: user.user,
      message: warningMessage,
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeUserStatus(user);
      }
    });
  }

  private changeUserStatus(user: UserModel) {

    this.userService.updateUserState(user.uuid, user.state, user.user, user.name, user.firstName, user.timeZone, user.language, user.email, user.rol).subscribe((response: any) => {
      let auxMessage = '';
      let auxMessage2 = '';
      if (response.updateUsuario !== null) {
        user.state === 'Activado' ? user.state = 'Desactivado' : user.state = 'Activado';
        this.elementDataAux.map(item => {
          if (item.uuid === user.uuid) {
            item = user;
          }
        });
        this.elementData = [];
        this.elementData = this.elementDataAux;
        this.userService.updateCollection(this.elementData);
        this.translate.get('USER.CHANGE_STATUS_OK').subscribe(value => auxMessage = value);
        this.translate.get('USER.SUCCESS').subscribe(value => auxMessage2 = value);
        this.toastr.showSuccess(auxMessage, auxMessage2);
      } else {
        this.translate.get('USER.CHANGE_STATUS_ERROR').subscribe(value => auxMessage = value);
        this.translate.get('USER.ERROR').subscribe(value => auxMessage2 = value);
        this.toastr.showError(auxMessage, auxMessage2);
      }
    });
  }

  confirmDeleteUser(user: UserModel) {
    const options = {
      title: user.user,
      message: 'USER.DELETE_USER_MESSAGE',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteUser(user);
      }
    });
  }

  private deleteUser(user: UserModel) {
    this.userService.removeUser(user.uuid).subscribe(response => {
      if (response !== null) {
        _.remove(this.elementDataAux, item => item.uuid === user.uuid);
        this.elementData = [...this.elementDataAux];
        let auxMessage = '';
        this.translate.get('USER.USER_DELETE_OK').subscribe(value => auxMessage = value);
        let auxMessage2 = '';
        this.translate.get('USER.SUCCESS').subscribe(value => auxMessage2 = value);
        this.toastr.showSuccess(auxMessage, auxMessage2);
        this.userService.updateCollection(this.elementData);
      }
    });
  }

  editUser(user: UserModel) {
    this.enableUserForm = true;
    this.formMode = 'edit';
    this.userSelected = user;
  }

  addUser(user: UserModel) {
    this.userService.createUser(user).subscribe((response: any) => {
      if (response.createUsuario !== null) {
        let uuidUserCreated = '';
        uuidUserCreated = response.createUsuario.uuid;
        user.uuid = uuidUserCreated;
        this.elementDataAux.push(user);
        this.elementData = [];
        this.elementData = this.elementDataAux;
        this.userService.updateCollection(this.elementData);
        this.toggleUserForm();
        let auxMessage = '';
        this.translate.get('USER.USER_CREATE_OK').subscribe(value => auxMessage = value);
        let auxMessage2 = '';
        this.translate.get('USER.SUCCESS').subscribe(value => auxMessage2 = value);
        this.toastr.showSuccess(auxMessage, auxMessage2);

        for (const factory of user.factories) {
          this.userService.addFactoryToUser(uuidUserCreated, factory.uuid).subscribe();
        }
      }
      else {
        let auxMessage = '';
        this.toggleUserForm();
        this.translate.get('USER.USER_CREATE_ERROR').subscribe(value => auxMessage = value);
        let auxMessage2 = '';
        this.translate.get('USER.ERROR').subscribe(value => auxMessage2 = value);
        this.toastr.showError(auxMessage, auxMessage2);
      };
    });
  }

  editUserAtBack(userToEdit: UserModel) {
    this.userService.editUser(userToEdit).subscribe((response: any) => {
      if (response.updateUsuario !== null) {
        this.toggleUserForm();
        this.elementDataAux.map((item: UserModel) => {
          if (item.uuid === userToEdit.uuid) {
            item = Object.assign(item, userToEdit);
          }
        });
        this.elementData = [];
        this.elementData = this.elementDataAux;
        this.userService.updateCollection(this.elementData);
        let auxMessage = '';
        this.translate.get('USER.USER_EDIT_OK').subscribe(value => auxMessage = value);
        let auxMessage2 = '';
        this.translate.get('USER.SUCCESS').subscribe(value => auxMessage2 = value);
        this.toastr.showSuccess(auxMessage, auxMessage2);
      }
    });
  }

  removeFactoriesOfUser(factoriesToRemove: FactorySimpleModel[]) {
    for (const factory of factoriesToRemove) {
      this.userService.removeFactoryUser(this.userSelected.uuid, factory.uuid).subscribe();
    }
  }

  addFactoriesToUser(factoriesToAdd: FactorySimpleModel[]) {
    for (const factory of factoriesToAdd) {
      this.userService.addFactoryToUser(this.userSelected.uuid, factory.uuid).subscribe();
    }
  }
}
