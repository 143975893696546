import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() diameter = 80;
  @Input() strokeWidth = 5;
  @Input() loadingText: string;

  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit() {
    if (this.loadingText === undefined) {
      this.loadingText = 'LOADING.DEFAULT';
    }
    /** spinner starts on init */
    this.spinner.show();
  }

}
