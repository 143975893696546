import { Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl} from '@angular/forms';

interface XData {
  data: string;
}

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit, OnDestroy {
  public rTable = {
    titulo: '',
    consumoTitulo: '',
    consumoK: '',
    itemTitulo: '',
    itemK: '',
    ratioTitulo: '',
    ratiomedioTitulo: '',
  };
  constructor(
    public dialogRef: MatDialogRef<ReportTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: XData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }

}
