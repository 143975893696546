import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.scss']
})
export class GraphicsComponent implements OnInit {
  dispositivos: any[] = [];
  loading = true;

  constructor() { }

  ngOnInit() {
  }

}
