import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import gql from 'graphql-tag';
import { QueryReports, ReportItf, QueryGeoPos, QueryAllGeoPos, QueryFabricas, QueryDato,QueryEstructura, FindReportCodeQuery} from './interfaces.consultas';
import { ConditionalExpr } from '@angular/compiler';

  /// Empieza el programa


@Injectable()
export class GraphFunciones{
    private subscrip: Subscription [] = [];

    constructor(
        private apollo: Apollo ) {
    }

    ///////////////
    // MUTATIONS //
    ///////////////

    // Borrar REPORTS
    borrarReportFabrica(puuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          datos: deleteReportFabrica(puuid: "${puuid}")
        }`,
        refetchQueries: [{query: QueryReports}]});
    }

    borrarReportMae(puuid: string): Observable<any> {
      return this.apollo.mutate({
        mutation: gql `mutation MyMutation {
          __typename
          datos: deleteReportMae(puuid: "${puuid}")
        }`,
        refetchQueries: [{query: QueryReports}]});
    }
   

    ////////////
    // QUERYS //
    ////////////

    // Recuperar los códigos existentes en base de datos para los reports


    
    findReportCodes(): Observable<any> {
      return this.apollo.query<any>({
        query: FindReportCodeQuery
        });
      }

    // Cargar un Dato
    dameDato(dato: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: QueryDato,
      variables: {uuid: dato}
      }).valueChanges;
    }
    // Listado de fabricas
    cargarFabricas(): Observable<any> {
      return this.apollo.watchQuery<any>({
        query: QueryFabricas}).valueChanges;
    }


    // Cargar toda la estructura del report
    cargarEstructura(ruuid: string): Observable<any> {
      return this.apollo.watchQuery<any>({
          query: QueryEstructura,
          variables: {uuid: ruuid}}).valueChanges;
  }

    cargarFabricasGeoPos(): Observable<any> {
      return this.apollo.watchQuery<any>({
        query: QueryFabricas}).valueChanges
        .pipe(map(response =>
          response.data = response.data.datos
        ))
    }

    cargarGeoPos(uuid:string): Observable<any>{
      return this.apollo.watchQuery<any>({
        query: QueryGeoPos,
        variables: {uuid: uuid}
      }).valueChanges;
    }

    cargarAllGeoPos(): Observable<any>{
      return this.apollo.watchQuery<any>({
        query: QueryAllGeoPos,
      }).valueChanges
      .pipe(map(response => response
      ))
    }

    // Listado de reports
    cargarReports(): Observable<any> {
      return this.apollo.watchQuery<any>({
        query: QueryReports,
      fetchPolicy:"network-only"}).valueChanges;
    }

   
}
