import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Dashboard } from 'src/app/models/widgets/widgets.model';
import { MAT_DIALOG_DATA } from '@angular/material';

interface DashboardRecived {
  title: string;
  info?: Dashboard;
}

interface DashboardForm {
  name: string;
}

@Component({
  selector: 'app-dashboard-form',
  templateUrl: './dashboard-form.component.html',
  styleUrls: ['./dashboard-form.component.scss']
})
export class DashboardFormComponent implements OnInit {

  public dashboard: DashboardForm = {name: ''};
  constructor(@Inject(MAT_DIALOG_DATA) public data: DashboardRecived) { }

  ngOnInit() {
    if (this.data.info !== undefined) {
      this.dashboard.name = this.data.info.name;
    }
  }

  isEmpty() {
    if (_.isEmpty(this.dashboard.name)) {
      return true;
    }
    return false;
  }

}
