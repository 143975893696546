import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FactoryModel } from "src/app/models/FactoryModel";
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { DeviceGraphItem } from 'src/app/models/device-graph.model';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import { State } from 'src/app/models/contract/list-item.model';

@Component({
  selector: 'app-graph-factory-deep-list',
  templateUrl: './graph-factory-deep-list.component.html',
  styleUrls: ['./graph-factory-deep-list.component.scss']
})
export class GraphFactoryDeepListComponent implements OnInit {

  @Input() factory: FactoryModel;
  deviceSettings: DeviceListSettings =
    {
      titleType: 'factory',
      actionIcon: 'add-circle',
      selectable: State.selectable
    }
  public factoryDeepList: DeviceGraphItem[];
  public loadingChildren: boolean;
  @Output() deviceGraphItemSelected: EventEmitter<DeviceGraphItem> = new EventEmitter<DeviceGraphItem>();
  @Output() deviceGraphItemUnselected: EventEmitter<DeviceGraphItem> = new EventEmitter<DeviceGraphItem>();
  @Output() childrenDeviceCollection: EventEmitter<DeviceGraphItem[]> = new EventEmitter<DeviceGraphItem[]>();

  constructor(private dispositivoService: DispositivoService,
    private loginService: LoginService) { }

  ngOnInit() {
    if (this.factory !== null || this.factory !== undefined) {
      let currentUsuario = this.loginService.currentUserValue;
      this.loadingChildren = true;
      this.dispositivoService.getDeviceDescentByUserAndDeviceRootWithDataType(currentUsuario.uuid, this.factory.uuid)
        .subscribe(item => {
          this.factoryDeepList = item;
          this.loadingChildren = false;
          this.childrenDeviceCollection.emit(this.factoryDeepList);
        }
      );
    }
  }

  public selectedItem(item: DeviceGraphItem) {
    this.deviceGraphItemSelected.emit(item);
  }

  public unSelectedItem(item: DeviceGraphItem) {
    this.deviceGraphItemUnselected.emit(item);
  }
}
