import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'selectMap'
})
export class SelectPipeBBDD implements PipeTransform {
    transform(obj: any, name: string = '') {
        if (name && obj && obj.data) {
            return name.split('.').reduce((prev, curr) => {
                return prev ? prev[curr] : undefined;
            }, obj.data);
        }
    }
}
