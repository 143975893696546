import { Mapper } from './../../../services/ngx-chart-mapper/ngx-chart-mapper.service';
import {
  Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef,
  ChangeDetectorRef, AfterViewChecked, AfterViewInit
} from '@angular/core';
import { LynxColors } from 'src/app/models/lynxColors.model';
import { InfluxDbEpoch } from 'src/app/services/influxdb/influxdb.service';
import { WidgetMapperService } from 'src/app/services/widget-mapper/widget-mapper.service';
import { DeviceWidgetParam, WidgetType, GaugeWidget, DataSize } from 'src/app/models/widgets/widget-interfaces.model';
import { NgxDevice } from 'src/app/models/ngxDevice.model';
import { GaugeChartBuilder } from 'src/app/models/gauge-chart-builder';
import { Subscription, BehaviorSubject, forkJoin } from 'rxjs';
import { PlotlyChartBuilder } from 'src/app/models/plotly/plotlyChartBuilder';
import { PlotlyWidget, PlotlyChartType } from 'src/app/models/plotly/plotly-chart.model';
import { TimezoneService } from 'src/app/services/timezone.service';
import { MatDialog } from '@angular/material/dialog';
import { EditFormComponent } from 'src/app/components/shared/edit-form/edit-form.component';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatSidenav } from '@angular/material';
import { NavbarComponent } from 'src/app/components/shared/navbar/navbar.component';
import { DataTypeService } from 'src/app/services/datatype/data-type.service';
import { DatatypeTreeHierarchi } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DashboardService } from 'src/app/services/widgets/dashboards.service';
import { TabStepperData } from 'src/app/components/side-graphs/side-graphs.component';
import * as _ from 'lodash';
import { NewDashbordModel, NewWidgetsModel } from 'src/app/models/dashboard/newDashboard.model';
import { GridsterConfig, GridType, DisplayGrid } from 'angular-gridster2';
import { WidgetParameter } from 'src/app/components/tab-stepper/tab-stepper.component';
import { ToolsService } from 'src/app/services/tools/tools.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { TableWidgetBuilder } from 'src/app/models/table-widget-builder';
import { TableWidget } from 'src/app/models/table-widget.model';
import { DigitalWidget } from 'src/app/models/digital-widget.model';
import { DigitalWidgetBuilder } from 'src/app/models/digital-widget-builder';
import { ShareDashboardComponent } from 'src/app/components/share-dashboard/share-dashboard.component';
import { UserModel } from 'src/app/models/userModel';

interface DataToConfirmModal {
  title: string;
  message: string;
  existingData: boolean;
}

interface WidthHeightPixels {
  width: number;
  height: number;
}
interface LoaderAction {
  uuid: string;
  status: boolean;
}
export interface DivMeasurement {
  width: number;
  height: number;
  uuid: string;
  columnWidth?: number;
  columnHeight?: number;
}
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @Input() dashboard: NewDashbordModel;
  gaugeCollection: Array<GaugeWidget> = [];
  plotlyChartCollection: Array<PlotlyWidget> = [];
  tableWidgetCollection: Array<TableWidget> = [];
  digitalWidgetCollection: Array<DigitalWidget> = [];
  widgetCollection: NewWidgetsModel[] = [];
  colorsArray: LynxColors[] = [];
  loading: boolean;
  loadingText = 'LOADING.WIDGETS';
  dataChartCounter = 0;
  dataGauge: Subscription[] = [];
  dataCharts: Subscription[] = [];
  gaugeName: string;
  timezoneBS: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() editDashboardEmitter: EventEmitter<NewDashbordModel> = new EventEmitter<NewDashbordModel>();
  @Output() deleteDashboardEmitter: EventEmitter<NewDashbordModel> = new EventEmitter<NewDashbordModel>();
  @Output() unsubscribeDashboardEmitter: EventEmitter<NewDashbordModel> = new EventEmitter<NewDashbordModel>();
  @Output() refreshDashboard: EventEmitter<boolean> = new EventEmitter<boolean>();
  dataTypeTree: DatatypeTreeHierarchi[];
  counter = 0;
  height: 300;
  options: GridsterConfig;
  periodObject: WidgetParameter;
  widthHeightComponent: WidthHeightPixels = null;
  loader: LoaderAction = {
    uuid: '',
    status: false
  };
  plotlyChartBuilder: PlotlyChartBuilder = new PlotlyChartBuilder(this.translate);
  _translated: string;
  _translatedArray: String[];
  tituloString: string;
  requestInterval: any;

  constructor(
    private widgetService: WidgetMapperService,
    private timezone: TimezoneService,
    public dialog: MatDialog,
    private navbar: NavbarComponent,
    private datatypeService: DataTypeService,
    private cd: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private toolsService: ToolsService,
    private toastService: ToastService,
    private translate: TranslateService) {
  }


  ngOnInit() {
    this.refreshData();
    this.detectChanges();
    this.options = {
      gridType: GridType.Fit,
      displayGrid: DisplayGrid.Always,
      pushItems: true,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      },
      minCols: 12,
      maxCols: 100,
      minRows: 6,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 4,
      maxItemRows: 100,
      minItemRows: 3,
      maxItemArea: 2500,
      minItemArea: 2,
      defaultItemCols: 2,
      defaultItemRows: 2,
      itemChangeCallback: (item: PlotlyWidget) => {
        // update DB with new size
        // send the update to widgets
        setTimeout(() => {
          this.resizeItem(item);
        }, 0);
      }
    };
    this.datatypeService.getDataType().subscribe(data => {
      this.dataTypeTree = _.sortBy(data, ['name'], ['asc']);
    });
  }

  resizeItem(item: PlotlyWidget) {
    let index = 0;
    if (!_.isEmpty(item)) {
      this.dashboardService.updateWidgetSizesPosition(item.relationUUID, item.cols, item.rows, item.x, item.y)
        .subscribe(res => {
          if (!_.isEmpty(res)) {
            index = this.getGridItemIndex(item.uuid);
          }
          if (this.content !== undefined) {
            const auxSizes: DivMeasurement = {
              width: item.cols,
              height: item.rows,
              uuid: item.uuid,
              columnWidth: this.content.nativeElement.children[0].children[index].offsetWidth,
              columnHeight: this.content.nativeElement.children[0].children[index].offsetHeight
            };
            this.getDivChartSize(auxSizes);
          }
        });
    }
  }

  getGridItemIndex(itemUUID: string): number {
    if (this.content !== undefined) {
      const gridItem = this.content.nativeElement.children[0];
      for (const child of gridItem.children) {
        if (child.id === itemUUID) {
          return _.indexOf(gridItem.children, child);
        }
      }
      return -1;
    }
  }

  ngAfterViewInit() {
    this.requestInterval = setInterval(() => {
      this.regenerateWidgets()
    }, 3 * Math.pow(10, 5));
  }

  regenerateWidgets() {
    this.deleteWidgetCollection();
    this.buildWidgetGraphics(this.getWidgetCollection(this.dashboard));
  }

  ngAfterViewChecked() {
    this.getSizeContent();
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.requestInterval)
      clearInterval(this.requestInterval);
    this.destroySubscriptions();
  }

  readyRender($event) {
    if (this.loader.uuid === $event) {
      setTimeout(() => {
        this.loader.uuid = '';
        this.loader.status = false;
      }, 500);
    }
  }
  resized(evn) {
  }
  edit() {
    this.editDashboardEmitter.emit(this.dashboard);
  }

  refreshData() {
    this.timezoneBS.subscribe(data => {
      if (data) {
        this.deleteWidgetCollection();
        this.buildWidgetGraphics(this.getWidgetCollection(this.dashboard));
      }
    });
  }

  deleteWidgetCollection() {
    this.gaugeCollection = [];
    this.plotlyChartCollection = [];
    this.widgetCollection = [];
    this.dataChartCounter = 0;
    this.digitalWidgetCollection = [];
    this.destroySubscriptions();
  }


  detectChanges() {
    this.timezone.currentTimeZone$.subscribe((data: string) => {
      this.timezoneBS.next(data);
    });
  }

  destroySubscriptions() {
    if (this.dataCharts.length > 0) {
      for (const suscription of this.dataCharts) {
        suscription.unsubscribe();
      }
    }
    if (this.dataGauge.length > 0) {
      for (const suscription of this.dataGauge) {
        suscription.unsubscribe();
      }
    }
  }

  getWidgetCollection(dashboard: NewDashbordModel): NewWidgetsModel[] {
    this.deleteWidgetCollection();
    if (dashboard.widgets !== undefined) {
      for (const widget of dashboard.widgets) {
        if (widget !== undefined) {
          if (widget.widgetType !== 'undefined') {
            this.widgetCollection.push(widget);
          }
        }
      }
      return this.widgetCollection;
    }
  }

  buildWidgetGraphics(widgetCollection: NewWidgetsModel[]) {
    if (!_.isEmpty(widgetCollection)) {
      for (const widget of widgetCollection) {
        if (widget.widgetType === 'Gauge') {
          this.getDataGauge(widget);
        } else if (widget.widgetType === 'Table') {
          this.getDataTable(widget);
        } else if (widget.widgetType === 'digital') {
          this.getDataDigital(widget);
        } else {
          this.getDataWidget(widget);
        }
      }
    }
  }
  // Obtenemos el ancho del componente que tiene el columnado de drag&drop
  getSizeContent() {
    if (this.content !== undefined) {
      if (this.counter < 1) {
        this.widthHeightComponent = {
          width: this.content.nativeElement.offsetWidth,
          height: this.content.nativeElement.ownerDocument.documentElement.clientHeight
        };
        this.buildWidgetGraphics(this.getWidgetCollection(this.dashboard));
        this.counter++;
      }
    }
  }
  // Pasamos ancho de columna a pixels
  columnToPixel(column): WidthHeightPixels {
    let widthPercent: number;
    switch (Math.trunc(column)) {
      case 1:
        widthPercent = 8.3333333333;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 2:
        widthPercent = 16.6666666667;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 3:
        widthPercent = 25;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 4:
        widthPercent = 33.3333333333;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 5:
        widthPercent = 41.6666666667;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 6:
        widthPercent = 50;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 7:
        widthPercent = 58.3333333333;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 8:
        widthPercent = 66.6666666667;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 9:
        widthPercent = 75;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 10:
        widthPercent = 83.3333333333;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 11:
        widthPercent = 91.6666666667;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      case 12:
        widthPercent = 100;
        return this.calculatePercent(this.widthHeightComponent, widthPercent);
      /* default:
        widthPercent = 41.6666666667;
        return this.calculatePercent(this.widthHeightComponent, widthPercent); */
    }
  }
  // Calculamos el valor en pixels segun el porcentaje del columnado
  calculatePercent(widthHeightComponent: WidthHeightPixels, columnPercentValue: number): WidthHeightPixels {
    const pixelValue: WidthHeightPixels = {
      width: columnPercentValue * widthHeightComponent.width / 100,
      height: columnPercentValue * widthHeightComponent.height / 100
    };
    return pixelValue;
  }
  showLoader($event) {
    const selectedElementClasses: string[] = $event.target.classList;
    if (_.includes(selectedElementClasses, 'gridster-item-resizing')) {
      this.loader = {
        uuid: $event.target.parentElement.id,
        status: true
      };
    }
  }
  hideLoader($event) {
    this.loader = {
      uuid: '',
      status: false
    };
  }
  parseWidgetTitle(widgetData: any) {
    this.tituloString = '';
    this._translatedArray = [];
    for (let i = 0; i < widgetData.dataType.length; i++) {
      if (i < widgetData.dataType.length - 1) {
        this.translate.get('DATA_TYPES_AND_AGRUPATIONS.'.concat(widgetData.dataType[i].name)).subscribe((translated: string) => {
          if (!this.tituloString.includes(translated)) {
            this.tituloString += translated + ', ';
          }
        });
      } else if (i == widgetData.dataType.length - 1) {
        this.translate.get('DATA_TYPES_AND_AGRUPATIONS.'.concat(widgetData.dataType[i].name)).subscribe((translated: string) => {
          if (!this.tituloString.includes(translated)) {
            this.tituloString += translated;
          }
          this.translate.get('OTHERS.OF_DEVICES').subscribe((translated: string) => {
            this.tituloString += translated;
          });
        });
      };
    };
    for (let j = 0; j < widgetData.devices.length; j++) {
      if (j < widgetData.devices.length - 1) {
        this.tituloString += ' ' + widgetData.devices[j].name + ', '
      } else {
        this.tituloString += ' ' + widgetData.devices[j].name
      }
    };
    widgetData.name = this.tituloString;
  }


  getDataWidget(widget: NewWidgetsModel) {
    const dataSize: DataSize = new DataSize();
    dataSize.x = 0;
    dataSize.y = 4;
    dataSize.width = 5;
    dataSize.height = 5;
    dataSize.minWidth = 5;
    dataSize.minHeight = 5;
    dataSize.widthPx = this.columnToPixel(5).width;
    dataSize.heightPx = this.columnToPixel(5).height;

    if (!_.isEmpty(widget.devices) && !_.isEmpty(widget.dataType)) {
      this.loading = true;
      let mapperAuxArray = [new Mapper()];
      this.widgetService.getDeviceDataSource(widget, InfluxDbEpoch.SEC)
        .subscribe(dataChartCol => {
          const auxDataLabel: any = [] //= [];
          const auxDataSets: any = [];
          const auxMapperDataLabel: any[][] = [];
          const auxMapperDataSet: any[][] = [];
          let datasets: any = [];
          let idxKey: number = 0;
          let array = [];
          const auxUUID: string[] = [];
          for (let z = 0; z < dataChartCol.length; z++) {
            for (let elem of widget.dataType) {
              if (elem.uuid == dataChartCol[z].dataTypeUUID)
                dataChartCol[z].codigo = elem.codigo;
            }
            auxDataLabel.push(dataChartCol[z].dataLabel);
            auxDataSets.push(dataChartCol[z].dataSet);
            if (auxUUID.indexOf(dataChartCol[z].deviceUUID) === -1) auxUUID.push(dataChartCol[z].deviceUUID); // modificado
          }
          datasets = _.groupBy(dataChartCol, item => {
            return item.codigo + item.deviceUUID;
          });
          array.push(datasets)
          for (let i = 0; i < array.length; i++) {
            // Iteramos por el par deviceUUID+datatypeUUID
            for (let key of Object.keys(array[i])) {
              let mapperAux = new Mapper();
              let dl: any = [];
              let ds: any = [];
              for (let j = 0; j < array[i][key].length; j++) {
                dl = dl.concat(array[i][key][j].dataLabel)
                ds = ds.concat(array[i][key][j].dataSet)
                if (j == array[i][key].length - 1) {
                  let prueba: any = [];
                  for (let z = 0; z < dl.length; z++) {
                    prueba.push({ ts: dl[z], value: ds[z] })
                  }
                  let sortedData = prueba.sort(({ ts: a }, { ts: b }) => a - b);
                  let dsAux = sortedData.map(function (obj) {
                    return obj.value;
                  });
                  let dlAux = sortedData.map(function (obj) {
                    return obj.ts;
                  });
                  auxMapperDataLabel.push(dlAux);
                  auxMapperDataSet.push(dsAux);
                  mapperAux.codigo = array[i][key][0].codigo;
                  mapperAux.dataLabel = dlAux;
                  mapperAux.dataSet = dsAux;
                  mapperAux.deviceUUID = array[i][key][0].deviceUUID;
                  mapperAux.dataTypeUUID = array[i][key][0].dataTypeUUID;
                  mapperAuxArray.push(mapperAux);
                }
              }
              array[i][idxKey] = array[i][key];
              idxKey += 1;
              delete array[i][key];
            }
          }
          this.checkTitleParse(widget)
          if (!_.isEmpty(mapperAuxArray)) {
            this.plotlyChartCollection.push(this.plotlyChartBuilder
              .withMapper(mapperAuxArray.filter(value => Object.keys(value).length !== 0))
              .withPlotlyWidgetUUID(widget.uuid)
              .withUuidDeviceCollectionSource(auxUUID)
              .withDeviceCollection(widget.devices)
              .withChartType(this.assignChartType(widget.widgetType))
              .withWidgetName(widget.name)
              .withWidgetDataType(widget.dataType)
              .withDataTypeUnity(widget.dataType[0].unity)
              .withXAxis(auxMapperDataLabel)
              .withYAxis(auxMapperDataSet)
              .withXPos(widget.x)
              .withYPos(widget.y)
              .withRow(widget.rows)
              .withCol(widget.cols)
              .withDataSize(dataSize)
              .withRelationUUID(widget.relationUuid)
              .withOrientation(widget.orientation)
              .build());
          } else {
            this.checkTitleParse(widget)
            this.plotlyChartCollection.push(this.plotlyChartBuilder
              .withMapper(mapperAuxArray.filter(value => Object.keys(value).length !== 0))
              .withPlotlyWidgetUUID(widget.uuid)
              .withUuidDeviceCollectionSource(auxUUID)
              .withDeviceCollection(widget.devices)
              .withChartType(this.assignChartType(widget.widgetType))
              .withWidgetName(widget.name)
              .withWidgetDataType(widget.dataType)
              .withDataTypeUnity(widget.dataType[0].unity)
              .withXAxis(auxMapperDataLabel)
              .withYAxis(auxMapperDataSet)
              .withXPos(widget.x)
              .withYPos(widget.y)
              .withRow(widget.rows)
              .withCol(widget.cols)
              .withDataSize(dataSize)
              .withRelationUUID(widget.relationUuid)
              .withOrientation(widget.orientation)
              .build());
          }
          this.dataChartCounter++;
          this.checkLoading();
          setTimeout(() => {
            this.resizeItem(this.plotlyChartCollection.filter(item => item.uuid === widget.uuid)[0]);
          }, 0);
        }
        );
    }
  }

  checkTitleParse(widget) {
    widget.dataType.some(element => {
      if (widget.name.includes(element.name)) {
        this.parseWidgetTitle(widget);
      }
    })
  }

  assignChartType(widgetType: WidgetType): PlotlyChartType {
    switch (widgetType) {
      case 'Line':
        return PlotlyChartType.SCATTER;
      case 'ClusteredColumns':
        return PlotlyChartType.BAR;
      case 'Pie':
        return PlotlyChartType.PIE;
    }
  }

  processDigitalAndGaugeData(dataModel) {
    let r = dataModel.map(mp => {
      let max = 0;
      if (dataModel.length == 1) {
        return [0]
      } else {
        for (let i = 0; i < dataModel.length - 1; i++) {
          if (new Date(dataModel[i].dataLabel[0]).getTime() < new Date(dataModel[i + 1].dataLabel[0]).getTime()) {
            max = i + 1
          }
        }
        return max;
      }
    });
    return r[0];
  }

  async getDataGauge(gauge: NewWidgetsModel) {
    const dataSize: DataSize = new DataSize();
    dataSize.x = 0;
    dataSize.y = 0;
    dataSize.width = 3;
    dataSize.height = 5;
    dataSize.minWidth = 3;
    dataSize.minHeight = 5;
    dataSize.widthPx = this.columnToPixel(3).width;
    dataSize.heightPx = this.columnToPixel(4).height;

    this.loading = true;
    this.widgetService.getDeviceDataSource(gauge, InfluxDbEpoch.SEC).subscribe(
      dataGauge => {
        const auxDataSets: number[][] = [];
        for (const dataChart of dataGauge) {
          auxDataSets.push(dataChart.dataSet);
        }
        let maxIdx = this.processDigitalAndGaugeData(dataGauge)
        if (!_.isEmpty(dataGauge)) {
          this.checkTitleParse(gauge)
          const gaugeChartBuilder: GaugeChartBuilder = new GaugeChartBuilder();
          this.gaugeCollection.push(gaugeChartBuilder
            .withGaugeUUID(gauge.uuid)
            .withGaugeName(gauge.name)
            .withUuidSource(this.getUuid(gauge.devices))
            .withDataSource([auxDataSets[maxIdx]])
            .withMinSource(gauge.min)
            .withMaxSource(gauge.max)
            .withLimitSource(gauge.limit)
            .withDataType(gauge.dataType[0])
            .withDataSize(dataSize)
            .withXPos(gauge.x)
            .withYPos(gauge.y)
            .withRow(gauge.rows)
            .withCol(gauge.cols)
            .withRelationUUID(gauge.relationUuid)
            .build());
        } else {
          this.checkTitleParse(gauge);
          const gaugeChartBuilder: GaugeChartBuilder = new GaugeChartBuilder();
          this.gaugeCollection.push(gaugeChartBuilder
            .withGaugeUUID(gauge.uuid)
            .withGaugeName(gauge.name)
            .withUuidSource(this.getUuid(gauge.devices))
            .withMinSource(gauge.min)
            .withMaxSource(gauge.max)
            .withLimitSource(gauge.limit)
            .withDataType(gauge.dataType[0])
            .withDataSize(dataSize)
            .withXPos(gauge.x)
            .withYPos(gauge.y)
            .withRow(gauge.rows)
            .withCol(gauge.cols)
            .withRelationUUID(gauge.relationUuid)
            .build());
        }
        this.dataChartCounter++;
        this.checkLoading();
      }
    );
  }

  getDataTable(tableWidget: NewWidgetsModel) {
    const dataSize: DataSize = new DataSize();
    dataSize.x = 0;
    dataSize.y = 0;
    dataSize.width = 3;
    dataSize.height = 5;
    dataSize.minWidth = 3;
    dataSize.minHeight = 5;
    dataSize.widthPx = this.columnToPixel(3).width;
    dataSize.heightPx = this.columnToPixel(4).height;
    tableWidget.period = {
      clave: '',
      valor: '',
      cantidad: ''
    };

    this.loading = true;
    let mapperAuxArray = [new Mapper()];
    this.widgetService.getDeviceDataSource(tableWidget, InfluxDbEpoch.SEC).subscribe(
      dataTable => {
        const auxDataSets: any = [];
        const auxDataLabel: any = [] //= [];
        let datasets: any = [];
        let array = [];
        const auxUUID: string[] = [];
        let values = []
        let diffDataTypes= [];
        for (let z = 0; z < dataTable.length; z++) {
          for (let elem of tableWidget.dataType) {
            if (elem.uuid == dataTable[z].dataTypeUUID) {
                dataTable[z].codigo = elem.codigo;
                dataTable[z].dataTypeName = elem.name;
            }
          }
          auxDataLabel.push(dataTable[z].dataLabel);
          auxDataSets.push(dataTable[z].dataSet);
          if (auxUUID.indexOf(dataTable[z].deviceUUID) === -1) auxUUID.push(dataTable[z].deviceUUID); // modificado
        }
        datasets = _.groupBy(dataTable, item => {
          return item.codigo + item.deviceName;
        });
        array.push(datasets)
        for (let i = 0; i < array.length; i++) {
          for (let key of Object.keys(array[i])) {
            let mapperAux = new Mapper();            
            // for  (let j = 0; j < array[i][key].length; j++) {
              let mostRecentObj = array[i][key].reduce((a, b) => {
                return new Date(a.dataLabel[a.dataSet.findIndex(item => item != "-")]) > new Date(b.dataLabel[a.dataSet.findIndex(item => item != "-")]) ? a : b;
              });
              if (mapperAuxArray.includes(mostRecentObj)) {
                continue
              } else {
                diffDataTypes.push(mostRecentObj.dataTypeUUID)
                mapperAux.codigo = mostRecentObj.codigo;
                mapperAux.dataTypeName = mostRecentObj.dataTypeName;
                mapperAux.dataLabel = mostRecentObj.dataLabel[mostRecentObj.dataSet.findIndex(item => item != "-")];
                mapperAux.dataSet = mostRecentObj.dataSet[mostRecentObj.dataSet.findIndex(item => item != "-")];
                mapperAux.deviceUUID = mostRecentObj.deviceName;
                mapperAux.dataTypeUUID = mostRecentObj.dataTypeUUID;
                mapperAuxArray.push(mapperAux);
              }
            // }
          }
          values = Array.from(new Set(mapperAuxArray.filter(obj => Object.keys(obj).length !== 0)))
        }
      let labelsForColumns = values.filter((obj, index) => {
        return index === values.findIndex(o => obj.codigo === o.codigo);
      });
        if (!_.isEmpty(dataTable)) {
          this.checkTitleParse(tableWidget);
          const tableWidgetBuilder: TableWidgetBuilder = new TableWidgetBuilder();
          this.tableWidgetCollection.push(
            tableWidgetBuilder.withTableUUID(tableWidget.uuid)
              .withTableName(tableWidget.name)
              .withWidgetName(tableWidget.name)
              .withDeviceCollection(this.getLabels(tableWidget.devices))
              .withDataTypeCollection(this.getLabels(labelsForColumns))
              .withDataSize(dataSize)
              .withTableValues(values.map( item => item.dataSet))
              .withXPos(tableWidget.x)
              .withYPos(tableWidget.y)
              .withRow(tableWidget.rows)
              .withCol(tableWidget.cols)
              .withRelationUUID(tableWidget.relationUuid)
              .withUnity(tableWidget.dataType[0].unity)
              .build());
        } else {
          this.checkTitleParse(tableWidget);
          const tableWidgetBuilder: TableWidgetBuilder = new TableWidgetBuilder();
          this.tableWidgetCollection.push(
            tableWidgetBuilder.withTableUUID(tableWidget.uuid)
              .withTableName(tableWidget.name)
              .withWidgetName(tableWidget.name)
              .withDeviceCollection(this.getLabels(tableWidget.devices))
              .withDataTypeCollection(this.getLabels(labelsForColumns))
              .withDataSize(dataSize)
              .withTableValues(_.flatten(auxDataSets))
              .withXPos(tableWidget.x)
              .withYPos(tableWidget.y)
              .withRow(tableWidget.rows)
              .withCol(tableWidget.cols)
              .withRelationUUID(tableWidget.relationUuid)
              .withUnity(tableWidget.dataType[0].unity)
              .build());
        }
        this.dataChartCounter++;
        this.checkLoading();
      });
  }

  getDataDigital(digitalWidget: NewWidgetsModel) {
    const dataSize: DataSize = new DataSize();
    dataSize.x = 0;
    dataSize.y = 0;
    dataSize.width = 3;
    dataSize.height = 5;
    dataSize.minWidth = 3;
    dataSize.minHeight = 5;
    dataSize.widthPx = this.columnToPixel(3).width;
    dataSize.heightPx = this.columnToPixel(4).height;

    this.widgetService.getDeviceDataSource(digitalWidget, InfluxDbEpoch.SEC).subscribe(
      dataDigital => {
        this.loading = true;
        const auxDataSets: number[][] = [];
        for (const dataChart of dataDigital) {
          auxDataSets.push(dataChart.dataSet);
        }
        let maxIdx = this.processDigitalAndGaugeData(dataDigital);
        if (!_.isEmpty(dataDigital)) {
          this.checkTitleParse(digitalWidget);
          const digitalBuilder: DigitalWidgetBuilder = new DigitalWidgetBuilder();
          this.digitalWidgetCollection.push(digitalBuilder
            .withDigitalUUID(digitalWidget.uuid)
            .withUnity(digitalWidget.dataType[0].unity)
            .withDigitalName(digitalWidget.name)
            .withDigitalValue(auxDataSets[maxIdx][0])
            .withDigitalDate(dataDigital[maxIdx].dataLabel[0])
            .withDataSize(dataSize)
            .withXPos(digitalWidget.x)
            .withYPos(digitalWidget.y)
            .withRow(digitalWidget.rows)
            .withCol(digitalWidget.cols)
            .withRelationUUID(digitalWidget.relationUuid)
            .build());
        } else {
          this.checkTitleParse(digitalWidget);
          const digitalBuilder: DigitalWidgetBuilder = new DigitalWidgetBuilder();
          this.digitalWidgetCollection.push(digitalBuilder
            .withDigitalUUID(digitalWidget.uuid)
            .withDigitalName(digitalWidget.name)
            .withUnity(digitalWidget.dataType[0].unity)
            .withDigitalValue(0)
            .withDataSize(dataSize)
            .withXPos(digitalWidget.x)
            .withYPos(digitalWidget.y)
            .withRow(digitalWidget.rows)
            .withCol(digitalWidget.cols)
            .withRelationUUID(digitalWidget.relationUuid)
            .build());
        }
        this.dataChartCounter++;
        this.checkLoading();
      }
    );
  }

  private getUuid(devices: DeviceWidgetParam[]): string[] {
    const ngxDeviceMethods: NgxDevice = new NgxDevice();
    return ngxDeviceMethods.getUuid(devices);
  }

  private getLabels(devices: DeviceWidgetParam[]): string[] {
    const ngxDeviceMethods: NgxDevice = new NgxDevice();
    const labelsDevices = ngxDeviceMethods.getLabels(devices);
    return labelsDevices;
  }

  checkLoading() {
    if (this.dataChartCounter === this.widgetCollection.length) {
      this.loading = false;
    }
  }

  deleteDashboard() {
    this.deleteModal();
  }

  openTabStepperToCreateWidget() {
    this.sidenav.open();
    this.toggleEvent();
  }

  toggleEvent() {
    this.navbar.onToggleSideNav();
  }
  getPeriod($event: WidgetParameter) {
    this.periodObject = $event;
  }
  addWidget(newWidget: TabStepperData) {
    let auxMessageGraphError: string;
    this.translate.get('DASHBOARD.ERROR_ADD_WIDGET').subscribe(value => auxMessageGraphError = value);
    const positionX = 0;
    const positionY = 0;
    const widgetWidth = 5;
    const widgetHeight = 5;
    const widgetUUIDDeviceCollection: string[] = [];
    const widgetUUIDDataTypeCollection: string[] = [];
    const chartType = newWidget.chartType;

    for (const device of newWidget.devices) {
      widgetUUIDDeviceCollection.push(device.uuid);
    }
    for (const dataType of newWidget.tipoDato) {
      widgetUUIDDataTypeCollection.push(dataType.uuid);
    }
    this.dashboardService.addDashboardWidget(this.dashboard.uuid, newWidget.chartTitle, positionX,
      positionY, widgetWidth, widgetHeight).subscribe((data: any) => {
        if (!_.isEmpty(data)) {
          if (chartType === PlotlyChartType.TABLE) {
            const multipleRequest = forkJoin(
              this.addParameterToNewWidget(data.addWidget.uuid, 'tipoplot', chartType),
              this.addParameterToNewWidget(data.addWidget.uuid, 'orientation', newWidget.orientation),
              // tslint:disable-next-line: max-line-length
              this.addDeviceParameterToNewWidget(data.addWidget.uuid, 'dises', this.toolsService.generateRandomChartId().toString(), widgetUUIDDeviceCollection),
              // tslint:disable-next-line: max-line-length
              this.addDataTypeParameterToNewWidget(data.addWidget.uuid, 'tipomeds', this.toolsService.generateRandomChartId().toString(), widgetUUIDDataTypeCollection));
            multipleRequest.subscribe(result => {
              if (result !== null) {
                this.refreshDashboard.emit(true);
              } else {
                this.toastService.showError(auxMessageGraphError, '');
              }
            });
          } else if (chartType === PlotlyChartType.GAUGE) {
            const multipleRequest = forkJoin(
              this.addParameterToNewWidget(data.addWidget.uuid, 'min', newWidget.min.toString()),
              this.addParameterToNewWidget(data.addWidget.uuid, 'max', newWidget.max.toString()),
              this.addParameterToNewWidget(data.addWidget.uuid, 'limite', newWidget.limit.toString()),
              // tslint:disable-next-line: max-line-length
              this.addDeviceParameterToNewWidget(data.addWidget.uuid, 'dises', this.toolsService.generateRandomChartId().toString(), widgetUUIDDeviceCollection),
              // tslint:disable-next-line: max-line-length
              this.addDataTypeParameterToNewWidget(data.addWidget.uuid, 'tipomeds', this.toolsService.generateRandomChartId().toString(), widgetUUIDDataTypeCollection));
            multipleRequest.subscribe(result => {
              if (result !== null) {
                this.refreshDashboard.emit(true);
              } else {
                this.toastService.showError(auxMessageGraphError, '');
              }
            });
          } else if (chartType === PlotlyChartType.DIGITAL) {
            const multipleRequest = forkJoin(
              this.addParameterToNewWidget(data.addWidget.uuid, 'tipoplot', chartType),
              // tslint:disable-next-line: max-line-length
              this.addDeviceParameterToNewWidget(data.addWidget.uuid, 'dises', this.toolsService.generateRandomChartId().toString(), widgetUUIDDeviceCollection),
              // tslint:disable-next-line: max-line-length
              this.addDataTypeParameterToNewWidget(data.addWidget.uuid, 'tipomeds', this.toolsService.generateRandomChartId().toString(), widgetUUIDDataTypeCollection));
            multipleRequest.subscribe(result => {
              if (result !== null) {
                this.refreshDashboard.emit(true);
              } else {
                this.toastService.showError(auxMessageGraphError, '');
              }
            });
          } else {
            const multipleRequest = forkJoin(
              this.addParameterToNewWidget(data.addWidget.uuid, 'tipoplot', chartType),
              this.addParameterToNewWidget(data.addWidget.uuid, 'orientation', newWidget.orientation),
              // tslint:disable-next-line: max-line-length
              this.addDeviceParameterToNewWidget(data.addWidget.uuid, 'dises', this.toolsService.generateRandomChartId().toString(), widgetUUIDDeviceCollection),
              // tslint:disable-next-line: max-line-length
              this.addDataTypeParameterToNewWidget(data.addWidget.uuid, 'tipomeds', this.toolsService.generateRandomChartId().toString(), widgetUUIDDataTypeCollection),
              this.addParameterToNewWidget(data.addWidget.uuid, 'periodo', this.periodObject.clave),
              this.addParameterToNewWidget(data.addWidget.uuid, this.periodObject.clave, this.periodObject.valor));
            multipleRequest.subscribe(result => {
              if (result !== null) {
                this.refreshDashboard.emit(true);
              } else {
                this.toastService.showError(auxMessageGraphError, '');
              }
            });
          }
        }
      });
  }

  addwidgetToDashboard(dashboardUUID, chartTitle, positionX, positionY, widgetWidth, widgetHeight) {
    return this.dashboardService.addDashboardWidget(dashboardUUID, chartTitle, positionX,
      positionY, widgetWidth, widgetHeight);
  }

  addParameterToNewWidget(widgetUUID, clave, valor) {
    return this.dashboardService.addDashboardWidgetParametro(widgetUUID, clave, valor);
  }

  addDeviceParameterToNewWidget(widgetUUID, clave, valor, deviceCollection) {
    return this.dashboardService.addDashboardWidgetParametroDispositivo(widgetUUID, clave, valor, deviceCollection);
  }

  addDataTypeParameterToNewWidget(widgetUUID, clave, valor, dataTypeCollection) {
    return this.dashboardService.addDashboardWidgetParametroTipoDato(widgetUUID, clave, valor, dataTypeCollection);
  }

  editWidget(widgetToEdit: NewWidgetsModel, collectionFrom: any[]) {
    const dialogRef = this.dialog.open(EditFormComponent, {
      data: { title: 'DASHBOARD.EDIT_WIDGET.TITLE', value: widgetToEdit.name, campo: 'DASHBOARD.EDIT_WIDGET.NAME' },
      width: '300px'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (!_.isEmpty(result) && result !== widgetToEdit.name) {
        this.dashboardService.editWidgetName(widgetToEdit.uuid, result).subscribe((data: any) => {
          if (!_.isEmpty(data)) {
            collectionFrom.map(item => { if (item.uuid === widgetToEdit.uuid) { item.name = result; } });
          }
        });
      }
    });
  }

  deleteWidget(widgetToRemove: NewWidgetsModel, collectionFrom: any[]) {
    const opt = {
      title: widgetToRemove.name,
      message: 'CONFIRM_MODAL.MESSAGE.QUESTION_WIDGET',
      existingData: true
    };
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      width: '300px',
      data: opt
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboard.widgets.map(item => {
          if (item !== undefined) {
            if (item.uuid === widgetToRemove.uuid) {
              this.dashboard.widgets.remove(item);
            }
          }
        });

        this.dashboardService.removeDashboardWidget(this.dashboard.uuid, widgetToRemove.uuid).subscribe((data: any) => {
          if (!_.isEmpty(data)) {
            collectionFrom.map(item => { if (item.uuid === widgetToRemove.uuid) { collectionFrom.remove(item); } });
          }
        });
      }
    });
  }


  deleteModal() {
    const opt: DataToConfirmModal = {
      title: this.dashboard.name,
      message: 'CONFIRM_MODAL.MESSAGE.QUESTION_DASHBOARD',
      existingData: this.dashboard !== undefined ? true : false
    };
    this.openDialogConfirm(opt);
  }

  openDialogConfirm(opt: DataToConfirmModal) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: opt
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteDashboardEmitter.emit(this.dashboard);
      }
    });
  }


  dropGraph(event: CdkDragDrop<string[]>, widgetCollection) {
    moveItemInArray(widgetCollection, event.previousIndex, event.currentIndex);
  }

  generateChart(chart) {
    this.sidenav.close();
  }

  getDivChartSize(event: DivMeasurement) {
    if (this.gaugeCollection.find(item => item.uuid === event.uuid)) {
      for (const widget of this.gaugeCollection) {
        if (widget.uuid === event.uuid) {
          if (widget.dataSize.widthPx !== event.columnWidth * 0.90) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.widthPx = event.columnWidth * 0.90;
          }
          if (widget.dataSize.heightPx !== event.columnHeight * 0.80) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.heightPx = event.columnHeight * 0.80;
          }
        }
      }
    } else if (this.tableWidgetCollection.find(item => item.uuid === event.uuid)) {
      for (const widget of this.tableWidgetCollection) {
        if (widget.uuid === event.uuid) {
          if (widget.dataSize.widthPx !== event.columnWidth * 0.90) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.widthPx = event.columnWidth * 0.90;
          }
          if (widget.dataSize.heightPx !== event.columnHeight * 0.80) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.heightPx = event.columnHeight * 0.80;
          }
        }
      }
    } else if (this.digitalWidgetCollection.find(item => item.uuid === event.uuid)) {
      for (const widget of this.digitalWidgetCollection) {
        if (widget.uuid === event.uuid) {
          if (widget.dataSize.widthPx !== event.columnWidth * 0.90) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.widthPx = event.columnWidth * 0.90;
          }
          if (widget.dataSize.heightPx !== event.columnHeight * 0.80) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.heightPx = event.columnHeight * 0.80;
          }
        }
      }
    } else {
      for (const widget of this.plotlyChartCollection) {
        if (widget.uuid === event.uuid) {
          if (widget.dataSize.widthPx !== event.columnWidth * 0.90) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.widthPx = event.columnWidth * 0.90;
          }
          if (widget.dataSize.heightPx !== event.columnHeight * 0.80) {
            this.loader.uuid = event.uuid;
            this.loader.status = true;
            widget.dataSize.heightPx = event.columnHeight * 0.80;
          }
        }
      }
    }
  }

  shareDashboard() {
    let successResponseCounter = 0;
    const failedResponseCounter: UserModel[] = [];

    const dialogRef = this.dialog.open(ShareDashboardComponent, {
      height: 'auto',
      data: this.dashboard
    });

    dialogRef.afterClosed().subscribe((userSelectedCollection: UserModel[]) => {
      if (userSelectedCollection && userSelectedCollection.length > 0) {
        for (const user of userSelectedCollection) {
          this.dashboardService.assignDashboardToUsers(user.uuid, this.dashboard.uuid).subscribe(
            (data: any) => {
              if (data.compartirDashboard !== null) {
                successResponseCounter++;
              } else {
                failedResponseCounter.push(user);
              }
              if (successResponseCounter > 0 && successResponseCounter === userSelectedCollection.length) {
                this.toastService.showSuccess('Dashboard: ' + this.dashboard.name +
                  ' asignado correctamente a todos los usuarios ', 'Exito');
              } else {
                if (_.uniqBy(failedResponseCounter, 'user').length === userSelectedCollection.length - successResponseCounter) {
                  const concatUser = _.uniqBy(failedResponseCounter, 'user').map(item => item.user).join(', ');
                  this.toastService.showError('No ha podido asignarse el dashboard: ' +
                    this.dashboard.name + 'a los usuarios: ' + concatUser, 'Error');
                }
              }
            }
          );
        }
      }
    });
  }
}

