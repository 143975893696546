import { PlotlyChartType, PlotlyWidget, PlotlyData, PlotlyLayout, BarMode, ChartOrientationType } from './plotly-chart.model';
import { LynxColors } from '../lynxColors.model';
import { DeviceGraphItem, DeviceGraphDataType } from '../device-graph.model';
import * as _ from 'lodash';
import { DataSize } from '../widgets/widget-interfaces.model';
import { Mapper } from 'src/app/services/ngx-chart-mapper/ngx-chart-mapper.service';
import { TranslateService } from '@ngx-translate/core';


export class PlotlyChartBuilder {

    private _plotlyWidgetUuid: string;
    private _xAxis: string[][];
    private _yAxis: number[][];
    private _chartType: PlotlyChartType;
    private _widgetName: string;
    private _DeviceCollection: DeviceGraphItem[];
    private _UuidDeviceCollectionSource: string[];
    private _widgetDataType: DeviceGraphDataType[];
    private _dataTypeUnity: string;
    private _dataSize: DataSize;
    private _x: number;
    private _y: number;
    private _row: number;
    private _col: number;
    private _relationUUID: string;
    private _mapperCollection: Mapper[];
    private _orientationType: ChartOrientationType;
    private _translated: any;

    constructor(private translate:TranslateService) {
     }
    public withMapper(mapperCollection: Mapper[]): PlotlyChartBuilder {
        this._mapperCollection = mapperCollection;
        return this;
    }
    public withPlotlyWidgetUUID(newPlotlyWidgetUUID): PlotlyChartBuilder {
        this._plotlyWidgetUuid = newPlotlyWidgetUUID;
        return this;
    }

    public withXAxis(newXAxis: string[][]): PlotlyChartBuilder {
        this._xAxis = newXAxis;
        return this;
    }

    public withYAxis(newYAxis: number[][]): PlotlyChartBuilder {
        this._yAxis = newYAxis;
        return this;
    }

    public withChartType(newChartType: PlotlyChartType): PlotlyChartBuilder {
        this._chartType = newChartType;
        return this;
    }

    public withWidgetName(newWidgetName: string): PlotlyChartBuilder {
        this._widgetName = newWidgetName;
        return this;
    }

    public withDeviceCollection(newWidgetName: DeviceGraphItem[]): PlotlyChartBuilder {
        this._DeviceCollection = newWidgetName;
        return this;
    }



    public withUuidDeviceCollectionSource(newUuidDeviceCollectionSource: string[]): PlotlyChartBuilder {
        this._UuidDeviceCollectionSource = newUuidDeviceCollectionSource;
        return this;
    }

    public withWidgetDataType(newWidgetDataType: DeviceGraphDataType[]): PlotlyChartBuilder {
        this._widgetDataType = newWidgetDataType;
        return this;
    }
    
    public withDataTypeUnity(newDataTypeUnity: string): PlotlyChartBuilder {
        this._dataTypeUnity = newDataTypeUnity;
        return this;
    }
    public withDataSize(newDataSize: DataSize): PlotlyChartBuilder {
        this._dataSize = newDataSize;
        return this;
    }
    public withXPos(newPos: number): PlotlyChartBuilder {
        this._x = newPos;
        return this;
    }
    public withYPos(newPos: number): PlotlyChartBuilder {
        this._y = newPos;
        return this;
    }
    public withCol(newCol: number): PlotlyChartBuilder {
        this._col = newCol;
        return this;
    }
    public withRow(newRow: number): PlotlyChartBuilder {
        this._row = newRow;
        return this;
    }
    public withRelationUUID(newRelation: string): PlotlyChartBuilder {
        this._relationUUID = newRelation;
        return this;
    }

    public withOrientation(newOrientation: ChartOrientationType): PlotlyChartBuilder {
        this._orientationType = newOrientation;
        return this;
    }
    public build(): PlotlyWidget {

        let plotlyWidget = new PlotlyWidget();

        let plotlyDataChart: PlotlyData[] = [];
        plotlyDataChart = this.fillDataChart();

        let plotlyLayout: PlotlyLayout = new PlotlyLayout();
        plotlyLayout = this.fillLayout(plotlyLayout);

        return plotlyWidget = this.fillPlotlyWidget(plotlyWidget, plotlyDataChart, plotlyLayout);
    }

    private fillPlotlyWidget(
        plotlyWidget: PlotlyWidget,
        plotlyDataChart: PlotlyData[],
        plotlyLayout: PlotlyLayout): PlotlyWidget {
        plotlyWidget.x = this._x;
        plotlyWidget.y = this._y;
        plotlyWidget.uuid = this._plotlyWidgetUuid;
        plotlyWidget.name = this._widgetName;
        plotlyWidget.dataChart = plotlyDataChart;
        plotlyWidget.layoutChart = plotlyLayout;
        plotlyWidget.dataType = this._widgetDataType;
        plotlyWidget.dataSize = this._dataSize;
        plotlyWidget.relationUUID = this._relationUUID;
        plotlyWidget.cols = this._col;
        plotlyWidget.rows = this._row;

        return plotlyWidget;
    }

    private fillLayout(plotlyLayout: PlotlyLayout): PlotlyLayout {
        plotlyLayout.showLegend = false;
        plotlyLayout.title = this._widgetName;
        plotlyLayout.margin = {
            l: 10,
            r: 10,
            b: 10,
            t: 10,
            pad: 0
        };

        if (this._chartType === PlotlyChartType.BAR) {
            plotlyLayout.barMode = BarMode.GROUP;
        }
        return plotlyLayout;

    }

    getDeviceNamesByUUID(uuid: string): string {
        const deviceSelected = this._DeviceCollection.filter(item => item.uuid === uuid);
        if (!_.isEmpty(deviceSelected)) {
            return deviceSelected[0].name;
        } else {
            return null;
        }

    }
    getDatatypeNamesByUUID(uuid: string): string {
        const datatypeSelected = this._widgetDataType.filter(item => item.uuid === uuid);
        if (datatypeSelected.length > 0) {
            this.translate.get('DATA_TYPES_AND_AGRUPATIONS.'.concat(datatypeSelected[0].name), '').subscribe((translated: string) => {
                this._translated = translated;
            });
            return this._translated;
        } else {
            return '';
        }

    }

    private fillDataChart(): PlotlyData[] {
        const plotlyDataChartCollection: PlotlyData[] = [];

        for (let i = 0; i < this._mapperCollection.length; i++) {
            const plotlyDataChart: PlotlyData = new PlotlyData();
            /*  if (this._isDashboard && this._chartType === PlotlyChartType.SCATTER || !this._isDashboard) { */
            plotlyDataChart.x = this._xAxis[i];
            /* } else if (this._isDashboard && this._chartType === PlotlyChartType.BAR) {
                plotlyDataChart.x = this._labelDeviceCollectionSource;
            } */
            plotlyDataChart.type = this._chartType;
            if (this._widgetDataType.length > 1) {
                plotlyDataChart.name =
                    this.getDeviceNamesByUUID(this._mapperCollection[i].deviceUUID)
                    + ' (' +
                    this.getDatatypeNamesByUUID(this._mapperCollection[i].dataTypeUUID)
                    + ')';
            } else {
                plotlyDataChart.name = this.getDeviceNamesByUUID(this._mapperCollection[i].deviceUUID) !== null ?
                    this.getDeviceNamesByUUID(this._mapperCollection[i].deviceUUID) : this._mapperCollection[i].deviceUUID;
            }
            plotlyDataChart.orientation = this._orientationType;
            plotlyDataChart.unity = this._dataTypeUnity;
            plotlyDataChart.uuid = _.compact(this._UuidDeviceCollectionSource);
            plotlyDataChart.y = this._yAxis[i];
            if (!_.isEmpty(plotlyDataChart.x) && !_.isEmpty(plotlyDataChart.y)) {
                plotlyDataChartCollection.push(plotlyDataChart);
            }
        }
        return plotlyDataChartCollection;
    }
}
