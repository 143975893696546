import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface WidgetAddedToDashboard {
    dashboardUUID: string;
    widgetType: string;
    widgetName: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

export class AddDashboardWidgetBuilder {
    private _DASHBOARDUUID: string;
    private _WIDGETTYPE: string;
    private _WIDGETNAME: string;
    private _X: number;
    private _Y: number;
    private _WIDTH: number;
    private _HEIGHT: number;

    private get _mutation() {
        return gql`mutation addDashboardWidget(
            $dashboardUUID: String
            $widgetType: String
            $widgetName: String
            $x: Int
            $y: Int
            $width: Int
            $height: Int
          ) {
            addWidget(
              dashboard: $dashboardUUID
              widgetType: $widgetType
              nombre: $widgetName
              x: $x
              y: $y
              width: $width
              height: $height
            ) {
              uuid
            }
          }
          `;
    }

    public withDashboardUUID(newDashboardUUID: string): this {
        this._DASHBOARDUUID = newDashboardUUID;
        return this;
    }

    public withWidgetName(newWidgetName: string): this {
        this._WIDGETNAME = newWidgetName;
        return this;
    }

    public withX(newX: number): this {
        this._X = newX;
        return this;
    }

    public withY(newY: number): this {
        this._Y = newY;
        return this;
    }

    public withWidth(newWidth: number): this {
        this._WIDTH = newWidth;
        return this;
    }

    public withHeight(newHeight: number): this {
        this._HEIGHT = newHeight;
        return this;
    }

    public withWidgetType(newWidgetType: string): this {
        this._WIDGETTYPE = newWidgetType;
        return this;
    }

    public build() {
        const mutationParams: WidgetAddedToDashboard = {
            dashboardUUID: this._DASHBOARDUUID,
            widgetType: this._WIDGETTYPE,
            widgetName: this._WIDGETNAME,
            x: this._X,
            y: this._Y,
            width: this._WIDTH,
            height: this._HEIGHT
        };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
            .withParams(mutationParams)
            .build();
    }
}
