import { GaugeWidget, DataTypeWidgetParam, DataGauge, DataSize } from './widgets/widget-interfaces.model';
import * as lodash from 'lodash';
import { PlotlyChartType } from './plotly/plotly-chart.model';

export class GaugeChartBuilder {

    private _gaugeUUID: string;
    private _gaugeName: string;
    private _dataSource: number[][];
    private _uuidSource: string[];
    private _widgetName: string;
    private _limitSource: number;
    private _minSource: number;
    private _maxSource: number;
    private _widgetDataType: DataTypeWidgetParam;
    private _dataSize: DataSize;
    private _x: number;
    private _y: number;
    private _row: number;
    private _col: number;
    private _relationUUID: string;

    constructor() { }

    public withGaugeUUID(newGaugeUUID: string): GaugeChartBuilder {
        this._gaugeUUID = newGaugeUUID;
        return this;
    }

    public withGaugeName(newGaugeName: string): GaugeChartBuilder {
        this._gaugeName = newGaugeName;
        return this;
    }

    public withDataSize(newDataSize: DataSize): GaugeChartBuilder {
        this._dataSize = newDataSize;
        return this;
    }

    public withDataSource(newDataSource: number[][]): GaugeChartBuilder {
        this._dataSource = newDataSource;
        return this;
    }

    public withUuidSource(newUuidSource: string[]): GaugeChartBuilder {
        this._uuidSource = newUuidSource;
        return this;
    }

    public withWidgetName(widgetName: string): GaugeChartBuilder {
        this._widgetName = widgetName;
        return this;
    }

    public withLimitSource(limitSource: number): GaugeChartBuilder {
        this._limitSource = limitSource;
        return this;
    }

    public withMinSource(minSource: number): GaugeChartBuilder {
        this._minSource = minSource;
        return this;
    }

    public withMaxSource(maxSource: number): GaugeChartBuilder {
        this._maxSource = maxSource;
        return this;
    }

    public withDataType(dataType: DataTypeWidgetParam): GaugeChartBuilder {
        this._widgetDataType = dataType;
        return this;
    }

    public withXPos(newPos: number): GaugeChartBuilder {
        this._x = newPos;
        return this;
    }

    public withYPos(newPos: number): GaugeChartBuilder {
        this._y = newPos;
        return this;
    }

    public withCol(newCol: number): GaugeChartBuilder {
        this._col = newCol;
        return this;
    }

    public withRow(newRow: number): GaugeChartBuilder {
        this._row = newRow;
        return this;
    }

    public withRelationUUID(newRelation: string): GaugeChartBuilder {
        this._relationUUID = newRelation;
        return this;
    }

    public build(): GaugeWidget {

        // Debería hacerse en el mapper
        let gaugeData: DataGauge;
        if (this._dataSource !== undefined) {
            const dataFromInflux: number[] = lodash.nth(this._dataSource, 0);
            gaugeData = {
                value:  dataFromInflux[0],
                number: { valueformat: ',.' + "f"},
                title: { text: this._widgetName },
                type: PlotlyChartType.GAUGE,
                mode: 'gauge+number+delta',
                delta: { reference: (2 * (dataFromInflux[0])) - this._limitSource,
                    valueformat: ',.' + 4 + "n"},
                gauge: {
                    axis: {
                        range: [this._minSource, this._maxSource]
                    },
                    threshold: {
                        line: { color: 'red', width: 4 },
                        thickness: 0.75,
                        value: this._limitSource
                    }
                }
            };
        } else {
            gaugeData = {
                value: 0,
                number: {'valueformat':'.4r'},
                title: { text: this._widgetName },
                type: PlotlyChartType.GAUGE,
                mode: 'gauge+number+delta',
                delta: { reference: 0 , valueformat: ',.' + 2 + "n"},
                gauge: {
                    axis: {
                        range: [this._minSource, this._maxSource]
                    },
                    threshold: {
                        line: { color: 'red', width: 4 },
                        thickness: 0.75,
                        value: this._limitSource
                    }
                }
            };
        }

        const oGaugeWidget: GaugeWidget = {
            uuid: this._gaugeUUID,
            name: this._gaugeName,
            dataGauge: gaugeData,
            dataType: this._widgetDataType,
            dataSize: this._dataSize,
            x: this._x,
            y: this._y,
            cols: this._col,
            rows: this._row,
            relationUUID: this._relationUUID
        };
        return oGaugeWidget;
    }
}
