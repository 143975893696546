import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FieldDataService } from 'src/app/services/fieldData/field-data.service';
import { DeviceLabelsModalComponent } from '../device-labels-modal/device-labels-modal.component';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import {FakeServiceService} from '../../services/fakeService/fake-service.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-estructura-campo-nav',
    templateUrl: './estructuraCampoNav.component.html',
    styleUrls: ['./estructuraCampoNav.component.scss']
})
export class EstructuraCampoNavComponent implements OnInit, OnDestroy {

    // Creamos las variables para poder acceder a luego a ellas:
    mySubscription: any;
    querySubscription: any;
    sub: any;
    subStream: any;
    uuid: string;
    gatewayActual: any;
    atributosLabel:any[]= [];    
    labelsMapping:any;
    lmPing: any;

    // para el 'GatewayFrom'
    gatewaysFrom: any;
    displayedColumnsFrom: string[] = ['nombre'];
    dataSourceFrom: MatTableDataSource<any>;
    @ViewChild('TableFromPaginator', { static: true }) tableFromPaginator: MatPaginator;

    // para el 'GatewayTo'
    gatewaysTo: any;
    // displayedColumnsTo: string[] = ['nombre', 'last', 'comunicacion', 'problema_leve', 'problema_grave'];
    displayedColumnsTo: string[] = ['nombre', 'last', 'sources'];

    dataSourceTo: MatTableDataSource<any>;
    @ViewChild('TableToPaginator', { static: false }) tableToPaginator: MatPaginator;

    // para el 'Source'
    sources: any;
    displayedColumnsSources: string[] = ['nombre', 'topic', 'entradas', 'modelo'];
    dataSourceSources: MatTableDataSource<any>;
    @ViewChild('TableSourcesPaginator', { static: false }) tableSourcesPaginator: MatPaginator;

    // Para el momento actual:
    timeStamp = new Date().getTime() * 1000000
    topLimitAlerta: any; // De momento establecido a 1 hora  
    time: number;


    // prueba para la configuracion
    prueba: any;


    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private dataService: FieldDataService,
        private toastrservice: ToastrService,
        private dialog: MatDialog,
        private dispositivosService: DispositivoService,
        private fakeService:FakeServiceService
        ) {
            
    }

    ngOnInit() {
        // Hacemos que el componente sea renavegable
        this.renavigate();
        // Tramos los datos del servicio que hemos creado para ello
        this.getData();
        // this.time = this.timeStamp - this.topLimitAlerta

    }

    // Funcion para poder 'renavegar' por el mismo componente:
    renavigate() {
        // Con las dos suscripciones de debajo, hacemos creer a angular que no hemos visitado el componente
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Para hacer creer a angular que no hemos estado en el componente
                this.router.navigated = false;
            }
        });
        // Nos suscribimos al parametro de uuid del link para saber a que dispositivo tenemos que navegar
        // a continuacion.
        this.activatedRoute.params.subscribe(params => {
            // const uuid = params['uuid'];
            this.uuid = params.uuid;
        });
    }

    // Tramos los datos del servicio que hemos creado
    getData() {
        // Nos traemos la informacion de los gateways que cuelgan desde el servicio 
        this.dataService.getGatewaysFrom(this.uuid)
            .subscribe((data) => {
                this.dataSourceFrom = data;
                this.dataSourceFrom.paginator = this.tableFromPaginator;
            });

        // Nos traemos la informacion de los sources desde el servicio
        this.dataService.getSources(this.uuid)
            .subscribe((data) => {
                this.dataSourceSources = data;                
                // Lógica para ver 
                for (let i = 0; i < this.dataSourceSources.data.length; i++) {
                    let count = 0; let countRezagadas = 0; let countNotNull = 0
                    for (let j = 0; j < this.dataSourceSources.data[i].to.entradas.length; j++) {
                        if (this.dataSourceSources.data[i].to.entradas[j].lastFecha === null) { count = count + 1 }
                        if (this.dataSourceSources.data[i].to.entradas[j].lastFecha != null) { countNotNull = countNotNull + 1 }
                        if (this.dataSourceSources.data[i].to.entradas[j].lastFecha != null) {
                            if (this.timeStamp - this.dataSourceSources.data[i].to.entradas[j].lastFecha > this.topLimitAlerta) {
                                countRezagadas = countRezagadas + 1
                            }
                        }
                    }
                    this.dataSourceSources.data[i].to.entradasNulas = count;
                    this.dataSourceSources.data[i].to.entradasRezagadas = countRezagadas;
                    this.dataSourceSources.data[i].to.countNotNull = countNotNull;
                    this.dataSourceSources.data[i].to.entradasTotales = this.dataSourceSources.data[i].to.entradas.length
                }
                this.dataSourceSources.filterPredicate = (data, filter: string) => {
                    const accumulator = (currentTerm, key) => {
                        return this.nestedFilterCheck(currentTerm, data, key);
                    };
                    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                    // Transform the filter by converting it to lowercase and removing whitespace.
                    const transformedFilter = filter.trim().toLowerCase();
                    return dataStr.indexOf(transformedFilter) !== -1;
                };
                this.dataSourceSources.paginator = this.tableSourcesPaginator;
            });

        // Nos traemos la informacion del gateway actual desde el servicio
        this.dataService.getGatewayActual(this.uuid)
            .subscribe((data) => {
                this.gatewayActual = data;
            });



        // Obtener información del ping:
        this.dataService.getLMping(this.uuid)
            .subscribe((data) => {
                this.lmPing = data;
            })

        // Cargamos la configuración del limite de tiempo para mostrar una alerta
        // al usuario sobre el estado del gateway:
        this.dataService.getConfigFieldData()
            .subscribe((data) => {
                this.topLimitAlerta = data.limiteAlarma;
                this.time = this.timeStamp - this.topLimitAlerta;
                // también se puede aceder como data["limiteAlarma"]
            });

        // Para calcular la cantidad de sources rezagados que tiene el gateway actual!
        this.subStream = this.dataService.getGatewaysTo(this.uuid).subscribe((data) => {
            this.gatewaysTo = data;
            for (let i = 0; i < this.gatewaysTo.length; i++) {
                this.dataService.getsourceRezagados(this.gatewaysTo[i].to.uuid, this.time).
                    subscribe(data => {
                        this.gatewaysTo[i].to.sourcesRezagados = data
                    }
                    )
            }
            this.dataSourceTo = new MatTableDataSource(this.gatewaysTo);
            this.dataSourceTo.filterPredicate = (data, filter: string) => {
                const accumulator = (currentTerm, key) => {
                    return this.nestedFilterCheck(currentTerm, data, key);
                };
                const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                // Transform the filter by converting it to lowercase and removing whitespace.
                const transformedFilter = filter.trim().toLowerCase();
                return dataStr.indexOf(transformedFilter) !== -1;
            };
            this.dataSourceTo.paginator = this.tableToPaginator;
        }
        )
        this.getAtributosLabels();
    }
    // para filtros a distinto nivel en las tablas
    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }

    // Funcion para aplicar los filtros
    applyFilterTo(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceTo.filter = filterValue.trim().toLowerCase();
    }

    applyFilterFrom(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceFrom.filter = filterValue.trim().toLowerCase();
    }

    applyFilterSources(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceSources.filter = filterValue.trim().toLowerCase();
    }

    // función para alertar de que el uuid ha sido copiado al portapeles
    copiado(event: Event) {
        if (event) {
            this.toastrservice.info('', 'Copiado al portapapeles con éxito',
                { closeButton: true, timeOut: 2000, positionClass: "toast-top-center" });
        }
    }

    // Para volver al estado anterior de la navegacion
    previousState() {
        window.history.back();
    }

    //feature-3-editar-elementos-de-campo

    getAtributosLabels(){
        this.atributosLabel=[];
        // Get atributos Label
        this.fakeService.getDeviceLabelsMapping()
            .subscribe((data) => {
                this.labelsMapping = data.deviceLabelsMapping;
            });
        this.dataService.getAtributosGateWay(this.uuid)
            .subscribe((data) => {
                if(data){
                    Object.entries(data).forEach((atributo)=>{
                        if(atributo[0]!="__typename" && !_.some(this.atributosLabel,(x)=>x[0]==atributo[0]))
                        this.atributosLabel.push(atributo)
                    })                   
                }
            });
        this.dataService.getAtributosLMPing(this.uuid)
            .subscribe((data) => {
                if(data){
                    Object.entries(data).forEach((atributo)=>{
                        if(atributo[0]!="__typename" && !_.some(this.atributosLabel,(x)=>x[0]==atributo[0]))
                        this.atributosLabel.push(atributo)
                    })                   
                }
            });
        this.dataService.getAtributosDispositivo(this.uuid)
            .subscribe((data) => {
                if(data){
                    Object.entries(data).forEach((atributo)=>{
                        if(atributo[0]!="__typename" && !_.some(this.atributosLabel,(x)=>x[0]==atributo[0]))
                        this.atributosLabel.push(atributo)
                    })                   
                }
            });
        this.dataService.getAtributosMbBus(this.uuid)
            .subscribe((data) => {
                if (data){
                    Object.entries(data).forEach((atributo)=>{
                        if(atributo[0]!="__typename" && !_.some(this.atributosLabel,(x)=>x[0]==atributo[0]))
                        this.atributosLabel.push(atributo)
                    })                   
                }
            });
        this.dataService.getAtributosTcpDevice(this.uuid)
            .subscribe((data) => {
                if(data){
                    Object.entries(data).forEach((atributo)=>{
                        if(atributo[0]!="__typename" && !_.some(this.atributosLabel,(x)=>x[0]==atributo[0]))
                        this.atributosLabel.push(atributo)
                    })                   
                }
            }); 
        this.dataService.getAtributosMbRtuDevice(this.uuid)
            .subscribe((data) => {
                if(data){
                    Object.entries(data).forEach((atributo)=>{
                        if(atributo[0]!="__typename" && !_.some(this.atributosLabel,(x)=>x[0]==atributo[0]))
                        this.atributosLabel.push(atributo)
                    })                   
                }
            });
        
        // Fin atributosl Label
    }
    openDialog() {
        
        const dialogRef = this.dialog.open(DeviceLabelsModalComponent, {
          data: { "atributosLabel" :_.uniqWith(this.atributosLabel, _.isEqual),
                  "labelsMapping" : this.labelsMapping,
                  "uuid" : this.uuid,
                  "result": ""
                },
          minWidth: 270
        });
        dialogRef.afterClosed().subscribe(dispositivo => {
            if(!_.isEmpty(dispositivo)){
                dispositivo = {...dispositivo, actualuuid:this.uuid};
                this.guardarAtributosLabels(dispositivo);
                this.getData();
            }
        });
      }

      guardarAtributosLabels(dispositivo){
        this.gatewayActual.labels.forEach(label => {
            switch(label){
                case "Dispositivo":{    
                    this.dataService.actualizarDispositivo(dispositivo);
                    break;
                }
                case "Gateway":{    
                    this.dataService.actualizarGateway(dispositivo);
                    break;
                }
                case "LMping":{    
                    this.dataService.actualizarLMping(dispositivo);
                    break;
                }
                case "TcpBus":{    
                    this.dataService.actualizarTcpBus(dispositivo);
                    break;
                }
                case "TcpDevice":{    
                    this.dataService.actualizarTcpDevice(dispositivo);
                    break;
                }
                case "MbBus":{    
                    this.dataService.actualizarMbBus(dispositivo);
                    break;
                }
                case "MbRtuDevice":{    
                    this.dataService.actualizarMbRtuDevice(dispositivo);
                    break;
                }
                default: break;
            }
        });
      }

    ngOnDestroy() {
        // Cerramos el stream de datos para no tener problemas de memoria:
        this.subStream.unsubscribe();

    }

}
