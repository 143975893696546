import { from, Observable } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AcLayerComponent, AcNotification } from 'angular-cesium';
import { map, switchMap } from 'rxjs/operators';
import { MapaServiceService } from '../../../sections/mapa/mapa-service.service';

@Component({
  selector: 'app-html-layer-devices',
  templateUrl: './html-layer-devices.component.html',
  styleUrls: ['./html-layer-devices.component.scss']
})


export class HtmlLayerDevicesComponent implements OnInit {

  @Output() openTipoDatosModalEmitter: EventEmitter<null> = new EventEmitter();
  @Output() openDatosModalEmitter: EventEmitter<string> = new EventEmitter();

  @ViewChild(AcLayerComponent, { static: false }) layer: AcLayerComponent;
  htmls$: Observable<AcNotification>;
  entities$: Observable<AcNotification>;

  idSelected = [];

  constructor(
    private readonly mapService: MapaServiceService
  ) {

    this.loadGeoPos();
    this.mapService.mapElementsFiltered$.subscribe(elems => this.idSelected = elems.map(e => e.entityMap.id))
  }

  ngOnInit() {
    this.loadGeoPos();
  }

  loadGeoPos() {
    this.entities$ = this.mapService.mapElementsFiltered$.pipe(
      map(elements => elements.map(e => e.entityMap)),
      switchMap(data => {
        return from(data as AcNotification[])
      }),
    )

  }


  openTipoDatosModal() {
    this.openTipoDatosModalEmitter.emit();
  }

}
