import { User } from '../../user.model';
import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export class UpdateUserBuilder {
    private _USER: User;

    private get _mutation() {
        return gql `
        mutation updateUser($timeZone: String, $language: String) {
            updatePreferences(zonaHoraria: $timeZone, idioma: $language) {
                uuid
              }
          }          
        `;
    }

    public withUser(newUser: User): this {
        this._USER = newUser;
        return this;
    }

    public build() {
        const mutationParams: User = {
            uuid: this._USER.uuid,
            name: this._USER.name,
            firstName: this._USER.firstName,
            user: this._USER.user,
            timeZone: this._USER.timeZone,
            language: this._USER.language
        };
        const graphqlBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphqlBuilder.withMutation(this._mutation).withParams(mutationParams).build();
    }
}

