import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Subject } from 'rxjs/Subject';
@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  itemValue = new Subject();

  constructor(private storage: LocalStorage) { }

  setLocal(key: string, value) {
    this.storage.removeItem(key)
    this.itemValue.next(value);
    this.storage.setItem(key, value).subscribe()
    this.itemValue.next(value);
  }
  getLocal(key: string) {
    return this.storage.getItem(key);
  }
  removeLocal(key: string) {
    return this.storage.removeItem(key);
  }


}
