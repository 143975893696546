import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateRangeComponent } from '../date-range/date-range.component';
import { Moment } from 'moment';
import { DateRange } from 'src/app/models/dateRange.model';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit, OnChanges {
  @Output() rangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() generatedDate: DateRange;
  dateSelected = 'Select date range';
  timeZone: string;
  from: Moment
  to: Moment
  constructor(private _bottomSheet: MatBottomSheet) {
  }

  ngOnInit() {
  }

  openBottomSheet(): void {
    const elem = this._bottomSheet.open(DateRangeComponent, { data: this.generatedDate });
    elem.afterDismissed().subscribe(res => {
      const date = elem.instance.saveDate();
      this.rangeEmitter.emit(date);
      this.dateSelected = date.from + ' - ' + date.to;
    });

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.generatedDate !== null && this.generatedDate !== undefined) {
      this.from = this.generatedDate[0].from;
      this.to = this.generatedDate[0].to;
      this.dateSelected = this.from.format('DD-MM-YY') + ' - ' + this.to.format('DD-MM-YY');
    }
  }
}
