import { Component, Input, OnChanges, OnInit, Output  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonServiceService } from './services/commonService/common-service.service';
import { User } from './models/user.model';
import { LoginService } from './services/login/login.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges {
  title = 'Lynx';
  @Input() sideNavStatus = true;
  @Output() userRole: string[]; 
  toggleSideNav: boolean;
  titleSection: string;
  currentUser: User;
  
  constructor(
    private translate: TranslateService,
    private commonService: CommonServiceService,
    private loginService: LoginService,
    ) {
      this.commonService.menuStatus.subscribe(data => {
      this.sideNavStatus = false;
    });

    translate.setDefaultLang('es');

  }
  onToggleSideNav($event) {
    this.sideNavStatus = $event;

  }
  ngOnInit() {
    this.getFirstValueOfTitleSection();

    

    if (this.loginService.currentUser !== undefined){
      this.loginService.currentUser.subscribe(x => {
        if (!_.isNil(x)){
          this.currentUser = x
        }
          else{
            this.currentUser = null;
        }
        
      });
    }
    
   
  }

  private getFirstValueOfTitleSection() {
    switch (window.location.pathname) {
      case '/':
        this.titleSection = 'TITLES.DASHBOARD';
        break;
      case '/graphics':
        this.titleSection = 'TITLES.GRAPHICS';
        break;
      case '/devices':
        this.titleSection = 'TITLES.DEVICES';
        break;
      case '/alarms':
        this.titleSection = 'TITLES.ALARMS';
        break;
      case '/users':
        this.titleSection = 'TITLES.USERS';
      case '/bbdd':
        this.titleSection = 'TITLES.DATABASE';
        break;
    }
  }

  getTitle($event) {
    this.titleSection = $event;
  }

  ngOnChanges() {
  }

}
