import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { QueryDatos, QueryEstructura } from '../../informes/consultas/interfaces.consultas';
import { DialogData, Dispositivo, Dato, TipoDato } from '../interfaces/ventana-dsp.data';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit, OnDestroy {
  private subscrip: Subscription[] = [];
  public hayFabrica = false;
  public mas = false;
  public fabrica = '';
  public dsps: Dispositivo[] = [];
  public nivel: Dispositivo[] = [];
  public datos: Dato[] = [];
  public tipoDatos: TipoDato[] = [];
  public haydatosUiid = '';

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    //console.log("DATA->");
    //console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
    // console.log();
  }


  changeFabrica(ds) {
    //console.log(ds);
    this.nivel = [];
    this.nivel.push(ds);
    const qestru: Subscription = this.apollo.watchQuery<any>({
      query: QueryEstructura,
      variables: { uuid: ds.uuid }
    }).valueChanges.subscribe(({ data, loading }) => {
      //console.log(data.datos);
      this.dsps = [];
      data.datos.estructura.map(d => this.dsps.push(d));
      //console.log('DSPS =', this.dsps[0]);
      this.hayFabrica = true;
    });
    this.subscrip.push(qestru);
    this.haydatosUiid = '';
  }

  dispositivoClick(ds) {
    //console.log("DISPOSITIVO CLICK (1): ",ds);
    const qdis: Subscription = this.apollo.watchQuery<any>({
      query: QueryEstructura,
      variables: { uuid: ds.uuid }
    }).valueChanges.subscribe(({ data, loading }) => {
      //console.log("DISPOSITIVO CLICK (2): ",data.datos);
      if (data.datos.estructuraCantidad > 0) {
        this.nivel.push(ds);
        this.dsps = [];
        data.datos.estructura.map(d => this.dsps.push(d));
        //console.log("DISPOSITIVO CLICK (3): DSPS: ", this.dsps);
        this.mas = true;
      } else {
        this.mas = false;
      }
    });
    this.subscrip.push(qdis);
    this.haydatosUiid = '';
  }

  cambioNivel(ds) {
    //console.log('CAMBIO DE NIVEL (1): ',ds);
    const idxs = this.nivel.keys();
    let k = 0;
    for (const idx of idxs) {
      if (this.nivel[idx].uuid === ds.uuid) {
        k = idx + 1;
        break;
      }
    }
    const dif = this.nivel.length - k;
    this.nivel.splice(k, dif);
    const qnivel: Subscription = this.apollo.watchQuery<any>({
      query: QueryEstructura,
      variables: { uuid: ds.uuid }
    }).valueChanges.subscribe(({ data, loading }) => {
      //console.log('CAMBIO DE NIVEL (2): ',data.datos);
      if (data.datos.estructuraCantidad > 0) {
        this.dsps = [];
        if (data.datos != null)
          data.datos.estructura.map(d => this.dsps.push(d));
          console.log('DSPS (CAMBIO NIVEL 3) =', this.dsps);
        this.mas = true;
      } else {
        this.mas = false; 
      }
    });
    this.subscrip.push(qnivel);
    this.haydatosUiid = '';
  }

  datosClick(ds) {
    //console.log('DATOS CLICK 1: ',ds);
    if (ds.uuid === this.haydatosUiid) {
      this.haydatosUiid = '';
    } else {
      const qnivel: Subscription = this.apollo.watchQuery<any>({
        query: QueryDatos,
        variables: { d_uuid: ds.uuid }
      }).valueChanges.subscribe(({ data, loading }) => {
        // console.log('DATOS CLICK 2: ',data.datos);
        // console.log(data.datos.length);
        if (data.datos.length > 0) {
          this.datos = [];
          this.tipoDatos = [];
          data.datos.map(d => {
            this.datos.push({
              uuid: d.uuid,
              periodo: d.periodo,
              descripcion: d.descripcion
            });
            this.tipoDatos.push({
              uuid: d.es.uuid,
              nombre: d.es.nombre,
              unidad: d.es.unidad
            });
          });
          // console.log('DATOS: ', this.datos);
          this.haydatosUiid = ds.uuid;
        } else {
          this.haydatosUiid = '';
        }
      });
      this.subscrip.push(qnivel);
    }
  }

  haydatos(uuid) {
    //console.log("HAY DATOS: ",this.haydatosUiid, uuid)
    if (this.haydatosUiid === uuid) {
      return true;
    } else {
      return false;
    }
  }
  ngOnDestroy() {
    this.subscrip.map(qs => qs.unsubscribe());
  }

}
