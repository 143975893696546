import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewChangeState {
    uuidSupervisa: string;
    nuevoEstado: string;
}

export class ChangeAlarmStateBuilder {
    private _ALARMUUID: string;
    private _STATE: string;

    private get _mutation() {
        return gql `mutation actualizarEstadoAlarma($uuidSupervisa: String, $nuevoEstado: String) {
            updateAlarmaState(supervisa: $uuidSupervisa, state: $nuevoEstado) {
              uuid
            }
          }`;
    }

    public withAlarmUUID(alarmUUID: string): this {
        this._ALARMUUID = alarmUUID;
        return this;
    }

    public withState(newState: string): this {
        this._STATE = newState;
        return this;
    }

    public build(): MutationOptions {
        let mutationParams: NewChangeState;
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = {
            uuidSupervisa: this._ALARMUUID,
            nuevoEstado: this._STATE
        };
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
