export interface InfluxQuery {
    deviceUuid: string;
    dataTypeUuid: string;
}

export class InfluxWidgetQueryBuilder {
    private _deviceUuid: string;    
    private _dataTypeUuid: string;

    
    constructor(){
        
    }

    public withDeviceUuid(newDeviceUuid: string): InfluxWidgetQueryBuilder {
        this._deviceUuid = newDeviceUuid;
        return this;
    } 
    public withDataTypeUuid(newDataTypeUuid: string): InfluxWidgetQueryBuilder{
        this._dataTypeUuid = newDataTypeUuid;
        return this;
    }
   

    public build(): string{       
        const influxQuery: string = `SELECT \"${this._deviceUuid}\" FROM \"${this._dataTypeUuid}\" ORDER BY time DESC LIMIT 1000`;
        return influxQuery;
    }
 
}