import { Component, OnInit, Input } from '@angular/core';
import { AlarmaModel, TipoAlarmaModel } from './../models/alarma-models'

@Component({
  selector: 'app-alarma-detail',
  templateUrl: './alarma-detail.component.html',
  styleUrls: ['./alarma-detail.component.scss']
})
export class AlarmaDetailComponent implements OnInit {

  @Input() alarma: AlarmaModel;
  constructor() { }

  ngOnInit() {
  }

}
