import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { DeviceModel } from 'src/app/models/device.model';
import { State } from 'src/app/models/contract/list-item.model';
import * as _ from 'lodash';
import { GroupBy, LastDataModel, Medicion } from '../../device-datos/device-datos.component';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { GeoDevicesService } from 'src/app/services/mapa/geodevices.service';
import { map } from 'rxjs/operators';
import { GetDatePipe } from 'src/app/pipes/pipes/pipe-date.pipe';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-device-last-stats-list',
  templateUrl: './device-last-stats-list.component.html',
  styleUrls: ['./device-last-stats-list.component.scss'],
  providers: [
    GetDatePipe
],
})
export class DeviceLastStatsListComponent implements OnInit, OnDestroy {

  @Input() allDevicesWithChildren: DeviceModel[];
  @Input() title: string;
  @Input() deviceChildrenList: DeviceModel[];
  @Input() loadingChildren: boolean;
  @Input() parentDeviceId: string;
  deviceSettings: DeviceListSettings =
    {
      titleType: 'factory',
      actionIcon: 'circle-right',
      selectable: State.selected
    }

  obileMode = false;
  loading = false;
  loadingText = 'Loading '
  entities$: Observable<LastDataModel[]>;
  filteredEntities$: Observable<LastDataModel[]>;

  deviceFilter$ = new BehaviorSubject<string>(null);
  filter: FormControl;
  refreshSubscription: Subscription;
  interval: any;

  public itemObservable: DeviceModel[];
  public allItemObservable: DeviceModel[];
  @Output() deviceItemNavegate: EventEmitter<DeviceModel> = new EventEmitter<DeviceModel>();

  constructor(
    private dispositivoService: DispositivoService,
    private geoDevicesService: GeoDevicesService,
    private getDatePipe: GetDatePipe,
    private loginService: LoginService,
    private datePipe: GetDatePipe
  ) { }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.loadDevices()
    }, 6 * Math.pow(10, 5))
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  loadDevices() {
    this.itemObservable = _.sortBy(this.deviceChildrenList, ['name'], ['asc']);
    this.filter = new FormControl('');
    this.allItemObservable = _.sortBy(this.allDevicesWithChildren, ['name'], ['asc']);
    this.entities$ = this.getDevicesNew();
    if (this.entities$)
      this.refreshSubscription = this.entities$.subscribe();
  }

  ngOnChanges(changes: any): void {
    if (changes.deviceChildrenList !== undefined) {
      this.itemObservable = changes.deviceChildrenList.currentValue;


    } else {
      if (changes.loadingChildren !== undefined) {
      }
    }
    if (changes.parentDeviceId !== undefined) {
      this.loadDevices();
      this.getFilteredDevices();
      this.loadingChildren = false;
    }

  }

  public navigate(item: DeviceModel) {
    this.deviceItemNavegate.emit(item);
  }




  devicesToShow: string[] = [];

  getChildren(devices: DeviceModel[]) {
    devices.forEach(device => {
      this.devicesToShow.push(device.uuid);
      if (device.children.length > 0) {
        let children = device.children.map(child => _.filter(this.allDevicesWithChildren, { uuid: child.uuid })[0])
        this.getChildren(children)
      } else {
        return;
      }
    })
  }

  getDevicesNew(): Observable<LastDataModel[]> {
    return this.geoDevicesService.findLastMeasuresWithDescendancy(this.parentDeviceId).pipe(
      map(res => {
        return res.filter(device => device.tiene.length > 0)
          .map(device => {
            let lastDataMapped: LastDataModel = {
              uuid: device.uuid,
              nombreDispositivo: device.nombre,
              mediciones: _.flattenDeep(device.tiene.map(tiene => {
                let dato: Medicion;
                let lastFecha, lastFechaEpoch
                if (!_.isNil(tiene.to.lastFecha)) {
                  lastFecha = this.datePipe.getDatePipe(parseFloat(tiene.to.lastFecha))
                  lastFechaEpoch = tiene.to.lastFecha;
                }

                return dato = {
                  nombre: tiene.to.es.nombre,
                  stats_last: !_.isNil(tiene.to.lastValor) ? Number(tiene.to.lastValor) : !_.isNil(tiene.to.stats_last) ? Number(tiene.to.stats_last) : null,
                  lastFecha: !_.isNil(tiene.to.lastFecha) ? lastFecha : null,
                  lastFechaEpoch: !_.isNil(tiene.to.lastFecha) ? lastFechaEpoch : null,
                  codigo: !_.isNil(tiene.to.es.agrupacion[0]) ? tiene.to.es.agrupacion[0].nombre : '',
                  unidad: tiene.to.es.unidad,
                  nombreCode: !_.isNil(tiene.to.es.codigo) ? tiene.to.es.codigo : '',
                  descripcion: tiene.to.es.descripcion,
                }
              })).filter(dato => dato.codigo !== 'record' && dato.codigo !== 'otros' && !dato.nombre.includes('_CLB(normalizado)')),
              medicionesAgrupadas: '',
              medicionesTable: [],
              medicionesTableFiltered: [],
              children: []
            }
            return lastDataMapped;
          })
      }),
      // tap (res => console.log ('mapped', res)),
      map(res => {
        return res.map(device => {
          device.medicionesAgrupadas = _.groupBy(device.mediciones, group => group.codigo)
          let record: (Medicion)[] = [];
          Object.entries(device.medicionesAgrupadas).forEach(entry => {
            let groupBy: GroupBy = {
              codigo: (entry[0]),
              nombreCode: "",
              isGroupBy: true
            }
            record.push(...device.medicionesAgrupadas[entry[0]]);
          });

          device.medicionesTable = record.filter(record => record.lastFechaEpoch != null);
          return device;
        })
      }),
      map(res => {
        return _.sortBy(res, 'nombreDispositivo')
          .filter(dispositivo => dispositivo.medicionesTable.length > 0)
      }),
    )
  }

  applyFilter() {
    this.deviceFilter$.next(this.filter.value);
  }

  getFilteredDevices() {

    this.entities$ = this.getDevicesNew();
    this.filteredEntities$ = combineLatest([
      this.entities$,
      this.deviceFilter$
    ]).pipe(
      map(([entities, deviceFilter]) => {
        if (!deviceFilter) {
          return entities;
        }
        return entities.filter(r => r.nombreDispositivo.toLowerCase().includes(deviceFilter.toLowerCase()));
      })
    );
  }

  public hasChildren(collectionToCheck: Array<any>): boolean {
    return !(collectionToCheck === null || collectionToCheck === undefined || collectionToCheck.length === 0);
  }

}
