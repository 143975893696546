import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AcNotification, ActionType } from 'angular-cesium';
import { map, mergeAll, pluck, filter } from 'rxjs/operators';
import { GeoDevicesService, EntidadGeoPOS, EntidadMapa } from './../../../services/mapa/geodevices.service';
import * as _ from 'lodash';


@Component({
  selector: 'billboard-layer-devices',
  templateUrl: './billboard-layer-devices.component.html',
  styleUrls: ['./billboard-layer-devices.component.scss']
})
export class BillboardLayerDevicesComponent implements OnInit {

  entities$: Observable<AcNotification>;
  Cesium = Cesium;
  constructor(private dataProvider: GeoDevicesService) { 
    dataProvider.fabricas;

  }

    ngOnInit() {

  //   this.entities$ = this.dataProvider.getData$()
  //     .pipe(
  //       mergeAll(),
  //     )
  //     .pipe(
  //       pluck("data","GeoPos"),
  //       filter((data)=>{
  //         return (data[0].longitud!==null)
  //       }),
  //       map((data,i)=>{
  //         let geoPos: EntidadGeoPOS;
  //         geoPos = data[0];
  //         let fabrica = _.find(this.dataProvider.fabricas, (o) => o.uuid == geoPos.uuid );

  //         let entityMap:AcNotification;
  //            entityMap = {
  //             id: geoPos.uuid,
  //             actionType: ActionType.ADD_UPDATE,
  //             entity:  {
  //                     id:geoPos.uuid, 
  //                     name:  `${fabrica.nombre}`,
  //                     position:Cesium.Cartesian3.fromDegrees(geoPos.longitud, geoPos.latitud, 0.00)
  //                   }
  //             }
  //             return entityMap;
  //       })
  //     )
    }

}

