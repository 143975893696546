import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewAlarmInforma {
    alarmaUUID: string;
    usuarioUUID: string;
}

export class AddAlarmaInformaBuilder {
    private _ALARMUUID: string;
    private _USERUUID: string;

    private get _mutation() {
        return gql`mutation asociarAlarmaAUsuario($alarmaUUID: String, $usuarioUUID: String) {
            addAlarmaInforma(alarma: $alarmaUUID, usuario: $usuarioUUID) {
              uuid
            }
          }`;
    }

    public withAlarmUuid(newAlarmUuid: string): this {
        this._ALARMUUID = newAlarmUuid;
        return this;
    }

    public withUserUuid(userToAssociateUuid: string): this {
        this._USERUUID = userToAssociateUuid;
        return this;
    }

    public build() {
        let mutationParams: NewAlarmInforma;
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = {
            alarmaUUID: this._ALARMUUID,
            usuarioUUID: this._USERUUID
        };
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }

}
