import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FieldDataService } from 'src/app/services/fieldData/field-data.service';

@Component({
  selector: 'app-entradas-nav',
  templateUrl: './entradas-nav.component.html',
  styleUrls: ['./entradas-nav.component.scss']
})
export class EntradasNavComponent implements OnInit {

  // Para la renavegación del componente
  mySubscription: any;
  uuid: string;

  // Para la información de la navegación actual
  nombreActual: string;
  dispositivoActual: string;

  // para las entradas
  entradas: any;
  displayedColumns: string[] = ['topic', 'descripcion', 'last', 'lastFecha', 'lastValor'];
  dataSource: MatTableDataSource<any>;
  // @ViewChild('TableEntradasPaginator', { static: true }) tableEntradasPaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  loading: boolean = true;

  // Para el momento actual:
  timeStamp = Date.now() * 1000000
  limiteAlerta: any;     // Establecemos el limite superiorpara la alerta a 1 hora  
  // [(60*60) * 1000000000] para conseguirlo en nanosegundos



  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: FieldDataService
  ) { }

  ngOnInit() {
    // Renavegamos
    this.renavigate();
    // Obtenemos los datos
    this.getData();
  }


  // Funcion para poder 'renavegar' por el mismo componente:
  renavigate() {
    // Con las dos suscripciones de debajo, hacemos creer a angular que no hemos visitado el componente
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Para hacer creer a angular que no hemos estado en el componente
        this.router.navigated = false;
      }
    });
    // Nos suscribimos al parametro de uuid del link para saber a que dispositivo tenemos que navegar
    // a continuacion.
    this.activatedRoute.params.subscribe(params => {
      this.uuid = params.uuid;
    });
  }


  getData() {
    // Obtenemos la información de la entrada actual
    this.dataService.getEntradas(this.uuid).subscribe(({ data, loading }) => {
      this.entradas = data.findSource;
      this.nombreActual = this.entradas.nombre;
      this.dispositivoActual = this.entradas.entradas[0].to.tiene.nombre;
      this.dataSource = new MatTableDataSource(this.entradas.entradas);
      this.dataSource.paginator = this.paginator;
      this.loading = loading;
    })

    // Cargamos la config desde el servicio de estructura de campo
    this.dataService.getConfigFieldData()
      .subscribe(data => this.limiteAlerta = data.limiteAlarma);
  }

  // Para volver al estado anterior de la navegacion
  previousState() {
    window.history.back();
  }

  // Para filtrar la tabla
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // Para el filtro anidado
  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

}
