import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ListItem, State } from '../../models/contract/list-item.model';
import { DeviceListSettings } from '../../models/deviceSettings.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DataTypeItem } from '../../models/datatype/datatype-hierarchi.model';
import * as lodash from 'lodash';

@Component({
  selector: 'app-mapa-datatype-tab',
  templateUrl: './mapa-datatype-tab.component.html',
  styleUrls: ['./mapa-datatype-tab.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        width: 0
      })),
      transition('void <=> *', animate('0.3s')),
    ])
  ],

})
export class MapaDatatypeTabComponent implements OnInit, OnChanges {

  @Input() loading: boolean;
  @Input() itemCollection: DataTypeItem[];
  @Input() deviceSettings: DeviceListSettings;
  @Output() itemSelected: EventEmitter<DataTypeItem> = new EventEmitter<DataTypeItem>();
  @Output() itemNavigate: EventEmitter<ListItem> = new EventEmitter<ListItem>();
  @Output() itemUnselected: EventEmitter<DataTypeItem> = new EventEmitter<DataTypeItem>();
  @Output() goBackItem: EventEmitter<ListItem> = new EventEmitter<ListItem>();
  @Input() buttonActive: boolean;
  @Input() deviceNavigate: boolean;
  @Input() parent: ListItem = null;
  @Input() dataTypeSelectedCollection: DataTypeItem[] = [];
  searchStatus = true;
  actionIcon: string;

  constructor() { }

  ngOnInit() {
    this.listItemSettingsFunc(this.deviceSettings);
  }

  ngOnChanges() {
    this.checkIfSelectedDataTypeIsEmpty();
    this.checkDatatypeSelectedCollection();
  }

  private checkDatatypeSelectedCollection() {
    if (!lodash.isEmpty(this.dataTypeSelectedCollection)) {
      for (const item of this.itemCollection) {
        this.moveStateDataTypeSelectedCollectionToSelected(item);
        this.checkUnityCompatibility(item);
      }
    }
  }

  private moveStateDataTypeSelectedCollectionToSelected(item: DataTypeItem) {
    for (const selected of this.dataTypeSelectedCollection) {
      if (item.uuid === selected.uuid) {
        item.selectedState = State.selected;
      }
    }
  }

  private checkUnityCompatibility(item: DataTypeItem) {
    if (!lodash.isEmpty(item.unity) && item.unity !== this.dataTypeSelectedCollection[0].unity) {
      item.selectedState = State.notSelectable;
    }
  }

  private listItemSettingsFunc(settings: DeviceListSettings) {
    this.actionIcon = settings.actionIcon;
  }

  goBackButton(event) {
    this.goBackItem.emit(event);
  }

  onChangeSearchStatus() {
    this.searchStatus = !this.searchStatus;
  }

  public hasChildren(collectionToCheck: Array<any>): boolean {
    return !(collectionToCheck === null || collectionToCheck === undefined || collectionToCheck.length === 0);
  }

  onClickItem(itemClicked: DataTypeItem) {
    if (itemClicked.selectedState === State.selectable) {
      this.changeStateToSelected(itemClicked);
    } else if (itemClicked.selectedState === State.selected) {
      this.changeStateToSelectable(itemClicked);
    }
  }

  private changeStateToSelectable(itemClicked: DataTypeItem) {
    itemClicked.selectedState = State.selectable;
    this.dataTypeSelectedCollection.removeBy(item => item.uuid === itemClicked.uuid);
    this.checkIfSelectedDataTypeIsEmpty();
    this.itemUnselected.emit(itemClicked);
  }

  private checkIfSelectedDataTypeIsEmpty() {
    this.dataTypeSelectedCollection = this.dataTypeSelectedCollection;
    if (lodash.isEmpty(this.dataTypeSelectedCollection) && !lodash.isEmpty(this.itemCollection)) {
      for (const item of this.itemCollection) {
        item.selectedState = State.selectable;
      }
    }
  }

  private changeStateToSelected(itemClicked: DataTypeItem) {
    itemClicked.selectedState = State.selected;
    this.checkCompatibilityOfUnity(itemClicked);
    this.itemSelected.emit(itemClicked);
  }

  private checkCompatibilityOfUnity(itemClicked: DataTypeItem) {
    for (const item of this.itemCollection) {
      if (item.unity !== itemClicked.unity) {
        item.selectedState = State.notSelectable;
      }
    }
  }

  onClickItemNavegate(itemNavegate: ListItem) {
    this.itemNavigate.emit(itemNavegate);
  }
}
