import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export class UsersDashboardsBuilder {
    private get _query(): DocumentNode {
        return gql`{
            usuarios {
              uuid
              usuario
              nombre
              dashboards {
                uuid
                from {
                  uuid
                  nombre
                }
                deleted
                owner
                to {
                  uuid
                  nombre
                }
              }
            }
          }
          `;
    }

    public build(): PureQueryOptions {
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQlBuilder
            .withQuery(this._query)
            .build();
    }
}
