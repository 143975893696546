import { DeviceConsumption } from './utils/device-consumption.model';
import { NgxChart } from './ngx-chart.model';
import { DeviceDetailGraphQLResponse } from './graphQL/device-detail-graphQL.response';

export class DeviceConsumptionInfo {
    waterConsumption?: DeviceConsumption;
    energyConsumption?: DeviceConsumption;
    gasConsumption?: DeviceConsumption;
    productivity?: DeviceConsumption;
}

export class DeviceInfo {
    water: string;
    light:string;
    gas: string;
    consumptionInfo: DeviceConsumptionInfo;
    dataGraphCollection?: NgxChart[];
    
    constructor(newDeviceInfo?:Partial<DeviceInfo>){
        Object.assign(this,newDeviceInfo);
    }
}