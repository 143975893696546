import gql from 'graphql-tag';

import { DocumentNode } from 'apollo-link';
import { GraphQlQueryBuilder } from './graphQl-query.builder';
import { PureQueryOptions } from 'apollo-client';
interface DeviceChildrenGraphQL {
  deviceUUID: string;
}

export class DeviceChildrenWithDatatypes {
  private _deviceId: string;

  private get _query(): DocumentNode {

    return gql`query findChildsWithDataTypes($deviceUUID:String) {
        findEstructura(uuid:$deviceUUID) {
          uuid
    nombre
    descripcion
    estructura {
          estructuraCantidad
          uuid
          nombre
          descripcion
          tiene {
            to {
              es {
                uuid
                nombre
                unidad
                codigo
                __typename
              }
              __typename
            }
        __typename
      }
      __typename
    }
    __typename
        }
       }`;
  }


  public withDeviceIdParam(deviceId: string): this {
    this._deviceId = deviceId;
    return this;
  }

  public build(): PureQueryOptions {
    let queryParams: DeviceChildrenGraphQL;
    const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
    queryParams = { deviceUUID: this._deviceId };
    return graphQlBuilder.withQuery(this._query)
      .withParams(queryParams)
      .build();
  }
}
