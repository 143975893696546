import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceConsumptionInfo } from 'src/app/models/deivce-info';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent implements OnInit {

  @Input() deviceDetail: DeviceConsumptionInfo;
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  showProductivityCard = false;


  constructor(private settingsService: SettingsService) {
    this.getProductivitySettings();
  }

  ngOnInit() {

  }

  getProductivitySettings() {
    this.settingsService.getProductivitySettings().subscribe((data: boolean) => {
      this.showProductivityCard = data;
    });
  }



}
