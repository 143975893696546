import { Component, OnInit,OnDestroy, Input, ViewChild } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';


const queryDispositivosRaiz = gql`
  query queryDispositivosRaiz{
    bbddRaiz{
      uuid
      nombre
      descripcion
      estructuraCantidad
      tiene {
        to {
          stats_count
        }
      }
    }
  } `;


interface arrayDato {
  to: {
    stats_count: number;
  }
}

interface dispositivosRaiz {
  uuid: string;
  nombre: string;
  descripcion: string;
  estructuraCantidad: number;
  tiene: [arrayDato]
 
};

interface DispositivosRaiz {
  bbddRaiz: dispositivosRaiz[];
}




@Component({
  selector: 'app-bbdd',
  templateUrl: './bbdd.component.html',
  styleUrls: ['./bbdd.component.scss']
})



export class BbddComponent implements OnInit {
  public querySubscription: any;
  @Input() titleSection: string;

  dispRaiz: dispositivosRaiz[];
  p: number = 1; // Para la paginacion
  

  displayedColumns: string[] = ['nombre', 'descripcion', 'uuid', 'cantidadDatos','dispEnEstructura', 'botones'];
  dataSource: MatTableDataSource<dispositivosRaiz>;
  loading = true;



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(
    private apollo: Apollo,
    private toastrservice: ToastrService
  ) {}

  ngOnInit() {
    // Para la paginacion
    

    // Funcion que va a guardar todas las 
    this.querySubscription = this.apollo
      .query<DispositivosRaiz>({
        query: queryDispositivosRaiz
      })
      .subscribe(({ data, loading }) => {
        this.dispRaiz = data.bbddRaiz;
        this.dataSource = new MatTableDataSource(this.dispRaiz);
        this.dataSource.paginator = this.paginator;
        this.loading = loading;
        this.querySubscription.unsubscribe();
      });
    
    

  }
  // Para filtrar la tabla:
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();  
  }

  copiado(event: Event) {
    if (event) {
      this.toastrservice.info('', 'uuid copiado al portapapeles',
        { closeButton: true, timeOut: 2000 });
    }
  } 

  ngOnDestroy() { 
    this.querySubscription.unsubscribe()
  } 

}
