import { PopUpComponent } from 'src/app/components/utilidades/ventana-dsp/pop-up.component';
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";
import { Apollo } from "apollo-angular";
import { QueryDatos, QueryEstructura } from "./querys/querys";
import {
  DialogData,
  Dispositivo,
  Dato,
  TipoDato
} from "./interfaces/interfaces";

@Component({
  selector: "app-navegacion-dependencias",
  templateUrl: "./navegacion-dependencias.component.html",
  styleUrls: ["./navegacion-dependencias.component.scss"]
})
export class NavegacionDependenciasComponent implements OnInit, OnDestroy {
  private subscrip: Subscription[] = [];
  public hayFabrica = false;
  public mas = false;
  public dsps: Dispositivo[] = [];
  public nivel: Dispositivo[] = [];
  public datos: Dato[] = [];
  public tipoDatos: TipoDato[] = [];
  public haydatosUiid = "";
  public uuidDispRaiz = '';

  constructor(
    private apollo: Apollo,
    public dialogRef: MatDialogRef<NavegacionDependenciasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeFabrica(ds) {
    this.nivel = [];
    this.nivel.push(ds);
    const qestru: Subscription = this.apollo
      .query<any>({
        query: QueryEstructura,
        variables: { uuid: ds.uuid },
        fetchPolicy: 'network-only'
      })
      .subscribe(({ data, loading }) => {
        let datos = data.getDispositivo;
        this.dsps = [];
        if (datos) {
          datos.estructura.map(d => this.dsps.push(d));
        } else {
            this.dsps.push({
                uuid:datos.uuid,
                nombre: datos.nombre
            })
        }
        this.uuidDispRaiz = datos.uuid;
        this.hayFabrica = true;
      });
    this.subscrip.push(qestru);
    this.haydatosUiid = "";
  }

  dispositivoClick(ds) {
    const qdis: Subscription = this.apollo
      .query<any>({
        query: QueryEstructura,
        variables: { uuid: ds.uuid },
        fetchPolicy: 'network-only'
      })
      .subscribe(({ data, loading }) => {
        let datos = data.getDispositivo
        if (datos.estructuraCantidad > 0) {
          this.nivel.push(ds);
          this.dsps = [];
          datos.estructura.map(d => this.dsps.push(d));
          this.mas = true;
        } else {
          datos.estructura.map(d => this.dsps.push(d))
            this.mas = false;
        }
      });
    this.subscrip.push(qdis);
    this.haydatosUiid = "";
  }

  cambioNivel(ds) {
    const idxs = this.nivel.keys();
    let k = 0;
    for (const idx of idxs) {
      if (this.nivel[idx].uuid === ds.uuid) {
        k = idx + 1;
        break;
      }
    }
    const dif = this.nivel.length - k;
    this.nivel.splice(k, dif);
    const qnivel: Subscription = this.apollo
      .query<any>({
        query: QueryEstructura,
        variables: { uuid: ds.uuid },
        fetchPolicy: 'network-only'
      })
      .subscribe(({ data, loading }) => {
        let datos = data.getDispositivo;
        if (datos.estructuraCantidad > 0) {
          this.dsps = [];
          datos.estructura.map(d => this.dsps.push(d));
          this.mas = true;
        } else {
          this.mas = false;
        }
      });
    this.subscrip.push(qnivel);
    this.haydatosUiid = "";
  }

  datosClick(ds) {
    if (ds.uuid === this.haydatosUiid) {
      this.haydatosUiid = "";
    } else {
      const qnivel: Subscription = this.apollo
        .query<any>({
          query: QueryDatos,
          variables: { d_uuid: ds.uuid },
          fetchPolicy: 'network-only'
        })
        .subscribe(({ data, loading }) => {
          let datos = data.getDatosFromDevice;
          if (datos.length > 0) {
            this.datos = [];
            this.tipoDatos = [];
            datos.map(d => {
              this.datos.push({
                uuid: d.uuid,
                periodo: d.periodo,
                descripcion: d.descripcion
              });
              this.tipoDatos.push({
                uuid: d.es.uuid,
                nombre: d.es.nombre,
                unidad: d.es.unidad
              });
            });
            this.haydatosUiid = ds.uuid;
          } else {
            this.haydatosUiid = "";
          }
        });
      this.subscrip.push(qnivel);
    }
  }

  haydatos(uuid) {
    if (this.haydatosUiid === uuid) {
      return true;
    } else {
      return false;
    }
  }
  ngOnDestroy() {
    this.subscrip.map(qs => qs.unsubscribe());
  }
}
