import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface DashboardDeleted {
   dashboardUUID: string;
   usuario: string;
}

export class RemoveDashboardBuilder {
    private _DASHBOARDUUID: string;
    private _USUARIOUUID: string;

    private get _mutation() {
        return gql`mutation deleteDashboard($usuario: String, $dashboardUUID: String) {
            deleteDashboard(usuario: $usuario, uuidDashboard: $dashboardUUID) {
              uuid
            }
          }`;
    }

    public withUserUUID(usuario: string): this {
        this._USUARIOUUID = usuario;
        return this;
    }

    public withDashboardUUID(dashboardUUID: string): this {
        this._DASHBOARDUUID = dashboardUUID;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: DashboardDeleted  = { usuario: this._USUARIOUUID, dashboardUUID: this._DASHBOARDUUID };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
