import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'uniqueDataTypeByCode',
    pure: false
})

export class UniquePipe implements PipeTransform {
    transform(value: any): any {
        if (value !== undefined && value !== null) {
            return value.reduce((td, x) => td.concat(td.find(y => y.codigo === x.codigo) ? [] : [x]), []);
        }
        return value;
    }
}