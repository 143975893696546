import { Component, OnInit, AfterContentChecked, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/services/widgets/dashboards.service';
import { MatDialog } from '@angular/material/dialog';
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { LoginService } from 'src/app/services/login/login.service';
import { NewDashbordModel } from 'src/app/models/dashboard/newDashboard.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

interface DashboardForm {
  name: string;
}

declare function appendButton(): void;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, AfterContentChecked {
  selected = new FormControl(0);
  public userDashboards: Array<NewDashbordModel> = [];
  loading: boolean;
  loadingText = 'LOADING.DASHBOARD';
  suscriptionCollecion: Subscription;
  dashboardSuscription: Subscription;

  @HostListener('click', ['$event'])
  onClick(event): void {
    if (event) {
      if (event.target) {
        if (event.target.parentNode) {
          if (event.target.parentNode.id === 'buttonTest') {
            this.addDashboard(false);
          }
        }
      }
    }
  }

  constructor(
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private translateService: TranslateService) {
    if (localStorage.getItem('tabSelected') === '') {
      localStorage.setItem('tabSelected', this.selected.value.toString());
    }
  }
  ngOnInit() {
    this.getDashboards();
    // tslint:disable-next-line: radix
    this.selected.setValue(parseInt(localStorage.getItem('tabSelected')));

  }
  ngAfterContentChecked() {
    appendButton();
  }

  addDashboard(noDashboard: boolean) {
    const dialogRef = this.dialog.open(DashboardFormComponent, {
      data: { title: 'DASHBOARD.ADD_DASHBOARD' }
    });
    dialogRef.afterClosed().subscribe((result: DashboardForm) => {
      if (!_.isEmpty(result) && !_.isEmpty(result.name)) {
        this.dashboardService.createDashboard(this.loginService.currentUserValue.uuid, result.name).subscribe((data: any) => {
          if (data !== null) {
            const newDashboard: NewDashbordModel = new NewDashbordModel(data.createDashboard.uuid, data.createDashboard.nombre, [], []);
            this.userDashboards.unshift(newDashboard);
            setTimeout(() => {
              this.selected.setValue(-1);
            }, 200);
          }
        });
        if (noDashboard) {
          appendButton();
        }
      }
    });

  }

  deleteDashboard(dashboardToRemove: NewDashbordModel) {
    const opt = {
      title: dashboardToRemove.name,
      message:  'CONFIRM_MODAL.MESSAGE.QUESTION_DASHBOARD',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: opt
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboardService.removeDashboard(this.loginService.currentUserValue.uuid, dashboardToRemove.uuid).subscribe((data: any) => {
          if (data.deleteDashboard !== null) {
            this.translateService.get('DASHBOARD.DASHBOARD_DELETED_SUCCESFULLY').subscribe(data => this.toastrService.success("", data))
            this.userDashboards.remove(dashboardToRemove);
          }
          if (data.errors) {
            this.translateService.get('DASHBOARD.DASHBOARD_DELETE_ERROR').subscribe(data => this.toastrService.error("", data))
          }
        });
      }
    });
  }

  unsubscribeFromDashboard(dashboardToRemove: NewDashbordModel) {
    const opt = {
      title: dashboardToRemove.name,
      message: 'CONFIRM_MODAL.MESSAGE.QUESTION_DASHBOARD_UNSUBSCRIBE',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: opt
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dashboardService.unsubscribeFromDashboard(this.loginService.currentUserValue.uuid, dashboardToRemove.uuid).subscribe((data: any) => {
          console.log('data en unsubscribe: ',data);
          if (data.unsubscribeFromDashboard !== null && !data.errors) {
            this.translateService.get('DASHBOARD.DASHBOARD_DELETED_SUCCESFULLY').subscribe(data => this.toastrService.success("", data))
            this.userDashboards.remove(dashboardToRemove);
          } 
          if (data.errors) {
            this.translateService.get('DASHBOARD.DASHBOARD_DELETE_ERROR').subscribe(data => this.toastrService.error("", data))
          }
        });
      }
    });
  }



  editDashboard(dashboard: NewDashbordModel) {
    const dialogRef = this.dialog.open(DashboardFormComponent, {
      data: { title: 'DASHBOARD.EDIT_DASHBOARD', info: dashboard },
      minWidth: 270
    });

    dialogRef.afterClosed().subscribe((result: DashboardForm) => {
      if (!_.isEmpty(result) && !_.isEmpty(result.name)) {
        this.dashboardService.updateDashboard(dashboard.uuid, result.name).subscribe((data: any) => {
          if (data !== null) {
            this.userDashboards.map((item: NewDashbordModel) => {
              if (item.uuid === dashboard.uuid) {
                item.name = result.name;
              }
            });
          }
        });
      }
    });
  }


  getDashboards() {
    this.loading = true;
    this.dashboardSuscription = this.dashboardService.findAllDashboardsByUser(this.loginService.currentUserValue.uuid).subscribe(data => {
      this.userDashboards = data;
      this.loading = false;
    });
  }

  refreshDashboard($event: boolean) {
    this.dashboardSuscription.unsubscribe();
    this.getDashboards();
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selected.setValue(event.index);
    localStorage.setItem('tabSelected', this.selected.value.toString());
    appendButton();
    this.getDashboards();
  }
}
