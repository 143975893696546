import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { State, ListItemSelectable, ListItem } from 'src/app/models/contract/list-item.model';
import { DeviceGraphItem } from 'src/app/models/device-graph.model';
@Component({
  selector: 'app-graphis-devices-hierarchi',
  templateUrl: './graphis-devices-hierarchi.component.html',
  styleUrls: ['./graphis-devices-hierarchi.component.scss']
})
export class GraphisDevicesHierarchiComponent implements OnInit {
  @Input() title: string;
  @Input() deviceChildrenList: ListItemSelectable[];
  @Input() loadingChildren: boolean;
  @Input() isRoot: boolean;

  deviceSettings: DeviceListSettings =
    {
      titleType: 'factory',
      actionIcon: 'add-circle',
      selectable: State.selectable
    }
    public itemObservable: ListItemSelectable[];
    @Output() deviceItemSelected: EventEmitter<DeviceGraphItem> = new EventEmitter<DeviceGraphItem>();
    @Output() deviceItemUnSelected: EventEmitter<DeviceGraphItem> = new EventEmitter<DeviceGraphItem>();
    @Output() deviceItemNavegate: EventEmitter<DeviceGraphItem> = new EventEmitter<DeviceGraphItem>();
    @Output() goBackOnDevices: EventEmitter<ListItem> = new EventEmitter<ListItem>();

    constructor() { }

    ngOnInit() {
      this.itemObservable = this.deviceChildrenList;
    }

    ngOnChanges(changes: any): void {
      if (changes.deviceChildrenList !== undefined) {
        this.itemObservable = changes.deviceChildrenList.currentValue;
      } else {
        if (changes.loadingChildren !== undefined) {
          this.loadingChildren = changes.loadingChildren.currentValue;
        }
      }

    }

    public goBack() {
      this.goBackOnDevices.emit();
    }

    public selectedItem(item: DeviceGraphItem) {
      this.deviceItemSelected.emit(item);
    }

    public unSelectedItem(item: DeviceGraphItem) {
      this.deviceItemUnSelected.emit(item);
    }

    public navigate(item: DeviceGraphItem) {
      this.deviceItemNavegate.emit(item);
    }
}
