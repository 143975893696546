
import gql from 'graphql-tag';

// // prueba
export const sourceRezagados = gql`
query sourceRezagados($gwuuid: String, $time: Float) {
        sourceRezagados(gwuuid: $gwuuid, time: $time) 

}`

// Obtenemos los elementos que tienen el campo ping:

export const elemPing = gql`
query LMping{
  lmpings {
    uuid
    host
    alive {
      lastEntrada {
        lastFecha
        lastValor
      }
      tiene {
        nombre
      }
    }
  }
}`




// Obtenemos la estructura de los gateway raiz:
// Falta añadir algún campo pero tengo que mirarlos
// porque dan todos a null, algo hay mal calculado 
// posiblemente.
export const gatewaysRaiz = gql`
query gatewaysRaiz {
  gatewaysRaiz{
    nombre
    uuid
    sourcesTotal
    last {
      nombre
      lastEntrada  {
        last
      }
    }
  }
  }
`


// Obtenemos la estructura desde el gateway que estamos navegando
// export const dataField = gql`
// query Gateway($uuid: ID) {
//     Gateway(uuid: $uuid) {
//             uuid
//     nombre
//     topic
//     last
//     lastFecha
//     labels
//     gateways {
//       to {
//         uuid
//         Gateway {
//           last
//           lastFecha
//           uuid
//           nombre
//           topic
//           sources {
//             Source {
//               uuid
//               nombre
//               topic
//             }
//           }
//           labels
//         }
//       }
//     	from {
//         Gateway {
//           uuid
//           nombre
//           topic
//           sources {
//             Source {
//               uuid
//               nombre
//               topic
//             }
//           }
//           labels
//         }
//       }
//     }
// 		sources {
//       uuid
//       Source {
//         uuid
//         nombre
//         topic
//       }
//     }

//     }
// }`;


export const dataField = gql`
query Gateway($uuid: ID) {
  findGateway (uuid: $uuid) {
    nombre
    topic
    last {
      lastEntrada {
        lastFecha
        lastValor
      }
    }
    labels
    gateways {
      to {
        uuid
        nombre
        topic
        sources {
          uuid
          to {
            uuid
            nombre
            topic
          }
        }
      }
      from {
        uuid
        nombre
        topic
        sources {
          uuid
          to {
            uuid
            nombre
            topic
          }
        }
      }
    }
    sources {
      uuid
      to {
        uuid
        nombre
        topic
        modelo
      }
    }
  
  }
}`;


// Atributos de Label
export const atributosGateway = gql`
query Gateway($uuid: ID) {
  findGateway(uuid: $uuid) {
    nombre
    topic
  }
}`;
export const atributosDispositivo = gql`
query Dispositivo($uuid: String) {
    findDispositivo(uuid: $uuid) {
      nombre
      descripcion
      nombreTecnico
    }
}`;
export const atributosLMPing = gql`
query LMPing($uuid: String) {
  findLmPing(uuid: $uuid) {
    host
    }
}`;
export const atributosMbBus = gql`
query MbBus($uuid: ID) {
    MbBus(uuid: $uuid) {
      nombre
      path
      baudRate
      parity
      dataBits
      stopBits
      timeout
      wait
    }
}`;

export const atributosTcpBus = gql`
query TcpBus($uuid: ID) {
    TcpBus(uuid: $uuid) {
      ip
      mask
    }
}`;

export const atributosTcpDevice = gql`
query TcpDevice($uuid: ID) {
    TcpDevice(uuid: $uuid) {
      nombre
      host
    }
}`;

export const atributosMbRtuDevice = gql`
query MbRtuDevice($uuid: ID) {
    MbRtuDevice(uuid: $uuid) {
      nombre
      path
      baudRate
      parity
      dataBits
      stopBits
    }
}`;

export const updateDispositivo = gql`
mutation($uuid: String!, $nombre: String!, $descripcion: String, $nombreTecnico: String) {
  updateDispositivo(uuid: $uuid, nombre: $nombre, descripcion: $descripcion,nombreTecnico: $nombreTecnico) {
    uuid
    nombre
    descripcion
    nombreTecnico
  }
}`;

export const updateGateway = gql`
mutation($uuid: ID!, $nombre: String!, $topic: String) {
  UpdateGateway(uuid: $uuid, nombre: $nombre, topic: $topic) {
    uuid
    nombre
    topic
  }
}`;

export const updateLMping = gql`
mutation($uuid: ID!, $nombre: String!, $host: String) {
  UpdateLMping(uuid: $uuid, nombre: $nombre, host: $host) {
    uuid
    nombre
    host
  }
}`;

export const updateTcpBus = gql`
mutation($uuid: ID!, $nombre: String!, $Ip: String, $mask:String) {
  UpdateTcpBus(uuid: $uuid, nombre: $nombre, Ip: $Ip, mask: $mask) {
    uuid
    nombre
    Ip
    mask
  }
}`;

export const updateTcpDevice = gql`
mutation($uuid: ID!, $nombre: String!, $host: String) {
  UpdateTcpDevice(uuid: $uuid, nombre: $nombre, host: $host) {
    uuid
    nombre
    host
  }
}`;

export const updateMbBus = gql`
mutation($uuid: ID!, $nombre: String!, $path: String, $baudRate: Int, $parity:String,
  $dataBits: Int, $stopBits: Int, $timeout : Int, $wait: Int
  ) {
    UpdateMbBus(uuid: $uuid, nombre: $nombre, path: $path, baudRate: $baudRate, parity: $parity
      dataBits: $dataBits, stopBits: $stopBits, timeout: $timeout, wait: $wait) {
        uuid
        nombre
        path
        baudRate
        parity
        dataBits
        stopBits
        timeout
        wait
  }
}`;

export const updateMbDevice = gql`
mutation($uuid: ID!, $nombre: String!, $mbit: String) {
  UpdateMbDevice(uuid: $uuid, mbit: $mbit) {
    uuid
    mbid
  }
}`;

export const updateMbRtuDevice = gql`
mutation($uuid: ID!, $nombre: String!, $path: String, $baudRate: Int, $parity:String,
  $dataBits: Int, $stopBits: Int
  ) {
    UpdateMbBus(uuid: $uuid, nombre: $nombre, path: $path, baudRate: $baudRate, parity: $parity
      dataBits: $dataBits, stopBits: $stopBits) {
        uuid
        nombre
        path
        baudRate
        parity
        dataBits
        stopBits
  }
}`;
// Fin atributos Label

// Solo los gateways en el campo 'to'
// export const gatewaysTo = gql`
// query Gateway($uuid: ID) {
//     Gateway(uuid: $uuid) {
//         nombre
//         uuid
//         gateways {
//           to {
//             Gateway {
//               last
//               lastFecha
//               nombre
//               topic
//               uuid
//             }
//           }
//         }
//     }
// }`;

export const gatewaysTo = gql`
query Gateway($uuid: ID) {
  findGateway(uuid:$uuid) {
    nombre
    uuid
    gateways {
      to {

          nombre
          topic
          uuid
          sourcesTotal
          last {
            lastEntrada {
              last
            }
          }
          
        
      }
    }
}
}`;



// Solo los gateways en el campo 'from'
export const gatewaysFrom = gql`
query Gateway($uuid: ID) {
  findGateway(uuid: $uuid) {
    nombre
    uuid
    gateways {
      from {
        
          uuid
          nombre
        
      }
    }
}
}`;


// Para extraer los sources y entradas
export const sources = gql`
query Gateway($uuid: ID) {
  findGateway(uuid: $uuid) {
        sources {
            to {
              nombre
              topic
              uuid
              entradas {
                topic
                last
                lastFecha
                lastValor
              }
            }
          }
    }
}`;


// Para obtener las entradas que más adelante se vincularán con un dato
export const entradas = gql`
query Source($uuid: String) {
    findSource(uuid: $uuid) {
        uuid
        nombre
        topic
        modelo
        entradas {
            topic
            last
            lastFecha
            lastValor
            to {
                uuid
                es {
                    nombre
                    nombreCode
                    descripcion
                }
                tiene {
                  uuid
                  nombre
                  labels
                }
            }
        }
    } 
}`




// Extraemos información sobre LMping!
export const LMping = gql`
query LMping($uuid: String) {
  findLmPing(uuid: $uuid) {
    uuid
    host
   labels
    alive {
      uuid
      lastFecha
      lastValor
      lastEntrada  {
        uuid
        lastFecha
        lastValor
        topic
      }
    }
  }
}
`





