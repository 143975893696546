import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Budget } from 'src/app/models/budget.model';
import { HttpClient } from '@angular/common/http';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';
import { PureQueryOptions, QueryOptions, MutationOptions } from 'apollo-client';
import * as lodash from 'lodash';
import { AllUserGraphQLBuilder } from 'src/app/models/graphQL/all-user-graphQL.request';
import { AllDispositivosRaizBuilder } from 'src/app/models/graphQL/allDispositivosRaiz.request';
import { AllDispositivosRaizResponseGraphQL } from 'src/app/models/graphQL/allDispositivosRaiz.response';
import { FactorySimpleModel } from 'src/app/models/FactorySimple.model';
import { UserModel, UserDashboardModel } from 'src/app/models/userModel';
import { AllUserResponse, UserWithDashboardResponse } from 'src/app/models/graphQL/all-user.response';
import { RemoveUserBuilder } from 'src/app/models/graphQL/mutations/removeUser.mutation';
import { ChangeUserPasswordBuilder } from 'src/app/models/graphQL/mutations/changeUserPassword.mutation';
import { ChangeUserStateBuilder } from 'src/app/models/graphQL/mutations/ChangeUserState.mutation';
import { CreateUserBuilder } from 'src/app/models/graphQL/mutations/CreateUser.mutation';
import { AddFactoryToUserBuilder } from 'src/app/models/graphQL/mutations/addFactoryToUser.mutation';
import { EditUserBuilder } from 'src/app/models/graphQL/mutations/EditUser.mutation';
import { RemoveFactoryToUserBuilder } from 'src/app/models/graphQL/mutations/removeFactoryToUser.mutation';
import { UsersDashboardsBuilder } from 'src/app/models/graphQL/usersWithDashboards.request';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userCollectionBS: BehaviorSubject<UserModel[]>;
  public userCollectionObservable: Observable<UserModel[]>;
  private budgetCollectionBS: BehaviorSubject<Budget[]>;
  public budgetCollectionObservable: Observable<Budget[]>;

  constructor(private http: HttpClient, private apolloService: ApolloGraphQlService) {
    this.userCollectionBS = new BehaviorSubject<UserModel[]>(new Array<UserModel>());
    this.userCollectionObservable = this.userCollectionBS.asObservable();
    this.budgetCollectionBS = new BehaviorSubject<Budget[]>(new Array<Budget>());
    this.budgetCollectionObservable = this.budgetCollectionBS.asObservable();
  }

  public getBudgetCollection(): Observable<Budget[]> {
    this.userCollectionObservable.subscribe(data => {
      let budgetCollection: Budget[] = [];
      budgetCollection = [
        { uuid: '1', name: 'USER.TOTAL', value: data.length, selected: true },
        { uuid: '2', name: 'USER.ACTIVATED', value: data.filter(item => item.state === 'Activado').length, selected: false },
        { uuid: '3', name: 'USER.DEACTIVATED', value: data.filter(item => item.state === 'Desactivado').length, selected: false }
      ];
      this.budgetCollectionBS.next(budgetCollection);
    });
    return this.budgetCollectionObservable;
  }

  getAllDispositivosRaiz(): Observable<Array<FactorySimpleModel>> {
    const queryOptions: QueryOptions = new AllDispositivosRaizBuilder()
      .build();
    const allFactoryCollection = this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toFactorySimpleModelResponse);
    if (!lodash.isEmpty(allFactoryCollection)) {
      return allFactoryCollection;
    }
  }

  private toFactorySimpleModelResponse(factoryCollectionFromGraphQL: AllDispositivosRaizResponseGraphQL): Array<FactorySimpleModel> {
    const auxAlarmTypeCollection: FactorySimpleModel[] = [];
    for (const factory of factoryCollectionFromGraphQL.fabricas) {
      const newFactory = new FactorySimpleModel();
      newFactory.uuid = factory.uuid;
      newFactory.name = factory.nombre;
      newFactory.selected = false;
      auxAlarmTypeCollection.push(newFactory);
    }
    return auxAlarmTypeCollection;
  }

  public getUserCollectionReal(): Observable<UserModel[]> {
    const queryOptions: PureQueryOptions = new AllUserGraphQLBuilder()
      .build();
    this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toUserModelCollection).subscribe(data => {
      this.userCollectionBS.next(data);
    });
    return this.userCollectionObservable;
  }

  public getUserCollectionWithDashboard(): Observable<UserDashboardModel[]> {
    const queryOptions: PureQueryOptions = new UsersDashboardsBuilder()
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toUserWithDashboardModel);
  }

  private toUserWithDashboardModel(userWithDashboardResponse: UserWithDashboardResponse): UserDashboardModel[] {
    const userWithDashboardCollection: UserDashboardModel[] = [];
    for (const user of userWithDashboardResponse.usuarios) {
      const newUser: UserDashboardModel = new UserDashboardModel();
      newUser.uuid = user.uuid;
      newUser.name = user.nombre;
      newUser.user = user.usuario;
      newUser.dashboards = user.dashboards;
      userWithDashboardCollection.push(newUser);
    }
    return userWithDashboardCollection;
  }

  private toUserModelCollection(userCollectionResult: AllUserResponse): UserModel[] {
    const userCollection: UserModel[] = [];
    for (const user of userCollectionResult.usuarios) {
      const newUser = new UserModel();
      newUser.uuid = user.uuid;
      newUser.name = user.nombre;
      newUser.email = user.mail;
      newUser.firstName = user.apellidos;
      newUser.language = user.idioma;
      newUser.timeZone = user.zonaHoraria;
      newUser.user = user.usuario;
      newUser.rol = user.rol;
      if (user.enabled) {
        newUser.state = 'Activado';
      } else {
        newUser.state = 'Desactivado';
      }
      newUser.factories = [];
      for (const factory of user.dispositivos) {
        const auxFactory: FactorySimpleModel = new FactorySimpleModel();
        auxFactory.name = factory.to.nombre;
        auxFactory.uuid = factory.to.uuid;
        newUser.factories.add(auxFactory);
      }
      userCollection.push(newUser);
    }
    return userCollection;
  }

  public updateCollection(newCollection: UserModel[]) {
    this.userCollectionBS.next(newCollection);
  }

  removeUser(userUUID: string) {
    const mutationOptions: MutationOptions = new RemoveUserBuilder()
      .withUserUUID(userUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  changePasswordUser(user: string, oldPassword: string, newPassword: string) {
    const mutationOptions: MutationOptions = new ChangeUserPasswordBuilder()
      .withUser(user)
      .withOldPassword(oldPassword)
      .withNewPassword(newPassword)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }
  updateUserState(userUUID: string, state: string, usuario: string, nombre: string, apellidos: string, zonaHoraria: string, idioma: string, mail: string, rol:string[]) {
    let auxEnabled = true;
    if (state === 'Activado') {
      auxEnabled = false;
    }
    const mutationOptions: MutationOptions = new ChangeUserStateBuilder()
      .withUserUUID(userUUID)
      .withUserEnabled(auxEnabled)
      .withUser(usuario)
      .withName(nombre)
      .withSurnames(apellidos)
      .withTimeZone(zonaHoraria)
      .withLanguage(idioma)
      .withEmail(mail)
      .withRoles(rol)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  createUser(user: UserModel) {
    let auxEnabled = true;
    if (user.state === 'Desactivado') {
      auxEnabled = false;
    }
    const mutationOptions: MutationOptions = new CreateUserBuilder()
      .withName(user.name)
      .withUser(user.user)
      .withMail(user.email)
      .withFirstName(user.firstName)
      .withLanguage(user.language)
      .withTimeZone(user.timeZone)
      .withRol(user.rol)
      .withEnabled(auxEnabled)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  editUser(user: UserModel) {
    let auxEnabled = true;
    if (user.state === 'Desactivado') {
      auxEnabled = false;
    }
    const mutationOptions: MutationOptions = new EditUserBuilder()
      .withUUID(user.uuid)
      .withName(user.name)
      .withUser(user.user)
      .withMail(user.email)
      .withFirstName(user.firstName)
      .withLanguage(user.language)
      .withTimeZone(user.timeZone)
      .withRol(user.rol)
      .withEnabled(auxEnabled)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  addFactoryToUser(userUUID: string, factoryUUID: string) {
    const mutationOptions: MutationOptions = new AddFactoryToUserBuilder()
      .withUser(userUUID)
      .withFactory(factoryUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  removeFactoryUser(userUUID: string, factoryUUID: string) {
    const mutationOptions: MutationOptions = new RemoveFactoryToUserBuilder()
      .withUser(userUUID)
      .withFactory(factoryUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }
}
