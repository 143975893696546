import {  Component,  OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import {  Observable, Subscription } from 'rxjs';
import { Device } from 'src/app/models/device.model';
import { DeviceTabCollection } from 'src/app/models/device/tab/device-tab.collection.model';
import { DeviceTab } from 'src/app/models/device/tab/device-tab.model';
import { FactoryCollection } from 'src/app/models/factory/factory.collection.model';
import { FactoryModel } from 'src/app/models/FactoryModel';
import { FactoryDetailGraphQLResponse } from 'src/app/models/graphQL/factory-graphQL.respponse';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { FactoriesService } from 'src/app/services/factories/factories.service';

@Component({
  selector: 'app-ultimas-mediciones',
  templateUrl: './ultimas-mediciones.component.html',
  styleUrls: ['./ultimas-mediciones.component.scss']
})
export class UltimasMedicionesComponent implements OnInit {

  mobileMode = false;
  loading = false;
  loadingText = 'Loading '
  
  private readonly differencePropertyFilter: string = 'uuid';
  results: Observable<Array<Device>>;
  tabsSelected: DeviceTabCollection;
  tabSelected = new FormControl(0);
  factorySuscription: Subscription;
  factoryTest: FactoryCollection;

  constructor(
    private factoriesService: FactoriesService,
    private dispositivoService: DispositivoService,
  ) {
    this.tabsSelected = new DeviceTabCollection(dispositivoService);
   }

  ngOnInit() {

    this.results = this.dispositivoService.deviceCollectionObservable;
    this.factorySuscription = this.factoriesService.factorySelectedObservable.pipe().
      subscribe(factorySelected => {
        // console.log(this.factorySelectedManage(factorySelected))
        this.factorySelectedManage(factorySelected);
      });
  }

  
  private factorySelectedManage(factorySelected: FactoryCollection) {
    this.factoryTest = factorySelected;
    if (factorySelected === null || factorySelected === undefined) {
      return;
    }

    const resultCompare: number = factorySelected.compareToLynxCollection(this.tabsSelected);

    if (resultCompare > 1) {
      const difFactories = _.differenceBy(factorySelected.collection, this.tabsSelected.collection, this.differencePropertyFilter);

      for (let i = 0; i < difFactories.length; i++) {
        this.dispositivoService.getDeviceDetailGraphQl(difFactories[i].uuid).subscribe((item: Device[]) => {
          const deviceDetail = new DeviceTab(difFactories[i]);
          deviceDetail.fromDevice(item[0]);
          this.dispositivoService.getDeviceChildrenGraphQl(difFactories[i].uuid).subscribe(childrenItem => {
            childrenItem.removeBy(childItem => childItem.uuid === deviceDetail.currentDeviceDetail.uuid);
            deviceDetail.currentDeviceDetail.children = childrenItem;
            this.tabsSelected.addItem(deviceDetail);
          }
          );
        }
        );
      }
    }

    if (resultCompare === 1) {
      const diffItem = _.differenceBy(factorySelected.collection, this.tabsSelected.collection, this.differencePropertyFilter);

      this.dispositivoService.getDeviceDetailGraphQl(diffItem[0].uuid).subscribe((item: Device[]) => {
        const deviceDetail = new DeviceTab(diffItem[0]);
        deviceDetail.fromDevice(item[0]);

        this.dispositivoService.getDeviceChildrenGraphQl(diffItem[0].uuid).subscribe(childrenItem => {
          // tslint:disable-next-line: no-shadowed-variable
          childrenItem.removeBy(item => item.uuid === deviceDetail.currentDeviceDetail.uuid);
          deviceDetail.currentDeviceDetail.children = childrenItem;
          this.tabsSelected.addItem(deviceDetail);
        }
        );
      }
      );
    } else if (resultCompare === -1) {
      const removeDiffItem = _.differenceBy(this.tabsSelected.collection, factorySelected.collection, 'uuid');
      this.tabsSelected.removeItem(removeDiffItem[0]);
    }

  }

  public closeTab(tab: DeviceTab) {
    this.tabsSelected.removeItem(tab);
    this.tabsSelected = this.tabsSelected;

    const item: FactoryDetailGraphQLResponse = { uuid: tab.uuid, nombre: tab.name, descripcion: tab.name };
    const closeFactory: FactoryModel = new FactoryModel(item);

    this.removeFactoryCollection(closeFactory);
  }

  private removeFactoryCollection(selectedFactory: FactoryModel) {
    let removeFactoryCollection = new FactoryCollection();
    this.factoriesService.factorySelectedObservable.subscribe(data => removeFactoryCollection = data);
    removeFactoryCollection.collection.removeBy(item => item.uuid === selectedFactory.uuid);
    this.factoriesService.newSelectFactoryCollection(removeFactoryCollection);
  }

  changeTabName(tab: DeviceTab) {
    this.tabsSelected.collection.map(item => { if (item.uuid === tab.uuid) { item.name = tab.name; } });
  }
}