import { Injectable } from '@angular/core';
import { PureQueryOptions, MutationOptions } from 'apollo-client';
import { MiUsuarioGraphQlBuilder } from '../../models/graphQL/miUsuario-graphQL.request';
import { ApolloGraphQlService } from './../apollo/apollo-graphql.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { MiUsuarioGraphQLResponse } from 'src/app/models/graphQL/miUsuario-graphQL.response';
import { UpdateUserBuilder } from 'src/app/models/graphQL/mutations/updateUser.mutation';
import { FactoriesService } from '../factories/factories.service';
import { AllUserGraphQLBuilder } from 'src/app/models/graphQL/all-user-graphQL.request';
import { AllUserGraphQLResponse } from 'src/app/models/graphQL/all-user-graphQL.response';
import { AllUser } from 'src/app/models/allUser.model';
import { AuthService } from '../auth/auth.service';
import { JwtResponse } from '../auth/jwt-response';
import { HttpResponse } from '@angular/common/http';
import { first, mergeMap } from 'rxjs/operators';
import { Timezone, TimezoneService } from '../timezone.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isLogged = false;
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  timeZoneCollection: Timezone[] = [];

  constructor(
    private apolloService: ApolloGraphQlService,
    private factoriesService: FactoriesService,
    private authService: AuthService,
    private timezoneService: TimezoneService,
    ) {
    if(localStorage.getItem('loggedUser')!=='undefined'){
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('loggedUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }else{
      this.currentUserSubject = new BehaviorSubject<User>(null);
    }
    
    this.getAllTimezone()
  }

  public getAllUsers() {
    const queryOptions: PureQueryOptions = new AllUserGraphQLBuilder()
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toAllUserModel);
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  public login(user): Observable<HttpResponse<JwtResponse>> {
    return this.authService.auth(user)
  }

  public miUsuario() {
    const queryOptions: PureQueryOptions = new MiUsuarioGraphQlBuilder()      
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toMiUsuarioModel);
  }

  public updateUser(user: User) {
    const mutationOptions: MutationOptions = new UpdateUserBuilder()
      .withUser(user)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  toMiUsuarioModel(responseData: MiUsuarioGraphQLResponse): User {
    let user: User;
    if (responseData.miUsuario !== null) {
      user = new User(responseData);
    }
    return user;
  }

  toAllUserModel(responseData: AllUserGraphQLResponse): User[] {
    const userCollection: User[] = [];
    for (const user of responseData.miUsuario) {
      const newUser: AllUser = new AllUser(responseData);
      userCollection.push(newUser);
    }
    return userCollection;
  }

  logout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.factoriesService.removeAllFactories();
    return this.authService.signOut();
  }

  isAuthenticated(){
    return this.authService.isAuthenticated();
  }

  refresh() {
    return this.authService.verify().pipe(
      mergeMap<HttpResponse<JwtResponse>, any>(verifyResponse => {
        if (verifyResponse.status === 403) {
          return this.authService.refreshToken();
        } else {
          return verifyResponse;
        }
      })
    )
  }

  refreshToken(){
    return this.authService.refreshToken();
  } 

  setMiUsuario(user:User){
    let offset = 0;
      this.setLocalStorage(user);
      if (user.timeZone === null) {
        user.timeZone = 'UTC';
        this.updateUser(user).subscribe(data => {
          if (data !== null) {
          }
        });
      }
      if (user.language === null) {
        user.language = 'es';
        this.updateUser(user).subscribe(data => {
          if (data !== null) {
          }
        });
      }
      offset = this.getOffsetValue(user.timeZone);
      this.timezoneService.setLocale(user.timeZone);
      this.timezoneService.setOffset(offset);
      this.currentUserSubject.next(user);     
  }

  private setLocalStorage(res: User) {
    localStorage.setItem('loggedUser', JSON.stringify(res));
  }

  getOffsetValue(timezone: string): number {
    for (const time of this.timeZoneCollection) {
      for (const utc of time.utc) {
        if (utc === timezone) {
          return time.offset;
        }
      }
    }
  }

  getAllTimezone() {
    this.timezoneService.getTimezoneCollection().subscribe(
      result => this.timeZoneCollection = result
    );
  }

  
}
