import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, ChangeDetectionStrategy, DoCheck } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Alarm } from 'src/app/models/alarm/alarm.model';
import * as _ from 'lodash';
import { UserModel } from 'src/app/models/userModel';

@Component({
  selector: 'app-alarm-table',
  templateUrl: './alarm-table.component.html',
  styleUrls: ['./alarm-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class AlarmTableComponent implements OnInit, OnChanges {
  @Input() elementData: Alarm[];
  @Input() tableUser: boolean;
  @Input() activatedStyle: boolean;
  @Output() selectionRowEmitter: EventEmitter<Alarm> = new EventEmitter<Alarm>();
  @Output() selectionUserRowEmitter: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Output() selectionStateEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteAlarmEmitter: EventEmitter<Alarm> = new EventEmitter<Alarm>();
  @Output() deleteUserEmitter: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Output() editAlarmEmitter: EventEmitter<Alarm> = new EventEmitter<Alarm>();
  @Output() editUserEmitter: EventEmitter<UserModel> = new EventEmitter<UserModel>();
  @Output() changeAlarmStateEmitter: EventEmitter<Alarm> = new EventEmitter<Alarm>();
  @Output() changeUserStateEmitter: EventEmitter<UserModel> = new EventEmitter<UserModel>();

  dataSource: MatTableDataSource<Alarm>;
  columnsToDisplay = ['name', 'subject', 'type', 'param', 'state'];

  constructor() { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.showDataTable();
  }

  ngOnChanges() {
    this.showDataTable();
    // Si añadimos una alarma, debería mostrarse en primer lugar
    // para ello debemos tener un create_date y ordenarlas por esa propiedad
  }

  private showDataTable() {
    if (this.tableUser) {
      this.columnsToDisplay = [];
      this.columnsToDisplay = ['name', 'firstName', 'user', 'email', 'state'];
    }
    this.dataSource = new MatTableDataSource(this.elementData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  emitSelection(selectedObject) {
    this.tableUser ? this.selectionUserRowEmitter.emit(selectedObject) : this.selectionRowEmitter.emit(selectedObject);
  }

  throwDeleteEmitter(objectToDelete) {
    this.tableUser ? this.deleteUserEmitter.emit(objectToDelete) : this.deleteAlarmEmitter.emit(objectToDelete);
  }

  throwEditEmitter(objectToEdit) {
    this.tableUser ? this.editUserEmitter.emit(objectToEdit) : this.editAlarmEmitter.emit(objectToEdit);
  }

  throwChangeStateEmitter(objectTochangeState) {
    this.tableUser ? this.changeUserStateEmitter.emit(objectTochangeState) : this.changeAlarmStateEmitter.emit(objectTochangeState);
  }

  setStateStyles(elementState: string): string {
    if (elementState === 'Activada' || elementState === 'Activado') {
      return 'desactive';
    }
    return 'activeElement';
  }


  // Para filtrar la tabla:
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();  
  }
}
