import {  DataTypeDetailAggregateGraphQlResponse } from './graphQL/data-type-aggegate-GQL.response';
import { DataTypeDetailGraphQlResponse } from './graphQL/data-type-graphQl.response';

export class DataType {
    uuid: string;
    nombre: string;
    descripcion?: string;
    factorDecimal?: string;
    unidad?: string;
    codigo?: string;
    constructor(data: DataTypeDetailGraphQlResponse | DataTypeDetailAggregateGraphQlResponse) {
        if (data === undefined || data === null) {
            return;
        }
        this.uuid = data.uuid;
        this.nombre = data.nombre;
        this.descripcion = data.descripcion;
        this.factorDecimal = data.factorDecimal;
        this.unidad = data.unidad;
    }
}
