
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import gql from 'graphql-tag';

export interface WidgetRemoved {
  dashboardUUID: { uuid: string };
  widgetUUID: { uuid: string };
}

export class RemoveDashboardWidgetBuilder {
  private _DASHBOARDUUID: string;
  private _WIDGETTOREMOVE: string;

  private get _mutation() {
    return gql`mutation borrarDashboardWidget($dashboardUUID: String!, $widgetUUID: String!) {
            removeDashboardWidgets(from:$dashboardUUID, to: $widgetUUID) {
              uuid
            }
          }`;
  }

  public withDashboardUUID(newDashboardUUID: string): this {
    this._DASHBOARDUUID = newDashboardUUID;
    return this;
  }

  public withWidgetUUID(newWidgetToRemove: string): this {
    this._WIDGETTOREMOVE = newWidgetToRemove;
    return this;
  }

  public build() {
    const mutationParams = {
      dashboardUUID: this._DASHBOARDUUID,
      widgetUUID: this._WIDGETTOREMOVE
    };
    const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
    return graphQlMutationBuilder.withMutation(this._mutation)
      .withParams(mutationParams)
      .build();
  }
}
