import { Device, DeviceModel } from '../../device.model';
// tslint:disable-next-line: max-line-length
import { DeviceDetailGraphQLResponse, DeviceDetailDataGQLResponse, DataCategory, DataInterval } from '../../graphQL/device-detail-graphQL.response';
import { DeviceChildrenGraphQLResponse, DeviceChildrenDetailGraphQLResponse } from '../../graphQL/device-children-graphQL.response';
import { DeviceInfo, DeviceConsumptionInfo } from '../../deivce-info';
import * as lodash from 'lodash';
import { DeviceConsumption } from '../../utils/device-consumption.model';
import { DeviceGraphItem, DeviceGraphDataType } from '../../device-graph.model';
import { State } from '../../contract/list-item.model';

export class MapperDevice {

    public static deviceModelToDeviceGraphItem(dataT: DeviceChildrenDetailGraphQLResponse): DeviceGraphItem {
        if (dataT === null || dataT === undefined) {
            return null;
        }

        let auxData: DeviceGraphItem = new DeviceGraphItem();
        auxData.uuid = dataT.uuid;
        auxData.name = dataT.nombre;
        auxData.childrenNumber = dataT.estructuraCantidad;
        auxData.selectedState = State.selectable;
        auxData.dataType = dataT.tiene
            .filter(data => (data.to !== undefined && data.to !== null)
                && (data.to.es !== undefined && data.to.es !== null)
            )
            .map(data => new DeviceGraphDataType(data.to.es));

        return auxData;
    }
    public static deviceDetailGQLResponseToDevice(dataT: DeviceDetailGraphQLResponse): Device {

        if (dataT === null || dataT === undefined) {
            return null;
        }

        let auxDataDetail: Device = new Device(null);
        auxDataDetail.uuid = dataT.uuid;
        auxDataDetail.name = dataT.nombre;
        auxDataDetail.deviceType = dataT.tipo;
        auxDataDetail.info = new DeviceInfo();
        auxDataDetail.info.consumptionInfo = new DeviceConsumptionInfo();
        auxDataDetail.info.consumptionInfo.energyConsumption = MapperDevice.dataDetailToConsumtionInfo(dataT.findDataByCategory(DataCategory.ELECTRYCITY));
        auxDataDetail.info.consumptionInfo.gasConsumption = MapperDevice.dataDetailToConsumtionInfo(dataT.findDataByCategory(DataCategory.GAS));
        auxDataDetail.info.consumptionInfo.productivity = MapperDevice.dataDetailToConsumtionInfo(dataT.findDataByCategory(DataCategory.PRODUCCTION));
        auxDataDetail.info.consumptionInfo.waterConsumption = MapperDevice.dataDetailToConsumtionInfo(dataT.findDataByCategory(DataCategory.WATER));

        return auxDataDetail;
    }

    public static dataDetailToConsumtionInfo(dataConsumtionDetail: DeviceDetailDataGQLResponse[]): DeviceConsumption {
        if (lodash.isEmpty(dataConsumtionDetail)) {
            return null;
        }
        const deviceDataConsumtion = new DeviceConsumption();
        deviceDataConsumtion.monthValue = this.getConsumtionValue(dataConsumtionDetail.firstOrDefault(item => item.isInterval(DataInterval.MONTHLY)));
        deviceDataConsumtion.yearValue = this.getConsumtionValue(dataConsumtionDetail.firstOrDefault(item => item.isInterval(DataInterval.YEARLY)));
        deviceDataConsumtion.accumulatedValue = this.getConsumtionValue(dataConsumtionDetail.firstOrDefault(item => item.isInterval(DataInterval.ACCUMULATED)));
        return deviceDataConsumtion;
    }
    private static getConsumtionValue(dataDetail: DeviceDetailDataGQLResponse): number {
        if (dataDetail === null || dataDetail === undefined || !dataDetail.hasValue()) {
            return null;
        }
        return dataDetail.Dato.stats_count;
    }

    public static deviceChildrenGQLResponseToDevice(dataT: DeviceChildrenGraphQLResponse): Device {

        if (dataT === null || dataT === undefined) {
            return null;
        }

        let auxDataChildren: Device = new Device(null);
        let auxDeviceChildren: Array<DeviceModel> = new Array<DeviceModel>();

        auxDataChildren.uuid = dataT.findEstructura.uuid;
        auxDataChildren.name = dataT.findEstructura.nombre;
        auxDataChildren.children = auxDeviceChildren;

        return auxDataChildren;
    }
}