import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { DataDigitalWidget } from 'src/app/models/digital-widget.model';

@Component({
  selector: 'app-digital-widget',
  templateUrl: './digital-widget.component.html',
  styleUrls: ['./digital-widget.component.scss']
})
export class DigitalWidgetComponent implements OnInit, OnChanges {
  @Input() data: DataDigitalWidget;
  @Input() height: number;
  @Input() width: number;
  @Input() uuid: string;
  @Output() readyRender: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('digitalWidget', {static: false}) digitalWidget: ElementRef;
  @ViewChild('font1', {static: false}) font1: ElementRef;
  @ViewChild('font2', {static: false}) font2: ElementRef;
  @ViewChild('font3', {static: false}) font3: ElementRef;


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(change: SimpleChanges) {
    setTimeout(() => {
      this.animated(change);
      this.resizeDigitalWidget();
    },
    100);
  }

  resizeDigitalWidget() {
    if (this.digitalWidget !== undefined) {
      this.resizeCircle();
      this.resizeFontSize();
    }
  }

  resizeCircle() {
    this.digitalWidget.nativeElement.style.height = (this.height * 0.8) + 'px';
    this.digitalWidget.nativeElement.style.width = (this.width * 0.8) + 'px';
  }

  resizeFontSize() {
    this.font1.nativeElement.style.fontSize = (this.height / 6) + 'px';
    this.font2.nativeElement.style.fontSize = (this.height / 15) + 'px';
    this.font3.nativeElement.style.fontSize = (this.height / 25) + 'px';
  }

  animated(event) {
    this.readyRender.emit(this.uuid);
  }

}
