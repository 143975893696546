import { LoginService } from 'src/app/services/login/login.service';
import { Injectable } from '@angular/core';
import {
  InfluxDbEpoch, InfluxdbService, InfluxDbDeviceParameter,
  InfluxDbHttpResponseWidget, InfluxDbHttpResponseDataType
} from '../influxdb/influxdb.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PeriodType, WidgetType } from 'src/app/models/widgets/widget-interfaces.model';
import { DateRange } from 'src/app/models/dateRange.model';
import moment from 'moment-timezone';
import { Mapper } from '../ngx-chart-mapper/ngx-chart-mapper.service';
import { TimezoneService } from '../timezone.service';
import * as _ from 'lodash';
import { DeviceModel } from 'src/app/models/device.model';


@Injectable({
  providedIn: 'root'
})
export class WidgetMapperService {

  constructor(private influxDbService: InfluxdbService,
    private timezoneService: TimezoneService,
    private loginService: LoginService
  ) { }

  getDeviceDataSource(
    deviceParams: any,
    epochValue: InfluxDbEpoch): Observable<Mapper[]> {

    if (deviceParams.dataType !== undefined && deviceParams.devices !== undefined && epochValue !== undefined) {
      deviceParams.devices = _.compact(deviceParams.devices);
      deviceParams.dataType = _.filter(deviceParams.dataType, _.size);
      const params: InfluxDbDeviceParameter = {
        deviceUUID: deviceParams.devices.map(item => item.uuid).join('\", \"'),
        dataTypeUUID: deviceParams.dataType.map(item => item.uuid).join('\", \"'),
        epoch: epochValue
      };
      if (deviceParams.period &&
        deviceParams.period.clave !== '' &&
        deviceParams.period.valor !== '' &&
        deviceParams.period.cantidad !== '') {
        params.deviceUUID = deviceParams.devices.map(item => item.uuid).join('\", \"');
        const dr = this.periodToDateRange(deviceParams.period);
        params.from = dr.from.unix();
        params.to = dr.to.unix();

        return this.influxDbService.executeQueryDataType(params).pipe(
          map((data: InfluxDbHttpResponseDataType[]) => this.newMapper2(data)));
      } else if (deviceParams.widgetType === WidgetType.TABLE) {
        return this.influxDbService.executeQueryGenericWidgetResponse(params).pipe(
          map((data: InfluxDbHttpResponseWidget) => this.mapperForTableWidget(data)));
      } else if (deviceParams.widgetType === WidgetType.DIGITAL || deviceParams.widgetType === WidgetType.GAUGE) {
        return this.influxDbService.executeQueryGenericWidgetResponse(params).pipe(map((data: InfluxDbHttpResponseWidget) =>
          this.mapperForDigitalAndGauge(data, deviceParams.devices)));
      }
      return this.influxDbService.executeQueryGenericWidgetResponse(params).pipe(
        map((data: InfluxDbHttpResponseWidget) => this.newMapper(data, deviceParams.devices))
      );
    }
  }

  public newMapper2(dataTypeCollection: InfluxDbHttpResponseDataType[]): Mapper[] {
    const myMapperCollection: Mapper[] = [];
    let dataLabel: string[] = [];
    let dataSet: number[] = [];
    let name: string;
    for (const data of dataTypeCollection) {
      for (let i = 1; i < data.columns.length; i++) {
        for (let z = 0; z < data.values.length; z++) {
          if (data.values[z][i] !== null) {
            // dataLabel.push(this.getDatesToString(data.values[z][0]));
            // dataSet.push(data.values[z][i]);
            // name = data.name;
            dataLabel.push(data.values[z][0].toString());
            dataSet.push(data.values[z][i]);
            name = data.name;
          }
        }
        const mapper: Mapper = new Mapper();
        mapper.deviceUUID = data.columns[i];
        mapper.dataLabel = dataLabel;
        mapper.dataSet = dataSet;
        mapper.dataTypeUUID = name;
        myMapperCollection.push(mapper);
        dataSet = [];
        dataLabel = [];
        name = null;
      }
    }
    return myMapperCollection;
  }

  getDatesToString(date: number): string {
    const timezone = this.loginService.currentUserValue.timeZone
    return moment.unix(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
  }

  public newMapper(dataTypeCollection: InfluxDbHttpResponseWidget, deviceCollection: DeviceModel[]): Mapper[] {
    const myMapperCollection: Mapper[] = [];
    let dataLabel: string[] = [];
    let dataSet: number[] = [];
    let name: string;
    if (!_.isEmpty(dataTypeCollection.results[0].series)) {
      for (const data of dataTypeCollection.results[0].series) {
        if (data !== undefined) {
          for (let i = 0; i < deviceCollection.length; i++) {
            for (let z = 0; z < data.values.length; z++) {
              if (data.values[z][i] !== null) {
                dataLabel.push(this.getDatesToString(data.values[z][0]));
                dataSet.push(data.values[z][i]);
                name = data.name;
              }
            }
            const mapper: Mapper = new Mapper();
            mapper.deviceUUID = deviceCollection[i].uuid;
            mapper.deviceName = deviceCollection[i].name;
            mapper.dataLabel = dataLabel;
            mapper.dataSet = dataSet;
            mapper.dataTypeUUID = name;
            myMapperCollection.push(mapper);
            dataSet = [];
            dataLabel = [];
            name = null;
          }
        } else {
          return myMapperCollection;
        }
      }
    }
    return myMapperCollection;
  }

  fillNullsFromNextArray(array: any) {
    // get null indexes from first array:
    let nullIDX = array.reduce(function(a, e, i) {
      if (e === null)
          a.push(i);
      return a;
  }, []);  
  let i = 0;
  for(let elem of nullIDX){


  }

  }

  public mapperForTableWidget(dataTypeCollection: InfluxDbHttpResponseWidget): Mapper[] {
    const myMapperCollection: Mapper[] = [];
    let dataLabel: string[] = [];
    let dataSet: any[] = [];
    let name: string;
    if (!_.isEmpty(dataTypeCollection.results[0].series)) {
      for (const data of dataTypeCollection.results[0].series) {
        if (data !== undefined) {
          for (let i = 1; i < data.columns.length; i++) {
            this. fillNullsFromNextArray(data.values[0])
            for (let z = 0; z < data.values.length; z++) {
              data.values[z][i] !== null ? dataSet.push(data.values[z][i]) : dataSet.push('-');
              dataLabel.push(this.getDatesToString(data.values[z][0]));
              name = data.name;
            }
            const mapper: Mapper = new Mapper();
            mapper.deviceName = data.columns[i];
            mapper.dataLabel = dataLabel;
            mapper.dataSet = dataSet;
            mapper.dataTypeUUID = name;
            myMapperCollection.push(mapper);
            dataSet = [];
            dataLabel = [];
            name = null;
          }
        } else {
          return myMapperCollection;
        }
      }
    }
    return myMapperCollection;
  }

  mapperForDigitalAndGauge(dataCollection: InfluxDbHttpResponseWidget, deviceCollection: DeviceModel[]): Mapper[] {
    const myMapperCollection: Mapper[] = [];
    let dataLabel: string[] = [];
    let dataSet: number[] = [];
    let name: string;
    if (!_.isEmpty(dataCollection.results[0].series)) {
      for (const data of dataCollection.results[0].series) {
        if (data !== undefined) {
          for (let i = 0; i < deviceCollection.length; i++) {
            for (let z = 0; z < data.values.length; z++) {
              if (data.values[z][i] !== null) {
                dataLabel.push(this.getDatesToString(data.values[z][0]));
                dataSet.push(data.values[z][1]);
                name = data.name;
              }
            }
            const mapper: Mapper = new Mapper();
            mapper.deviceUUID = deviceCollection[i].uuid;
            mapper.deviceName = deviceCollection[i].name;
            mapper.dataLabel = dataLabel;
            mapper.dataSet = dataSet;
            mapper.dataTypeUUID = name;
            myMapperCollection.push(mapper);
            dataSet = [];
            dataLabel = [];
            name = null;
          }
        } else {
          return myMapperCollection;
        }
      }
    }
    return myMapperCollection;
  }

  public mapperWithDateRange(dataTypeCollection: InfluxDbHttpResponseDataType[]): Mapper[] {
    const myMapperCollection: Mapper[] = [];
    let dataLabel: string[] = [];
    let dataSet: number[] = [];
    let name: string;
    for (const data of dataTypeCollection) {
      if (data !== undefined) {
        for (let i = 1; i < data.columns.length; i++) {
          for (let z = 0; z < data.values.length; z++) {
            if (data.values[z][i] !== null) {
              dataLabel.push(this.getDatesToString(data.values[z][0]));
              dataSet.push(data.values[z][i]);
              name = data.name;
            }
          }
          const mapper: Mapper = new Mapper();
          mapper.deviceUUID = data.columns[i];
          mapper.dataLabel = dataLabel;
          mapper.dataSet = dataSet;
          mapper.dataTypeUUID = name;
          myMapperCollection.push(mapper);
          dataSet = [];
          dataLabel = [];
          name = null;
        }
      } else {
        return myMapperCollection;
      }
    }
    return myMapperCollection;
  }

  findIndexValue(itemValue: any, index: number): number {
    let value: number = null;
    for (let i = 0; i < itemValue.length && !value; i++) {
      if (itemValue[i][index] !== null) {
        value = itemValue[i][index];
      }
    }
    if (value === null) {
      value = 0;
    }
    return value;
  }

  periodToDateRange(period: any): DateRange {
    let dateRangeConverted: DateRange;
    if (period.period) {
      if (period.period === PeriodType.MONTH) {
        dateRangeConverted = new DateRange(moment().subtract(30, 'days').endOf('day'), moment().endOf('day'));
      } else if (period.period === PeriodType.DAY) {
        dateRangeConverted = new DateRange(moment().subtract(1, 'days').endOf('day'), moment().endOf('day'));
      } else if (period.period === PeriodType.HOUR) {
        dateRangeConverted = new DateRange(moment().subtract(1, 'hours'), moment());
      }
    } else if (period.valor) {
      switch (period.valor) {
        case 'Month':
          dateRangeConverted = new DateRange(moment().subtract(period.cantidad, 'months').startOf('day'), moment().endOf('day'));
          break;
        case 'Week':
          dateRangeConverted = new DateRange(moment().subtract(period.cantidad, 'weeks').startOf('day'), moment().endOf('day'));
          break;
        case 'Day':
          dateRangeConverted = new DateRange(moment().subtract(period.cantidad, 'days').startOf('day'), moment().endOf('day'));
          break;
        case 'Hour':
          dateRangeConverted = new DateRange(moment().subtract(period.cantidad, 'hours'), moment());
          break;
        case 'horas':
          dateRangeConverted = new DateRange(moment().subtract(period.cantidad, 'hours'), moment());
          break;
      }
    }
    return dateRangeConverted;
  }


}
