import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { DashboardService } from 'src/app/services/widgets/dashboards.service';
import { LoginService } from 'src/app/services/login/login.service';
import { Dashboard } from 'src/app/models/widgets/widgets.model';
import { Subscription } from 'rxjs';
import { NgChartGeneratorComponent } from '../ng-chart-generator/ng-chart-generator.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NewDashbordModel } from 'src/app/models/dashboard/newDashboard.model';
interface DashboardItem {
  uuid: string;
  name: string;
}
@Component({
  selector: 'app-list-dashboard',
  templateUrl: './list-dashboard.component.html',
  styleUrls: ['./list-dashboard.component.scss']
})
export class ListDashboardComponent implements OnInit, OnDestroy {
  public userDashboards: Array<NewDashbordModel> = [];
  dashboardSuscription: Subscription;
  selectedDash: DashboardItem;
  constructor(
    private dashboardService: DashboardService,
    private loginService: LoginService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: boolean,
    private dialogRef: MatDialogRef<NgChartGeneratorComponent>) { }

  ngOnInit() {
    this.getDashboards();
  }
  getDashboards() {
    this.dashboardSuscription = this.dashboardService.findAllDashboardsByUser(this.loginService.currentUserValue.uuid).subscribe(data => {
      this.userDashboards = data;
    });
  }
  ngOnDestroy() {
    this.dashboardSuscription.unsubscribe();
  }
  selectedDashboard(dash: DashboardItem) {
    this.selectedDash = dash;
  }
  saveDashboardAdd(){
    this.dialogRef.close(this.selectedDash);
  }
}
