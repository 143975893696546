import { Component, OnInit, Input } from '@angular/core';
import { GaugeWidget } from 'src/app/models/widgets/widget-interfaces.model';

@Component({
  selector: 'app-gaue-chart',
  templateUrl: './gaue-chart.component.html',
  styleUrls: ['./gaue-chart.component.scss']
})
export class GaueChartComponent implements OnInit {
  @Input() gaugeWidget: GaugeWidget;

  constructor() { }

  ngOnInit() { }
}
