import { Device, DeviceModel } from '../../device.model';
import { ListItem, ListItemHierachi, State } from '../../contract/list-item.model';
import { DeviceConsumptionInfo } from '../../deivce-info';
import { DeviceGraphItem } from '../../device-graph.model';
import * as lodash from 'lodash';

export class DeviceTab {
    children?: ListItemHierachi[];
    public parent?: ListItem;
    public selected: boolean;
    public uuid: string;
    public name: string;
    public navegationStack: Array<ListItem>;
    public currentDeviceDetail?: Device;

    constructor(listItem: ListItem) {
        this.uuid = listItem.uuid;
        this.name = listItem.name;
        this.navegationStack = [];
        this.parent = null;
        this.currentDeviceDetail = null;
    }

    public fromDevice(destinyDevice: Device) {
        this.uuid = destinyDevice.uuid;
        this.name = destinyDevice.name;
        this.navegationStack = [];
        this.parent = null;
        this.currentDeviceDetail = destinyDevice;
    }

    public goBack(destinyDevice: Device) {

        this.navegationStack.removeBy(navegationItem => navegationItem.uuid === destinyDevice.uuid);
        this.navegationStack = this.navegationStack;
        this.parent = lodash.last(this.navegationStack);
        this.currentDeviceDetail = destinyDevice;
    }

    public getDeviceCollectionToRepresent(devices, listItem: ListItem): DeviceGraphItem[] {
        const auxArray: DeviceGraphItem[] = [];
        const auxDevice: DeviceModel = new DeviceModel();

        auxArray.addRange(devices);
        auxDevice.uuid = listItem.uuid;
        auxDevice.name = listItem.name;
        auxArray.add(auxDevice);

        return auxArray;
    }

    public navigateOfMenuStack(device: Device) {
        let navigationWithMenu: ListItem = null;

        if (this.isRoot()) {
            navigationWithMenu = { uuid: this.uuid, name: this.name };
        } else {
            navigationWithMenu = { uuid: this.currentDeviceDetail.uuid, name: this.currentDeviceDetail.name};
        }

        if (this.navegationStack !== undefined) {
           this.parent = lodash.last(this.navegationStack);
        }
        this.parent = this.parent;

        this.currentDeviceDetail = device;
    }

    public navigate(selectedDevice: Device) {

        let navigation: ListItem = null;

        if (this.isRoot()) {
            navigation = { uuid: this.uuid, name: this.name };
        } else {
            navigation = { uuid: this.currentDeviceDetail.uuid, name: this.currentDeviceDetail.name};
        }

        this.navegationStack.push(navigation);
        this.navegationStack = this.navegationStack;

        if (this.navegationStack !== undefined) {
            this.parent = lodash.last(this.navegationStack);
        }
        this.parent = this.parent;

        this.currentDeviceDetail = selectedDevice;
    }

    public isRoot(): boolean {
       return this.parent === null || this.parent === undefined;
    }

    public getDeviceConsumptionDetail(): DeviceConsumptionInfo {
        if (this.currentDeviceDetail === null || this.currentDeviceDetail === undefined) {
            return null;
        }
        return this.currentDeviceDetail.getConsumptionDetail();
    }

    public getDeviceChildren(): DeviceModel[] {
        if (this.currentDeviceDetail === null || this.currentDeviceDetail === undefined) {
            return null;
        }
        return this.currentDeviceDetail.children;
    }

    public isLeaf(): boolean {
        throw new Error("Method not implemented.");
    }
    public hasDescendentById(uuid: String): boolean {
        throw new Error("Method not implemented.");
    }
    public hasAnyDescendentById(uuid: String[]): boolean {
        throw new Error("Method not implemented.");
    }
}
