import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

interface DashboardUpdated {
    dashboardUUID: string;
    newDashboardName: string;
}

export class UpdateDashboardBuilder {

    private _DASHBOARDUUID: string;
    private _NEWDASHBOARDNAME: string;

    private get _mutation() {
        return gql `mutation actualizarDashboard($dashboardUUID: String, $newDashboardName: String) {
            updateDashboard(uuid: $dashboardUUID, nombre: $newDashboardName) {
              uuid
              nombre
            }
          }
          `;
    }

    public withDashboardUUID(dashboardUUID: string): this {
        this._DASHBOARDUUID = dashboardUUID;
        return this;
    }

    public withNewDashboardName(newDashboardName: string): this {
        this._NEWDASHBOARDNAME = newDashboardName;
        return this;
    }

    public build() {
        const mutationParams: DashboardUpdated = {dashboardUUID: this._DASHBOARDUUID, newDashboardName: this._NEWDASHBOARDNAME };
        const graphqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphqlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
