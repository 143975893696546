import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg-device.component.html',
  styleUrls: ['./svg-device.component.scss']
})
export class SvgDeviceComponent implements OnInit {

  @Input() width = '24' ;
  @Input() height = '24';
  @Input() name: string
  constructor() { }

  ngOnInit() {
  }

}
