import { Injectable } from '@angular/core';
import { DateRange } from '../../models/dateRange.model';
import { InfluxDbEpoch, InfluxdbService, InfluxDbDeviceParameter, InfluxDbHttpResponseDataType } from '../influxdb/influxdb.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceGraphItem, DeviceGraphDataType } from '../../models/device-graph.model';
import * as lodash from 'lodash';
import { TimezoneService } from '../timezone.service';
import * as _ from 'lodash';
import spacetime from 'spacetime';
import { LimitInfluxParams } from 'src/app/models/limitInfluxParams.model';

export class Mapper {
  dataTypeUUID: string;
  dataSet: number[];
  dataTypeName?: string;
  codigo: string;
  dataLabel: Array<string>;
  toRemove?: number[];
  deviceUUID: string;
  deviceName?: string;
}

export interface TemporaryDate {
  index: number;
  timestampRange: number[];
}


@Injectable({
  providedIn: 'root'
})
export class NgxChartMapperService {

  fakeInfluxParams: InfluxDbDeviceParameter = {
    deviceUUID: '',
    dataTypeUUID: '',
    from: 0,
    to: 0,
    epoch: InfluxDbEpoch.SEC
  };

  temporaryDate: TemporaryDate = { index: 0, timestampRange: [] };
  offset = 0;

  constructor(
    private influxDbService: InfluxdbService,
    private timezoneService: TimezoneService) { }

  getDeviceDataSource(
    tipoDato: DeviceGraphDataType[],
    device: DeviceGraphItem[],
    from: number,
    to: number,
    epochValue: InfluxDbEpoch,
    limitInfluxParams: LimitInfluxParams): Observable<Mapper[]> {

    if (tipoDato !== undefined && device !== undefined && from !== undefined && to !== undefined && epochValue !== undefined) {
      device = this.modifyUuidIfDisplayFieldExists(device, tipoDato);
      const params: InfluxDbDeviceParameter = {
        deviceUUID: device.map(item => item.uuid).join('\", \"'),
        dataTypeUUID: tipoDato.map(item => item.uuid).join('\", \"'),
        from: from,
        to: to,
        epoch: epochValue,
        limitParams: limitInfluxParams
      };

      return this.influxDbService.executeQueryDataType(params).pipe(
        map((data: InfluxDbHttpResponseDataType[]) => this.newMapper(data)));
    }
  }

  public newMapper(dataTypeCollection: InfluxDbHttpResponseDataType[]): Mapper[] {
    const myMapperCollection: Mapper[] = [];
    let dataLabel: any[] = [];
    let dataSet: number[] = [];
    let name: string;
    // Recorremos la colección de tipos de dato y metemos en dataLabel y dataSet los
    // valores que vamos a mostrar en el eje X y en el eje Y (eje temporal en epoch de momento)
    for (const data of dataTypeCollection) {
      for (let i = 1; i < data.columns.length; i++) {
        for (let z = 0; z < data.values.length; z++) {
          if (data.values[z][i] !== null) {
            // Este de abajo es el push original
            // dataLabel.push(this.getDatesToString(data.values[z][0]));
            dataLabel.push(data.values[z][0]);
            dataSet.push(data.values[z][i]);
            name = data.name;
          }
        }
        const mapper: Mapper = new Mapper();
        mapper.deviceUUID = data.columns[i];
        mapper.dataLabel = dataLabel;
        mapper.dataSet = dataSet;
        mapper.dataTypeUUID = name;
        myMapperCollection.push(mapper);
        dataSet = [];
        dataLabel = [];
        name = null;
      }
    }
    return myMapperCollection;
  }

  getDatesToString(date: number): string {
    const dateAux = new Date(date * 1000);
    let timezone = '';
    this.timezoneService.currentTimeZone$.subscribe((dateRes: string) => {
      timezone = dateRes;
      if (timezone === '') { timezone = 'UTC'; }
    });
    return moment(dateAux).format('YYYY-MM-DD HH:mm:ss');
  }

  findIndexValue(itemValue: number[], index: number): number {
    let value: number;
    for (const item of itemValue) {
      if (item[index] !== null) {
        value = item[index];
      }
    }
    if (value == null || value === undefined) {
      value = 0;
    }
    return value;
  }

  // Esta función no se llama en ningún momento.
  formatDateLabel(arrayLabels: number[]): string[] {

    let timezone = '';
    this.timezoneService.currentTimeZone$.subscribe((date: string) => {
      timezone = date;
      if (timezone === '') { timezone = 'UTC'; }
    });
    if (this.temporaryDate !== undefined) {
      for (let i = 0; i < this.temporaryDate.timestampRange.length; i++) {
        if (!arrayLabels.includes(this.temporaryDate.timestampRange[i])) {
          arrayLabels.splice(this.temporaryDate.index + 1, 0, this.temporaryDate.timestampRange[i]);
        }
      }
    }

    const auxLabelArray: string[] = [];
    for (const dateLabel of arrayLabels) {
      const date = new Date(dateLabel * 1000);
      auxLabelArray.push(spacetime(date, timezone).format('{numeric-uk}, {time-24}').toString());
    }
    return auxLabelArray;
  }

  getFrequencyPattern(timeArray: number[]): number {
    const aux: number[] = [];
    for (let i = timeArray.length; i >= 0; i--) {
      if (timeArray[i] - timeArray[i - 1] > 0) {
        aux.push(timeArray[i] - timeArray[i - 1]);
        return aux.sort((a, b) =>
          aux.filter(v => v === a).length - aux.filter(v => v === b).length).pop();
      }
    }
  }

  findTemporaryJumps(timeArray: number[], frequencyPattern: number): TemporaryDate {
    let difference = 0;
    let x = 0;
    let position = 0;
    const newLabels: number[] = [];
    for (let i = timeArray.length; i >= 0; i--) {
      if ((timeArray[i] - timeArray[i - 1]) > frequencyPattern) {
        difference = timeArray[i] - timeArray[i - 1];
        position = _.indexOf(timeArray, timeArray[i - 1]);
        x = timeArray[i] - frequencyPattern;
        newLabels.push(x);
        for (let j = 0; j < (difference / frequencyPattern) - 2; j++) {
          x -= frequencyPattern;
          newLabels.push(x);
        }
      }
    }
    return {
      index: position,
      timestampRange: newLabels
    };
  }

  refactorDateValue(dateValues: number[][]): number[][] {
    for (const date of dateValues) {
      if (date[0].toString().charAt(date[0].toString().length - 1) !== '0') {
        let valorAuxiliar = date[0].toString().substr(0, date[0].toString().length - 1);
        valorAuxiliar += '0';
        date[0] = lodash.toNumber(valorAuxiliar);
      }
    }
    return dateValues;
  }

  modifyUuidIfDisplayFieldExists(deviceCollection: DeviceGraphItem[], dataTypeCollection: DeviceGraphDataType[]): DeviceGraphItem[] {
    for (const dataType of dataTypeCollection) {
      for (const device of deviceCollection) {
        if (!device.uuid.includes(dataType.displayField) && !_.isEmpty(dataType.displayField)) {
          device.uuid += '_' + dataType.displayField;
        }
      }
    }
    return deviceCollection;
  }

  removeDisplayFieldFromUuid(deviceCollection: string[]): string[] {
    let auxDeviceCollection: string[] = [];
    for (const device of deviceCollection) {
      if (device.includes('_')) {
        let aux: string[] = [];
        aux = device.split('_');
        auxDeviceCollection.push(aux[0]);
      } else {
        auxDeviceCollection = deviceCollection;
      }
    }
    return auxDeviceCollection;
  }

  fillItemWithZeroValues(itemValue: number[][]): number[][] {
    const auxLabelArray: number[] = [];
    lodash.mapValues(lodash.groupBy(itemValue, 0),
      (item: Array<number>) => {
        if (!auxLabelArray.includes(item[0][0])) { auxLabelArray.push(item[0][0]); }
        this.temporaryDate = this.findTemporaryJumps(auxLabelArray, this.getFrequencyPattern(auxLabelArray));

      });
    const auxiliar = this.treatTemporaryDate();
    if (this.temporaryDate !== undefined) {
      for (let i = 0; i < this.temporaryDate.timestampRange.length; i++) {
        itemValue.splice(this.temporaryDate.index + 1, 0, auxiliar[i]);
      }
    }
    return itemValue;
  }

  treatTemporaryDate(): number[][] {

    const aux: number[] = [];
    let aux2: number[] = [];
    const auxBi: number[][] = [];

    if (this.temporaryDate !== undefined) {
      for (let i = 0; i < this.temporaryDate.timestampRange.length; i++) {
        aux.push(this.temporaryDate.timestampRange[i]);
        aux2 = _.concat(aux[i], 0);
        auxBi.push(aux2);
      }
    }
    return auxBi;
  }
}
