import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'
import { ToastrService } from 'ngx-toastr';
import { FieldDataService } from 'src/app/services/fieldData/field-data.service';
import { gatewayRaiz, GatewayRaizInterface } from './querys/querys';
import { elemPingInterface } from './models/models';
import { Apollo } from 'apollo-angular';
import * as _ from "lodash";



@Component({
    selector: 'app-estructura-campo',
    templateUrl: './estructuraCampo.component.html',
    styleUrls: ['./estructuraCampo.component.scss']
})
export class EstructuraCampoComponent implements OnInit, OnDestroy {
    public querySubscription: any;
    gatewayInterface: any;
    sub: any;

    // Para la tabla de gateways raiz
    displayedColumns: string[] = ['gateway', 'last', 'sources'];
    dataSource: MatTableDataSource<GatewayRaizInterface>;
    @ViewChild('TableGatewayRaizPaginator', { static: true }) paginator: MatPaginator;

    // Para la tabla de concentradores
    displayedColumnsConcen: string[] = ['nombre', 'host', 'lastFecha', 'alive'];
    dataSourceConcen: MatTableDataSource<elemPingInterface[]>;
    @ViewChild('TableConcenPaginator', { static: true }) paginatorConcen: MatPaginator;


    // Config para los umbrales que vamos a establecer:
    limiteAlerta: any; // Para establecer el límite de la alerta de tiempo
    timeStamp: any = new Date().getTime() * 1000000; // Epoch actual en UTC a nanosegundos
    time: number; // Para asignar el momento actual menos la diferencia del json de config


    dataGatewaysRaiz: any;


    constructor(private apollo: Apollo,
        private toastrservice: ToastrService,
        private dataService: FieldDataService) { }

    ngOnInit() {
        // Obtenemos los gateway raiz:
        this.time = this.timeStamp - this.limiteAlerta;
        this.getData();
        this.getConcentradores();
    }

    getData() {
        // Traemos los datos del limite para la alerta (1h de momento);
        this.dataService.getConfigFieldData()
            .subscribe((data) => {
                this.limiteAlerta = data.limiteAlarma;
                this.time = this.timeStamp - this.limiteAlerta;

            })
        // Para calcular los sources rezagados        // Para calcular los sources rezagados
        this.sub = this.dataService.getGatewaysRaiz().subscribe((data) => {
            this.dataGatewaysRaiz = data;
            for (let i = 0; i < this.dataGatewaysRaiz.length; i++) {
                this.dataService.getsourceRezagados(this.dataGatewaysRaiz[i].uuid, this.time).
                    subscribe(data =>
                        this.dataGatewaysRaiz[i].sourcesRezagados = data
                    )
            }
            this.dataSource = new MatTableDataSource(this.dataGatewaysRaiz);
            this.dataSource.paginator = this.paginator;
        }
        )

    }

    // Para obtener el listado de elementos que tienen asignado una IP y responden a ping:
    getConcentradores() {
        // Para extraer información de los concrentradores:
        this.dataService.getelemPing().subscribe((data) => {
            data = data.lmpings;
            this.dataSourceConcen = new MatTableDataSource(data);
            this.dataSourceConcen.filterPredicate = (data, filter: string) => {
                const accumulator = (currentTerm, key) => {
                    return this.nestedFilterCheck(currentTerm, data, key);
                };
                const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                // Transform the filter by converting it to lowercase and removing whitespace.
                const transformedFilter = filter.trim().toLowerCase();
                return dataStr.indexOf(transformedFilter) !== -1;
            };
            this.dataSourceConcen.paginator = this.paginatorConcen;
        });
    }

    // función para alertar de que el uuid ha sido copiado al portapeles
    copiado(event: Event) {
        if (event) {
            this.toastrservice.info('', 'Elemento copiado al portapapeles',
                { closeButton: true, timeOut: 2000 });
        }
    }

    // Funcion pra filtrar la tabla de los gateways
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }


    applyFilterConcen(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSourceConcen.filter = filterValue.trim().toLowerCase();
    }





    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }


    ngOnDestroy() {
        // Nos desuscribimos del stream para evitar problemas de memoria
        this.sub.unsubscribe();
    }

}
