import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

interface UpdatedWidget {
    widgetUUID: string;
    newWidgetName: string;
}

export class UpdateWidgetBuilder {
    private _WIDGETUUID: string;
    private _NEWIDGETNAME: string;


    private get _mutation() {
        return gql `mutation actualizarWidget($widgetUUID: String!, $newWidgetName: String) {
            updateWidget(uuid:$widgetUUID, nombre: $newWidgetName) {
              uuid
              nombre
            }
          }`;
    }

    public withWidgetUUID(deviceUUID: string): this {
        this._WIDGETUUID = deviceUUID;
        return this;
    }

    public withNewWidgetName(newDeviceName: string): this {
        this._NEWIDGETNAME = newDeviceName;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: UpdatedWidget = {widgetUUID: this._WIDGETUUID, newWidgetName: this._NEWIDGETNAME};
        const grapqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return grapqlMutationBuilder
        .withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
