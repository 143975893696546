import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import * as _ from 'lodash';
import { Alarm, AlarmState } from 'src/app/models/alarm/alarm.model';
import { AlarmType } from 'src/app/models/alarmType.model';
import { AlarmService } from 'src/app/services/alarm/alarm.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { LoginService } from 'src/app/services/login/login.service';
import { FactoriesService } from 'src/app/services/factories/factories.service';
import { FactoryCollection } from 'src/app/models/factory/factory.collection.model';
import { FactoryModel } from 'src/app/models/FactoryModel';
import { ListItem, State } from 'src/app/models/contract/list-item.model';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { Subject } from 'rxjs';
import { DataTypeItem, DatatypeTreeHierarchi } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DataTypeService } from 'src/app/services/datatype/data-type.service';

@Component({
  selector: 'app-alarm-form',
  templateUrl: './alarm-form.component.html',
  styleUrls: ['./alarm-form.component.scss']
})
export class AlarmFormComponent implements OnInit {

  @Input() formMode: string;
  @Input() alarmToEdit: Alarm;
  @Output() cancelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitEmitter: EventEmitter<Alarm> = new EventEmitter<Alarm>();
  alarmForm: FormGroup;
  alarmTypeCollection: AlarmType[] = [];
  selected: AlarmType;
  alarmTypeSelected: string;
  public alarm: Alarm = {
    uuid: '',
    name: '',
    body: '',
    description: '',
    subject: '',
    type: {
      uuid: '',
      code: '',
      name: ''
    },
    state: AlarmState.desactivated,
    param: {
      uuid: '',
      clave: '',
      valor: ''
    },
    devices: [],
    supervisa: []
  };

  factoryCollection: FactoryCollection = new FactoryCollection();
  factoryCollectionSelected: FactoryModel[] = [];
  public title: Array<string> = [];
  public loading: Array<boolean> = [];
  public parents: Array<Array<ListItem>> = [[]];
  public isRoot: Array<boolean> = [];
  childrens: Array<DeviceGraphItem[]> = [];
  selectedDeviceList: DeviceGraphItem[] = [];
  devicesSelectedSubject = new Subject();
  tipoDatos: DeviceGraphDataType[] = [];
  dataTypeSelected: DataTypeItem[] = [];
  dataTypeTree: DatatypeTreeHierarchi[];

  constructor(private alarmService: AlarmService, private loginService: LoginService, private factoriesService: FactoriesService,
              private formBuilder: FormBuilder, private dispositivosService: DispositivoService,
              private dataTypeService: DataTypeService) {
    this.alarmForm = this.formBuilder.group({
      name: '',
      subject: '',
      body: '',
      description: '',
      type: {
        uuid: '',
        code: '',
        name: ''
      },
      value: 0,
      state: 'Desactivada',
      alarms: [],
      devices: []
    });
  }

  ngOnInit() {
    this.getDataTypeTree();
    this.initComboFactoryCollection();
    this.getAlarmTypeCollection();
    if (this.alarmToEdit !== undefined && this.alarmToEdit !== null) {
      this.initAlarm();
    }
  }

  private initAlarm() {
    this.alarmTypeSelected = this.alarmToEdit.type.uuid;
    this.alarmForm = this.formBuilder.group({
      uuid: this.alarmToEdit.uuid,
      name: this.alarmToEdit.name,
      subject: this.alarmToEdit.subject,
      body: this.alarmToEdit.body !== undefined ? this.alarmToEdit.body : '',
      description: this.alarmToEdit.description !== undefined ? this.alarmToEdit.description : '',
      type: {
        uuid: this.alarmToEdit.type.uuid,
        code: this.alarmToEdit.type.code,
        name: this.alarmToEdit.type.name
      },
      value: this.alarmToEdit.param.valor,
      state: this.alarmToEdit.state,
      // alarms: [this.alarmToEdit.alarms],
      devices: [this.alarmToEdit.devices],
      supervisa: [this.alarmToEdit.supervisa]
    });
    this.alarmForm.controls.type.setValue(this.alarmToEdit.type.uuid);
    this.selectedDeviceGraphItem(this.alarmToEdit.devices[0]);
    const auxDatatype: DataTypeItem = new DataTypeItem();
    auxDatatype.uuid = this.alarmToEdit.devices[0].dataType[0].uuid;
    auxDatatype.name = this.alarmToEdit.devices[0].dataType[0].name;
    auxDatatype.unity = this.alarmToEdit.devices[0].dataType[0].unity;
    this.selectedDatatype(auxDatatype);

  }

  getAlarmTypeCollection() {
    this.alarmService.getAllAlarmTypes().subscribe((data: AlarmType[]) => {
      this.alarmTypeCollection = data;
    });
  }

  changeSelection(event: MatSelectChange) {
    this.alarmTypeSelected = event.value;
  }

  isDataEmpty() {
    if (_.isEmpty(this.alarmForm.value.name) || _.isEmpty(this.alarmForm.value.subject) || _.isEmpty(this.alarmForm.value.body) ||
    _.isEmpty(this.alarmForm.value.description) || _.isEmpty(this.selectedDeviceList) || _.isEmpty(this.dataTypeSelected) ) {
      return true;
    } else {
      return false;
    }
  }

  onSubmitForm(formValue) {
    if (this.alarmToEdit !== undefined && this.alarmToEdit !== null) {
      this.alarm.uuid = this.alarmToEdit.uuid;
      this.alarm.param.uuid = this.alarmToEdit.param.uuid;
    }
    this.alarm.name = formValue.name;
    this.alarm.subject = formValue.subject;
    this.alarm.body = formValue.body;
    this.alarm.description = formValue.description;
    this.alarm.type = formValue.type;
    this.alarm.param.valor = formValue.value;
    this.alarm.state = formValue.state;
    this.alarm.devices = formValue.devices;
    this.assignNewAlarmType();
    this.assignSelectedDevicesToAlarm();
    this.submitEmitter.emit(this.alarm);
  }

  assignNewAlarmType() {
    const alarmChosen: AlarmType[] = _.filter(this.alarmTypeCollection, item => item.uuid === this.alarmTypeSelected);
    this.alarm.type = alarmChosen[0];
  }

  assignSelectedDevicesToAlarm() {
    this.alarm.devices = this.selectedDeviceList;
    this.alarm.devices.map(item => item.dataType = this.dataTypeSelected);
  }

  cancelForm() {
    this.alarmForm.reset();
    this.cancelEmitter.emit(true);
  }

  initComboFactoryCollection() {
    const usuario: User = this.loginService.currentUserValue;
    if (usuario !== null && usuario !== undefined) {
      this.factoriesService.getFactories(usuario.user).pipe(
      ).subscribe((data: FactoryCollection) => this.factoryCollection = data);
    }
  }

  selectFactoryEvent(factorySelected: FactoryModel) {
    if (!factorySelected.showTab) {
      this.addFactoryToFactoriesSelected(factorySelected);
    } else {
      this.removeFactoryFromFactoriesSelected(factorySelected);
    }
  }

  private addFactoryToFactoriesSelected(factorySelected: FactoryModel) {
    factorySelected.showTab = true;
    this.factoryCollectionSelected.push(factorySelected);
    this.getChildrenFactory(factorySelected.uuid, this.factoryCollectionSelected.length - 1);
    this.getTitleForCard(factorySelected.name, this.factoryCollectionSelected.length - 1);
    this.addParent(factorySelected.uuid, factorySelected.name, this.factoryCollectionSelected.length - 1);
  }

  private removeFactoryFromFactoriesSelected(factorySelected: FactoryModel) {
    factorySelected.showTab = false;
    this.removeDataOfFactoryUnselected(factorySelected);
    this.factoryCollectionSelected.removeBy(item => item.uuid === factorySelected.uuid);
  }

  private removeDataOfFactoryUnselected(factoryToRemove: FactoryModel) {
    const index = _.findIndex(this.factoryCollectionSelected, { uuid: factoryToRemove.uuid });
    this.title.remove(this.title[index]);
    this.childrens.remove(this.childrens[index]);
    this.loading.remove(this.loading[index]);
    this.isRoot.remove(this.isRoot[index]);
    this.parents.remove(this.parents[index]);
  }

  addParent(parentUUID: string, parentName: string, index: number) {
    if (this.parents[index] === undefined) {
      this.parents[index] = [];
    }
    const item: ListItem = { uuid: parentUUID, name: parentName };
    this.parents[index].add(item);
  }

  getTitleForCard(factorySelectedName: string, index: number) {
    this.title[index] = '';
    this.title[index] = factorySelectedName;
  }

  getChildrenFactory(factorySelectedUUID: string, index: number) {
    this.childrens[index] = [];
    this.loading[index] = true;
    this.dispositivosService.getDeviceChildrenAndDataTypesGraphQl(factorySelectedUUID)
      .subscribe(
        next => {
          this.childrens[index] = this.checkDeviceSelectedCollection(next);
          this.childrens[index] = _.sortBy(this.childrens[index], ['name'], ['asc']);
          this.loading[index] = false;
        }
      );
  }

  private checkDeviceSelectedCollection(deviceSelected: DeviceGraphItem[]): DeviceGraphItem[] {
    let auxDevices: DeviceGraphItem[];
    auxDevices = _.intersectionBy(deviceSelected, this.selectedDeviceList, 'uuid');
    for (const aux of auxDevices) {
      aux.selectedState = State.selected;
    }
    return deviceSelected;
  }

  public selectedDeviceGraphItem(item: DeviceGraphItem) {
    if (this.selectedDeviceList.length > 0) {
      this.unSelectedDeviceGraphItem(this.selectedDeviceList[0]);
    }
    this.selectedDeviceList.add(item);
    this.loadDeviceSelectedDataType();
    this.checkDataTypeSelectedCollection();
    item.selectedState = State.selected;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  private loadDeviceSelectedDataType() {
    const deviceSelectedDataTypeCollection: DeviceGraphDataType[][] = this.getDeviceSelectedDataTypeCollection();
    if (deviceSelectedDataTypeCollection !== null &&
      deviceSelectedDataTypeCollection !== undefined && deviceSelectedDataTypeCollection.length > 0) {
      this.tipoDatos = deviceSelectedDataTypeCollection.reduce(this.buildDeviceSelectedDataType);
    } else {
      this.tipoDatos = [];
    }
  }

  private checkDataTypeSelectedCollection() {
    const aux: DataTypeItem[] = [];
    if (!_.isEmpty(this.dataTypeSelected)) {
      for (const dataTypeSelected of this.dataTypeSelected) {
        for (const tipoDato of this.tipoDatos) {
          if (tipoDato.uuid === dataTypeSelected.uuid) {
            aux.add(dataTypeSelected);
          }
        }
      }
    }
    this.dataTypeSelected = aux;
  }

  getDeviceSelectedDataTypeCollection(): DeviceGraphDataType[][] {
    return this.selectedDeviceList.map(item => item.dataType);
  }

  private buildDeviceSelectedDataType(prev: DeviceGraphDataType[], current: DeviceGraphDataType[]): DeviceGraphDataType[] {
    if (current === null || current === undefined) {
      return prev;
    }
    return prev.intersect(current, item => item.uuid);
  }

  public unSelectedDeviceGraphItem(item: DeviceGraphItem) {
    if (this.selectedDeviceList.length < 2) {
      this.dataTypeSelected = [];
    }
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    this.loadDeviceSelectedDataType();
    item.selectedState = State.selectable;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  navigate(selected: DeviceGraphItem, i: number) {
    if (_.isEmpty(this.parents[i])) {
      this.isRoot[i] = true;
    } else {
      this.isRoot[i] = false;
    }
    this.getTitleForCard(selected.name, i);
    this.getChildrenFactory(selected.uuid, i);
    this.addParent(selected.uuid, selected.name, i);
  }

  goBack(i) {
    const uuidParent = this.parents[i][this.parents[i].length - 2].uuid;
    const nameParent = this.parents[i][this.parents[i].length - 2].name;

    this.parents[i].remove(this.parents[i][this.parents[i].length - 2]);
    this.parents[i].remove(this.parents[i][this.parents[i].length - 1]);

    if (_.isEmpty(this.parents[i])) {
      this.isRoot[i] = true;
    } else {
      this.isRoot[i] = false;
    }

    const aux: DeviceGraphItem = new DeviceGraphItem();
    aux.uuid = uuidParent;
    aux.name = nameParent;
    this.navigate(aux, i);
  }

  removeChipset(item: DeviceGraphItem) {
    item.selectedState = State.selectable;
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    for (const childCollection of this.childrens) {
      for (const child of childCollection) {
        if (child.uuid === item.uuid) {
          child.selectedState = State.selectable;
        }
      }
    }

    if (_.isEmpty(this.selectedDeviceList)) {
      this.dataTypeSelected = [];
    }
    this.selectedDeviceList.removeBy(x => x.uuid === item.uuid);
    this.loadDeviceSelectedDataType();
    item.selectedState = State.selectable;
    this.devicesSelectedSubject.next(this.selectedDeviceList);
  }

  selectedDatatype(selected: DataTypeItem) {
    if (this.dataTypeSelected.length > 0) {
      this.dataTypeSelected[0].selectedState = State.selectable;
      this.unSelectedDatatype(this.dataTypeSelected[0]);
    }
    this.dataTypeSelected.add(selected);
  }

  unSelectedDatatype(unselected: DataTypeItem) {
    this.dataTypeSelected.removeBy(item => item.uuid === unselected.uuid);
  }

  getDataTypeTree() {
    this.dataTypeService.getDataType().subscribe(data => {
      this.dataTypeTree = _.sortBy(data, ['name'], ['asc']);
    });
  }
}
