import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface NewDashboard {
    uuidUser: string;
    dashboardName: string;
}

export class CreateDashboardBuilder {
    private _UUIDUSER: string;
    private _DASHBOARDNAME: string;

    private get _mutation() {
        return gql`mutation crearDashboard($uuidUser: String!, $dashboardName: String!) {
            createDashboard(usuario:$uuidUser, nombre: $dashboardName) {
              uuid
              nombre
            }
          }`;
    }

    public withUuidUser(uuidUser: string): this {
        this._UUIDUSER = uuidUser;
        return this;
    }

    public withDashboardName(dashboardName: string): this {
        this._DASHBOARDNAME = dashboardName;
        return this;
    }

    public build(): MutationOptions {
        let mutationParams: NewDashboard;
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = { uuidUser: this._UUIDUSER, dashboardName: this._DASHBOARDNAME };
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
