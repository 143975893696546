import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import * as _ from 'lodash';
import { FormGroup, FormBuilder } from '@angular/forms';

interface ChangeUserPassModel {
  modalTitle: string;
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-change-user-password-form',
  templateUrl: './change-user-password-form.component.html',
  styleUrls: ['./change-user-password-form.component.scss']
})
export class ChangeUserPasswordFormComponent implements OnInit {
  hide = true;
  hide1 = true;
  hide2 = true;
  newPassword: string;
  changePasswordForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: ChangeUserPassModel) {
    this.changePasswordForm = this.formBuilder.group({
      formCurrentPassword: '',
      formNewPassword: '',
      formConfirmNewPassword: ''
    });
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      formCurrentPassword: this.data.currentPassword,
      formNewPassword: '',
      formConfirmNewPassword: ''
    });
  }

  onSubmitForm(formValue) {
    this.data.newPassword = formValue.formNewPassword;
    this.data.currentPassword = formValue.formCurrentPassword;
  }

  validate(): boolean {
    if (!_.isEmpty(this.changePasswordForm.value.formNewPassword) || !_.isEmpty(this.changePasswordForm.value.formConfirmNewPassword)) {
      if (this.changePasswordForm.value.formNewPassword === this.changePasswordForm.value.formConfirmNewPassword) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  showAdvice(): boolean {
    if (_.isEmpty(this.changePasswordForm.value.formNewPassword) || _.isEmpty(this.changePasswordForm.value.formConfirmNewPassword)) {
      return false;
    } else {
      return this.validate();
    }
  }

}
