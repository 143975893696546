import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export class AllDispositivosRaizBuilder {
    private get _query(): DocumentNode {
        return gql`
        query getFactories {
            fabricas{
                uuid
                nombre
              }
          }`;
    }

    public build(): PureQueryOptions {
        const graphQLBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQLBuilder
            .withQuery(this._query)
            .build();
    }
}