import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';
import { DocumentNode } from 'apollo-link';

export interface DataTypeUUIDGraphQL {
    dataTypeUUID: string;
}

export class DataTypeUnityGraphQLBuilder {
    private _dataTypeUUID: string;
    private get _query(): DocumentNode {
        return gql `query getDataTypeUnity($dataTypeUUID: String) {
            findTipoDato(uuid: $dataTypeUUID) {
              unidad
              __typename
            }
          }
          `
    };

    public withDataTypeUUID(dataTypeUUID: string) {
        this._dataTypeUUID = dataTypeUUID;
        return this;
    }

    public build(): PureQueryOptions {
        let queryParams: DataTypeUUIDGraphQL = {dataTypeUUID: this._dataTypeUUID};
        const graphQLBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQLBuilder.withQuery(this._query)
        .withParams(queryParams)
        .build();
    }
}