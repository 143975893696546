import { Component, Input, Output, OnInit, ComponentRef, ElementRef, ViewChild, Renderer, EventEmitter, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { GridStackItem } from './../models/grid-stack-item.model';
import { DataSize } from 'src/app/models/widgets/widget-interfaces.model';

@Component({
  selector: 'ngx-grid-stack-item',
  templateUrl: './grid-stack-item.component.html',
  styleUrls: ['./grid-stack-item.component.css']
})
export class GridStackItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('contentPlaceholder', { static: false }) contentPlaceholder: ViewContainerRef;
  @Input() contentTemplate: string;
  @Input() option: DataSize;
  @Output() GridConfigurationChanged = new EventEmitter<GridStackItem>();

  contentComponentRef: ComponentRef<any> = null;
  jGridRef: any = null;
  public jWidgetRef: any = null;

  constructor(private el: ElementRef, private renderer: Renderer) {
    this.jWidgetRef = el.nativeElement;
  }
  get nativeElement(): HTMLElement {
    return this.el.nativeElement;
  }
  ngOnInit() { }

  UpdateWidget(item: GridStackItem) { }
  RenderWidget(item: any) {
    if (item !== null) {
      this.option = item;
    }
    /*  this.renderer.setElementAttribute(this.nativeElement, 'style', 'margin-left:' + this.option.marginWidth + ';'); */
    this.renderer.setElementAttribute(this.nativeElement, 'data-gs-x', String(this.option.x));
    this.renderer.setElementAttribute(this.nativeElement, 'data-gs-y', String(this.option.y));
    this.renderer.setElementAttribute(this.nativeElement, 'data-gs-width', String(this.option.width)); //Tmb se puede utilizar la clase width pero será reemplazada en versiones futuras
    this.renderer.setElementAttribute(this.nativeElement, 'data-gs-height', String(this.option.height)); //Tmb se puede utilizar la clase height pero será reemplazada en versiones futuras
    this.renderer.setElementAttribute(this.nativeElement, 'data-gs-min-width', String(this.option.minWidth));
    this.renderer.setElementAttribute(this.nativeElement, 'data-gs-min-height', String(this.option.minHeight));
  }


  update(x: number, y: number, width: number, height: number): void {
    if (x === this.option.x && y === this.option.y && width === this.option.width && height === this.option.height) {
      return;
    }
    //REVISAR!
    if (this.option != null) {
      this.option.width = width;
      this.option.height = height;
      this.option.x = x;
      this.option.y = y;

      const gridStackItem: GridStackItem = new GridStackItem();
      gridStackItem.x = this.option.x;
      gridStackItem.y = this.option.y;
      gridStackItem.width = this.option.width;
      gridStackItem.minWidth = this.option.minWidth;
      gridStackItem.height = this.option.height;
      gridStackItem.minHeight = this.option.minHeight;

      const optionNew = GridStackItem.Clone(gridStackItem);
      this.GridConfigurationChanged.emit(optionNew);
    }
  }

  ngAfterViewInit(): void {
    this.RenderWidget(this.option);
  }

  ngOnDestroy(): void {
    if (this.contentComponentRef !== null) {
      this.contentComponentRef.destroy();
    }
  }
}
