import { LoginService } from './../../../services/login/login.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, ChangeDetectionStrategy, DoCheck } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import * as _ from 'lodash';
import { AlarmaModel } from './../models/alarma-models'


@Component({
  selector: 'app-alarma-table',
  templateUrl: './alarma-table.component.html',
  styleUrls: ['./alarma-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class AlarmaTableComponent implements OnInit, OnChanges {
  @Input() elementData: AlarmaModel[];
  @Input() tableAlarma: boolean;
  @Input() activatedStyle: boolean;
  @Input() mobileMode: boolean;
  @Output() selectionRowEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() selectionAlarmaRowEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() selectionStateEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteAlarmaEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() editAlarmaEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() detailAlarmaEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() changeAlarmaStateEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() unsubscribeAlarmaEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  @Output() rearmarAlarmaEmitter: EventEmitter<AlarmaModel> = new EventEmitter<AlarmaModel>();
  currentUser: string;

  dataSource: MatTableDataSource<AlarmaModel>;
  columnsToDisplay = ['alarmadaIcon', 'nombre',  'descripcion', 'disabledDate' , 'alarmadoDate' , 'acciones'];

  constructor(private loginService:LoginService) { }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.currentUser = this.loginService.currentUserValue.uuid;
    this.showDataTable();
  }

  ngOnChanges() {
    this.showDataTable();
    // Si añadimos una alarma, debería mostrarse en primer lugar
    // para ello debemos tener un create_date y ordenarlas por esa propiedad
  }

  private showDataTable() {
    if(this.mobileMode) {
      this.columnsToDisplay = ['nombre', 'acciones'];
    }
    this.dataSource = new MatTableDataSource(this.elementData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  emitSelection(selectedObject) {
    this.selectionRowEmitter.emit(selectedObject);
  }

  throwDeleteEmitter(objectToDelete) {
    this.deleteAlarmaEmitter.emit(objectToDelete);
  }

  throwEditEmitter(objectToEdit) {
    this.editAlarmaEmitter.emit(objectToEdit);
  }

  throwDetailEmitter(objectToEdit) {
    this.detailAlarmaEmitter.emit(objectToEdit);
  }

  throwChangeStateEmitter(objectTochangeState) {
    this.changeAlarmaStateEmitter.emit(objectTochangeState);
  }

  throwUnsubscribeAlarmEmitter(objectTochangeState) {
    this.unsubscribeAlarmaEmitter.emit(objectTochangeState);
  }

  throwRearmarEmitter(objectTochangeState) {
    this.rearmarAlarmaEmitter.emit(objectTochangeState);
  }

  setStateStyles(elementState: AlarmaModel): string {
    if(_.isNumber(elementState.disabled)){
      return 'desactive';
    }else{
      return 'activeElement';
    }
  }

  setRowStyle(elementState: AlarmaModel): string {
    let rowClass = '';
    if(this.displayAlarmado(elementState)){
      rowClass = 'row-alarmado' ;
    }
    if(_.isNumber(elementState.disabled)){
      rowClass = 'row-disabled' ;
    }
    
    return rowClass;
  }

  displayAlarmado(alarma: AlarmaModel): boolean{
    let informaArray = [];
    alarma.informa.forEach(informa =>{
      if(informa.to.uuid == this.currentUser) {
        return informaArray.push(informa)
      };
    });
    if (informaArray.length == 1 ) {
      return _.isNumber(informaArray[0].alarmado);
    } 
    return false
  }



  // Para filtrar la tabla:
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
