import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/'); 
//license token must be moved to a configuration file
Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4ODI2ZWIzZS1mZDc2LT' +
  'RmOGMtYTZjMC1hMTZiNDg2NTE1YmMiLCJpZCI6MzIwMDYsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1OTYzMzM0MzV9.sHbly7KThOCqkLgA1N7Ualjbt9FmR3-iukDHRDdSGhw';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
