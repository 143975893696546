import gql from 'graphql-tag';

import { DocumentNode } from 'apollo-link';
import { GraphQlQueryBuilder } from './graphQl-query.builder';
import { PureQueryOptions } from 'apollo-client';
interface DeviceChildrenGraphQL {
  deviceUUID: string;
}

export class DeviceChildrenGraphQLBuilder {
  private _deviceId: string;

  private get _query(): DocumentNode {

    return gql`query findChildDevices($deviceUUID: String!) {
      findEstructura(uuid: $deviceUUID) {
        uuid
        nombre
        estructuraCantidad
        estructura {
          uuid
          nombre
          nombreTecnico
          descripcion
          tipo {
            nombre
            uuid
            __typename
          }
          estructuraCantidad
          tiene {
            to {
              uuid
              stats_count
              es {
                uuid
                nombre
                codigo
                __typename
              }
              __typename
            }
            __typename
          }
          estado {
            nombre
            __typename
          }
          __typename
        }
        __typename
      }
    }`;
  }
 

  public withDeviceIdParam(deviceId: string): this {
    this._deviceId = deviceId;
    return this;
  }

  public build(): PureQueryOptions {
    let queryParams: DeviceChildrenGraphQL;
    const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
    queryParams = { deviceUUID: this._deviceId };
    return graphQlBuilder.withQuery(this._query)
      .withParams(queryParams)
      .build();
  }
}
