import { UserGraphQLResponse } from './graphQL/user-graphQL.response';
import { MiUsuarioGraphQLResponse } from './graphQL/miUsuario-graphQL.response';

export class User {
    uuid: string;
    user: string;
    name: string;
    firstName: string;
    timeZone: string;
    language: string;
    rol?: string[];

    constructor(response: MiUsuarioGraphQLResponse) {
        this.uuid = response.miUsuario.uuid;
        this.user = response.miUsuario.usuario;
        this.name = response.miUsuario.nombre;
        this.firstName = response.miUsuario.apellidos;
        this.timeZone = response.miUsuario.zonaHoraria;
        this.language = response.miUsuario.idioma;
        this.rol = response.miUsuario.rol;
    }
}
