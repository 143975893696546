import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FieldBase }  from '../dynamic-form-field/field-base';
import { DynamicFieldControlService} from '../../services/dynamicFieldControl/dynamic-field-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [DynamicFieldControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: FieldBase<string>[] = [];
  form: FormGroup;

  constructor(private dynamicFieldControlService: DynamicFieldControlService) {

   }

  ngOnInit() {
    this.form = this.dynamicFieldControlService.toFormGroup(this.fields);
  }

  onSubmit(){
    
  }

  get getPayLoad() { return this.form.getRawValue(); }

}
