import { DocumentNode } from 'apollo-link';

import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export class DataTypeGraphQlBuilder {
    private get _query(): DocumentNode {

        return gql`query findallDataType
        {
          tipoDatos{
            nombre
            descripcion
            factorDecimal
            unidad
            codigo
            uuid
            displayField
            codigo
            agrupacion {
              uuid
              nombre
              agrupacion{
                uuid
                 nombre
                agrupacion{
                  uuid
                  nombre
                  agrupacion{
                    uuid
                    nombre
                  }
                }
              }
            }
          }
          }`;
    }

    public build(): PureQueryOptions {
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQlBuilder.withQuery(this._query)
            .build();
    }
}
