import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface EditAlarmModel {
   uuidParametro: string;
   claveActualizada: string;
   valorActualizado: string;
}

export class EditAlarmValueBuilder {
    private _UUID: string;
    private _CLAVE: string;
    private _VALOR: string;

    private get _mutation() {
        return gql`mutation actualizarParametroAlarma($uuidParametro: ID!, $claveActualizada: String, $valorActualizado: String) {
            updateAlarmaParametro(uuid: $uuidParametro, clave: $claveActualizada, valor: $valorActualizado) {
              uuid
            }
          }`;
    }

    public withUUIDParametro(uuid: string): this {
        this._UUID = uuid;
        return this;
    }

    public withClaveActualizada(clave: string): this {
        this._CLAVE = clave;
        return this;
    }

    public withValorActualizado(valor: string): this {
        this._VALOR = valor;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: EditAlarmModel  = {
            uuidParametro: this._UUID,
            claveActualizada: this._CLAVE,
            valorActualizado: this._VALOR
        };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
