import { ListItemHierachi, State } from '../contract/list-item.model';

import * as lodash from 'lodash';

export class DataTypeItem implements ListItemHierachi {
    uuid: string;
    name: string;
    description: string;
    unity: string;
    codigo: string;
    decimalFactor: string;
    parent?:  DataGroup;
    selectedState?: State = State.selectable;
    displayField?: string;
    public isLeaf(): boolean {
        return true;
    }
    public hasDescendentById(uuidToCheck: String): boolean {
        return uuidToCheck === this.uuid;
    }
    public hasAnyDescendentById(uuidcollectionToCheck: String[]): boolean {
        return uuidcollectionToCheck.includes(this.uuid);
    }
}

export class DataGroup implements ListItemHierachi {
    uuid: string;
    name: string;
    children: ListItemHierachi[];
    parent:  DataGroup;
    public isLeaf(): boolean {
        return lodash.isEmpty(this.children);
    }
    public addChild(newChild: ListItemHierachi) {
        if (lodash.isEmpty(this.children)) {
            newChild.parent = this;
            this.children = [newChild];
            return;
        }
        const existedChildren = this.children.firstOrDefault(item => item.uuid == newChild.uuid);
        if (lodash.isEmpty(existedChildren)) {
            newChild.parent = this;
            this.children.add(newChild);
            return;
        }
        if(!lodash.isEmpty(newChild.children)){
            for(let item of newChild.children){
                (existedChildren as DataGroup).addChild(item);
            }
        }
    }
    public hasDescendentById(uuidToCheck: String): boolean {
        if (lodash.isEmpty(uuidToCheck))
            return false;
        return  this.uuid === uuidToCheck || this.children.any(item => item.uuid === uuidToCheck || item.hasDescendentById(uuidToCheck));
    }
    public hasAnyDescendentById(uuidcollectionToCheck: String[]): boolean {
        if (lodash.isEmpty(uuidcollectionToCheck))
            return false;
        return  uuidcollectionToCheck.includes(this.uuid) || this.children.any(item => uuidcollectionToCheck.includes(item.uuid) || item.hasAnyDescendentById(uuidcollectionToCheck));
    }
}

export class DatatypeTreeHierarchi {

    root: DataGroup
    public addChildren(children: ListItemHierachi[]) {
        
        if (lodash.isEmpty(this.root)|| lodash.isEmpty(children)) {
            return;
        }
        for(let item of children){
            this.root.addChild(item);
        }

    }
}

