import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { GraphicAddDevicesComponent } from '../graphic-add-devices/graphic-add-devices.component';
import { DeviceGraphItem } from 'src/app/models/device-graph.model';

@Component({
  selector: 'app-bottom-sheet-device',
  templateUrl: './bottom-sheet-device.component.html',
  styleUrls: ['./bottom-sheet-device.component.scss']
})
export class BottomSheetDeviceComponent implements OnInit {
  @Output() addedDevices: EventEmitter<DeviceGraphItem[]> = new EventEmitter<DeviceGraphItem[]>();
  constructor(private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {}

  addDevice() {
    const elem = this._bottomSheet.open(GraphicAddDevicesComponent);
    elem.afterDismissed().subscribe(res => {
     const userAcepted = elem.instance.userAcepted;
     if (userAcepted) {
       this.addedDevices.emit(elem.instance.addDevices());
     }
    });
  }

}
