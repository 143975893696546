import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  public menuStatus = new Subject();

  constructor() { }
  
  toggleMenu(){
    this.menuStatus.next(true)
  }
}
