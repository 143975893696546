import { Component, OnInit } from '@angular/core';
import { TimezoneService, Timezone } from 'src/app/services/timezone.service';
import spacetime from 'spacetime';
import { LoginService } from 'src/app/services/login/login.service';
import * as _ from 'lodash';
import { User } from 'src/app/models/user.model';
import { MatSelectChange } from '@angular/material';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit {

  timezoneCollection: Timezone[] = [];
  utcCollection: string[] = [];
  offSetCollection: number[] = [];
  date: string;
  selectedOption = 'UTC';
  constructor(
    private loginService: LoginService,
    private timezoneService: TimezoneService
  ) {
    this.selectedOption = this.loginService.currentUserValue.timeZone;
  }

  ngOnInit() {
    this.getTimeZoneCollection();
  }
  getTimeZoneCollection() {
    this.timezoneService.getTimezoneCollection().subscribe((data: Timezone[]) => {
      this.timezoneCollection = data;
      for (const timezone of data) {
        for (const utc of timezone.utc) {
          this.utcCollection.push(utc);
        }
      }
      this.utcCollection = _.sortBy(this.utcCollection);
    });
  }
  public changeTimeZone(event: MatSelectChange) {
    const newTimeZone: string = event.value;
    const auxUser: User = this.loginService.currentUserValue;
    this.timezoneService.setLocale(event.value);
    this.getOffsetFromTimezoneSelected(event);
    this.date = spacetime(event.value).format('DD-MM-YY, HH:mm').toString();
    auxUser.timeZone = newTimeZone;
    this.loginService.updateUser(auxUser).subscribe(data => {
      if (data !== null) {
      }
    });
    this.setLocalStorage(auxUser);
  }

  private getOffsetFromTimezoneSelected(event: MatSelectChange) {
    for (const time of this.timezoneCollection) {
      for (const utc of time.utc) {
        if (utc === event.value) {
          this.timezoneService.setOffset(time.offset);
        }
      }
    }
  }

  private setLocalStorage(res: User) {
    localStorage.setItem('loggedUser', JSON.stringify(res));
  }

}
