import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LastStatesRoutingModule } from './last-states-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LastStatesRoutingModule
  ]
})
export class LastStatesModule { }
