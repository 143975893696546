import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';
import { DeviceModel, Device } from 'src/app/models/device.model';
import { DeviceTab } from 'src/app/models/device/tab/device-tab.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NgxChartMapperService, Mapper } from 'src/app/services/ngx-chart-mapper/ngx-chart-mapper.service';
import { DateRange } from 'src/app/models/dateRange.model';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import { InfluxDbEpoch } from 'src/app/services/influxdb/influxdb.service';
import { NgxDevice } from 'src/app/models/ngxDevice.model';
import { LynxColors } from 'src/app/models/lynxColors.model';
import { DeviceType } from 'src/app/models/deviceType.model';
import { ListItem } from 'src/app/models/contract/list-item.model';
import { DataTypeService } from 'src/app/services/datatype/data-type.service';
import { PlotlyWidget, PlotlyChartType } from 'src/app/models/plotly/plotly-chart.model';
import { PlotlyChartBuilder } from 'src/app/models/plotly/plotlyChartBuilder';
import { ConsumptionDataType } from 'src/app/models/datatype/consumptionDataType.model';
import { WidgetMapperService } from 'src/app/services/widget-mapper/widget-mapper.service';
import { WidgetBasicParam } from 'src/app/models/widgets/widget-interfaces.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DeviceConsumption } from 'src/app/models/utils/device-consumption.model';
import { TimezoneService } from 'src/app/services/timezone.service';
import { MatDialog } from '@angular/material/dialog';
import { FactoriesService, FactoryChanged } from 'src/app/services/factories/factories.service';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-tab-content-device',
  templateUrl: './tab-content-device.component.html',
  styleUrls: ['./tab-content-device.component.scss']
})
export class TabContentDeviceComponent implements OnInit {

  @Input() tab: DeviceTab;
  @Output() tabChangedEmitter: EventEmitter<Device> = new EventEmitter<Device>();
  public loading: boolean;
  public title: string;
  twoWeeksDateRange: DateRange = new DateRange(moment().subtract(40, 'days'), moment());
  childrenDeviceCollectionToShow: DeviceGraphItem[] = [];
  parentDevice: Device;
  plotlyChartCollection: Array<PlotlyWidget> = [];
  colorsArray: LynxColors[] = [];
  deviceType: DeviceType;
  deviceCollectionToRepresent: DeviceGraphItem[] = [];
  dataTypeCollection: DeviceGraphDataType[] = [];
  graphicsDataType: DeviceGraphDataType[] = [];
  currentUnity: string[] = [];
  public responseConsumptionBS: BehaviorSubject<number>;
  public responseConsumptionObservable: Observable<number>;
  consumptionDataType: ConsumptionDataType[] = [];
  suscriptionCollection: Subscription[] = [];
  timezoneBS: BehaviorSubject<string> = new BehaviorSubject<string>('');
  loadingChart = false;
  loadingText = 'LOADING.DEVICES';
  loadingChartText = 'LOADING.CHART';
  editMode = false;
  showProductivityCard = false;

  constructor(
    private factoriesService: FactoriesService,
    private dispositivoService: DispositivoService,
    private settingsService: SettingsService,
    private ngxChartService: NgxChartMapperService,
    private dataTypeService: DataTypeService,
    private widgetMapperService: WidgetMapperService,
    private timezone: TimezoneService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.getProductivitySettings();
  }

  ngOnInit() {
    const currentDevice: DeviceGraphItem = new DeviceGraphItem();
    currentDevice.uuid = this.tab.currentDeviceDetail.uuid;
    currentDevice.name = this.tab.currentDeviceDetail.name;
    this.getDataTypeCollection(currentDevice);
    const auxListItem: ListItem = { uuid: this.tab.uuid, name: this.tab.name };
    this.deviceCollectionToRepresent = this.tab.getDeviceCollectionToRepresent(this.tab.getDeviceChildren(), auxListItem);
    this.title = this.tab.name;
    this.childrenDeviceCollectionToShow = this.getDevicesToShow();
    this.detectChanges();
    this.refreshData();
  }

  getProductivitySettings() {
    this.settingsService.getProductivitySettings().subscribe((data: boolean) => {
      this.showProductivityCard = data;
    });
  }

  onFocusOut() {
    this.editMode = false;
    this.dispositivoService.UpdateDeviceName(this.tab.currentDeviceDetail.uuid, this.tab.currentDeviceDetail.name)
      .subscribe(data => {
        if (data !== null) {
          const factoryChanged: FactoryChanged = { uuid: this.tab.currentDeviceDetail.uuid, name: this.tab.currentDeviceDetail.name };
          this.title = this.tab.currentDeviceDetail.name;
          this.tabChangedEmitter.emit(this.tab.currentDeviceDetail);
          this.factoriesService.setFactoryChanged(factoryChanged);
        }
      });
  }

  activeEdition() {
    this.editMode = true;
    setTimeout(() => {
      document.getElementById('editDeviceName').focus();
    }, 0);
  }

  detectChanges() {
    this.timezone.currentTimeZone$.subscribe((data: string) => {
      this.timezoneBS.next(data);
    });
  }

  refreshData() {
    this.timezoneBS.subscribe(data => {
      if (data) {
        this.destroySuscriptionCollection();
        this.refreshAllGraphics();
      }
    });
  }

  refreshAllGraphics() {
    this.plotlyChartCollection = _.uniq(this.plotlyChartCollection);
    this.graphicsDataType = _.uniq(this.graphicsDataType);
    const auxListItem: ListItem = { uuid: this.tab.uuid, name: this.tab.name };
    this.deviceCollectionToRepresent =
      this.tab.getDeviceCollectionToRepresent(this.tab.getDeviceChildren(), auxListItem);
    this.showAllGraphics();
  }

  showAllGraphics() {
    // se cambia el datatypeCollection por graphicsDataType que incluye los uuid de los tipos de datos por turnos
    this.plotlyChartCollection = [];
    this.loadingChart = true;
    let limitParams;
    for (let i = 0; i < this.graphicsDataType.length; i++) {
      const auxDataType: DeviceGraphDataType[] = [];
      auxDataType.add(this.graphicsDataType[i]);
      this.getDataTypeUnity(this.graphicsDataType[i].uuid);
      this.suscriptionCollection.push(this.ngxChartService.getDeviceDataSource(auxDataType,
        this.deviceCollectionToRepresent,
        this.twoWeeksDateRange.from.unix(),
        this.twoWeeksDateRange.to.unix(),
        InfluxDbEpoch.SEC,
        limitParams)
        .subscribe((dataChartCol: Mapper[]) => {
          const auxDataLabel: string[][] = [];
          const auxDataSets: number[][] = [];
          const auxUUID: string[] = [];
          for (let dataChart of dataChartCol) {
            auxDataLabel.push(dataChart.dataLabel);
            auxDataSets.push(dataChart.dataSet);
            auxUUID.push(dataChart.dataTypeUUID);

          }

          if(dataChartCol.length > 0){
            // HACEMOS UNA COPIA DE LOS DISPOSITIVOS A REPRESENTAR PARA BORRAR LOS QUE NO TENGAN DATOS Y QUE SALGAN LAS LEYENDAS BIEN
          let deviceCollectionAux: DeviceGraphItem[] = [];
          deviceCollectionAux = [...this.deviceCollectionToRepresent];
          const plotlyBuilder: PlotlyChartBuilder = new PlotlyChartBuilder(this.translate);
          this.plotlyChartCollection.push(plotlyBuilder
            .withMapper(dataChartCol)
            .withUuidDeviceCollectionSource(auxUUID)
            .withDeviceCollection(this.deviceCollectionToRepresent)
            .withWidgetName(this.graphicsDataType[i].name)
            .withChartType(PlotlyChartType.SCATTER)
            .withWidgetDataType([])
            .withDataTypeUnity(this.currentUnity[i])
            .withXAxis(auxDataLabel)
            .withYAxis(auxDataSets)
            .build());
          } else {
            this.loadingChart = false;
          }
        },
          err => console.error('Observer got an error: ' + err),
          () => {
            setTimeout(() => {
              this.loadingChart = false;
            }, 0);
          }));
    }
  }

  private getLabels(devices: DeviceGraphItem[]): string[] {
    const ngxDeviceMethods: NgxDevice = new NgxDevice();
    return ngxDeviceMethods.getLabels(devices);
  }

  getDevicesToShow(): DeviceGraphItem[] {
    const auxDeviceCollection: DeviceGraphItem[] = this.tab.getDeviceChildren();
    return _.sortBy(auxDeviceCollection, ['name'], ['asc']);
  }

  destroySuscriptionCollection() {
    for (const susbcription of this.suscriptionCollection) {
      susbcription.unsubscribe();
    }
  }

  getDataTypeCollection(currentDevice: DeviceGraphItem) {
    this.dataTypeService.getDataTypeCollection()
      .subscribe((data: ConsumptionDataType[]) => {
        const aux: DeviceGraphDataType[] = [];
        this.graphicsDataType = [];
        for (const card of data) {
          // añadimos los tipos de datos por turno del json en graphicsDataType
          if (!_.isEmpty(card.grafica.uuid)) {
            this.graphicsDataType.add(card.grafica);
          }

          for (const dato of card.datos) {
            if (!_.isEmpty(dato.uuid) && !_.isEmpty(dato.name)) {
              const dataType: DeviceGraphDataType = new DeviceGraphDataType();
              dataType.uuid = dato.uuid;
              dataType.name = dato.name;
              aux.add(dataType);
            }
          }

        }
        this.dataTypeCollection = aux;
        this.consumptionDataType = data;
        this.fillConsumptionInfo(data, currentDevice);
      },
        error => error,
        () => {
          if (!_.isEmpty(this.dataTypeCollection)) {
            this.showAllGraphics();
          }
        }
      );
  }

  private getDataTypeUnity(dataTypeUUID: string) {
    this.dataTypeService.getDataTypeUnity(dataTypeUUID).subscribe(
      (data: string) => {
        this.currentUnity.push(data);
      }
    );
  }

  private fillConsumptionInfo(dataCollection: ConsumptionDataType[], currentDevice: DeviceGraphItem) {
    for (const data of dataCollection) {
      for (const dato of data.datos) {
        this.getDataOfInflux(currentDevice, dato.uuid, dato.name);
      }
    }

    this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption = new DeviceConsumption();

  }

  private getDataOfInflux(currentDevice: DeviceGraphItem, uuid: string, name: string) {
    const deviceParams: WidgetBasicParam = new WidgetBasicParam();
    const dataType: DeviceGraphDataType = new DeviceGraphDataType();
    const dataTypeCollection: DeviceGraphDataType[] = [];
    dataType.uuid = uuid;
    dataType.name = name;
    dataTypeCollection.push(dataType);
    deviceParams.dataType = dataTypeCollection;
    deviceParams.devices = [];
    deviceParams.devices.push(currentDevice);
    this.widgetMapperService.getDeviceDataSource(deviceParams, InfluxDbEpoch.SEC).subscribe(
      data => {
        /* if (data !== undefined) {
          this.responseConsumptionBS = new BehaviorSubject<number>(0);
          this.responseConsumptionObservable = this.responseConsumptionBS.asObservable();
          this.responseConsumptionBS.next(Math.trunc(data.dataSet[0][0]));
          this.responseConsumptionObservable.subscribe(num => {
            this.fillConsumptionCards(num, data.uuid);
          });
        } */
      });
  }

  public fillConsumptionCards(data: number, uuid: string) {
    if (uuid === this.consumptionDataType[0].datos[0].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.waterConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.waterConsumption.monthValue = data;
    }

    if (uuid === this.consumptionDataType[0].datos[1].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.waterConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.waterConsumption.yearValue = data;
    }

    if (uuid === this.consumptionDataType[0].datos[2].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.waterConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.waterConsumption.accumulatedValue = data;
    }

    if (uuid === this.consumptionDataType[1].datos[0].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption.monthValue = data;
    }

    if (uuid === this.consumptionDataType[1].datos[1].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption.yearValue = data;
    }

    if (uuid === this.consumptionDataType[1].datos[2].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.energyConsumption.accumulatedValue = data;
    }

    if (uuid === this.consumptionDataType[2].datos[0].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.gasConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.gasConsumption.monthValue = data;
    }

    if (uuid === this.consumptionDataType[2].datos[1].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.gasConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.gasConsumption.yearValue = data;
    }

    if (uuid === this.consumptionDataType[2].datos[2].uuid) {
      this.tab.currentDeviceDetail.info.consumptionInfo.gasConsumption = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.gasConsumption.accumulatedValue = data;
    }

    if (uuid === this.consumptionDataType[3].datos[0].uuid && this.showProductivityCard) {
      this.tab.currentDeviceDetail.info.consumptionInfo.productivity = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.productivity.monthValue = data;
    }

    if (uuid === this.consumptionDataType[3].datos[1].uuid && this.showProductivityCard) {
      this.tab.currentDeviceDetail.info.consumptionInfo.productivity = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.productivity.yearValue = data;
    }

    if (uuid === this.consumptionDataType[3].datos[2].uuid && this.showProductivityCard) {
      this.tab.currentDeviceDetail.info.consumptionInfo.productivity = new DeviceConsumption();
      this.tab.currentDeviceDetail.info.consumptionInfo.productivity.accumulatedValue = data;
    }

  }

  public get deviceChildren(): DeviceModel[] {
    if (this.tab === null || this.tab === undefined) {
      return [];
    }

    return this.tab.getDeviceChildren();
  }

  selectedDeviceGraphItem(selected: DeviceModel) {
    this.loading = true;
    this.title = selected.name;
    this.tab.currentDeviceDetail.children = [];
    this.dispositivoService.getDeviceDetailGraphQl(selected.uuid).subscribe(item => {
      const device = item[0];
      this.deviceType = device.deviceType;
      this.dispositivoService.getDeviceChildrenGraphQl(selected.uuid).subscribe(
        childrenItem => {
          childrenItem.removeBy(childItem => childItem.uuid === selected.uuid);
          this.childrenDeviceCollectionToShow = _.sortBy(childrenItem, ['name'], ['asc']);
          device.children = _.sortBy(childrenItem, ['name'], ['asc']);
          const auxListItem: ListItem = { uuid: device.uuid, name: device.name };
          this.deviceCollectionToRepresent = this.tab.getDeviceCollectionToRepresent(device.children, auxListItem);
          this.tab.navigate(device);
          const currentDevice: DeviceGraphItem = new DeviceGraphItem();
          currentDevice.uuid = selected.uuid;
          currentDevice.name = selected.name;
          this.getDataTypeCollection(currentDevice);
          this.loading = false;
        });
    }
    );
  }

  navigateOfMenuStack(device: DeviceModel) {
    this.loading = true;
    this.title = device.name;
    this.tab.currentDeviceDetail.children = [];
    this.dispositivoService.getDeviceDetailGraphQl(device.uuid).subscribe(item => {
      const device = item[0];
      this.deviceType = device.deviceType;
      this.dispositivoService.getDeviceChildrenGraphQl(device.uuid).subscribe(
        childrenItem => {
          childrenItem.removeBy(childItem => childItem.uuid === device.uuid);
          this.childrenDeviceCollectionToShow = _.sortBy(childrenItem, ['name'], ['asc']);
          device.children = _.sortBy(childrenItem, ['name'], ['asc']);
          const auxListItem: ListItem = { uuid: device.uuid, name: device.name };
          this.deviceCollectionToRepresent = this.tab.getDeviceCollectionToRepresent(device.children, auxListItem);
          this.tab.navigateOfMenuStack(device);
          const currentDevice: DeviceGraphItem = new DeviceGraphItem();
          currentDevice.uuid = device.uuid;
          currentDevice.name = device.name;
          this.getDataTypeCollection(currentDevice);
          this.loading = false;

        });
    }
    );
  }

  navigate(item: DeviceModel) {
    this.destroySuscriptionCollection();
    this.selectedDeviceGraphItem(item);
  }

  stackNavigation(item: DeviceModel, index: number) {
    this.deleteOnStack(index);
    this.navigateOfMenuStack(item);
  }

  private deleteOnStack(index: number) {
    const arrayToDelete = this.tab.navegationStack.reverse();
    for (let i = -1; i < index; i++) {
      arrayToDelete.splice(0, 1);
    }
    arrayToDelete.reverse();
  }

  onGoBack(parent: DeviceModel) {
    this.destroySuscriptionCollection();
    this.loading = true;
    this.title = parent.name;
    this.tab.currentDeviceDetail.children = [];
    this.dispositivoService.getDeviceDetailGraphQl(parent.uuid).subscribe(item => {
      const device = item[0];
      this.dispositivoService.getDeviceChildrenGraphQl(parent.uuid).subscribe(
        childrenItem => {
          childrenItem.removeBy(childItem => childItem.uuid === parent.uuid);
          this.childrenDeviceCollectionToShow = _.sortBy(childrenItem, ['name'], ['asc']);
          device.children = _.sortBy(childrenItem, ['name'], ['asc']);
          const auxListItem: ListItem = { uuid: device.uuid, name: device.name };
          this.deviceCollectionToRepresent = this.tab.getDeviceCollectionToRepresent(device.children, auxListItem);
          this.tab.goBack(device);
          const currentDevice: DeviceGraphItem = new DeviceGraphItem();
          currentDevice.uuid = parent.uuid;
          currentDevice.name = parent.name;
          this.getDataTypeCollection(currentDevice);
          this.loading = false;
        });
    }
    );
    this.childrenDeviceCollectionToShow = this.getDevicesToShow();
  }
}
