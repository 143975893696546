import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface UpdateDevice {
    deviceUUID: string;
    newDeviceName: string;
}

export class UpdateDeviceBuilder {
    private _DEVICEUUID: string;
    private _NEWDEVICENAME: string;

    private get _mutation() {
        return gql `mutation actualizarDispositivo($deviceUUID: ID!, $newDeviceName: String) {
            UpdateDispositivo(uuid:$deviceUUID, nombre: $newDeviceName) {
              uuid
              nombre
            }
          }`;
    }

    public withDeviceUUID(deviceUUID: string): this {
        this._DEVICEUUID = deviceUUID;
        return this;
    }

    public withNewDeviceName(newDeviceName: string): this {
        this._NEWDEVICENAME = newDeviceName;
        return this;
    }

    public build(): MutationOptions {
        const mutationOptions: UpdateDevice = {deviceUUID: this._DEVICEUUID, newDeviceName: this._NEWDEVICENAME};
        const grapqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return grapqlMutationBuilder
        .withMutation(this._mutation)
        .withParams(mutationOptions)
        .build();
    }
}
