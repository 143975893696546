import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export class AllUserGraphQLBuilder {
    private get _query(): DocumentNode {
        return gql`{
          usuarios {
            uuid
            nombre
            usuario
            apellidos
            zonaHoraria
            idioma
            mail
            enabled
            rol
            dispositivos {
              to {
                uuid
                nombre
              }
            }
          }
          }
          `;
    }

    public build(): PureQueryOptions {
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQlBuilder
            .withQuery(this._query)
            .build();
    }
}
