import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlarmDetailComponent } from 'src/app/sections/alarms/alarm-detail/alarm-detail.component';
import { AlarmBudgetComponent } from 'src/app/components/alarm-budget/alarm-budget.component';
import { AlarmTableComponent } from 'src/app/components/alarm-table/alarm-table.component';
import { AlarmDataTypeComponent } from 'src/app/components/alarm-data-type/alarm-data-type.component';
import { AlarmDataTypeNavigateComponent } from 'src/app/components/alarm-data-type-navigate/alarm-data-type-navigate.component';
import { AlarmFormComponent } from 'src/app/sections/alarms/alarm-form/alarm-form.component';


import { SharedModuleModule } from '../shared-module/shared-module.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material/material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AlarmFormComponent,
    AlarmDetailComponent,
    AlarmBudgetComponent,
    AlarmTableComponent,
    AlarmDataTypeNavigateComponent,
    AlarmDataTypeComponent,
  ],
  imports: [
    CommonModule,
    SharedModuleModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    AlarmFormComponent,
    AlarmDetailComponent,
    AlarmBudgetComponent,
    AlarmTableComponent,
    AlarmDataTypeNavigateComponent,
    AlarmDataTypeComponent,
  ]
})
export class AlarmModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
