import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DateRange } from 'src/app/models/dateRange.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { InfluxQueryBuilder } from 'src/app/models/influx-query-builder';
import { ToastService } from '../toast/toast.service';
import { InfluxWidgetQueryBuilder } from 'src/app/models/influxWidget-query';
import { LimitInfluxParams } from 'src/app/models/limitInfluxParams.model';


export interface InfluxDbHttpRequest {
  url: string;
  options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
  };
}

export interface InfluxDbHttpResponse {
  columns: string[];
  name: string;
  values: number[][];
}

export interface InfluxDbHttpResponseDataType {
  columns: string[];
  dataTypeUUID: string[];
  codigo: string;
  values: number[][];
  name?: string;
}

export interface InfluxDbHttpResponseWidget {
  results: {
    series: {
      columns: string[];
      name: string;
      values: number[][];
    }
  };
}



export interface InfluxDbDataParameter {
  dataTypeUUID: string;
  from?: number,
  to?: number
  epoch: InfluxDbEpoch;
  limitParams?: LimitInfluxParams;
}
export interface InfluxDbDataWidgetParameter {
  dataTypeUUID: string;
  epoch: InfluxDbEpoch;
}


export interface InfluxDbDeviceParameter extends InfluxDbDataParameter {
  deviceUUID: string;
}
export interface InfluxDbDeviceWidgetParameter extends InfluxDbDataWidgetParameter {
  deviceUUID: string;
}

export interface InfluxDbMultipleDeviceParameter extends InfluxDbDataParameter {
  deviceUUIDCollection: string[];
}

export enum InfluxDbEpoch {
  MS = 'ms',
  SEC = 's'
}
@Injectable({
  providedIn: 'root'
})
export class InfluxdbService {

  private query: string;

  constructor(private http: HttpClient) {
  }

  public executeQueryGenericResponse<TResult>(parameter: InfluxDbDeviceParameter): Observable<TResult> {
    const request: InfluxDbHttpRequest = this.buildInfluxRequest(parameter);
    return this.http.get<TResult>(request.url, request.options);
  }
  public executeQueryGenericWidgetResponse<TResult>(parameter: InfluxDbDeviceWidgetParameter): Observable<TResult> {
    const request: InfluxDbHttpRequest = this.buildInfluxWigetRequest(parameter);
    return this.http.get<TResult>(request.url, request.options);
  }
  public executeQuery(parameter: InfluxDbDeviceParameter): Observable<InfluxDbHttpResponse> {
    return this.executeQueryGenericResponse<InfluxDbHttpResponse>(parameter)
      .pipe(
        map(result => this.mapToInfluxDbHttpResponse(result))
      );
  }
  mapToInfluxDbHttpResponse(result: any): InfluxDbHttpResponse {
    if (result.results[0].series === null || result.results[0].series === undefined) {
      // this.toastService.showWarning('No hay datos en el intervalo seleccionado', 'No disponible');
      const resultEmpty: InfluxDbHttpResponse = {
        columns: [],
        name: '',
        values: []
      };
      return resultEmpty;
    } else {
      return result.results[0].series[0];
    }
  }

  public executeQueryDataType(parameter: InfluxDbDeviceParameter): Observable<InfluxDbHttpResponseDataType[]> {
    return this.executeQueryGenericResponse<InfluxDbHttpResponseDataType[]>(parameter)
      .pipe(
        map(result => this.mapToInfluxDbHttpResponseDataType(result))
      );
  }
  mapToInfluxDbHttpResponseDataType(result: any): InfluxDbHttpResponseDataType[] {
    if (result.results[0].series === null || result.results[0].series === undefined) {
      const resultEmpty: InfluxDbHttpResponseDataType[] = [{
        columns: [],
        codigo: '',
        dataTypeUUID: [],
        values: []
      }];
      return resultEmpty;
    } else {
      return result.results[0].series;
    }
  }

  private buildInfluxRequest(parameter: InfluxDbDeviceParameter): InfluxDbHttpRequest {
    const influxdbRequest: InfluxDbHttpRequest = {
      url: `${environment.influxDbHost}/query`,
      options: {
        headers: this.buildInfluxRequestHeader(),
        params: this.buildInfluxRequestParams(parameter)
      }
    };
    return influxdbRequest;

  }
  private buildInfluxWigetRequest(parameter: InfluxDbDeviceWidgetParameter): InfluxDbHttpRequest {
    const influxdbRequest: InfluxDbHttpRequest = {
      url: `${environment.influxDbHost}/query`,
      options: {
        headers: this.buildInfluxRequestHeader(),
        params: this.buildInfluxRequestWidgetParams(parameter)
      }
    };
    return influxdbRequest;

  }

  private buildInfluxRequestHeader(): HttpHeaders {
    const requestHeader: HttpHeaders = new HttpHeaders();
    const authdata = window.btoa(`${environment.influxUser}:${environment.influxPassword}`);
    return requestHeader.set('Authorization', `Basic ${authdata}`);
  }

  private buildInfluxRequestParams(parameter: InfluxDbDeviceParameter): HttpParams {
    const requestParams = new HttpParams();
    const influxQueryBuilder: InfluxQueryBuilder = new InfluxQueryBuilder();
    this.query = influxQueryBuilder
      .withDeviceUuid(parameter.deviceUUID)
      .withDataTypeUuid(parameter.dataTypeUUID)
      .withDateFrom(parameter.from)
      .withDateTo(parameter.to)
      .withLimitParams(parameter.limitParams)
      .build();
    return requestParams.set('db', environment.influxDb)
      .set('epoch', parameter.epoch)
      .set('q', this.query);

  }
  private buildInfluxRequestWidgetParams(parameter: InfluxDbDeviceWidgetParameter): HttpParams {
    const requestParams = new HttpParams();
    const influxQueryBuilder: InfluxWidgetQueryBuilder = new InfluxWidgetQueryBuilder();
    this.query = influxQueryBuilder
      .withDeviceUuid(parameter.deviceUUID)
      .withDataTypeUuid(parameter.dataTypeUUID)
      .build();
    return requestParams.set('db', environment.influxDb)
      .set('epoch', parameter.epoch)
      .set('q', this.query);

  }

  /*  private buildInfluxRequestParams(parameter: InfluxDbParameter): HttpParams {
     const requestParams = new HttpParams();

     return requestParams.set('db', environment.influxDb)
                  .set('epoch', parameter.epoch)
                  .set('q', this.testInfluxQuery());

   } */



  /*  public testInfluxQuery() {
     const influxQuery = 'SELECT "340b208b-dea5-4deb-a4c3-d52147e83577" as "340b208b-dea5-4deb-a4c3-d52147e83577" FROM "53aec960-1622-4cc1-830c-e62dba9f3377" WHERE time >= 1543536000s and time <= 1546297200s ';
     return influxQuery;
   } */
}
