import gql from 'graphql-tag';

////////////////////////////
// definir los interfaces //
////////////////////////////
// Interfaces
export interface ReportItM {
  uuid: string;
  titulo: string;
  descripcion: string;
  codigo: string;
  logo: string;
  idioma: string;
  imagen: string;
  fechaImplantacion: any;
  paramsDis: any [];
  paramsTipoDato: any[];
}

export interface RegistroMae{
  report: ReportItM;
  fabrica: { 
      uuid:string;
      codigo:string;
      fabuuid:string;
      fabnombre:string;
  };
  dispositivo:{
    uuid:string; 
    codigo:string;
    disuuid:string;
    disnombre:string;
  };
  mensual:{
    uuid:string; 
    unidadK: string; 
    unidadT:string;
    periodo:string;
    codigo:string;
    tduuid:string;
    tdnombre:string;
  };
  horario:{
    uuid:string; 
    unidadK:string, 
    unidadT:string;
    periodo:string;
    codigo:string;
    tduuid:string;
    tdnombre:string;
  };
} 

export interface DatoUuid {
  dato: {
    uuid: string;
  };
}
export interface Nume {
  dato: {
    numero: number;
  };
}

export interface Dato {
    uuid: string;
    periodo: string;
    tiene: Dispositivo;
    es: TipoDato;
}
export interface TipoDato {
    uuid: string;
    nombre: string;
    unidad: string;
  }
export interface Dispositivo {
    uuid: string;
    nombre: string;
    tiene: Dato [];
  }
export interface Datos {
    datos: Dato [];
}
export interface IQuery {
  datos: any;
}

///////////////
// Mutations //
///////////////



/////////////
// QUERYS //
////////////
 

// Todos los datos asociados a un report
export const QueryReportMae = gql`
query QueryReportMae($uuid: String) {
  datos: findReportMae(uuid: $uuid) {
    codigo
    idioma
    imagen
    logo
    titulo
    descripcion
    fechaImplantacion
    uuid
    paramsDis {
      codigo
      uuid
      to {
        descripcion
        nombre
        uuid
        __typename
      }
      __typename
    }
    paramsTipoDato {
      codigo
      periodo
      unidadK
      unidadT
      uuid
      to {
        codigo
        descripcion
        nombre
        uuid
        __typename
      }
      __typename
    }
    __typename
  }
}`;
