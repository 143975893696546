import { Injectable } from '@angular/core';
import { AcNotification } from 'angular-cesium/lib/angular-cesium';
import { element } from 'protractor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { GeoDevicesService, LastDataDevice, TipoDato } from '../../services/mapa/geodevices.service';
export interface MapElements {
  uuid: string;
  nombre: string;
  longitud: number;
  latitud: number;
  labels: string[];
  __typename: string;
}

export interface MapElementData {
  entityMap: AcNotification,
  lastDataDevice: LastDataDevice,
  selected: boolean
}

@Injectable({
  providedIn: 'root'
})
export class MapaServiceService {
  private mapElements$: BehaviorSubject<MapElementData[]> = new BehaviorSubject([]);
  mapElementsFiltered$: Observable<MapElementData[]>;
  mapFilterText$: BehaviorSubject<string> = new BehaviorSubject('');

  tipeDataSelected$: BehaviorSubject<TipoDato[]> = new BehaviorSubject([])

  constructor(private geoDevicesService: GeoDevicesService) {
    this.mapElementsFiltered$ = this.mapElements$.pipe(switchMap(elements => {
      return this.mapFilterText$.pipe(map(
        text => elements.filter(element => JSON.stringify(element).toLowerCase().includes(text.toLowerCase()))
      ))
    }))
  }


  getElementData(elementId) {
    return this.mapElements$.pipe(map(elements => elements.find(e => e.entityMap.id === elementId)))
  }

  getDataElementById(elementId): MapElementData {
    const elements = this.mapElements$.value;
    return elements.find(element => element.entityMap.id === elementId)
  }

  addElement(element: {
    entityMap: AcNotification,
    lastDataDevice: LastDataDevice, selected: boolean
  }) {
    const oldElements = this.mapElements$.value;
    oldElements.push(element);
    this.mapElements$.next(oldElements);
  }

  addAllElements(elements: {
    entityMap: AcNotification,
    lastDataDevice: LastDataDevice, selected: boolean
  }[]) {
    this.mapElements$.next(elements)
  }


  getDeviceData(deviceId): Observable<LastDataDevice> {
    return this.geoDevicesService.getLastDataDispositivo(deviceId)
  }

  removeTipoDato(codigo: string) {
    const oldValues = [...this.tipeDataSelected$.value];
    const index = oldValues.findIndex(value => value.codigo === codigo)
    oldValues.splice(index, 1);
    this.tipeDataSelected$.next(oldValues)
  }

  addTipoDato(dato: TipoDato) {
    const oldData = this.tipeDataSelected$.value;
    oldData.push(dato);
    this.tipeDataSelected$.next(oldData);
  }

  resetTipoDato() {
    this.tipeDataSelected$.next([])
  }
}
