import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Color, BaseChartDirective } from 'ng2-charts';
import { NgxChartElementColor, NgxChart, NgxChartType } from 'src/app/models/ngx-chart.model';
import { ColorsService } from 'src/app/services/colors/colors.service';
import { ChangeColor } from '../../side-graphs/side-graphs.component';
import 'chartjs-plugin-zoom';
import { PlotlyWidget } from 'src/app/models/plotly/plotly-chart.model';

@Component({
  selector: 'app-ng-chart',
  templateUrl: './ng-chart.component.html',
  styleUrls: ['./ng-chart.component.scss']
})
export class NgChartComponent implements OnInit, OnChanges {

  @Input() plotlyChart: PlotlyWidget;

  @Input() ngxChart: NgxChart;
  @Input() hideShowDevice: number;
  @Input() changeColor: ChangeColor;
  @Input() legend: boolean;
  @Input() opacity: string; 
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() changeChartType: NgxChartType;
  ngxMethods: NgxChartElementColor = new NgxChartElementColor();
  public lineChartColors: Color[] = [];
  chartOptions;

  constructor(
    private colorsService: ColorsService) {}

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
  }

  assignChartOptions(chartType:NgxChartType) {
    if(chartType == NgxChartType.PIE) 
      this.chartOptions = {
        responsive: true,
        legend: {
          position: 'top',
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              const label = ctx.chart.data.labels[ctx.dataIndex];
              return label;
            },
          },
        }
      };
    else 
      this.chartOptions  ={
        tooltip: { intersect: false },
        scales: {
          xAxes: [{}],
          yAxes: [
            {
              id: 'y-axis-0',
              position: 'left',
            }       
          ]
        },
        annotation: {
          annotations: [
            {
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: 'March',
              borderColor: 'orange',
              borderWidth: 1,
              label: {
                enabled: true,
                fontColor: 'orange',
                content: 'LineAnno'
              }
            },
          ],
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy',
              rangeMin: {
                x: null,
                y: null
              },
              rangeMax: {
                x: null,
                y: null
              },
            },
            zoom: {
              enabled: true,
              drag: false,
              mode: 'x',
              rangeMin: {
                x: 10,
                y: null
              },
              rangeMax: {
                x: 100,
                y: null
              },
              speed: 1,
            }
          }
        }
      };  
  }

  changeColorFunction(color: ChangeColor) {
    this.lineChartColors[color.index] = color.customColor
  }

  hideShowDeviceFunction(device) {
    if (device)
      this.chart.hideDataset(device.index, !device.hidden);
  }

 
  onChartClick($event) {
  
  }



} 
