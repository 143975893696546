import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewAlarm {
    name: string;
    description: string;
    subject: string;
    body: string;
    uuidTipo: string;
}

export class CreateAlarmBuilder {
    private _ALARMNAME: string;
    private _ALARMDESCRIPTION: string;
    private _ALARMSUBJECT: string;
    private _ALARMBODY: string;
    private _ALARMTYPEUUID: string;

    private get _mutation() {
        return gql`mutation createAlarm($name: String, $description: String, $subject: String, $body: String, $uuidTipo: String) {
            createAlarma(nombre: $name, descripcion: $description, subject: $subject, body: $body, tipo: $uuidTipo) {
              uuid
            }
          }`;
    }

    public withAlarmName(newAlarmName: string): this {
        this._ALARMNAME = newAlarmName;
        return this;
    }

    public withAlarmDescription(newAlarmDescription: string): this {
        this._ALARMDESCRIPTION = newAlarmDescription;
        return this;
    }

    public withAlarmSubject(newAlarmSubject: string): this {
        this._ALARMSUBJECT = newAlarmSubject;
        return this;
    }

    public withAlarmBody(newAlarmBody: string): this {
        this._ALARMBODY = newAlarmBody;
        return this;
    }

    public withAlarmTypeUuid(newAlarmTypeUuid: string): this {
        this._ALARMTYPEUUID = newAlarmTypeUuid;
        return this;
    }

    public build(): MutationOptions {
        let mutationParams: NewAlarm;
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        mutationParams = {
            name: this._ALARMNAME,
            description: this._ALARMDESCRIPTION,
            subject: this._ALARMSUBJECT,
            body: this._ALARMBODY,
            uuidTipo: this._ALARMTYPEUUID
        };
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
