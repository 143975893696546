import { DataType } from './data-type.model';
import { DataTypeDetailAggregateGraphQlResponse, DataTypeGroupGraphQlResponse } from './graphQL/data-type-aggegate-GQL.response';
import { ListItemHierachi } from './contract/list-item.model';
import * as lodash from 'lodash';

export class DataTypeGroup implements ListItemHierachi {

    parent?: ListItemHierachi;
    children?: ListItemHierachi[];
    uuid: string;
    name: string;

    public isLeaf(): boolean {
        return lodash.isEmpty(this.children);
    }
    public addChild(newChild: ListItemHierachi) {
        if(lodash.isEmpty(this.children)){
            this.children = [];
        }
        this.children.add(newChild);
    }
    public hasDescendentById(uuid: String):boolean {
        throw new Error("Method not implemented.");
    }
    public hasAnyDescendentById(uuid: String[]):boolean {
        throw new Error("Method not implemented.");
    }
  /*   public addGrandChild(newGrandChild: ListItemHierachi) {
        if(lodash.isEmpty(this.children[0].children)){
            this.children[0].children = [];
        }
        this.children[0].children.add(newGrandChild);
    } */

}

export class DataTypeAggregate {
    root: DataTypeGroup;
     constructor(data: DataTypeDetailAggregateGraphQlResponse) {
        if (data === undefined || data === null) {
            return;
        }
       // this.root =  this.buildRootAggregate(data);
    }
    /* private buildRootAggregate(data: DataTypeDetailAggregateGraphQlResponse): DataTypeGroup {
        let root: DataTypeGroup = null;
        if (data === null || data === undefined) {
            return null;
        }
        if (data.agrupacion === null || data.agrupacion === undefined) {
            return new DataTypeGroup();
        }
        let currentDataTypeGroupGQ: DataTypeGroupGraphQlResponse = data.agrupacion;
        root = new DataTypeGroup(this.getDataTypeGroupRoot(currentDataTypeGroupGQ));
        let currentDataTypeGroup: DataTypeGroup = root;
        while (this.hasGroup(currentDataTypeGroupGQ)) {
            currentDataTypeGroup.addChild(new DataTypeGroup(this.getDataTypeGroupChildren(currentDataTypeGroupGQ.agrupacion)));
            currentDataTypeGroup = currentDataTypeGroup.child;
            currentDataTypeGroupGQ = currentDataTypeGroupGQ.agrupacion;
        }
        currentDataTypeGroup.dataType = new DataType(data);
        return root;
    } */
  /*   private getDataTypeGroupRoot(data?: DataTypeGroupGraphQlResponse): DataTypeGroupGraphQlResponse {
        if (data === null || data === undefined) {
            return null;
        }
        if (!this.hasGroup(data)) {
            return data;
        }
        return this.getDataTypeGroupRoot(data.agrupacion);
    } */
    private getDataTypeGroupChildren(data?: DataTypeGroupGraphQlResponse): DataTypeGroupGraphQlResponse {
        if (data === null || data === undefined) {
            return null;
        }
        return;
    }
    private hasGroup(dataTypeGroup: DataTypeGroupGraphQlResponse): boolean {
        return dataTypeGroup !== null && dataTypeGroup !== undefined;
    }
}
