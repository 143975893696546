import { Injectable } from '@angular/core';
import { iridiumEvents } from './../../models/graphQL/iridium-events-table-graphQL.request';
import gql from 'graphql-tag';
import { Apollo, Subscription } from 'apollo-angular';
import { IRIDIUM_EVENTS_SUBSCRIPTION } from './../../models/graphQL/subscriptions/iridium-events-graphQL.subscription';

@Injectable({
  providedIn: 'root'
})
export class IridiumSubscriptionGQLService extends Subscription {
  subscriptionToEvents = IRIDIUM_EVENTS_SUBSCRIPTION;
  eventsTableData = iridiumEvents;
  
  listen() {
    return this.apollo.subscribe({
      query: this.subscriptionToEvents,
      fetchPolicy: "no-cache"
    })
  }

  refreshEventTable() {
    return this.apollo.watchQuery<any>({
      query: iridiumEvents,
      fetchPolicy: "network-only"
    })
  }



}