import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'getToFixed',
    pure: true
})
export class ToFixedPipe implements PipeTransform {

    transform(value: number, args?: any): any {
        return this.getToFixed(value)
    }
    getToFixed(n: number): String {
        if (n != null) {
            return n.toFixed(2)
        } else {
            return '';
        }
    }
}