import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { User } from '../../../../models/user.model';
import { ToastrService } from 'ngx-toastr';
import { GraphFunciones } from '../../consultas/consultas-graphql.service';
import { Subscription } from 'rxjs';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';




@Component({
  selector: 'app-informes-tables',
  templateUrl: './informes-tables.component.html',
  styleUrls: ['./informes-tables.component.scss']
})

export class InformesTablesComponent implements OnInit {

  @Input() codigoFilter: string;
  @Input() user: User;
  @Input() year: number;
  @Input() month: number;
  displayedColumns: string[] = ['titulo_informe', 'logo_informe', 'generar_informe'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private querySubscription: Subscription[] = [];
  loading = true;
  pdfString: string;


  constructor(
    private gF: GraphFunciones,
    private toastrservice: ToastrService) {
  }

  ngOnInit() {
    this.checkAdmin();
    const qrepor: Subscription = this.gF.cargarReports()
      .subscribe(({ data, loading }) => {
        const r = data.datos.map(function (obj) { obj['link'] = '/informe-' + obj.codigo; return obj; }).filter(key => key.codigo == this.codigoFilter);
        this.dataSource = new MatTableDataSource(r);
        this.dataSource.paginator = this.paginator;
        this.loading = loading;
      });
    this.querySubscription.push(qrepor);
  }

  generateReport(report) {
    this.pdfString = `/report/${report.codigo}/pdf?uuid=${report.uuid}&anio=${this.year}&mes=${this.month}&idioma=${report.idioma}`
  }


   // Botones
   borrar(report) {
    if (window.confirm('¿Desea eliminar el informe' + report.titulo + '?')) {
      if (report.codigo == 'mae') {
        const qrepor: Subscription = this.gF.borrarReportMae(report.uuid)
          .subscribe(res => {
            this.showToast(res);
          });
        this.querySubscription.push(qrepor);
      }
      if (report.codigo == 'fabrica') {
        const qrepor: Subscription = this.gF.borrarReportFabrica(report.uuid)
          .subscribe(res => {
            this.showToast(res);
          });
        this.querySubscription.push(qrepor);
      }
    }
  }


  checkAdmin() {
    if (this.user.rol.includes('admin')) {
      this.displayedColumns.unshift('uuid_informe');
      this.displayedColumns.push('acciones_informe');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  hideImg(image) {
    image.parentNode.parentNode.style.display = 'none';
  }

  copiado(event: Event) {
    if (event) {
      this.toastrservice.info('', 'uuid copiado al portapapeles',
        { closeButton: true, timeOut: 3000 });
    }
  }

  showToast(res) {
    if (res.data && res.data.datos && res.data.datos != null) {
      this.toastrservice.success('Report eliminado correctamente', 'Éxito',
        { closeButton: true, timeOut: 3000 });
    } else {
      this.toastrservice.error('El report no ha podido ser eliminado', 'Error',
        { closeButton: true, timeOut: 3000 });

    }
  }


}
