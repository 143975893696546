import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-admin',
  templateUrl: './no-credentials.component.html',
  styleUrls: ['./no-credentials.component.scss']
})
export class NoCredentialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
