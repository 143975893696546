import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'getUtcDatePipe',
    pure: true
})
export class GetUtcDatePipe implements PipeTransform {

    transform(value: string, args?: any): any {
        return this.getUtcDatePipe(value)
    }

    getUtcDatePipe(incomingUTCepoch: string): String {
        var dDate = new Date()
        if (incomingUTCepoch != null) {
            var mEpoch = parseInt(incomingUTCepoch); 
            if(mEpoch<10000000000) mEpoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)
            dDate.setTime(mEpoch)
            return dDate.toLocaleString();
        } else {
            return '';
        }
    }
    getInvalidatedUtcDatePipe(invalidated: string): String {
        if (invalidated != null) {
            return new Date(Number(invalidated) / 1000000).toISOString();
        } else {
            return 'Sin invalidar'
        }

    }
    
}