
import { FactorySimpleModel } from './FactorySimple.model';
import { Dashboard } from './widgets/widgets.model';

export class UserModel {
    uuid: string;
    user: string;
    name: string;
    firstName: string;
    timeZone: string;
    language: string;
    state: string;
    email: string;
    rol: string[];
    // pwd: string;
    factories: FactorySimpleModel[];
}

export class UserDashboardModel {
    uuid: string;
    user: string;
    name: string;
    dashboards: [{
        to: Dashboard;
    }];
}
