import { DeviceInfo, DeviceConsumptionInfo } from './deivce-info';
import { NgxChartElementColor } from './ngx-chart.model';
import { ListItemHierachi, State } from './contract/list-item.model';
import {  DeviceChildrenDetailGraphQLResponse } from './graphQL/device-children-graphQL.response';
import { DeviceType } from './deviceType.model';

export class DeviceModel implements ListItemHierachi {
    children?: ListItemHierachi[];

    uuid: string;    
    name: string;
    parent?: ListItemHierachi;
    selectedState?: State;
    childrenNumber?: number;
    
    constructor(response?:DeviceChildrenDetailGraphQLResponse){
        if(response === null || response === undefined)  {
            return
        }
        this.uuid = response.uuid;
        this.name = response.nombre;
        this.selectedState = State.selectable;
        this.childrenNumber = response.estructuraCantidad;
    }
    isLeaf(): boolean {
        throw new Error("Method not implemented.");
    }
    hasDescendentById(uuid: String): boolean {
        throw new Error("Method not implemented.");
    }
    hasAnyDescendentById(uuid: String[]): boolean {
        throw new Error("Method not implemented.");
    }
}

export class Device implements ListItemHierachi {
  
    uuid: string;
    name: string;
    color?: NgxChartElementColor;
    parent?: DeviceModel;
    info?: DeviceInfo;
    preview?: DeviceInfo;
    children?: DeviceModel[];
    deviceType?:DeviceType;

    constructor(response: DeviceGraphQLResponse) {
        if(response === null || response === undefined)  {
            return
        }
        this.uuid = response.uuid;
        this.name = response.nombre;
        this.deviceType = response.deviceType;
    }

    public getConsumptionDetail(): DeviceConsumptionInfo {
        if (this.info === null || this.info === undefined) {
            return null;
        }
        return this.info.consumptionInfo
    }
   public setConsumptionDetail(newDeviceInfo: DeviceInfo): any {
        this.info = newDeviceInfo;
      }
      isLeaf(): boolean {
        throw new Error("Method not implemented.");
    }
      public hasDescendentById(uuid: String):boolean {
        throw new Error("Method not implemented.");
    }
    public hasAnyDescendentById(uuid: String[]):boolean {
        throw new Error("Method not implemented.");
    }
}

export interface DeviceGraphQLResponse {
    uuid: string;
    nombre: string;
    parent?: string;
    deviceType?: DeviceType;
}
