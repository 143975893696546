import { Mapper } from './../../services/ngx-chart-mapper/ngx-chart-mapper.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { Subscription, BehaviorSubject, forkJoin, from } from 'rxjs';
import { GeneratedDate, TabStepperData } from '../side-graphs/side-graphs.component';
import { NgxDevice } from 'src/app/models/ngxDevice.model';
import { DateRange } from 'src/app/models/dateRange.model';
import { MatDialog, MatSidenav } from '@angular/material';
import { DateRangeComponent } from '../date-range/date-range.component';
import { InfluxDbEpoch } from 'src/app/services/influxdb/influxdb.service';
import { NgxChartMapperService } from 'src/app/services/ngx-chart-mapper/ngx-chart-mapper.service';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import * as _ from 'lodash';
import { PlotlyChartBuilder } from 'src/app/models/plotly/plotlyChartBuilder';
import { PlotlyWidget, PlotlyChartType, PlotlyData, ChartOrientationType } from 'src/app/models/plotly/plotly-chart.model';
import { DataTypeWidgetParam } from 'src/app/models/widgets/widget-interfaces.model';
import { TimezoneService } from 'src/app/services/timezone.service';
import { DataTypeItem } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DevicesRepresentedService } from 'src/app/services/addDevices/devices-represented.service';
import { ListDashboardComponent } from '../list-dashboard/list-dashboard.component';
import { SettingsService } from 'src/app/services/settings/settings.service';
import moment from 'moment-timezone';
import { DashboardService } from 'src/app/services/widgets/dashboards.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { WidgetParameter } from '../tab-stepper/tab-stepper.component';
import { ToolsService } from 'src/app/services/tools/tools.service';
import { LoginService } from 'src/app/services/login/login.service';
import { LimitInfluxParams } from 'src/app/models/limitInfluxParams.model';
import { AlarmService } from 'src/app/services/alarm/alarm.service';



interface DataRepresentedCollection {
  deviceCollection: Array<DeviceGraphItem>;
  dataTypeCollection: Array<DataTypeItem>;
}

export interface UpdateGraphTitleModel {
  graphicNumber: number;
  title: string;
}

export interface DataSource {
  uuid: string;
  name: string;
}

@Component({
  selector: 'app-ng-chart-generator',
  templateUrl: './ng-chart-generator.component.html',
  styleUrls: ['./ng-chart-generator.component.scss']
})

export class NgChartGeneratorComponent implements OnInit {
  @Input() generatedCharts: TabStepperData;
  @Input() numberGraph: number;
  @Input() dataCollection: DataRepresentedCollection[];
  @Input() graphCounter: number;
  @Input() chosenPeriod: boolean;
  @Input() periodObject: WidgetParameter;
  @Output() rangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedChart?: EventEmitter<TabStepperData> = new EventEmitter<TabStepperData>();
  @Output() updateTitleEmitter: EventEmitter<UpdateGraphTitleModel> = new EventEmitter<UpdateGraphTitleModel>();
  @Output() deleteEmitter: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @ViewChild('calendarEmit', { static: false }) calendarEmit: DateRangeComponent;

  heightScreen = window.screen.availHeight * 0.665;
  dataSource: DataSource[] = [];
  generated = false;
  generatedDate: GeneratedDate[];
  currentDate: DateRange;
  period: any;
  chartTitle: string;
  event: any[] = [];
  tipoDatoSelected: DeviceGraphDataType[];
  unidadSelected: string;
  chartType: PlotlyChartType;
  loading: boolean;
  datatypeRepresented: DeviceGraphDataType[] = [];
  devicesRepresented: Array<DeviceGraphItem> = [];
  plotlyChart: PlotlyWidget = null;
  dataTypeCollection: DataTypeWidgetParam[] = [];
  public datachartSuscription: Subscription;
  timezoneBS: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedDevices: Array<DeviceGraphItem> = [];
  selectedDatatype: DataTypeItem[] = [];
  loadingText = 'LOADING.GRAPHIC';
  editMode = false;
  exportPath: string;
  orientation: ChartOrientationType;
  minParam: number;
  maxParam: number;
  limitParam: number;
  dateWithOffset: DateRange = new DateRange(moment(), moment());
  tiposDatoTitulo: string[] = [];
  offset: number;
  timeZone: string;
  auxMapper: any;


  constructor(
    public dialog: MatDialog,
    private timeZoneService: TimezoneService,
    private ngxChartService: NgxChartMapperService,
    private settingsService: SettingsService,
    private representedService: DevicesRepresentedService,
    private dashboardService: DashboardService,
    private toastService: ToastService,
    private translate: TranslateService,
    private toolsService: ToolsService,
    private loginService: LoginService,
    private alarmService: AlarmService,
  ) {

  }

  ngOnInit() {
    this.detectChanges();
    this.refreshData();
    this.timeZone = this.loginService.currentUserValue.timeZone;
    this.setExportSettings();
  }

  private setExportSettings() {
    this.settingsService.getExportPathSettings().subscribe((data: string) => {
      this.exportPath = data;
      let date: string;
      let dispositivos = '';
      let tipoDatos = '';
      let auxTipoDatoTitulo = [];
      const timezone = this.loginService.currentUserValue.timeZone;
      date = 'from=' + this.currentDate.from
        + '&to=' + this.currentDate.to;
      for (let i = 0; i < this.devicesRepresented.length; i++) {
        dispositivos += this.devicesRepresented[i].uuid;
        if (i !== this.devicesRepresented.length - 1) {
          dispositivos += ',';
        }
      }
      for (let i = 0; i < this.tipoDatoSelected.length; i++) {
        tipoDatos += this.tipoDatoSelected[i].uuid;
        this.translate.get('DATA_TYPES_AND_AGRUPATIONS.'.concat(this.tipoDatoSelected[i].name)).subscribe((translated: string) => {
          auxTipoDatoTitulo.push(translated);
        });
        if (i !== this.tipoDatoSelected.length - 1) {
          tipoDatos += ',';
        }
      }
      this.tiposDatoTitulo = [...new Set(auxTipoDatoTitulo)];

      this.exportPath += '?' + date + '&dispositivos=' + dispositivos
        + '&tipoDatos=' + tipoDatos + '&zona=' + encodeURIComponent(timezone);
    });
  }

  exportGraph() {
    
    this.setExportSettings();
    setTimeout(() => {
      window.open(this.exportPath);
    }, 500);
  }

  deleteChart() {
    this.deleteEmitter.emit(this.numberGraph);
  }

  onFocusOut() {
    this.editMode = false;
    const newTitle: UpdateGraphTitleModel = { graphicNumber: this.numberGraph, title: this.chartTitle };
    this.updateTitleEmitter.emit(newTitle);
  }

  activeEdition() {
    this.editMode = true;
    setTimeout(() => {
      document.getElementById('editTitle').focus();
    }, 0);
  }

  refreshData() {
    this.timezoneBS.subscribe(data => {
      if (data) {
        this.deleteDataChart();
        this.generatedCharts.rangeDate = this.dateWithOffset;
        this.generateChart(this.generatedCharts);
        this.setExportSettings();
      }
    });
  }

  deleteDataChart() {
    if (this.plotlyChart !== null) {
      this.plotlyChart.dataChart = [];
    }
  }

  detectChanges() {
    this.timeZoneService.currentTimeZone$.subscribe((data: string) => {
      this.timezoneBS.next(data);
    });
  }

  getDate($event: DateRange) {
    this.applyTimezoneToDateRange($event);
    const auxDatasource = [];
    for (const aux of this.dataSource) {
      if (aux.uuid !== undefined) {
        auxDatasource.push({ name: aux.name, uuid: aux.uuid });
      }
    }
    this.currentDate = new DateRange($event.from, $event.to);
    const oData: TabStepperData = {
      chartTitle: this.chartTitle,
      devices: auxDatasource,
      tipoDato: this.tipoDatoSelected,
      unidad: this.unidadSelected,
      rangeDate: new DateRange(this.dateWithOffset.from, this.dateWithOffset.to),
      period: this.period,
      chartType: this.chartType,
      orientation: this.orientation,
      min: this.minParam,
      max: this.maxParam,
      limit: this.limitParam,
      timeZone: this.timeZone
    };
    this.generateChart(oData);
  }

  toRemoveDeviceFromCollection(toRemoveIndex: number[], deviceCollection: DeviceGraphItem[]) {
    _.pullAt(deviceCollection, toRemoveIndex);
  }

  public generateChart($event: TabStepperData) {
    this.dateWithOffset.from = $event.rangeDate.from;
    this.dateWithOffset.to = $event.rangeDate.to;
    this.plotlyChart = null;
    this.loading = true;
    this.devicesRepresented = this.removeDisplayFieldFromUuid($event.devices);
    this.chartTitle = $event.chartTitle;
    this.tipoDatoSelected = $event.tipoDato;
    this.chartType = $event.chartType;
    this.unidadSelected = $event.unidad;
    this.currentDate = new DateRange($event.rangeDate.from, $event.rangeDate.to);
    this.orientation = $event.orientation;
    this.minParam = $event.min;
    this.maxParam = $event.max;
    this.limitParam = $event.limit;
    // Extraemos el offset para saber cuántos datos hay que mover/reemplazar
    this.timeZoneService.currentTimeZone$.subscribe(
      result => {
        this.timeZone = result;
      }
    );

    this.alarmService.getDatoUuid($event.devices[0].uuid, $event.tipoDato[0].uuid).subscribe(
      dato => {
        let limitParams: LimitInfluxParams;
        if (dato !== undefined) {
          limitParams = {
            uuid: dato.uuid,
            ptop: dato.ptop,
            pbottom: dato.pbottom
          };
        }
        setTimeout(() => {
          let mapperAuxArray = [new Mapper()];
          const timezone = this.loginService.currentUserValue.timeZone
          const from = moment.tz($event.rangeDate.from.format("YYYY-MM-DD"), timezone);
          const to = moment.tz($event.rangeDate.to.format("YYYY-MM-DD"), timezone).add(1, 'days');

          this.ngxChartService.getDeviceDataSource($event.tipoDato, $event.devices, from.unix(), to.unix(), InfluxDbEpoch.SEC, limitParams)
            .subscribe(dataChartCol => {
              if (dataChartCol.length > 0) {
                const auxDataLabel: any = [] //= [];
                const auxDataSets: any = [];
                const auxMapperDataLabel: any[][] = [];
                const auxMapperDataSet: any[][] = [];
                let datasets: any = [];
                let idxKey: number = 0;
                let array = [];
                const auxUUID: string[] = [];
                for (let z = 0; z < dataChartCol.length; z++) {
                  for (let elem of $event.tipoDato) {
                    if (elem.uuid == dataChartCol[z].dataTypeUUID)
                      dataChartCol[z].codigo = elem.codigo;
                  }
                  auxDataLabel.push(dataChartCol[z].dataLabel);
                  auxDataSets.push(dataChartCol[z].dataSet);
                  if (auxUUID.indexOf(dataChartCol[z].deviceUUID) === -1) auxUUID.push(dataChartCol[z].deviceUUID); // modificado
                }
                datasets = _.groupBy(dataChartCol, item => {
                  return item.codigo + item.deviceUUID;
                });
                array.push(datasets)
                for (let i = 0; i < array.length; i++) {
                  // Iteramos por el par deviceUUID+datatypeUUID
                  for (let key of Object.keys(array[i])) {
                    let mapperAux = new Mapper();
                    let dl: any = [];
                    let ds: any = [];
                    for (let j = 0; j < array[i][key].length; j++) {
                      dl = dl.concat(array[i][key][j].dataLabel)
                      ds = ds.concat(array[i][key][j].dataSet)
                      if (j == array[i][key].length - 1) {
                        let prueba: any = [];
                        for (let z = 0; z < dl.length; z++) {
                          prueba.push({ ts: dl[z], value: ds[z] })
                        }
                        let sortedData = prueba.sort(({ ts: a }, { ts: b }) => a - b);
                        let dsAux = sortedData.map(function (obj) {
                          return obj.value;
                        });
                        let dlAux = sortedData.map(function (obj) {
                          return obj.ts;
                        });
                        auxMapperDataLabel.push(dlAux);
                        auxMapperDataSet.push(dsAux);
                        mapperAux.codigo = array[i][key][0].codigo;
                        mapperAux.dataLabel = dlAux;
                        mapperAux.dataSet = dsAux;
                        mapperAux.deviceUUID = array[i][key][0].deviceUUID;
                        mapperAux.dataTypeUUID = array[i][key][0].dataTypeUUID;
                        mapperAuxArray.push(mapperAux);
                      }
                    }
                    array[i][idxKey] = array[i][key];
                    idxKey += 1;
                    delete array[i][key];
                  }
                }
                const plotlyBuilder: PlotlyChartBuilder = new PlotlyChartBuilder(this.translate);
                this.plotlyChart = plotlyBuilder
                  .withMapper(mapperAuxArray.filter(value => Object.keys(value).length !== 0))
                  .withUuidDeviceCollectionSource(auxUUID)
                  .withWidgetName($event.chartTitle)
                  .withDeviceCollection($event.devices)
                  .withChartType($event.chartType)
                  .withWidgetDataType(this.tipoDatoSelected)
                  .withXAxis(auxMapperDataLabel)
                  .withYAxis(auxMapperDataSet)
                  .withOrientation(this.orientation)
                  .build();
                this.generatedDate = [{ from: $event.rangeDate.from, to: $event.rangeDate.to }];
                this.generateDatasource($event.devices);
              }
              this.loading = false;
            });
        }, 2000);

      });
  }


  applyTimezoneToDateRange(dateRange: DateRange) {
    const dateRangeAux: DateRange = dateRange;
    dateRangeAux.from.utc();
    dateRangeAux.to.utc();
    // this.timeZoneService.currentOffset$.subscribe(
    //   result => {
    //     this.dateWithOffset.from = dateRangeAux.from.utcOffset(result).add(result, 'hours');
    //     this.dateWithOffset.to = dateRangeAux.to.utcOffset(result).add(result, 'hours');
    //   }
    // );
    this.dateWithOffset.from = dateRangeAux.from.utc();
    this.dateWithOffset.to = dateRangeAux.to.utc();
  }



  generateDatasource(dataChart: DeviceGraphItem[]) {
    this.dataSource = [];
    for (const data of dataChart) {
      this.dataSource.push({ uuid: data.uuid, name: data.name });
    }
  }

  getTimeRange(event: any) {
    this.period = event;
  }

  ngOnChanges(changes: SimpleChange) {
    this.generateChart(this.generatedCharts);
  }

  getAddedDevices(addDevices: DeviceGraphItem[]) {
    this.selectedDevices.addRange(addDevices);

    const auxDatasource = [];
    for (const aux of this.dataSource) {
      if (aux.uuid !== undefined) {
        auxDatasource.push({ name: aux.name, uuid: aux.uuid });
      }
    }
    for (const aux of addDevices) {
      if (aux.uuid !== undefined) {
        auxDatasource.push({ name: aux.name, uuid: aux.uuid });
      }
    }

    const oData: TabStepperData = {
      chartTitle: this.chartTitle,
      devices: auxDatasource,
      tipoDato: this.tipoDatoSelected,
      unidad: this.unidadSelected,
      rangeDate: new DateRange(this.currentDate.from, this.currentDate.to),
      period: this.period,
      chartType: this.chartType,
      orientation: this.orientation,
      min: this.minParam,
      max: this.maxParam,
      limit: this.limitParam,
      timeZone: this.timeZone
    };
    this.updatedChart.emit(oData);
    this.generateChart(oData);
  }

  addDevices() {
    this.selectedDevices = this.dataCollection[this.numberGraph].deviceCollection;
    this.selectedDatatype = this.dataCollection[this.numberGraph].dataTypeCollection;
    this.representedService.newDatatypeRepresented(this.dataCollection[this.numberGraph].dataTypeCollection);
    this.representedService.newDevicesRepresented(this.dataCollection[this.numberGraph].deviceCollection);
  }

  removeDisplayFieldFromUuid(deviceCollection: DeviceGraphItem[]): DeviceGraphItem[] {
    for (const device of deviceCollection) {
      if (device.uuid.includes('_')) {
        let aux: string[] = [];
        aux = device.uuid.split('_');
        device.uuid = aux[0];
      }
    }
    return deviceCollection;
  }
  openDashboardCombo($event: PlotlyWidget) {
    const dataTypeUUIDCollection: Array<string> = [];
    const uniqueDeviceCollection: Array<string> = [];
    let auxMessageMaxGraphSuccess: string;
    let auxMessageGraphError: string;
    this.translate.get('DASHBOARD.DASHBOARD_ADDED_SUCCESFULLY').subscribe(value => auxMessageMaxGraphSuccess = value);
    this.translate.get('DASHBOARD.DASHBOARD_NO_ADDED').subscribe(value => auxMessageGraphError = value);
    for (const dataType of $event.dataType) {
      dataTypeUUIDCollection.push(dataType.uuid);
    }
    for (const deviceUUID of $event.dataChart[0].uuid) {
      uniqueDeviceCollection.push(deviceUUID);
    }
    const dialogRef = this.dialog.open(ListDashboardComponent, {
      data: {
        userId: ''
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res !== undefined) {
        this.dashboardService.addDashboardWidget(res.value, $event.name, 0,
          0, 4, 3).subscribe((data: any) => {
            if (!_.isEmpty(data)) {
              // tslint:disable-next-line: deprecation
              const multipleRequest = forkJoin(
                this.addParameterToNewWidget(data.addWidget.uuid, 'tipoplot', this.chartType),
                // tslint:disable-next-line: max-line-length
                this.addDeviceParameterToNewWidget(data.addWidget.uuid, 'dises', this.toolsService.generateRandomChartId().toString(), uniqueDeviceCollection),
                // tslint:disable-next-line: max-line-length
                this.addDataTypeParameterToNewWidget(data.addWidget.uuid, 'tipomeds', this.toolsService.generateRandomChartId().toString(), dataTypeUUIDCollection),
                this.addParameterToNewWidget(data.addWidget.uuid, 'periodo', this.periodObject.clave),
                this.addParameterToNewWidget(data.addWidget.uuid, this.periodObject.clave, this.periodObject.valor));
              multipleRequest.subscribe(result => {
                if (result !== null) {
                  this.toastService.showSuccess(auxMessageMaxGraphSuccess, '');
                } else {
                  this.toastService.showError(auxMessageGraphError, '');
                }
              });
            }
          });
      }
    });
  }

  addParameterToNewWidget(widgetUUID, clave, valor) {
    return this.dashboardService.addDashboardWidgetParametro(widgetUUID, clave, valor);
  }

  addDeviceParameterToNewWidget(widgetUUID, clave, valor, deviceCollection) {
    return this.dashboardService.addDashboardWidgetParametroDispositivo(widgetUUID, clave, valor, deviceCollection);
  }

  addDataTypeParameterToNewWidget(widgetUUID, clave, valor, dataTypeCollection) {
    return this.dashboardService.addDashboardWidgetParametroTipoDato(widgetUUID, clave, valor, dataTypeCollection);
  }
}
