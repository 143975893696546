import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface AddFactoryToUserModel {
   usuario: string;
   dispositivo: string;
}

export class AddFactoryToUserBuilder {
    private _USER: string;
    private _FACTORY: string;

    private get _mutation() {
        return gql`mutation addDeviceToUser($usuario:String, $dispositivo:String){
            addUsuarioDispositivo(usuario:$usuario, dispositivo:$dispositivo){
             uuid
           }
         }`;
    }

    public withUser(userUUID: string): this {
        this._USER = userUUID;
        return this;
    }

    public withFactory(factoryUUID: string): this {
        this._FACTORY = factoryUUID;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: AddFactoryToUserModel  = {
            usuario: this._USER,
            dispositivo: this._FACTORY
        };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
