import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';
import { MutationOptions } from 'apollo-client';

export interface CreateUserModel {
   uuid: string;
   nombre: string;
   usuario: string;
   mail: string;
   apellidos: string;
   idioma: string;
   zonaHoraria: string;
   rol: string[];
   enabled: boolean;
}

export class EditUserBuilder {
    private _USERNAME: string;
    private _USERUSERNAME: string;
    private _USERMAIL: string;
    private _USERUUID: string;
    private _USERFIRSTNAME: string;
    private _USERLANGUAGE: string;
    private _USERTIMEZONE: string;
    private _USERROL: string[];
    private _USERENABLED: boolean;

    private get _mutation() {
        return gql`
        mutation updateUsuario($uuid:String!, $enabled:Boolean, $usuario:String, $nombre:String, $apellidos:String
            ,$zonaHoraria:String, $idioma:String, $mail:String, $rol:[String]){
                updateUsuario(uuid: $uuid, nombre: $nombre, apellidos: $apellidos, mail: $mail, idioma: $idioma, zonaHoraria: $zonaHoraria, usuario: $usuario, rol: $rol, enabled: $enabled) {
                    uuid
                  }
             }`;
    }

    public withName(name: string): this {
        this._USERNAME = name;
        return this;
    }

    public withUser(user: string): this {
        this._USERUSERNAME = user;
        return this;
    }

    public withMail(mail: string): this {
        this._USERMAIL = mail;
        return this;
    }

    public withUUID(uuid: string): this {
        this._USERUUID = uuid;
        return this;
    }

    public withFirstName(firstName: string): this {
        this._USERFIRSTNAME = firstName;
        return this;
    }

    public withLanguage(language: string): this {
        this._USERLANGUAGE = language;
        return this;
    }

    public withTimeZone(timeZone: string): this {
        this._USERTIMEZONE = timeZone;
        return this;
    }

    public withRol(rol: string[]): this {
        this._USERROL = rol;
        return this;
    }

    public withEnabled(userEnabled: boolean): this {
        this._USERENABLED = userEnabled;
        return this;
    }

    public build(): MutationOptions {
        const mutationParams: CreateUserModel  = {
            nombre: this._USERNAME,
            usuario: this._USERUSERNAME,
            mail: this._USERMAIL,
            uuid: this._USERUUID,
            apellidos: this._USERFIRSTNAME,
            idioma: this._USERLANGUAGE,
            zonaHoraria: this._USERTIMEZONE,
            rol: this._USERROL,
            enabled: this._USERENABLED
        };
        const graphQlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphQlMutationBuilder.withMutation(this._mutation)
        .withParams(mutationParams)
        .build();
    }
}
