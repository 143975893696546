import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-dashboard',
  templateUrl: './sidebar-dashboard.component.html',
  styleUrls: ['./sidebar-dashboard.component.scss']
})
export class SidebarDashboardComponent implements OnInit {

  opciones:string[]=["opcion 1","opcion 2","opcion 3","opcion 4"];

  constructor() { }

  ngOnInit() {
  }

}
