import { DocumentNode } from 'apollo-link';
import gql from 'graphql-tag';
import { PureQueryOptions } from 'apollo-client';
import { GraphQlQueryBuilder } from './graphQl-query.builder';

export class MiUsuarioGraphQlBuilder {

    private get _query(): DocumentNode {
        return gql`query miUsuario{
            miUsuario {
              uuid
              nombre
              usuario
              apellidos
              zonaHoraria
              idioma
              mail
              rol
            }
          }
          `;
    }
    
    public build(): PureQueryOptions {
        const graphQlBuilder: GraphQlQueryBuilder = new GraphQlQueryBuilder();
        return graphQlBuilder.withQuery(this._query)
          .build();
      }
}
