import { DocumentNode } from 'apollo-link';
import { OperationVariables, QueryOptions } from 'apollo-client';

export class GraphQlQueryBuilder {
    private _QUERY: DocumentNode;
    private _PARAMS: OperationVariables;

    public withQuery(query: DocumentNode): GraphQlQueryBuilder {
        this._QUERY = query;
        return this;
    }

    public withParams(params: OperationVariables): GraphQlQueryBuilder {
        this._PARAMS = params;
        return this;
    }

    public build(): QueryOptions {
        const queryOptions: QueryOptions = {query: this._QUERY, variables: this._PARAMS, fetchPolicy: 'network-only' };
        return queryOptions;
    }
}
