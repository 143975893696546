import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceGraphItem, DeviceGraphDataType } from 'src/app/models/device-graph.model';
import * as lodash from 'lodash';
import { DataTypeItem } from 'src/app/models/datatype/datatype-hierarchi.model';

@Injectable({
  providedIn: 'root'
})

export class DevicesRepresentedService {

  private devicesRepresentedBS: BehaviorSubject<Array<DeviceGraphItem>>;
  public devicesRepresentedObservable: Observable<Array<DeviceGraphItem>>;

  private datatypeRepresentedBS: BehaviorSubject<DataTypeItem[]>;
  public datatypeRepresentedObservable: Observable<DataTypeItem[]>;

  constructor() {
    this.devicesRepresentedBS = new BehaviorSubject<Array<DeviceGraphItem>>(new Array<DeviceGraphItem>());
    this.devicesRepresentedObservable = this.devicesRepresentedBS.asObservable();
    this.datatypeRepresentedBS = new BehaviorSubject<DataTypeItem[]>(new Array<DataTypeItem>());
    this.datatypeRepresentedObservable = this.datatypeRepresentedBS.asObservable();
  }

  public newDevicesRepresented(devices: Array<DeviceGraphItem>) {
    if (!lodash.isEmpty(devices)) {
      this.devicesRepresentedBS.next(this.removeDisplayFieldFromUuid(devices));
    }

  }

  public newDatatypeRepresented(datatype: DataTypeItem[]) {
    if (!lodash.isEmpty(datatype)) {
      this.datatypeRepresentedBS.next(datatype);
    }
  }

  public toDeviceGraphDataType(dataTypeCollection: DataTypeItem[]): DeviceGraphDataType[] {
    const aux: DeviceGraphDataType = new DeviceGraphDataType();
    const auxCollection: DeviceGraphDataType[] = [];
    for (const dataType of dataTypeCollection) {
      aux.name = dataType.name;
      aux.uuid = dataType.uuid;
      aux.unity = dataType.unity;
      auxCollection.push(aux);
    }
    return auxCollection;
  }

  private removeDisplayFieldFromUuid(deviceCollection: DeviceGraphItem[]): DeviceGraphItem[] {
    for (const device of deviceCollection) {
      if (device.uuid.includes('_')) {
        let aux: string[] = [];
        aux = device.uuid.split('_');
        device.uuid = aux[0];
      }
    }
    return deviceCollection;
  }
}
