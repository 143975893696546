import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FakeServiceService } from 'src/app/services/fakeService/fake-service.service';

export interface PeriodicElement {
  dispositivo: string;
  contadorAgua: string;
  contadorAireComprimido: string;
  contadorDesmoldeante: string;
  contadorGas: string;
  estado: string;
  energiaActiva: string;
  energiaReactiva: string;
  factorPotencia: string;
  intensidadLinea1: string;
  intensidadLinea2: string;
  intensidadLinea3: string;
  pesoBruto: string;
  pesoNeto: string;
  piezaBuena: string;
  piezaMala: string;
  potenciaActiva: string;
  potenciaReactiva: string;
  referenciaPieza: string;
  tensionLinea1: string;
  tensionLinea2: string;
  tensionLinea3: string;
  tiempoCiclo: string;
  totalPiezas: string;
  totalPiezasCorrectas: string;
  unidades: string;
}

@Component({
  selector: 'app-latest-measurements',
  templateUrl: './latest-measurements.component.html',
  styleUrls: ['./latest-measurements.component.scss']
})
export class LatestMeasurementsComponent {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns = ['dispositivo', 'contadorAgua', 'contadorAireComprimido', 'contadorDesmoldeante', 'contadorGas',
  'estado', 'energiaActiva', 'energiaReactiva', 'factorPotencia', 'intensidadLinea1','intensidadLinea2','intensidadLinea3',
  'pesoBruto', 'pesoNeto', 'piezaBuena', 'piezaMala', 'potenciaActiva', 'potenciaReactiva', 'referenciaPieza',
  'tensionLinea1', 'tensionLinea2', 'tensionLinea3', 'tiempoCiclo', 'totalPiezas', 'totalPiezasCorrectas', 'unidades'];

  dataSource; 

  constructor(private fakeService: FakeServiceService){}

  getData() {
    this.fakeService.getFactoriesData().subscribe((data:PeriodicElement[])=>{
      this.dataSource = new MatTableDataSource(data);
     
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }  

  ngOnInit() {
    this.getData();
  }
}