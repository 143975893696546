import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewDashboardWidgetParametroTipoDato {
    widgetUUID: string;
    clave: string;
    valor: string;
    tipoDatos: string[];
}

export class AddDashboardWidgetParametroTipoDatoBuilder {

    private _WIDGETUUID: string;
    private _CLAVE: string;
    private _VALOR: string;
    private _DATATYPEUUIDCOLLECTION: string[];

    private get _mutation() {
        return gql`mutation AddDashboardWidgetParametroTipoDato(
            $widgetUUID: String
            $clave: String
            $valor: String
            $tipoDatos: [String]
          ) {
            addWidgetParametroTipoDato(
              widget: $widgetUUID
              clave: $clave
              valor: $valor
              tipoDatos: $tipoDatos
            ) {
              uuid
            }
          }
            `;
    }

    public withWidgetUUID(newWidgetUUID: string): this {
        this._WIDGETUUID = newWidgetUUID;
        return this;
    }

    public withClave(newClave: string): this {
        this._CLAVE = newClave;
        return this;
    }

    public withValor(newValor: string): this {
        this._VALOR = newValor;
        return this;
    }

    public withDataTypeUUIDCollection(newDataTypeUUIDCollection: string[]): this {
        this._DATATYPEUUIDCOLLECTION = newDataTypeUUIDCollection;
        return this;
    }

    public build() {
        const mutationParams: NewDashboardWidgetParametroTipoDato = {
            widgetUUID: this._WIDGETUUID,
            clave: this._CLAVE,
            valor: this._VALOR,
            tipoDatos: this._DATATYPEUUIDCOLLECTION
        };
        const graphqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphqlMutationBuilder.withMutation(this._mutation)
            .withParams(mutationParams)
            .build();
    }
}
