import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckMeasurementsProductsRoutingModule } from './check-measurements-products-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CheckMeasurementsProductsRoutingModule
  ]
})
export class CheckMeasurementsProductsModule { }
