import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { Apollo, Mutation } from 'apollo-angular';
import { MatDatepickerInputEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopUpComponent } from '../utilidades/ventana-dsp/pop-up.component';
import { DialogData } from '../utilidades/interfaces/ventana-dsp.data';
import { GraphFuncionesMae } from './consultas/mae/consultas-graphql.service';
import { GraphFunciones } from './consultas/consultas-graphql.service';
import { ReportItM,  RegistroMae } from './consultas/mae/interfaces.consultas';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';


@Component({
    selector: 'app-informe-mae',  
    templateUrl: './informe-mae.component.html',
    styleUrls: ['./informes.component.scss']
})   

export class InformeMaeComponent implements OnInit, OnDestroy {
    private subscrip: Subscription [] = [];
    public report: ReportItM = null;
    public reportInicial: ReportItM = null;
    public reportEdit=false
    public reportEditParam=false
    public CAMBIOS = 0;
    public loading = true;
    public uuid: string;
    public modo: string;
    public fab=[];
    public disfab=[{}];
    public tdmdis=[{}];
    public tdhdis=[{}];
    public registro:RegistroMae ={ 
      report :{
        uuid: '',
        titulo: '',
        descripcion: '',
        codigo: 'mae',
        logo: '',
        idioma: '',
        imagen: '',
        fechaImplantacion: '',
        paramsDis: [],
        paramsTipoDato: [],
      },
      fabrica:{uuid:'',codigo:'fabrica',fabuuid:'',fabnombre:''},
      dispositivo:{uuid:'', codigo:'dispositivo',disuuid:'',disnombre:''},
      mensual:{uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:'',tdnombre:''},
      horario:{uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:'',tdnombre:''}
   } 
   public fabrica={uuid:'',codigo:'fabrica',fabuuid:'',fabnombre:''};
   public dispositivo={uuid:'', codigo:'dispositivo',disuuid:'',disnombre:''};
   public mensual={uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:'',tdnombre:''};
   public horario={uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:'',tdnombre:''};
   public fim=new Date().toISOString().split('T')[0];

    constructor(private rutaActiva: ActivatedRoute,
                private gF: GraphFuncionesMae,
                private gFp: GraphFunciones,
                private apollo: Apollo ,
                public dialog: MatDialog) {
    }
    ngOnInit() {
        console.log('REPORT FABRICA INICIANDO');

        const qpf: Subscription = this.gFp.cargarFabricas()   // Cargando fabricas
        .subscribe(({data}) => {
          this.fab = [];
          this.fab = data.datos;
          //console.log("FABRICAS: ",this.fab);
        });
        this.subscrip.push(qpf);

        const spar: Subscription = this.rutaActiva.params.subscribe(
            (params: Params) => {
                console.log(params);
                this.uuid = params.uuid;
                this.modo = params.modo;
                if (this.modo === 'nuevo') {
                  this.report = {
                    uuid: '',
                    titulo: '',
                    descripcion:'',
                    codigo: 'mae',
                    logo: '',
                    idioma: '',
                    imagen: '',
                    fechaImplantacion: "",
                    paramsDis: [],
                    paramsTipoDato: [],
                  };
                  // darle le alta en B.D.datos-a
                  console.log('INICIO ALTA B.D. REPORT');
                  this.fim=new Date().toISOString().split('T')[0];
                  this.report.fechaImplantacion=Date.parse(this.fim);
                  let fec=0;
                  if(typeof(this.report.fechaImplantacion)==='string') fec=parseFloat(this.report.fechaImplantacion);
                  else fec=this.report.fechaImplantacion;
                  this.gF.crearReportMae(
                    this.report.codigo,
                    this.report.logo,
                    this.report.imagen,
                    this.report.idioma,
                    this.report.titulo,
                    this.report.descripcion,
                    fec).subscribe(res => {
                      //console.log(res);
                      this.report.uuid = res.data.dato.uuid;
                      this.loading = false;
                      console.log('FIN ALTA B.D.');
                      this.registro.report=this.report;
                      this.registro.fabrica={uuid:'',codigo:'fabrica',fabuuid:'',fabnombre:''};
                      this.registro.dispositivo={uuid:'', codigo:'dispositivo',disuuid:'',disnombre:''};
                      this.registro.mensual={uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:'',tdnombre:''};
                      this.registro.horario={uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:'',tdnombre:''};
                      console.log(this.registro);
                  });
                } else {
                  const qrepor: Subscription = this.gF.cargarReportMae(this.uuid).
                                subscribe(({data, loading}) => {
                                  const datos: any = this.gF.obtenerDatosReportMae(data.datos);
                                  this.report = datos.report;
                                  this.reportInicial = datos.report;
                                 this.fim=new Date(datos.report.fechaImplantacion).toISOString().split('T')[0];
                                  console.log("REPORT CARGADO :",this.report);
                                  this.registro.report=datos.report;
                                  this.registro.fabrica={uuid:'',codigo:'fabrica',fabuuid:'',fabnombre:''};
                                  this.registro.dispositivo={uuid:'', codigo:'dispositivo',disuuid:'',disnombre:''};
                                  this.registro.mensual={uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:'',tdnombre:''};
                                  this.registro.horario={uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:'',tdnombre:''};
                                  datos.report.paramsDis.map(d =>{
                                    if(d.codigo==='fabrica'){
                                      this.registro.fabrica.uuid=d.uuid;
                                      this.registro.fabrica.codigo=d.codigo; 
                                      const qpf: Subscription = this.gF.getFabricaMae(d.uuid)   // Cargando fabricas
                                      .subscribe(({data}) => {
                                        //console.log('fab-->', data);
                                        this.registro.fabrica.fabuuid=data.dato.uuid;
                                        this.registro.fabrica.fabnombre=data.dato.nombre;
                                      });
                                      this.subscrip.push(qpf);
                                    } 
                                    else if (d.codigo==='dispositivo') {
                                      this.registro.dispositivo.uuid=d.uuid;
                                      this.registro.dispositivo.codigo=d.codigo; 
                                      const qpf: Subscription = this.gF.getDispositivoMae(d.uuid)   // Cargando Dispositivo
                                      .subscribe(({data}) => {
                                        //console.log('dis-->', data);
                                        this.registro.dispositivo.disuuid=data.dato.uuid;
                                        this.registro.dispositivo.disnombre=data.dato.nombre;
                                      });
                                      this.subscrip.push(qpf);
                                    } 
                                  });
                                  datos.report.paramsTipoDato.map(td =>{
                                    if(td.periodo==='MENSUAL'){
                                      this.registro.mensual.uuid=td.uuid;
                                      this.registro.mensual.unidadK=td.unidadK;
                                      this.registro.mensual.unidadT=td.unidadT;
                                      this.registro.mensual.periodo=td.periodo;
                                      this.registro.mensual.codigo=td.codigo;
                                      const qpf: Subscription = this.gF.getTipoDatoMae(td.uuid)   // Cargando Dispositivo
                                      .subscribe(({data}) => {
                                        //console.log('men-->', data);
                                        this.registro.mensual.tduuid=data.dato.uuid;
                                        this.registro.mensual.tdnombre=data.dato.nombre;
                                      });
                                      this.subscrip.push(qpf);
                                    } 
                                    if (td.periodo==='HORARIO'){
                                      this.registro.horario.uuid=td.uuid;
                                      this.registro.horario.unidadK=td.unidadK;
                                      this.registro.horario.unidadT=td.unidadT;
                                      this.registro.horario.periodo=td.periodo;
                                      this.registro.horario.codigo=td.codigo;
                                      const qpf: Subscription = this.gF.getTipoDatoMae(td.uuid)   // Cargando Dispositivo
                                      .subscribe(({data}) => {
                                        //console.log('hor-->', data);
                                        this.registro.horario.tduuid=data.dato.uuid;
                                        this.registro.horario.tdnombre=data.dato.nombre;
                                      });
                                      this.subscrip.push(qpf);
                                    } 
                                  }); 
                                  console.log("REGISTRO CARGADO:");
                                  console.log(this.registro);
                                  this.loading = datos.loading;
                                });
                  this.subscrip.push(qrepor);
                }
        });
        this.subscrip.push(spar);
        // setInterval(() => console.log(this.report), 10000);
        // optener datos
   }

  editReport() {
    this.reportEdit = !this.reportEdit;
    if (this.reportEdit === false) {
      console.log('INICIO MOD B.D.');
      this.report.fechaImplantacion=new Date(this.fim).getTime();
      let fec=0;
      if(typeof(this.report.fechaImplantacion)==='string') fec=parseFloat(this.report.fechaImplantacion);
      else fec=this.report.fechaImplantacion;
      this.gF.updateReportMae(
        this.report.uuid,
        this.report.titulo,
        this.report.descripcion,
        this.report.codigo,
        this.report.logo,
        this.report.idioma,
        this.report.imagen,
        fec).subscribe(res => {
          console.log(res);
          console.log('FIN MOD B.D.');
      });
    }
  }
  cargaDisTds(){
    const qpf: Subscription = this.gF.getDispositivosAhorroEnFabrica(this.registro.fabrica.fabuuid)   // Cargando dispositivos fabricas
    .subscribe(({data}) => {
      this.disfab.length=0;
      data.dato.map(d =>this.disfab.push({uuid: d.uuid, nombre:d.nombre}));
      console.log("dispositivos",this.disfab);
    });
    this.subscrip.push(qpf);
    const qpff: Subscription = this.gF.getTipoDatosAhorroEnDispositivoMes(this.registro.dispositivo.disuuid)   // Cargando tipoDatos Dispositivos
    .subscribe(({data}) => {
      this.tdmdis.length=0;
      data.dato.map(d =>this.tdmdis.push({uuid: d.uuid, nombre:d.nombre}));
      console.log("TipoDatosMes",this.tdmdis);
    });
    this.subscrip.push(qpff);
    const qpfff: Subscription = this.gF.getTipoDatosAhorroEnDispositivoHora(this.registro.dispositivo.disuuid)   // Cargando tipoDatos Dispositivos
    .subscribe(({data}) => {
      this.tdhdis.length=0;
      data.dato.map(d =>this.tdhdis.push({uuid: d.uuid, nombre:d.nombre}));
      console.log("TipoDatosHora",this.tdhdis);
    });
    this.subscrip.push(qpfff);
  } 

  editReportParam() {
    this.reportEditParam = !this.reportEditParam;
    if(this.reportEditParam===true){  // Editando
      // Copiar anteriores
      this.fabrica=JSON.parse(JSON.stringify(this.registro.fabrica));
      this.dispositivo=JSON.parse(JSON.stringify(this.registro.dispositivo));
      this.mensual=JSON.parse(JSON.stringify(this.registro.mensual));
      this.horario=JSON.parse(JSON.stringify(this.registro.horario));
      this.cargaDisTds();
    } 
    if (this.reportEditParam === false) {
      console.log('INICIO MOD B.D.');
      //analizando cambios
      // Fabrica
      if(this.registro.fabrica.fabuuid!==this.fabrica.fabuuid){  // Hay cambio
        if(this.fabrica.uuid!==''){ 
          const qp: Subscription = this.gF.borrarReporParamtMaeDispositivo(this.fabrica.uuid)   
          .subscribe();
          this.subscrip.push(qp);
        } 
        const qp1: Subscription = this.gF.createReportParamMaeDis(this.registro.report.uuid,this.registro.fabrica.fabuuid,this.registro.fabrica.codigo)   
        .subscribe(({data}) => {
          this.registro.fabrica.uuid=data.dato.uuid;
        });
        this.subscrip.push(qp1);
      } 
      // Dispositivo
      if(this.registro.dispositivo.disuuid!==this.dispositivo.disuuid){  // Hay cambio
        if(this.dispositivo.uuid!==''){ 
          const qp: Subscription = this.gF.borrarReporParamtMaeDispositivo(this.dispositivo.uuid)   
          .subscribe();
          this.subscrip.push(qp);
        } 
        const qp1: Subscription = this.gF.createReportParamMaeDis(this.registro.report.uuid,this.registro.dispositivo.disuuid,this.registro.dispositivo.codigo)   
        .subscribe(({data}) => {
          this.registro.dispositivo.uuid=data.dato.uuid;
        })
        this.subscrip.push(qp1);
      } 
      // Mensual
      if(this.registro.mensual.tduuid!==this.mensual.tduuid){  // Hay cambio
        if(this.mensual.uuid!==''){
          const qp: Subscription = this.gF.borrarReporParamtMaeTipoDato(this.mensual.uuid)   
          .subscribe();
          this.subscrip.push(qp);
        } 
        if(this.registro.mensual.tduuid!==''){ 
          const qp1: Subscription = this.gF.createReportParamMaeTipoDato(this.registro.report.uuid,this.registro.mensual.tduuid,this.registro.mensual.codigo,this.registro.mensual.periodo, this.registro.mensual.unidadT,this.registro.mensual.unidadK)   
          .subscribe(({data}) => {
            this.registro.mensual.uuid=data.dato.uuid;
          })
          this.subscrip.push(qp1);
        } 
      } else {
        if(this.registro.mensual.uuid!==''){ 
           const qp1: Subscription = this.gF.updateReportParamMaeTipoDato(this.registro.report.uuid,this.registro.mensual.uuid,this.registro.mensual.codigo,this.registro.mensual.periodo, this.registro.mensual.unidadT,this.registro.mensual.unidadK)   
          .subscribe();
          this.subscrip.push(qp1);
        } 
      } 
      // Horario
      if(this.registro.horario.tduuid!==this.horario.tduuid){  // Hay cambio
        console.log(this.report);
        if(this.horario.uuid!==''){
          const qp: Subscription = this.gF.borrarReporParamtMaeTipoDato(this.horario.uuid)   
          .subscribe();
          this.subscrip.push(qp);
        } 
        if(this.registro.horario.tduuid!==''){ 
          const qp1: Subscription = this.gF.createReportParamMaeTipoDato(this.registro.report.uuid,this.registro.horario.tduuid,this.registro.horario.codigo,this.registro.horario.periodo, this.registro.horario.unidadT,this.registro.horario.unidadK)   
          .subscribe(({data}) => {
            this.registro.horario.uuid=data.dato.uuid;
          });
          this.subscrip.push(qp1);
        } 
      } 
      else{
        if(this.registro.horario.uuid!==''){ 
          const qp1: Subscription = this.gF.updateReportParamMaeTipoDato(this.registro.report.uuid,this.registro.horario.uuid,this.registro.horario.codigo,this.registro.horario.periodo, this.registro.horario.unidadT,this.registro.horario.unidadK)   
          .subscribe();
          this.subscrip.push(qp1);
        } 
      } 
      console.log('FIN MOD B.D.');
    }
  }

   sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  guardarCambios() {
    console.log('SALVANDO: ', this.report);
  }

  changeFabrica(ds) {
    console.log(ds);
    if(ds.uuid!==this.registro.fabrica.fabuuid){
       this.registro.fabrica.fabuuid=ds.uuid;
       this.registro.fabrica.fabnombre=ds.nombre;
       this.registro.fabrica.codigo='fabrica';
       this.registro.dispositivo={uuid:'', codigo:'dispositivo',disuuid:'',disnombre:''};
       this.registro.mensual={uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:'',tdnombre:''};
       this.registro.horario={uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:'',tdnombre:''}; 
      this.cargaDisTds();
    } 
  }

  changeDispositivo(ds) {
    console.log(ds);
    if(ds.uuid!==this.registro.dispositivo.disuuid){
       this.registro.dispositivo={uuid:'', codigo:'dispositivo',disuuid:ds.uuid,disnombre:ds.nombre};
       this.registro.mensual={uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:'',tdnombre:''};
       this.registro.horario={uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:'',tdnombre:''}; 
      this.cargaDisTds();
    } 
  }

  changeMensual(ds) {
    console.log(ds);
    if(ds.uuid!==this.registro.mensual.tduuid){
       this.registro.mensual={uuid:'', unidadK:'0', unidadT:'',periodo:'MENSUAL',codigo:'tipodato',tduuid:ds.uuid,tdnombre:ds.nombre};
    } 
  }

  changeHorario(ds) {
    console.log(ds);
    if(ds.uuid!==this.registro.mensual.tduuid){
       this.registro.horario={uuid:'', unidadK:'0', unidadT:'',periodo:'HORARIO',codigo:'tipodato',tduuid:ds.uuid,tdnombre:ds.nombre};
    } 
  }


  ngOnDestroy() {
   this.subscrip.map(qs => qs.unsubscribe());
 }

}
