import { Component, OnInit, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { Apollo, Mutation } from 'apollo-angular';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopUpComponent } from '../utilidades/ventana-dsp/pop-up.component';
import { DialogData } from '../utilidades/interfaces/ventana-dsp.data';
import { ReportParamComponent } from './ventanas/fabrica/report-param/report-param.component';
import { ReportRowComponent } from './ventanas/fabrica/report-row/report-row.component';
import { ReportTableComponent } from './ventanas/fabrica/report-table/report-table.component';
import { GraphFuncionesFabrica } from './consultas/fabrica/consultas-graphql.service';
import { GraphFunciones } from './consultas/consultas-graphql.service';
import { ReportItf, DatoUuid, IQuery } from './consultas/interfaces.consultas';




@Component({
    selector: 'app-informe-fabrica',  
    templateUrl: './informe-fabrica.component.html',
    styleUrls: ['./informes.component.scss']
})   

export class InformeFabricaComponent implements OnInit, OnDestroy {
    private subscrip: Subscription [] = [];
    public report: ReportItf = null;
    public reportInicial: ReportItf = null;
    public CAMBIOS = 0;
    public loading = true;
    public uuid: string;
    public modo: string;
    public paramHead: string [] = ['', '', 'uuid', 'codigo', 'periodo', 'unidadT', 'unidadK', 'tipo', 'dato'];
    public rowHead: string [] = ['', '', 'uuid', 'titulo', 'orden', 'unidadT', 'unidadK', 'datos'];
    // tslint:disable-next-line: max-line-length
    public tableHead: string [] = ['', '', 'uuid', 'titulo', 'consumoTitulo', 'consumoK', 'itemTitulo', 'itemK', 'ratioTitulo', 'ratiomedioTitulo', 'Disp.', 't.dato'];
    public reportEdit = false;
    public rowsShow: boolean [] = [];
    public rowsEdit: boolean [] = [];
    public tablesShowD = [];
    public tablesShowT = [];
    public tablesEditT = [];
    public tablesEditD = [];
    public paramRow: boolean [] = [];
    public paramRowDato: boolean [] = [];
    public tablesEdit: boolean [] = [];
    public vpop: DialogData = {
      fabricas: [],
      dispositivo: {
        uuid: '',
        nombre: ''
      },
      dato: {
        uuid: '',
        periodo: '',
        descripcion:''
      },
      tipoDato: {
        uuid: '',
        nombre: '',
        unidad: ''
      }
    };

    constructor(private rutaActiva: ActivatedRoute,
                private gF: GraphFuncionesFabrica,
                private gFp: GraphFunciones,
                private apollo: Apollo ,
                public dialog: MatDialog) {
      for (let i = 0; i < 256; i++) {
        this.paramRow.push(false);
        this.paramRowDato.push(false);
        this.rowsShow.push(false);
        this.rowsEdit.push(false);
        this.tablesShowT.push(false);
        this.tablesShowD.push(false);
        // this.tablesShowT.push({dato: []});
        // this.tablesShowD.push({dato: []});
        this.tablesEditT.push({dato: []});
        this.tablesEditD.push({dato: []});
        this.tablesEdit.push(false);
        for (let j = 0; j < 256; j++) {
         // this.tablesShowT[i].dato.push(false);
         // this.tablesShowD[i].dato.push(false);
          this.tablesEditT[i].dato.push(false);
          this.tablesEditD[i].dato.push(false);
        }
      }

      // setInterval(() => {}, 10000 );
    }
    ngOnInit() {
        //console.log('REPORT FABRICA INICIANDO');
        const spar: Subscription = this.rutaActiva.params.subscribe(
            (params: Params) => {
                //console.log(params);
                this.uuid = params.uuid;
                this.modo = params.modo;
                if (this.modo === 'nuevo') {
                  this.report = {
                    uuid: '',
                    titulo: '',
                    codigo: 'fabrica',
                    logo: '',
                    idioma: '',
                    params: [],
                    rows: [],
                    tables: []
                  };
                  // darle le alta en B.D.datos-a
                  //console.log('INICIO ALTA B.D. REPORT');
                  this.gF.crearReportFabrica(
                    this.report.titulo,
                    this.report.codigo,
                    this.report.logo,
                    this.report.idioma).subscribe(res => {
                      //console.log(res);
                      this.report.uuid = res.data.dato.uuid;
                      this.loading = false;
                      //console.log('FIN ALTA B.D.');
                  });
                } else {
                  const qrepor: Subscription = this.gF.cargarReportFabrica(this.uuid).
                                subscribe(({data, loading}) => {
                                  const datos: any = this.gF.obtenerDatosReportFabrica(data.datos);
                                  this.report = datos.report;
                                  this.reportInicial = datos.report;
                                  this.loading = datos.loading;
                                  //console.log(this.report);
                                });
                  this.subscrip.push(qrepor);
                }
        });
        this.subscrip.push(spar);
        // setInterval(() => console.log(this.report), 10000);
   }

   sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  guardarCambios() {
    //console.log('SALVANDO: ', this.report);
  }

  ////////// Gestion de REPORTPARAM

  paramRowFun(index) {
    this.paramRow[index] = !this.paramRow[index];
    //console.log ('ParamRow ', index, this.paramRow[index] );
    if (this.paramRow[index] === false) {
      //console.log('INICIO MOD B.D.');
      //console.log(this.report.params[index]);
      this.gF.updateReportParam(
        this.report.uuid,
        this.report.params[index].uuid,
        this.report.params[index].codigo,
        this.report.params[index].periodo,
        this.report.params[index].unidadT,
        this.report.params[index].unidadK,
        this.report.params[index].tipo).subscribe(res => {
          //console.log(res);
          //console.log('FIN MOD B.D.');
      });
    }
  }

  editReport() {
    this.reportEdit = !this.reportEdit;
    if (this.reportEdit === false) {
      //console.log('INICIO MOD B.D.');
      this.gF.updateReportFabrica(
        this.report.uuid,
        this.report.titulo,
        this.report.codigo,
        this.report.logo,
        this.report.idioma).subscribe(res => {
          //console.log(res);
          //console.log('FIN MOD B.D.');
      });
    }
  }

  reportParamFun() {
    const ventana = this.dialog.open(ReportParamComponent, {
       width: '30%',
       height: '50%',
       data: {}
    });

    ventana.afterClosed().subscribe(result => {
      //console.log('RESULTADO DIALOG: ', result);
      if (result != null) {
        this.report.params.push({
          uuid: '',
          codigo: result.codigo,
          tipo: result.tipo,
          periodo: result.periodo,
          unidadT: result.unidadT,
          unidadK: result.unidadK,
          dato: {}
        });
      }
    });
  }

  borrarParam(index) {
    //console.log(index);
    const uuid = this.report.params[index].uuid;
    this.report.params.splice(index, 1);
    // Dar de baja en B.D
    //console.log('REPORT TABLE A BORRAR -> ', uuid);
    this.gF.borrarReportParam(this.report.uuid, uuid).subscribe(res => console.log(res));
  }
  borrarParamDato(index) {
    //console.log(index);
    this.report.params[index].dato = {};
  }

  anadeParamDato( duuid, index ) {
    const qrdato: Subscription = this.gF.dameDato(duuid)
    .subscribe(({data}) => {
           //console.log('PARAM DATOS ANADIDO: ', data.datos);
           const d = data.datos;
           this.report.params[index].dato = {
               uuid: d.uuid,
               periodo: d.periodo,
               dispositivo: {
                uuid: d.tiene.uuid,
                nombre: d.tiene.nombre,
               },
               tipoDato: {
                uuid: d.es.uuid,
                nombre: d.es.nombre,
                unidad: d.es.unidad
              }
           };
           // Anadimos a la B.D.
           //console.log('INICIO ALTA B.D.');
           this.gF.crearReportParam(this.report.uuid, d.uuid,
            this.report.params[index].codigo,
            this.report.params[index].periodo,
            this.report.params[index].unidadT,
            this.report.params[index].unidadK,
            this.report.params[index].tipo).subscribe(res => {
              //console.log(res);
              this.report.params[index].uuid = res.data.dato;
              //console.log('FIN ALTA B.D.');
            });
    });
    this.subscrip.push(qrdato);
  }

  reportParamFunDato(index) {
    const qpf: Subscription = this.gFp.cargarFabricas()
        .subscribe(({data}) => {
          //console.log(data.datos);
          this.vpop.fabricas = [];
          this.vpop.fabricas = data.datos;
          const dialogRef = this.dialog.open(PopUpComponent, {
            width: '50%',
            height: '60%',
            data: this.vpop
          });
          dialogRef.afterClosed().subscribe(result => {
            //console.log('RESULTADO DIALOG (reportParamFunDato): ', result);
            if (result != null) {
            if (result.hasOwnProperty('periodo')) {   // Es Dato
                this.anadeParamDato(result.uuid, index);
            }
          }
        });
    });
    this.subscrip.push(qpf);
  }

  ////////// FIN Gestion de REPORTPARAM

  ////////// Gestion de REPORTROW

  borrarRowDato(iRow, iDato) {
    // Borrar el enlace
    this.gF.borrarReportRowParam(this.report.uuid, this.report.rows[iRow].uuid, this.report.rows[iRow].dato[iDato].uuid)
    .subscribe(res => console.log());
    this.report.rows[iRow].dato.splice(iDato, 1);
  }

  reportRowFun() {
    const ventana = this.dialog.open(ReportRowComponent, {
       width: '30%',
       height: '50%',
       data: {}
    });
    ventana.afterClosed().subscribe(result => {
      let ruuid = '';
      //console.log('RESULTADO DIALOG (RR): ', result);
      if ( result != null) {
        // Crear todo en la base de datos
        // crear el reportrow y conectarlo con el report
        this.gF.crearReportRow(
          this.report.uuid,
          result.titulo,
          result.orden,
          result.unidadT,
          result.unidadK).subscribe( res => {
            ruuid = res.data.datos.uuid;
            //console.log(res, ruuid);
            this.gF.crearInludeRRW(this.report.uuid, ruuid).subscribe(
              rres => {
                  //console.log(rres);
                  this.report.rows.push({
                    uuid: ruuid,
                    titulo: result.titulo,
                    orden: result.orden,
                    unidadT: result.unidadT,
                    unidadK: result.unidadK,
                    dato: []
                  });
              }
            );
          });
      }
    });
  }

  borrarRow(index) {
    //console.log(index);
    // Borra el ReportRow (si esta completo solo el enlace sino todo)
    let rp=this.report.uuid;
    let rw=this.report.rows[index].uuid;
    if (this.report.rows[index].dato.length > 0) {  // solo el enlace
        this.gF.borrarIncludeRRW(rp,rw)
        .subscribe(res => console.log(res));
    } else {
      this.gF.borrarIncludeRRW(rp,rw)
      .subscribe(res =>{ 
        //console.log('Borrado enlace');
        //console.log(res);
        //console.log('ahora el reportrow');
        this.gF.borrarReportRow(rp,rw)
        .subscribe(res => console.log(res));
      });
    }
    this.report.rows.splice(index, 1);
  }
  anadeRowDato( duuid, index ) {
    //console.log("anadeRowDato: ",duuid,index)
    const qdato: Subscription = this.gF.dameDato(duuid)
      .subscribe(({data}) => {
        //console.log(data.datos);
        const d = data.datos; //data.datos[0];
        //console.log(this.report.rows[index].dato);
        // Crear ReportRowParam
        this.gF.crearReportRowParam(this.report.uuid, this.report.rows[index].uuid, d.uuid)
        .subscribe(res => {
              //console.log(res);
              this.report.rows[index].dato.push({
                uuid: d.uuid,
                periodo: d.periodo,
                dispositivo: {
                uuid: d.tiene.uuid,
                nombre: d.tiene.nombre,
                },
                tipoDato: {
                uuid: d.es.uuid,
                nombre: d.es.nombre,
                unidad: d.es.unidad
              }
            });
        });
    });
    this.subscrip.push(qdato);
  }

  reportRowFunDato(index) {
    const qpf: Subscription = this.gFp.cargarFabricas()
      .subscribe(({data}) => {
        //console.log(data.datos);
        this.vpop.fabricas = [];
        this.vpop.fabricas = data.datos;
        const dialogRef = this.dialog.open(PopUpComponent, {
          width: '50%',
          height: '60%',
          data: this.vpop
        });
        dialogRef.afterClosed().subscribe(result => {
          //console.log('RESULTADO DIALOG: ', result);
          if (result != null) {
          if (result.hasOwnProperty('periodo')) {   // Es Dato
              this.anadeRowDato(result.uuid, index);
          }
          }
        });
      });
    this.subscrip.push(qpf);
  }

  rowsEditfun(index) {
    //console.log('EDITANDO -> ',this.report.rows[index]);
    this.rowsEdit[index] = !this.rowsEdit[index];
    if (this.rowsEdit[index] === false) {  // Ha cambiado
      //console.log('INICIO MOD B.D.');
      this.gF.updateReportRow(
        this.report.uuid,
        this.report.rows[index].uuid,
        this.report.rows[index].titulo,
        parseInt(this.report.rows[index].orden, 10),
        this.report.rows[index].unidadT,
        this.report.rows[index].unidadK)
        .subscribe(res => {
          //console.log(res);
          //console.log('FIN MOD B.D.');
      });

    }
  }

  ////////// FIN Gestion de REPORTROW


  ////////// Gestion de REPORTABLE

  tablesEditFun(index) {
    this.tablesEdit[index] = !this.tablesEdit[index]
    if (this.tablesEdit[index] === false) {
      this.gF.updateReportTable(
        this.report.uuid,
        this.report.tables[index].uuid,
        this.report.tables[index].titulo,
        this.report.tables[index].consumoTitulo,
        this.report.tables[index].consumoK,
        this.report.tables[index].itemTitulo,
        this.report.tables[index].itemK,
        this.report.tables[index].ratioTitulo,
        this.report.tables[index].ratiomedioTitulo
      ).subscribe(res => console.log(res));
    }
  }

  tablesEditDFun(it, id) {
    //console.log(it, id,this.tablesEditD[it].dato[id] );
    this.tablesEditD[it].dato[id] = !this.tablesEditD[it].dato[id];
    if (this.tablesEditD[it].dato[id] === false) {
      //console.log(this.report.tables[it].dispositivos[id]);
      this.gF.updateReportReportTableParamDis(
        this.report.uuid,
        this.report.tables[it].dispositivos[id].uuid,
        this.report.tables[it].dispositivos[id].codigo)
        .subscribe(res => console.log(res));
    }
  }
  tablesEditTFun(it, id) {
    //console.log(it, id, this.tablesEditT[it].dato[id]);
    this.tablesEditT[it].dato[id] = !this.tablesEditT[it].dato[id];
    if (this.tablesEditT[it].dato[id] === false) {
      //console.log(this.report.tables[it].tipoDatos[id]);
      this.gF.updateReportReportTableParamTD(
        this.report.uuid,
        this.report.tables[it].tipoDatos[id].uuid,
        this.report.tables[it].tipoDatos[id].codigo)
        .subscribe(res => console.log(res));
    }
  }

  borrarTable(index) {
    //console.log(index);
    if (this.report.tables[index].dispositivos.length > 0 || this.report.tables[index].tipoDatos.length > 0 ) {   // solo enlace
        this.gF.borraReportReportTableInclude(
          this.report.uuid, this.report.tables[index].uuid)
          .subscribe(res => console.log(res));
    } else { this.gF.borraReportReportTable(this.report.uuid, this.report.tables[index].uuid)
      .subscribe(res => console.log(res));
    }
    this.report.tables.splice(index, 1);
  }
  borrarTablesDispositivos(t, td) {
    this.gF.borrarReportReportTableParamDis(this.report.uuid, this.report.tables[t].dispositivos[td].uuid)
    .subscribe(res => {
      //console.log(res);
      this.report.tables[t].dispositivos.splice(td, 1);
    });
  }

  borrarTablesTipoDato(t, td) {
    this.gF.borrarReportReportTableParamTD(this.report.uuid, this.report.tables[t].tipoDatos[td].uuid)
    .subscribe(res => {
      //console.log(res);
      this.report.tables[t].tipoDatos.splice(td, 1);
    });
  }

  reportTableFun() {
    const ventana = this.dialog.open(ReportTableComponent, {
       width: '30%',
       height: '60%',
       data: {}
    });
    ventana.afterClosed().subscribe(result => {
      //console.log('RESULTADO DIALOG: ', result);
      if ( result != null) {
        let xuuid = '';
        this.gF.createReportTable(
          this.report.uuid,
          result.titulo,
          result.consumoTitulo,
          result.consumoK,
          result.itemTitulo,
          result.itemK,
          result.ratioTitulo,
          result.ratiomedioTitulo).subscribe( res => {
            //console.log(res);
            xuuid = res.data.datos.uuid;
            //console.log('createReportTable', res);
            //console.log(this.report.uuid,  xuuid);
            this.gF.crearReportReportTableInclude(this.report.uuid, xuuid)
            .subscribe( rres => {
              //console.log('crearReportReportTableInclude', rres);
              this.report.tables.push({
                uuid: xuuid,
                titulo: result.titulo,
                consumoTitulo: result.consumoTitulo,
                consumoK: result.consumoK,
                itemTitulo: result.itemTitulo,
                itemK: result.itemK,
                ratioTitulo: result.ratioTitulo,
                ratiomedioTitulo: result.ratiomedioTitulo,
                dispositivos: [],
                tipoDatos: []
              });
            });
        });
      }
    });
  }

  anadeTableTipoDato( duuid, index ) {
    const qdato: Subscription = this.gF.dameDato(duuid)
      .subscribe(({data}) => {
          //console.log(data.datos);
          const d = data.datos;
          this.gF.crearReportReportTableTD(
            this.report.uuid,
            this.report.tables[index].uuid,
              d.es.uuid,
              (d.es.nombre.toUpperCase().includes('ELEC')) ? 'consumo' : 'items')
              .subscribe( res => {
                  //console.log(res);
                  const xuuid = res.data.datos;
                  this.report.tables[index].tipoDatos.push({
                    uuid:   xuuid,
                    codigo: (d.es.nombre.toUpperCase().includes('ELEC')) ? 'consumo' : 'items',
                    tipoDatos: {
                      uuid: d.es.uuid,
                      nombre: d.es.nombre,
                      unidad: d.es.unidad
                    }
                  });
              });
    });
    this.subscrip.push(qdato);
  }
  anadeTableDisp( duuid: string, dnombre: string, index: number ) {
    //console.log('Dispositivo Alta');
    this.gF.crearReportReportTableDis(
      this.report.uuid,
        this.report.tables[index].uuid,
        duuid,
        'dispositivo'
      )
      .subscribe( res => {
          //console.log(res);
          const xuuid = res.data.datos;
          this.report.tables[index].dispositivos.push({
            uuid:   xuuid,
            codigo: 'dispositivo',
            dispositivo: {
              uuid: duuid,
              nombre: dnombre,
            }
      });
    });
  }

  reporTableFunDisp(index: number) {
    const qpf: Subscription =this.gFp.cargarFabricas()
      .subscribe(({data}) => {
        //console.log(data.datos);
        this.vpop.fabricas = [];
        this.vpop.fabricas = data.datos;
        const dialogRef = this.dialog.open(PopUpComponent, {
          width: '50%',
          height: '60%',
          data: this.vpop
        });
        dialogRef.afterClosed().subscribe(result => {
          //console.log('RESULTADO DIALOG: ', result);
          if (result != null) {
          if (result.hasOwnProperty('nombre')) {   // Es dispositivo
              this.anadeTableDisp(result.uuid, result.nombre, index);
          }
        }
      });
    });
    this.subscrip.push(qpf);
  }
  reporTableFunDato(index) {
    const qpf: Subscription = this.gFp.cargarFabricas()
      .subscribe(({data}) => {
        //console.log(data.datos);
        this.vpop.fabricas = [];
        this.vpop.fabricas = data.datos;
        const dialogRef = this.dialog.open(PopUpComponent, {
          width: '50%',
          height: '60%',
          data: this.vpop
        });
        dialogRef.afterClosed().subscribe(result => {
          //console.log('RESULTADO DIALOG: ', result);
          if (result != null) {
          if (result.hasOwnProperty('periodo')) {   // Es Dato, (solo cojeremos el tipo de dato)
              this.anadeTableTipoDato(result.uuid, index);
          }
        }
      });
    });
    this.subscrip.push(qpf);
  }

  ////////// FIN Gestion de REPORTTABLE

  ngOnDestroy() {
   this.subscrip.map(qs => qs.unsubscribe());
 }

}
