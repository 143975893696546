import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparative-graphs',
  templateUrl: './comparative-graphs.component.html',
  styleUrls: ['./comparative-graphs.component.scss']
})
export class ComparativeGraphsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
