import { ListItem } from './list-item.model';


export abstract class LynxCollection<TItem extends ListItem>{

    public collection: TItem[] = [];

    public addItem(newItem: TItem) {
        if (newItem === null || newItem === undefined) {
            return;
        }
        this.collection.addIfNotExist(newItem, item => item.uuid);
    }

    public addRangeItem(newItemRange: TItem[]) {
        if (newItemRange === null || newItemRange === undefined || newItemRange.length === 0) {
            return;
        }
        this.collection.addRangeIfNotExist(newItemRange, item => item.uuid);
    }

    public addListItem(newItem: ListItem) {
       this.addItem(this.buildItem(newItem));
    }

    public addRangeListItem(newItemRange: ListItem[]) {
        this.addRangeItem(this.buildRangeItem(newItemRange));
    }
    public removeItem(newItem: TItem) {
       this.removeListItem(newItem);
    }

    public removeRangeItem(newItemRange: TItem[]) {
       this.removeRangeListItem(newItemRange);
    }

    public removeListItem(newItem: ListItem) {
        if (newItem === null || newItem === undefined) {
            return;
        }
        this.collection.removeBy(item => item.uuid === newItem.uuid);
    }

    public removeRangeListItem(newItemRange: ListItem[]) {
        if (newItemRange === null || newItemRange === undefined || newItemRange.length === 0) {
            return;
        }
        this.collection.forEach(itemToRemove => this.collection.removeBy(item => item.uuid === itemToRemove.uuid));
    }

    public difference(collectionToDifference: ListItem[]): ListItem[] {
        if (collectionToDifference === null || collectionToDifference === undefined || collectionToDifference.length === 0) {
            return this.collection;
        }
        const listItemCollection: ListItem[] = this.collection;
        return listItemCollection.difference(collectionToDifference, item => item.uuid);
    }

    public compareTo(collectionToCompare: ListItem[]): number {
        if (collectionToCompare === null || collectionToCompare === undefined) {
            return this.collection.length;
        }
        return this.collection.length - collectionToCompare.length;
    }

    public compareToLynxCollection(collectionToCompare:LynxCollection<ListItem>): number {
     
        if (collectionToCompare === null || collectionToCompare === undefined) {
           
            return this.collection.length;
        }
        return this.compareTo(collectionToCompare.collection);
    }
    public size() {
        if (this.collection === null || this.collection === undefined) {
            return 0;
        }
        return this.collection.length;
    }
    public isEmpty() {
        return (this.collection === undefined || this.collection === null || this.collection.length === 0);
    }

    public abstract buildItem(newItem: ListItem): TItem;

    public buildRangeItem(newRangeItem: ListItem[]): TItem[] {
        if (newRangeItem === null || newRangeItem === undefined) {
            return []
        }
        return newRangeItem.map(item => this.buildItem(item));
    }

}