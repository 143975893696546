import { DataTypeItem } from './datatype-hierarchi.model';
import { DataGroup } from "./DataGroup";
import { DataTypeAggregateGraphQlResponse, DataTypeDetailAggregateGraphQlResponse, DataTypeGroupGraphQlResponse } from '../graphQL/data-type-aggegate-GQL.response';
import { DataTypeGroup } from '../data-type-aggregate.model';

export class DataTypeMapper{

    public static dataTypeAggregateGQlResponseToDataTypeCollection(dataTypeCollection:DataTypeAggregateGraphQlResponse):DataTypeItem[] {

        if(dataTypeCollection === null || dataTypeCollection === undefined || dataTypeCollection.tipoDatos=== null || dataTypeCollection.tipoDatos=== undefined ){
            return [];
        }
        return dataTypeCollection.tipoDatos.map(DataTypeMapper.dataTypeDetailAggregateGQlResponseToDataTypeItem);
    }



    public static dataTypeDetailAggregateGQlResponseToDataTypeItem(dataTypeDetail:DataTypeDetailAggregateGraphQlResponse):DataTypeItem {

        if(dataTypeDetail === null || dataTypeDetail === undefined){
            return null;
        }

        let auxDataType: DataTypeItem = new DataTypeItem();
        auxDataType.name = dataTypeDetail.nombre;
        auxDataType.uuid = dataTypeDetail.uuid;
        auxDataType.description = dataTypeDetail.descripcion;
        auxDataType.unity = dataTypeDetail.unidad;
        auxDataType.codigo = dataTypeDetail.codigo;
        if(auxDataType.codigo === null) {auxDataType.codigo = dataTypeDetail.uuid}
        auxDataType.decimalFactor = dataTypeDetail.factorDecimal;   
        auxDataType.displayField = dataTypeDetail.displayField;

        return auxDataType;
    }
    public static dataTypeAggregateGQLResToDataGroup(dataTypeAggregate:DataTypeGroupGraphQlResponse):DataGroup{

        if(dataTypeAggregate === null || dataTypeAggregate === undefined){
            return null;
        }
        let auxDataTypeGroup: DataGroup = new DataGroup()
        auxDataTypeGroup.uuid = dataTypeAggregate.uuid;
        auxDataTypeGroup.name = dataTypeAggregate.nombre;
        // auxDataTypeGroup.codigo = dataTypeAggregate.codigo;
        return auxDataTypeGroup;
    }

}