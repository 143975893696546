import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { DeviceModel } from 'src/app/models/device.model';
import { State } from 'src/app/models/contract/list-item.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-device-detail-list',
  templateUrl: './device-detail-list.component.html',
  styleUrls: ['./device-detail-list.component.scss']
})
export class DeviceDetailListComponent implements OnInit {

  @Input() title: string;
  @Input() deviceChildrenList: DeviceModel[];
  @Input() loadingChildren: boolean;
  deviceSettings: DeviceListSettings =
    {
      titleType: 'factory',
      actionIcon: 'circle-right',
      selectable: State.selected
    }

  public itemObservable: DeviceModel[];
  @Output() deviceItemNavegate: EventEmitter<DeviceModel> = new EventEmitter<DeviceModel>();

  constructor() { }

  ngOnInit() {
    this.itemObservable = _.sortBy(this.deviceChildrenList, ['name'], ['asc']);
  }

  ngOnChanges(changes: any): void {
    if (changes.deviceChildrenList !== undefined) {
      this.itemObservable = changes.deviceChildrenList.currentValue;
    } else {
      if (changes.loadingChildren !== undefined) {
        this.loadingChildren = changes.loadingChildren.currentValue;
      }
    }
  }

  public navigate(item: DeviceModel) {
    this.deviceItemNavegate.emit(item);
  }
}
