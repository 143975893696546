import gql from 'graphql-tag';

export const TiposAlarma = gql`
query {
  tipoAlarmas{
    uuid
    codigo
    formula
    nombre
    nombreCode
    subject_es
    subject_en
    body_es
    body_en
    fields        
  }
}
`

export const Alarmas = gql`
query {
  Alarma {
    uuid
    nombre
    descripcion
    disabled
    deleted
    alarmado
    lastAlarmedTime	
    timeout
    tipo {
      uuid
      codigo
      formula
      nombre
      nombreCode
      subject_es
      subject_en
      body_es
      body_en
      fields
    }
    parametros {
      uuid
      clave
      valor
    }
    depende {
      variable
      Dato {
        uuid
        es {
          nombre
          descripcion
        }
        tiene {
          nombre
        }
        descripcion
      }
    }
    informa{
      uuid
      disabled
      alarmado
      Usuario{
        uuid
        nombre
        apellidos
        mail
        usuario
      }
    }
  }
}
`

export const AlarmasByUser = gql`
query listadoAlarmas {
  listadoAlarmas {
    uuid
    nombre
    descripcion
    alarmado
    lastAlarmedTime	
    deleted
    disabled
    timeout
    tipo {
      uuid
      codigo
      formula
      nombre
      nombreCode
      subject_es
      subject_en
      body_es
      body_en
      fields
    }
    parametros {    
      uuid
      clave
      valor
    }
    depende {
      uuid
      variable
      to {
        uuid
        es {
          nombre
          descripcion
        }
        tiene {
          nombre
        }
        descripcion
      }
    }
    informa{
      uuid
      disabled
      alarmado
      to{
        usuario
        uuid
        nombre
        apellidos
        mail
      }
    }
  }
}
`

export const AlarmasByOwner = gql`
query UsuarioAlarma {
  miUsuario {
    alarmas{      
      to {
         uuid
          nombre
          descripcion 
          disabled
          deleted  
          alarmado
          lastAlarmedTime
          timeout    
          tipo {
            uuid
            codigo
            formula
            nombre
            nombreCode
            subject_es
            subject_en
            body_es
            body_en
            fields
          }
          parametros {    
            uuid
            clave
            valor
          }
          depende {
            uuid
            variable
            to {
              uuid
              es {
                nombre
                descripcion
              }
              tiene {
                nombre
              }
              descripcion
            }
          }
          informa{
            uuid
            disabled
            alarmado
            to{
              usuario
              uuid
              nombre
              apellidos
              mail
            }
          }
      }
    }
  }
}
`

export const InformarAlarma = gql`
mutation informar ($usuario:String, $alarma:String) {
  informar(
    alarma:$alarma,
    usuario:$usuario
  ){
    uuid    
  }
}
`
export const DesinformarAlarma = gql`
mutation desinformar ($informa:String) {
  desinformar(
    informa:$informa
  )
}
`

export const createAlarma = gql`
mutation
  (
    $nombre: String!, 
    $descripcion: String!, 
    $tipo: String!,
    $usuario:String!
  ) {
  createAlarma
  (
    nombre: $nombre, 
    descripcion: $descripcion, 
    tipo: $tipo,
    usuario: $usuario
  ) 
  {
    uuid
    nombre
    descripcion
  }
}`;

export const addAlarmaParametro = gql`
mutation
  (
    $alarma: String!, 
    $clave: String!, 
    $valor: String!
  ) {
    addAlarmaParametro
  (
    alarma: $alarma, 
    clave: $clave, 
    valor: $valor
  ) 
  {
    uuid
    clave
    valor
  }
}`;

export const addAlarmaDepende = gql`
mutation
  (
    $alarma: String!, 
    $variable: String!, 
    $dato: String!
  ) {
    addAlarmaDepende
  (
    alarma: $alarma, 
    variable: $variable, 
    dato: $dato
  ) 
  {
    uuid
    variable
  }
}`;

export const addAlarmaDependeDispYCodigo = gql`
mutation
  ( $alarma: String!, 
    $dispUUID:String, 
    $codigoTD: String, 
    $variable: String)
   {
    addAlarmaDependeDispYCodigo
  (
    alarma: $alarma, 
    dispUUID: $dispUUID, 
    codigoTD: $codigoTD,
    variable: $variable
  ) 
  {
    uuid
    variable
  }
}`;



export const informarAlarma = gql`
mutation
  (
    $alarma: String!, 
    $usuario: String!
  ) {
    informar
  (
    alarma: $alarma, 
    usuario: $usuario
  ) 
  {
    uuid
  }
}`;

export const deleteAlarma = gql`
mutation
  (
    $uuid: String!, 
  ) {
    deleteAlarma
  (
    uuid: $uuid
  ) 
  {
    uuid,
    nombre,
    deleted
  }
}`;

export const disableAlarma = gql`
mutation
  (
    $uuid: String!, 
  ) {
    disableAlarma
  (
    uuid: $uuid
  ) 
  {
    disabled
  }
}`;

export const enableAlarma = gql`
mutation
  (
    $uuid: String!, 
  ) {
    enableAlarma
  (
    uuid: $uuid

  ) 
  {
    uuid
    disabled
  }
}`;

export const rearmarAlarma = gql`
mutation
  (
    $alarma: String!, 
    $usuario: String!, 
  ) {
    rearmarAlarma
  (
    alarma: $alarma
    usuario: $usuario
  ) 
  {
    uuid
    nombre
  }
}`;

export const updateAlarma = gql`
mutation
  (
    $uuid: ID!,
    $nombre:String,
    $descripcion:String,
    $timeout:Int
  ) {
    updateAlarma
  (
    uuid: $uuid
    nombre:$nombre
    descripcion:$descripcion
    timeout:$timeout
  )
  {
    uuid
    nombre
    descripcion
    timeout
  }
}`;

export const updateParameterAlarma = gql`
mutation
  (
    $uuid: ID!,
    $clave:String,
    $valor:String
  ) {
    updateAlarmaParametro
  (
    uuid: $uuid
    clave:$clave
    valor:$valor
  ) 
  {
    uuid
    clave
    valor
  }
}`;

export const EventosAlarma = gql`
query (
  $alarma: String
){
  getAlarmaEventos (alarma: $alarma ){
    dateCreated
    dateValues
    valuesNano
    formula
    alarmaUUID
    tipoEvento            
  }    
}
`


