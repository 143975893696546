import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MutationOptions, QueryOptions } from 'apollo-client';
import { ApolloGraphQlService } from '../apollo/apollo-graphql.service';

import { WidgetGraphQlBuilder } from 'src/app/models/graphQL/widget-graphQL.request';
import { UsuarioDashboardResponse } from 'src/app/models/graphQL/widgets-graphQL-response';
import { CreateDashboardBuilder } from 'src/app/models/graphQL/mutations/createDashboard.mutation';
import { RemoveDashboardBuilder } from 'src/app/models/graphQL/mutations/removeDashboard.mutation';
import { UnsubscribeDashboardBuilder } from 'src/app/models/graphQL/mutations/unsubscribeFromDashboard.mutation';
import { UpdateDashboardBuilder } from 'src/app/models/graphQL/mutations/UpdateDashboard.mutation';
import { UpdateWidgetBuilder } from 'src/app/models/graphQL/mutations/UpdateWidget.mutation';
import { RemoveDashboardWidgetBuilder } from 'src/app/models/graphQL/mutations/removeDashboardWidget.mutation';
import { AddDashboardWidgetBuilder } from 'src/app/models/graphQL/mutations/addDashboardWidget.mutation';
import { AddDashboardWidgetParametroBuilder } from 'src/app/models/graphQL/mutations/addDashboardWidgetParametro.mutation';
import { AddDashboardWidgetParametroDispositivoBuilder } from 'src/app/models/graphQL/mutations/addDashboardWidgetParametroDispositivo.mutation';
import { AddDashboardWidgetParametroTipoDatoBuilder } from 'src/app/models/graphQL/mutations/addDashboardWidgetParametroTipoDato.mutation';
import { NewDashboardMapper } from 'src/app/models/widgets/mapper/newDashboardMapepr';
import { NewDashbordModel, NewWidgetsModel } from 'src/app/models/dashboard/newDashboard.model';
import { UpdateWidgetSizePositionBuilder } from 'src/app/models/graphQL/mutations/updateWidgetSizesPosition.mutation';
import { WidgetsByDashboardUUIDBuilder } from 'src/app/models/graphQL/WidgetsByDashboardUUID.request';
import { AssignDashboardToUserBuilder } from 'src/app/models/graphQL/mutations/assignDashboardToUser.request';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private apolloService: ApolloGraphQlService) { }

  findAllDashboardsByUser(userUUID: string): Observable<Array<NewDashbordModel>> {
    const queryOptions: QueryOptions = new WidgetGraphQlBuilder()
      .withUserIdParam(userUUID)
      .build();
    return this.apolloService.executeQueryWithMappedFunction(queryOptions, this.toDashboardCollection);

  }

  getWidgetsByDashboardUUID(dashboardUUID: string) {
    const queryOptions: QueryOptions = new WidgetsByDashboardUUIDBuilder()
      .withDashboardUUID(dashboardUUID)
      .build();
    // tslint:disable-next-line: max-line-length
    return this.apolloService.executeQuery(queryOptions);
  }
  private toDashboardCollection(dashboardResponse: UsuarioDashboardResponse): Array<NewDashbordModel> {
    if (dashboardResponse === undefined || dashboardResponse === null) {
      return null;
    }

    if (dashboardResponse.miUsuario !== undefined) {
      return NewDashboardMapper.fromDashboardResponseCollectionToDashboardCollection(dashboardResponse.miUsuario.dashboards);
    }
  }

  createDashboard(userUUID: string, dashboardName: string) {
    const mutationOptions: MutationOptions = new CreateDashboardBuilder()
      .withUuidUser(userUUID)
      .withDashboardName(dashboardName)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  removeDashboard(userUUID: string, dashboardUUID: string) {
    const mutationOptions: MutationOptions = new RemoveDashboardBuilder()
      .withUserUUID(userUUID)
      .withDashboardUUID(dashboardUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  unsubscribeFromDashboard(userUUID: string, dashboardUUID: string) {
    const mutationOptions: MutationOptions = new UnsubscribeDashboardBuilder()
    .withUserUUID(userUUID)
    .withDashboardUUID(dashboardUUID)
    .build();
  return this.apolloService.executeMutation(mutationOptions);

  }

  updateDashboard(dashboardUUID: string, newDashboardName: string) {
    const mutationOptions: MutationOptions = new UpdateDashboardBuilder()
      .withDashboardUUID(dashboardUUID)
      .withNewDashboardName(newDashboardName)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }
  public updateWidgetSizesPosition(relationUuid: string, width: number, height: number, x: number, y: number) {
    const mutationOptions: MutationOptions = new UpdateWidgetSizePositionBuilder()
      .withWidgetRelationUUID(relationUuid)
      .withWidgetWidth(width)
      .withWidgetHeight(height)
      .withWidgetX(x)
      .withWidgetY(y)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public editWidgetName(widgetUUID: string, newWIdgetName: string) {
    const mutationOptions: MutationOptions = new UpdateWidgetBuilder()
      .withWidgetUUID(widgetUUID)
      .withNewWidgetName(newWIdgetName)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public removeDashboardWidget(containerDashboard: string, WidgetToRemove: string) {
    const mutationOptions: MutationOptions = new RemoveDashboardWidgetBuilder()
      .withDashboardUUID(containerDashboard)
      .withWidgetUUID(WidgetToRemove)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public addDashboardWidget(dashboardUUID: string,
    widgetName: string, x: number, y: number, width: number, height: number) {
    const mutationOptions: MutationOptions = new AddDashboardWidgetBuilder()
      .withDashboardUUID(dashboardUUID)
      .withWidgetType('41df3a7a-9151-4a6a-8212-c4df8dcf1a66') // Es un widgetType existente de prueba porque este dato
      // no se utiliza pero lo requiere la mutation
      .withWidgetName(widgetName)
      .withX(x)
      .withY(y)
      .withWidth(width)
      .withHeight(height)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public addDashboardWidgetParametro(widgetUUID: string, clave: string, valor: string) {
    const mutationOptions: MutationOptions = new AddDashboardWidgetParametroBuilder()
      .withWidgetUUID(widgetUUID)
      .withClave(clave)
      .withValor(valor)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public addDashboardWidgetParametroDispositivo(widgetUUID: string, clave: string, valor: string, deviceUUIDCollection: string[]) {
    const mutationOptions: MutationOptions = new AddDashboardWidgetParametroDispositivoBuilder()
      .withWidgetUUID(widgetUUID)
      .withClave(clave)
      .withValor(valor)
      .withDeviceUUIDCollection(deviceUUIDCollection)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public addDashboardWidgetParametroTipoDato(widgetUUID: string, clave: string, valor: string, dataTypeUUIDCollection: string[]) {
    const mutationOptions: MutationOptions = new AddDashboardWidgetParametroTipoDatoBuilder()
      .withWidgetUUID(widgetUUID)
      .withClave(clave)
      .withValor(valor)
      .withDataTypeUUIDCollection(dataTypeUUIDCollection)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }

  public assignDashboardToUsers(userUUID: string, dashboardUUID) {
    const mutationOptions: MutationOptions = new AssignDashboardToUserBuilder()
      .withUserUUID(userUUID)
      .withDashboardUUID(dashboardUUID)
      .build();
    return this.apolloService.executeMutation(mutationOptions);
  }
}
