import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlarmaDetailComponent } from 'src/app/sections/alarmas/alarmas-detail/alarma-detail.component';
import { AlarmaTableComponent } from 'src/app/sections/alarmas/alarma-table/alarma-table.component';
import { AlarmaFormComponent } from 'src/app/sections/alarmas/alarmas-form/alarma-form.component';


import { SharedModuleModule } from '../shared-module/shared-module.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material/material.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AlarmaFormComponent,
    AlarmaDetailComponent,
    AlarmaTableComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModuleModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    AlarmaFormComponent,
    AlarmaDetailComponent,
    AlarmaTableComponent,
  ]
})
export class AlarmaModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
