
export class AlarmaModel {
    uuid: string;
    nombre: string;
    descripcion: string;
    disabled:string;
    deleted:string;
    lastAlarmedTime?: number;
    alarmado?: number;
    alarmadoDate:string;
    disabledDate:string;
    tipo: TipoAlarmaModel;
    parametros: ParametrosAlarmaModel[];
    depende: VariablesModel[];
    informa: InformaModel[];
    usuario: string;
    timeout: number;
}

export class ParametrosAlarmaModel{
    uuid: string;
    clave: string;
    valor: string;
}

export class DatoModel{
    uuid: string;
    es: {
        nombre: string;
        descripcion: string;
        codigo?: string;
    };
    tiene: {
        uuid: string;
        nombre: string;
    }
    descripcion: null
}

export class VariablesModel{
    variable: string;
    to: DatoModel;
}

export class InformaModel{
    uuid: string;
    disabled: string;
    disabledDate?: string;
    alarmadoDate?: string;
    alarmado: string;
    to:UsuarioModel
}

export class UsuarioModel{
    uuid:string;
    nombre: string;
    apellidos: string;
    usuario:string;
}

export class TipoAlarmaModel {
    uuid: string;
    codigo: string; 
    formula: string;
    nombre: string;
    nombreCode: string;
    subject_es: string;
    subject_en: string;
    body_es: string;
    body_en: string;
    fields: string;
}

export class FieldModel {
    clave:string;
    descripcion:string;
    tipo:string;
}

export class NewDependeModel {
    idFormControl:string;
    uuidAlarma: string;
    variable:string;
    dato?:string;
    dispositivo?:string;
    codigo?:string;
}

export class NewParametroModel {
    idFormControl:string;
    uuidAlarma:string;
    clave:string;
    valor:string;
}

export class UpdateParametroModel {
    idFormControl:string;
    uuid:string;
    uuidAlarma:string;
    clave:string;
    valor:string;
}

export class NewInformaModel {
    uuidAlarma:string;
    uuidUsuario:string;
}

export class AlarmaEvento {
    dateCreated: Date
    dateValues: string
    valuesNano: string;
    formula: string
    alarmaUUID: string;
    tipoEvento: string;
}

