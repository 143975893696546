import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface SettingResponse {
  showProductivityCard: boolean;
  exportPath: string;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  public getProductivitySettings(): Observable<boolean> {
    return this.http.get<SettingResponse>('assets/data/settings.json')
      .pipe(item => item.map(res => this.getProductivityValue(res)));
  }

  private getProductivityValue(response: SettingResponse): boolean {
    return response.showProductivityCard;
  }

  public getExportPathSettings(): Observable<string> {
    return this.http.get<SettingResponse>('assets/data/settings.json')
      .pipe(item => item.map(res => this.getExportPathValue(res)));
  }

  private getExportPathValue(response: SettingResponse): string {
    return response.exportPath;
  }
}
