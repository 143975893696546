import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Budget } from 'src/app/models/budget.model';

@Component({
  selector: 'app-alarm-budget',
  templateUrl: './alarm-budget.component.html',
  styleUrls: ['./alarm-budget.component.scss']
})
export class AlarmBudgetComponent implements OnInit {
  @Output() budgetCollectionEmitter: EventEmitter<Budget[]> = new EventEmitter<Budget[]>();
  @Input() budgetCollection: Budget[];

  constructor() { }

  ngOnInit() {
  }

  selectStateToFilter(budgetSelected: Budget) {
    if (budgetSelected.name !== 'ALARM.TOTAL' && budgetSelected.name !== 'USER.TOTAL') {
      budgetSelected.selected = !budgetSelected.selected;
      for (const budget of this.budgetCollection) {
        if (budget.name === 'ALARM.TOTAL' || budget.name === 'USER.TOTAL') {
          budget.selected = false;
        }
      }
      this.budgetCollectionEmitter.emit(this.budgetCollection);
    } else {
      if (budgetSelected.selected === false) {
        budgetSelected.selected = true;
        for (const budget of this.budgetCollection) {
          if (budget.name !== 'ALARM.TOTAL' && budget.name !== 'USER.TOTAL') {
            budget.selected = false;
          }
        }
        this.budgetCollectionEmitter.emit(this.budgetCollection);
      }
    }
  }

}
