import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'NullFormat'
})
export class NullFormatPipe implements PipeTransform {

  transform(value: string|number): string|number {
    if (_.isEmpty(value) || value === 'sin unidad') {
      return value = '---';
    }
    return value;
  }

}
