import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComparativeGraphsComponent } from 'src/app/sections/visualizacionFolder/comparative-graphs/comparative-graphs.component';


const routes: Routes = [
  { path: 'comparative-graphs', component: ComparativeGraphsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComparativeGraphsRoutingModule { }
