import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FactoriesService, FactoryChanged } from 'src/app/services/factories/factories.service';
import { FactoryModel } from 'src/app/models/FactoryModel';
import { tap } from 'rxjs/operators';
import { FactoryCollection } from 'src/app/models/factory/factory.collection.model';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() titleSection: string;
  factoriasSelected: FactoryCollection;
  numberOfFactorySelected = 0;
  numberAlarmsAlerted = 0;
  factoria = new FormControl();
  factoryCollection: FactoryCollection;
  rotateDirection = '';
  opened = false;
  public sideNavStatus = true;
  isOnlyOneFactory = false;

  constructor(private factoryService: FactoriesService,
              private loginService: LoginService
              ) {
  this.factoryService.factoryChangedBS.subscribe(data => this.changeNameFactory(data));
  
  }

  ngOnInit() {
    this.loadUserFactoryCollection();
    
  }

  
  changeNameFactory(factoryChanged: FactoryChanged) {
    if (factoryChanged.uuid !== '' && this.factoryCollection !== undefined) {
      const toChangeFactory = this.factoryCollection.collection.find(item => item.uuid === factoryChanged.uuid);
      if (toChangeFactory !== undefined) {
        toChangeFactory.name = factoryChanged.name;
      }
    }
  }

  onSelectFactory(selectedFactory: FactoryModel) {
    selectedFactory.selected = !selectedFactory.selected;
    if (selectedFactory.selected) {
      this.addFactoryCollection(selectedFactory);
    } else {
      this.removeFactoryCollection(selectedFactory);
    }
  }

  private removeFactoryCollection(selectedFactory: FactoryModel) {
    let removeFactoryCollection = new FactoryCollection();
    this.factoryService.factorySelectedObservable.subscribe(data => removeFactoryCollection = data);
    removeFactoryCollection.collection.remove(selectedFactory);
    this.factoryService.newSelectFactoryCollection(removeFactoryCollection);
  }

  private addFactoryCollection(selectedFactory: FactoryModel) {
    let addFactoryCollection = new FactoryCollection();
    this.factoryService.factorySelectedObservable.subscribe(data => addFactoryCollection = data);
    addFactoryCollection.collection.add(selectedFactory);
    this.factoryService.newSelectFactoryCollection(addFactoryCollection);
    this.loadSelectedObservable();
  }

  private loadUserFactoryCollection() {
    const usuario = this.loginService.currentUserValue;
    this.factoryService.getFactories(usuario.user).pipe(
    ).subscribe((data: FactoryCollection) => {
      this.factoryCollection = data;

      // SI SOLO HAY UNA FACTORÍA LA SELECCIONAMOS
      if (this.factoryCollection !== undefined) {
        if (this.factoryCollection.collection.length === 1) {
          const auxFactory: FactoryModel = this.factoryCollection.collection[0];
          this.isOnlyOneFactory = true;
          this.onSelectFactory(auxFactory);
        }
      }
    });
  }

  private loadSelectedObservable() {
    this.factoryService
      .factorySelectedObservable
      .pipe(
        tap(factoriasSelected => this.numberOfFactorySelected = factoriasSelected.size()),
      ).subscribe(data => this.factoriasSelected = data);
  }

  public onToggleSideNav() {
    this.sideNavStatus = !this.sideNavStatus;
    this.rotateIcon();
    this.toggleSideNav.emit(this.sideNavStatus);
  }

  rotateIcon() {
    this.rotateDirection = this.sideNavStatus ? 'left' : 'right';
  }

  onCloseSidenav() {
    this.opened = false;
  }
}
