
import { Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl} from '@angular/forms';


interface XData {
  data: string;
}


@Component({
  selector: 'app-report-param',
  templateUrl: './report-param.component.html',
  styleUrls: ['./report-param.component.scss']
})
export class ReportParamComponent implements OnInit, OnDestroy {
  public rParam = {
    codigo: 'consumo',
    periodo: '',
    unidadT: '',
    unidadK: '',
    tipo: ''
  };

  constructor(
    public dialogRef: MatDialogRef<ReportParamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: XData) {
    }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
  }


}
