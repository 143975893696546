import { MutationOptions, OperationVariables } from 'apollo-client';
import { DocumentNode } from 'graphql';

export class GraphQlMutationBuilder {

    private _MUTATION: DocumentNode;
    private _PARAMS: OperationVariables;

    public withMutation(mutation: DocumentNode): GraphQlMutationBuilder {
        this._MUTATION = mutation;
        return this;
    }

    public withParams(params: OperationVariables): GraphQlMutationBuilder {
        this._PARAMS = params;
        return this;
    }

    public build(): MutationOptions {
        const mutationOptions: MutationOptions = {
            mutation: this._MUTATION, variables: this._PARAMS, errorPolicy: 'all'
        };
        return mutationOptions;
    }
}
