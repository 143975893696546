import { ListItemHierachi } from '../contract/list-item.model';
import * as lodash from 'lodash';
export class DataGroup implements ListItemHierachi {
    uuid: string;
    name: string;
    codigo: string;
    children: ListItemHierachi[];
    parent: DataGroup;
    public isLeaf(): boolean {
        return lodash.isEmpty(this.children);
    }
    public addChild(newChild: ListItemHierachi) {
        if (lodash.isEmpty(this.children)) {
            newChild.parent = this;
            this.children = [newChild];
            return;
        }
        const existedChildren = this.children.firstOrDefault(item => item.uuid == newChild.uuid);
        if (lodash.isEmpty(existedChildren)) {
            newChild.parent = this;
            this.children.add(newChild);
            return;
        }
        if (!lodash.isEmpty(newChild.children)) {
            for (let item of newChild.children) {
                (existedChildren as DataGroup).addChild(item);
            }
        }
    }
    hasDescendentById(uuidToCheck: String): boolean {
        if (lodash.isEmpty(uuidToCheck) || this.isLeaf())
            return false;
        return this.children.any(item => item.uuid === uuidToCheck || item.hasDescendentById(uuidToCheck));
    }
    hasAnyDescendentById(uuidcollectionToCheck: String[]): boolean {
        if (lodash.isEmpty(uuidcollectionToCheck) || this.isLeaf())
            return false;
        return this.children.any(item => uuidcollectionToCheck.includes(item.uuid) || item.hasAnyDescendentById(uuidcollectionToCheck));
    }
}
