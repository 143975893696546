import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Alarm, AlarmState } from 'src/app/models/alarm/alarm.model';
import { Budget } from 'src/app/models/budget.model';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AlarmService } from 'src/app/services/alarm/alarm.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login/login.service';
import { forkJoin } from 'rxjs';
import { LimitInfluxParams } from 'src/app/models/limitInfluxParams.model';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AlarmsComponent implements OnInit {

  elementData: Alarm[] = [];
  budgetCollection: Budget[] = [];
  isSelected = false;
  activatedStyle = false;
  elementDataAux: Alarm[] = [];
  onlyAlarmed = false;
  alarmSelected: Alarm;
  enableAlarmForm = false;
  enableAlarmDetail = false;
  formMode = '';
  detailedAlarm: Alarm;
  alarmToEdit: Alarm;

  valueInterval: number;
  intervalFetch: any;
  loading = false;

  constructor(
    public dialog: MatDialog,
    private toastr: ToastService,
    private alarmService: AlarmService,
    private translateService: TranslateService,
    private loginService: LoginService) {
  }

  ngOnInit() {
    this.initAlarmAndBudget();
    this.alarmService.getIntervalAlarmFetch().subscribe(data => {
      this.valueInterval = data.value;
      this.intervalFetch = setInterval(() => this.initAlarmAndBudget(), this.valueInterval);
    });
  }


  private initAlarmAndBudget() {
    this.loading = true;
    window.location.pathname === '/alarms/alarmed' ? this.onlyAlarmed = true : this.onlyAlarmed = false;
    this.getAllUserAlarmCollection();
    this.getBudgetCollection();
  }

  getAllUserAlarmCollection() {
    this.alarmService.getAlarmCollectionByUser(this.loginService.currentUserValue.uuid).subscribe((response: Alarm[]) => {
      this.elementData = response;
      this.elementDataAux = this.elementData;
      this.loading = false;
    });
  }

  getBudgetCollection() {
    this.alarmService.getBudgetCollection().subscribe(response => {
      this.budgetCollection = response;
      if (this.onlyAlarmed) {
        this.budgetCollection[0].selected = false;
        this.budgetCollection[3].selected = true;
        this.filterByState(this.budgetCollection);
      } else {
        this.budgetCollection[0].selected = true;
        this.budgetCollection[3].selected = false;
      }
    });
  }

  showAlarmDetail(alarm: Alarm) {
    this.enableAlarmDetail = true;
    this.detailedAlarm = alarm;
  }

  filterByState(newBudgetCollection: Budget[]) {
    const budgetStateCollection: string[] = [];
    for (const budget of newBudgetCollection) {
      if (budget.selected) {
        budgetStateCollection.push(this.getLiteralState(budget.name));
      }
    }
    if (budgetStateCollection.length === 0) {
      for (const budget of newBudgetCollection) {
        if (budget.name === 'ALARM.TOTAL') {
          budget.selected = !budget.selected;
          budgetStateCollection.push(this.getLiteralState(budget.name));
        }
      }
    }
    this.elementData = this.getAlarmCollectionByState(budgetStateCollection);
  }

  private getAlarmCollectionByState(stateCollection: string[]): Alarm[] {
    if (stateCollection[0] === 'Total') {
      return this.elementDataAux;
    } else {
      let auxAlarmCollectionByState: Alarm[] = [];
      auxAlarmCollectionByState = this.elementDataAux.filter(item => stateCollection.includes(item.state));
      return auxAlarmCollectionByState;
    }
  }

  private getLiteralState(budgetName: string): string {
    switch (budgetName) {
      case 'ALARM.ACTIVATED':
        return 'Activada';
      case 'ALARM.DEACTIVATED':
        return 'Desactivada';
      case 'ALARM.ALARMED':
        return 'Alarmada';
      case 'ALARM.TOTAL':
        return 'Total';
    }
  }

  controlStateButtonStyle(newState: string) {
    newState !== 'Activada' ? this.activatedStyle = true : this.activatedStyle = false;
  }

  changeSelectedAlarmState(alarmToChangeState: Alarm) {
    // tslint:disable-next-line: max-line-length
    alarmToChangeState.state === 'Activada' ? alarmToChangeState.state = AlarmState.desactivated : alarmToChangeState.state = AlarmState.activated;
    this.elementDataAux.map(item => {
      if (item.uuid === alarmToChangeState.uuid) {
        item = alarmToChangeState;
      }
    });
    for (const uuidSupervisa of alarmToChangeState.supervisa) {
      this.alarmService.changeAlarmState(uuidSupervisa, alarmToChangeState.state).subscribe(result => {
        if (result !== null) {
          this.alarmService.updateAlarmCollection(this.elementDataAux);
          this.controlStateButtonStyle(alarmToChangeState.state);
        }
      });
    }
  }

  showModalForChangeAlarmState(alarmToChangeState: Alarm) {
    let warningMessage = 'ALARM.CHANGE_TO_ACTIVATED';
    if (alarmToChangeState.state === 'Activada') {
      warningMessage = 'ALARM.CHANGE_TO_DEACTIVATED';
    }
    const options = {
      title: alarmToChangeState.name,
      message: warningMessage,
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeSelectedAlarmState(alarmToChangeState);
      }
    });
  }


  showModalForDeleteAlarm(alarmToDelete: Alarm) {
    this.enableAlarmDetail = false;
    const options = {
      title: alarmToDelete.name,
      message: 'ALARM.DELETE_ALARM_MESSAGE',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alarmService.deleteAlarm(alarmToDelete.uuid).subscribe(data => {
          if (data !== undefined) {
            this.deleteAlarmSelected(alarmToDelete);
          }
        });
      }
    });
  }

  private deleteAlarmSelected(alarmToDelete: Alarm) {
    _.remove(this.elementDataAux, item => item.uuid === alarmToDelete.uuid);
    // Clonamos para cambiar la referencia de memoria y el cambio se detecte en el onChanges del componente hijo
    this.elementData = [...this.elementDataAux];
    this.toastr.showSuccess('Se ha eliminado la alarma correctamente', 'Éxito');
    this.getBudgetCollection();
    this.alarmService.updateAlarmCollection(this.elementData);
  }

  showFormForEditAlarm(alarmSelected: Alarm) {

    /*  this.elementDataAux.splice(0, 1, _.find(this.elementDataAux, item => item.uuid === result.uuid));
     this.elementData = [...this.elementDataAux]; */

    this.alarmToEdit = alarmSelected;
    this.enableAlarmForm = true;
    this.formMode = ''; // Para que aparezca el título de editar alarma
  }

  toggleAlarmForm() {
    this.enableAlarmForm = !this.enableAlarmForm;
  }

  showAlarmForm() {
    this.toggleAlarmForm();
    this.formMode = 'create'; // Para que aparezca el título de crear alarma
    this.alarmToEdit = null; // Si ya está inicializada evitamos que al abrir la pantalla de crear estén los campos rellenos
  }

  goBackAlarmTable() {
    this.enableAlarmDetail = false;
  }

  submitForm(alarmSubmitted: Alarm) {
    const newAlarm = _.find(this.elementData, item => item.uuid === alarmSubmitted.uuid);
    newAlarm !== undefined ? this.updateAlarm(alarmSubmitted) : this.addAlarm(alarmSubmitted);
  }

  addAlarm(newAlarm: Alarm) {
    let successMessage = '';
    this.translateService.get('ALARM.ALARM_CREATED_SUCCESFULLY').subscribe(
      value => successMessage = value
    );

    let datoUUID = '';
    this.alarmService.getDatoUuid(newAlarm.devices[0].uuid, newAlarm.devices[0].dataType[0].uuid).subscribe(
      (data: LimitInfluxParams) => {
        datoUUID = data.uuid;
        this.alarmService.createAlarm(newAlarm).subscribe(
          (result: any) => {
            if (result !== null && result !== undefined) {
              const uuidNewAlarm: string = result.createAlarma.uuid;
              const requestMultiple = forkJoin(
                this.addAlarmaInforma(uuidNewAlarm, this.loginService.currentUserValue.uuid),
                this.addAlarmaParametro(uuidNewAlarm, newAlarm.type.code, newAlarm.param.valor.toString()),
                this.addAlarmaSupervisa(uuidNewAlarm, datoUUID)
              );
              requestMultiple.subscribe((res: any) => {
                if (res !== null) {
                  newAlarm.param.uuid = res[1].addAlarmaParametro.uuid;
                  newAlarm.uuid = uuidNewAlarm;
                  this.elementDataAux.push(newAlarm);
                  this.elementData = [..._.compact(this.elementDataAux)];
                  this.alarmService.updateAlarmCollection(this.elementData);
                  this.toggleAlarmForm();
                  this.getBudgetCollection();
                  this.toastr.showSuccess(successMessage, '');
                }
              });
            }
          }
        );
      }
    );


  }

  addAlarmaInforma(alarmUUID: string, userUUID: string) {
    return this.alarmService.addAlarmaInforma(alarmUUID, userUUID);
  }

  addAlarmaParametro(alarmUUID: string, clave: string, valor: string) {
    return this.alarmService.addAlarmaParametro(alarmUUID, clave, valor);
  }

  addAlarmaSupervisa(alarmUUID: string, datoUUID: string) {
    return this.alarmService.addAlarmaSupervisa(alarmUUID, datoUUID);
  }

  updateAlarm(alarmToUpdate: Alarm) {
    let successMessage = '';
    this.translateService.get('ALARM.ALARM_UPDATED_SUCCESFULLY').subscribe(
      value => successMessage = value
    );

    this.alarmService.updatedAlarm(alarmToUpdate).subscribe((response: any) => {
      if (response.updateAlarma !== null) {
        this.alarmService.updatedAlarmValue(alarmToUpdate).subscribe((res: any) => {
          if (res.updateAlarmaParametro !== null) {
            if (alarmToUpdate !== undefined) {
              this.elementDataAux.map((item: Alarm) => {
                if (item.uuid === alarmToUpdate.uuid) {
                  item = Object.assign(item, alarmToUpdate); // (target, sources)
                }
              });
              this.elementData = [..._.compact(this.elementDataAux)];
              this.toggleAlarmForm();
              this.getBudgetCollection();
              this.toastr.showSuccess(successMessage, '');
            }
          }
        });
      }
    });
  }

  cancelForm() {
    this.toggleAlarmForm();
  }
}
