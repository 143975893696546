import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuardGuard } from './guards/login-guard.guard';
import { RoleGuardGuard } from './guards/RoleGuard/role-guard.guard';
import { LoginComponent } from './sections/login/login.component';
import { PagenotfoundComponent } from './components/shared/pagenotfound/pagenotfound.component';
import { DispositivosComponent } from './sections/dispositivos/dispositivos.component';
import { GraphicsComponent } from './sections/visualizacionFolder/graphics/graphics.component';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { AlarmsComponent } from './sections/alarms/alarms.component';
import { AlarmasComponent } from './sections/alarmas/alarmas.component';
import { UsersComponent } from './sections/users/users.component';
import { BbddComponent } from './sections/bbdd/bbdd.component';
import { arbolDispositivos } from './services/arbolDispositivos/arbolDispositivos.component';
import { arbolDatos } from './components/arbolDatos/arbolDatos.component';
import { InformesComponent } from './components/informes/informes.component';
import { InformeFabricaComponent } from './components/informes/informe-fabrica.component';
import { InformeMaeComponent } from './components/informes/informe-mae.component';
import { EstructuraCampoComponent } from './sections/estructuraCampo/estructuraCampo.component';
import { EstructuraCampoNavComponent } from './components/estructuraCampoNav/estructuraCampoNav.component';
import { EntradasNavComponent } from './components/entradas-nav/entradas-nav.component';
import { NoCredentialsComponent } from './components/noCredentials/no-credentials.component';
import { MapaComponent } from './sections/mapa/mapa.component';
import { environment } from 'src/environments/environment'
import { IridiumEventsComponent } from './components/iridium-events/iridium-events.component';
import { FeatureGuard } from './guards/IridiumGuard/iridium-guard.guard'
import { UltimasMedicionesComponent } from './sections/ultimas-mediciones/ultimas-mediciones.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [LoginGuardGuard] },
  {
    path: 'devices', component: DispositivosComponent, canActivate: [LoginGuardGuard],
    data: {
      expectedRole: ['devices', 'admin']
    }
  },
  { path: 'graphics', component: GraphicsComponent, canActivate: [LoginGuardGuard] },
  {
    path: 'alarmas', component: AlarmasComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['alarms', 'admin']
    }
  },
  {
    path: 'alarmas/:filter', component: AlarmasComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['alarms', 'admin']
    }
  },
  {
    path: 'iridium', component: IridiumEventsComponent, canActivate: [RoleGuardGuard, FeatureGuard, LoginGuardGuard],
    data: {
      expectedFeature: ['iridium'],
      expectedRole: ['iridium', 'admin']
    }
  },
  {
    path: 'users', component: UsersComponent, canActivate: [LoginGuardGuard, RoleGuardGuard], 
    data: {
      expectedRole: ['user_manager', 'admin']
    }
  },
  { path: 'login', component: LoginComponent },
  { path: 'pageNotFound', component: PagenotfoundComponent },
  {
    path: 'bbdd', component: BbddComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  {
    path: 'disp/:uuid', component: arbolDispositivos, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  {
    path: 'dato/:uuid', component: arbolDatos, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }, runGuardsAndResolvers: 'always'
  },
  {
    path: 'estructuracampo', component: EstructuraCampoComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  {
    path: 'mapa', component: MapaComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin', 'map']
    }
  },
  {
    path: 'ultimasmediciones', component: UltimasMedicionesComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin', 'last_measures']
    }
  },
  {
    path: 'gateways/:uuid', component: EstructuraCampoNavComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  {
    path: 'source/:uuid', component: EntradasNavComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  {
    path: 'informes', component: InformesComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin', 'informs']
    }
  },
  {
    path: 'informe-fabrica/:modo/:uuid', component: InformeFabricaComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  {
    path: 'informe-mae/:modo/:uuid', component: InformeMaeComponent, canActivate: [LoginGuardGuard, RoleGuardGuard],
    data: {
      expectedRole: ['admin']
    }
  },
  { path: 'pageNotFound', component: PagenotfoundComponent },
  { path: 'accessDenied', component: NoCredentialsComponent },
  { path: '**', redirectTo: 'pageNotFound' }

  // { path: 'home', loadChildren: () => import(`./module/home/home.module`).then(m => m.HomeModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })], // , { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
