import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonServiceService } from 'src/app/services/commonService/common-service.service';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public currentUser;
  title = 'Lynx';
  @Input() sideNavStatus: boolean = true;
  toggleSideNav: boolean;
  titleSection: string;
  constructor(private translate: TranslateService,
    private commonService: CommonServiceService,
    private loginService: LoginService,
    private router: Router) {

    this.currentUser = localStorage.getItem('loggedUser') ? localStorage.getItem('loggedUser') : '';
    this.commonService.menuStatus.subscribe(data => {

      this.sideNavStatus = false;
    })

    this.translate.setDefaultLang('es');
    this.loginService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }

}
