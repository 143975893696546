import gql from 'graphql-tag';

export const iridiumEventsAlarmsRequest = gql`
query getDispositivoIridium{
    dispositivoIridiums {
        uuid
        descripcion
        nombre
        tipoDatoIridium {
            iridium
            uuid
            nombre
            nombreCode
            unidad
            codigo
        }
    }
}`

export const requestDataSDB = gql`
mutation requestDataSDB ($dispositivoIridium: String, $tipoDatoIridium: String) {
    peticionIridium (dispositivoIridium: $dispositivoIridium, tipoDatoIridium: $tipoDatoIridium)
  }`;