import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { FieldBase }     from '../dynamic-form-field/field-base';
import { TextboxField} from '../dynamic-form-field/field-textbox';
import { of } from 'rxjs';
import { Observable }      from 'rxjs';

@Component({
  selector: 'app-edit-form',
  templateUrl: './device-labels-modal.component.html',
  styleUrls: ['./device-labels-modal.component.scss'],
})

export class DeviceLabelsModalComponent implements OnInit {

  //Variables para el dynamicForm

  atributosLabelData:any[]= [];   
  AtributosLabelForm: FormGroup;
  title: string= "Atributos de Label";
  fields$: Observable<FieldBase<any>[]>;  
  labelsMapping:any;
  fields: FieldBase<string>[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                  private fb: FormBuilder,
                  
              ) { 
                this.atributosLabelData =  data.atributosLabel; //obtiene los datos del estructuraCampoNav
                this.labelsMapping =  data.labelsMapping; //obtiene los datos del estructuraCampoNav
                this.fields$ = this.getFields(); //Crea los campos para el dynamicForm
              } 

  ngOnInit() {
    this.atributosLabelData = [];  
  }

  getFields() {
    console.log(this.fields);
    this.atributosLabelData.forEach((atributo,i)=>{
      if (this.labelsMapping[atributo[0]]=="String" && atributo[0]=="nombre"){
        this.fields.push(
          new TextboxField({
            key: atributo[0],
            label: atributo[0],
            value: atributo[1],
            required: true,
            order: i,
            type: "text"
          })
        )
      } else if (this.labelsMapping[atributo[0]]=="String"){
        this.fields.push(
          new TextboxField({
            key: atributo[0],
            label: atributo[0],
            value: atributo[1],
            required: false,
            order: i,
            type: "text"
          })
        )
      } else if (this.labelsMapping[atributo[0]]=="Int"){
        this.fields.push(
          new TextboxField({
            key: atributo[0],
            label: atributo[0],
            value: atributo[1],
            required: false,
            order: i,
            type: "number"
          })
        )
      }

    })
    return of(this.fields.sort((a, b) => a.order - b.order));
  }
}
