import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceStatusComponent } from 'src/app/sections/visualizacionFolder/device-status/device-status.component';


const routes: Routes = [
  { path: 'device-status', component: DeviceStatusComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceStatusRoutingModule { }
