import gql from 'graphql-tag';
import { GraphQlMutationBuilder } from '../graphQL-mutation.builder';

export interface NewDashboardWidgetParametroDispositivo {
    widgetUUID: string;
    clave: string;
    valor: string;
    dispositivos: string[];
}

export class AddDashboardWidgetParametroDispositivoBuilder {

    private _WIDGETUUID: string;
    private _CLAVE: string;
    private _VALOR: string;
    private _DEVICEUUIDCOLLECTION: string[];

    private get _mutation() {
        return gql`mutation AddDashboardWidgetParametroDispositivo(
            $widgetUUID: String
            $clave: String
            $valor: String
            $dispositivos: [String]
          ) {
            addWidgetParametroDispositivos(
              widget: $widgetUUID
              clave: $clave
              valor: $valor
              dispositivos: $dispositivos
            ) {
              uuid
            }
          }
            `;
    }

    public withWidgetUUID(newWidgetUUID: string): this {
        this._WIDGETUUID = newWidgetUUID;
        return this;
    }

    public withClave(newClave: string): this {
        this._CLAVE = newClave;
        return this;
    }

    public withValor(newValor: string): this {
        this._VALOR = newValor;
        return this;
    }

    public withDeviceUUIDCollection(newDeviceUUIDCollection: string[]): this {
        this._DEVICEUUIDCOLLECTION = newDeviceUUIDCollection;
        return this;
    }

    public build() {
        const mutationParams: NewDashboardWidgetParametroDispositivo = {
            widgetUUID: this._WIDGETUUID,
            clave: this._CLAVE,
            valor: this._VALOR,
            dispositivos: this._DEVICEUUIDCOLLECTION
        };
        const graphqlMutationBuilder: GraphQlMutationBuilder = new GraphQlMutationBuilder();
        return graphqlMutationBuilder.withMutation(this._mutation)
            .withParams(mutationParams)
            .build();
    }
}
