import { Subject } from 'rxjs/Subject';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment'
import { IridiumSubscriptionGQLService } from './../../../services/iridium/iridium-subscription-gql.service';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from './../../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { take, share, publishLast, takeUntil } from 'rxjs/operators';





@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public visualB = false;
  @Input() sideNavStatus: boolean;
  @Output() titleEmitter: EventEmitter<string> = new EventEmitter<string>();
  userRole: string[];
  hasIridiumOption: boolean;
  data: any;
  iridiumSubscription: Subscription;
  userAuthenticated: boolean = false;


  constructor(private loginService: LoginService,
    private iridiumService: IridiumSubscriptionGQLService,
    private toastrservice: ToastrService,
  ) {
    this.checkUserRole();
    this.hasIridiumOption = JSON.parse(environment.iridium);
    this.listenToIridiumEvents();
  }

  ngOnInit() {
    this.checkUserRole();
    this.getFirstValueOfTitleSection();
  }

  getFirstValueOfTitleSection() {
    switch (window.location.pathname) {
      case '/':
        this.titleEmitter.emit('TITLES.DASHBOARD');
        break;
      case '/graphics':
        this.titleEmitter.emit('TITLES.GRAPHICS');
        break;
      case '/devices':
        this.titleEmitter.emit('TITLES.DEVICES');
        break;
      case '/alarmas':
        this.titleEmitter.emit('TITLES.ALARMS');
        break;
      case '/users':
        this.titleEmitter.emit('TITLES.USERS');
        break;
    }
  }

  emitTitle(title) {
    this.titleEmitter.emit(title);
  }

  visualToggle() {
    this.visualB = !this.visualB;
  }

  checkUserRole() {
    this.userRole = this.loginService.currentUserValue.rol;
  }


  listenToIridiumEvents() {
    if (localStorage.getItem('loggedUser') && this.hasIridiumOption === true) {
        this.iridiumSubscription = this.iridiumService.listen().subscribe(res => {
          this.toastrservice.warning('Navege a la sección Iridium para visualizarla', 'Recibida alarma vía Iridium', { closeButton: true, disableTimeOut: true });
      })  ;
     };
  };


  ngOnDestroy() {
    this.iridiumSubscription.unsubscribe();
  }


}
