import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaObserver, MediaChange} from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { AlarmasService } from '../../services/alarmas/alarmas.service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';

import { ToastService } from 'src/app/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login/login.service';

import { AlarmaModel, InformaModel } from './models/alarma-models'

import { GetDatePipe } from 'src/app/pipes/pipes/pipe-date.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alarmas',
  templateUrl: './alarmas.component.html',
  styleUrls: ['./alarmas.component.scss'],
  providers: [
    GetDatePipe
],
})
export class AlarmasComponent implements OnInit {

  //form
  activatedStyle = false;
  isSelected = false;
  enableAlarmasDetail = false;
  enableAlarmasForm = false;
  alarmaSelected: AlarmaModel;
  elementData: AlarmaModel[] = [];
  elementDataAux: AlarmaModel[] = [];
  formMode = '';
  isAdmin = false;
  loadingText = 'LOADING.ALARMAS';
  showAlarmas = false;
  loading = false;

  mobileMode: boolean = false;
  private mediaSub: Subscription; 
  userId: string;
  filter = '';

  
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
    private alarmasService: AlarmasService,
    private toastr: ToastService,
    private translate: TranslateService,
    private loginService: LoginService,
    public dialog: MatDialog,
    private getDatePipe: GetDatePipe,
    private route: ActivatedRoute,
  ) { 
    this.isAdminUser();
    //Cargar los tipos de alarma

    //this.
    //this.fields$ = this.getFields(); //Crea los campos para el dynamicForm
  }

  isAdminUser() {
    if (this.loginService.currentUserValue.rol.includes('admin')) {
      this.isAdmin = true;
    }
  }

  ngOnInit() {   

    this.filter = this.route.snapshot.paramMap.get('filter');
    
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (change:MediaChange)=>{
        
        if (change.mqAlias=="xs" || change.mqAlias=="sm"){
          this.mobileMode=true;
        }else{
          this.mobileMode=false;
        }
      }
    );
    
    this.userId =  this.loginService.currentUserValue.uuid;
    //this.userId = '192f8cc2-570c-4855-a2fa-d84cdfaab7d4';  //Aitor
    //this.userId = 'd282603a-a328-4b9d-bf77-1885e571ad78';//Iker
    this.getAlarmasCollectionByUser(this.userId);
    this.isAdminUser();
    
  }

  getAlarmasCollection(){
    this.loading=true;
    this.alarmasService.getAlarmas().subscribe(alarmas=>{
      this.elementData = alarmas["Alarma"];
      this.elementDataAux = this.elementData;
      this.loading = false;
    })
  }

  getAlarmasCollectionByUser(uuidUser: string){

    this.alarmasService.getAlarmasByUser(uuidUser)
    this.alarmasService.getAlarmasByOwner(uuidUser)
    .subscribe(alarmasUser=>{
      //get Alarmas by owner
      
      if(alarmasUser["miUsuario"]["alarmas"]){
        this.elementData = alarmasUser["miUsuario"]["alarmas"].map(alarma =>{
          return alarma.to as AlarmaModel;
        }).filter((alarma:AlarmaModel)=>{
          return alarma.deleted==null;
        });
      }
      //setear el usuario logeado como el owner en el arreglo de Alarmas
      this.elementData.forEach(data=>{
         data.usuario = uuidUser
      })

      //get subscribedAlarmas
      this.alarmasService.getAlarmasByUser(uuidUser)
      .subscribe(alarmas=>{
        let alarmasGeneral:AlarmaModel[];
        let subsribedAlarmas:AlarmaModel[]=[];
        alarmasGeneral= alarmas["listadoAlarmas"] as AlarmaModel[];
        
        alarmasGeneral.filter((alarma:AlarmaModel)=>{
          return alarma.deleted==null;
        })        
        .forEach(alarma=>{
            alarma.informa.forEach(informa=>{
              if(informa.to.uuid==uuidUser){
                if (!_.find(this.elementData, ['uuid', alarma.uuid])  && alarma.disabled==null){ //agregarla si no es owner de la alarma
                  this.elementData.push({...alarma , usuario:""});
                }
              }
            })
        });
        this.elementData.map (element=>{
          if(element.alarmado && element.alarmado !== null){
            element.alarmadoDate = this.getDatePipe.transform(Number(element.alarmado));
          }
          if(element.disabled && element.disabled !== null){
            element.disabledDate = this.getDatePipe.transform(Number(element.disabled));
                      }
          element.informa.forEach(r => {
            r.alarmadoDate = r.alarmado !== null ? this.getDatePipe.transform(Number(r.alarmado)) : '';
            r.disabledDate = r.disabled !== null ? this.getDatePipe.transform(Number(r.disabled)) :  '';
          })
          return element;
        })
        this.elementDataAux = this.elementData;

        this.filterAlarmas();

        this.loading = false;
      })
    })
  }

  filterAlarmas(){
    switch (this.filter){
      case 'alarmed' :
        this.AlarmasAlarmed()
        break;
      case 'subscribed':
        this.AlarmasSubscribed()
        break;
        case 'active':
          this.AlarmasActive();
          break;
      default:
        break;
    }
  }

  AlarmasAlarmed(){
    this.elementDataAux = this.elementData.filter(alarma=>{
      let informa:any[];
      informa = alarma.informa.filter(informa =>{
        return (informa.to.uuid === this.userId && _.isNumber(informa.alarmado));
      });
      return (informa.length > 0 )
    })
  }

  AlarmasSubscribed(){
    this.elementDataAux =  this.elementData.filter(alarma=>{
      let informa:any[];
      informa = alarma.informa.filter(informa =>{
        return (informa.to.uuid === this.userId && _.isNumber(informa.alarmado));
      });
      return (informa.length === 0 )
    })
  }

  AlarmasActive(){
    this.elementDataAux =  this.elementData.filter(alarma=>{
      return (alarma.disabled===null && alarma.deleted===null);
    })
  }
  
  showAlarmasForm() {
    this.toggleAlarmaForm();
    this.formMode = 'create';
  }

  controlStateButtonStyle(newState: string) {
    newState !== 'Activada' ? this.activatedStyle = true : this.activatedStyle = false;
  }
  
  selectAlarma(alarma: AlarmaModel) {
    this.alarmaSelected = alarma;
    this.enableAlarmasDetail = !this.enableAlarmasForm;
  }

  goBackAlarmasTable() {
    this.enableAlarmasDetail = false;
  }

  toggleAlarmaForm() {
    this.enableAlarmasForm = !this.enableAlarmasForm;
  }

  cancelForm() {
    this.toggleAlarmaForm();
  }

  refreshAlarmas(){
    this.getAlarmasCollectionByUser(this.userId);
  }

  confirmDeleteAlarma(alarma: AlarmaModel) {
    const options = {
      title: alarma.nombre,
      message: 'ALARMAS.DELETE_ALARMAS_MESSAGE',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAlarma(alarma);
      }
    });
  }

  private deleteAlarma(alarma: AlarmaModel) {
    this.alarmasService.deleteAlarma(alarma.uuid).subscribe(response => {
      let auxMessage = '';
      let auxMessage2 = '';
      if ( !_.isEmpty(response.nombre) ) {
         this.translate.get('ALARMAS.ALARMAS_DELETE_OK').subscribe(value => auxMessage = value);
         this.translate.get('ALARMAS.SUCCESS').subscribe(value => auxMessage2 = value);
         this.toastr.showSuccess(auxMessage, auxMessage2);
         this.getAlarmasCollectionByUser(this.userId);
      }else{
        this.translate.get('ALARMAS.ALARMAS_DELETE_ERROR').subscribe(value => auxMessage = value);
        this.translate.get('ALARMAS.ERROR').subscribe(value => auxMessage2 = value);
        this.toastr.showError(auxMessage, auxMessage2);
      }
    });
  }

  editAlarma(alarma: AlarmaModel) {
    this.enableAlarmasForm = true;
    this.formMode = 'edit';
    this.alarmaSelected = alarma;
  }

  detailAlarma(alarma: AlarmaModel) {
    this.enableAlarmasForm = true;
    this.formMode = 'detail';
    this.alarmaSelected = alarma;
  }

  changeAlarmaState(alarmaToEdit: AlarmaModel){

    if(!_.isNumber(alarmaToEdit.disabled)){
      this.alarmasService.disableAlarma(alarmaToEdit.uuid)
      .subscribe(data=>{
        alarmaToEdit.disabled=data["disabled"];
      });
    }else{//rearmarAlarma
      this.alarmasService.enableAlarma(alarmaToEdit.uuid)
      .subscribe(data=>{
        alarmaToEdit.disabled=data["disabled"];
      });
    }

  }

  confirmUnsubscribeAlarma(alarma: AlarmaModel) {
    const options = {
      title: alarma.nombre,
      message: 'ALARMAS.UNSUBSCRIBE_ALARMAS_MESSAGE',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unsubscribeAlarma(alarma);
      }
    });
  }

  unsubscribeAlarma(alarma: AlarmaModel){
    let auxMessage = '';
    let auxMessage2 = '';
    let informaArray: InformaModel[] = this.getInformaId(alarma);
    informaArray.forEach(informa=>{
      this.alarmasService.unsubscribeUserAlarma(
        informa.uuid
        ).subscribe(response=>{
          if ( response["desinformar"]==1)  {
            this.translate.get('ALARMAS.ALARMAS_UNSUBSCRIBE_OK').subscribe(value => auxMessage = value);
            this.translate.get('ALARMAS.SUCCESS').subscribe(value => auxMessage2 = value);
            this.toastr.showSuccess(auxMessage, auxMessage2);
            this.getAlarmasCollectionByUser(this.userId);
         }else{
           this.translate.get('ALARMAS.UNSUBSCRIBE_ERROR').subscribe(value => auxMessage = value);
           this.translate.get('ALARMAS.ERROR').subscribe(value => auxMessage2 = value);
           this.toastr.showError(auxMessage, auxMessage2);
         }
        });
    })
    
    
  }

  confirmRearmarAlarma(alarma: AlarmaModel) {
    const options = {
      title: alarma.nombre,
      message: 'ALARMAS.REARMAR_ALARMAS_MESSAGE',
      existingData: true
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      data: options
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rearmarAlarma(alarma);
      }
    });
  }

  rearmarAlarma(alarma:AlarmaModel){
    this.alarmasService.rearmarAlarma(alarma.uuid,this.userId)
    .subscribe(response=>{
      let auxMessage = '';
      let auxMessage2 = '';
      if ( _.isEmpty(response.alarmado) ) {
         this.translate.get('ALARMAS.ALARMAS_REARMAR_OK').subscribe(value => auxMessage = value);
         this.translate.get('ALARMAS.SUCCESS').subscribe(value => auxMessage2 = value);
         this.toastr.showSuccess(auxMessage, auxMessage2);
         this.getAlarmasCollectionByUser(this.userId);
      }else{
        this.translate.get('ALARMAS.ALARMAS_REARMAR_ERROR').subscribe(value => auxMessage = value);
        this.translate.get('ALARMAS.ERROR').subscribe(value => auxMessage2 = value);
        this.toastr.showError(auxMessage, auxMessage2);
      }
    });
  }

  getInformaId(alarma:AlarmaModel) : InformaModel[]{
    let informaArray = alarma.informa.filter(informa=>{
      return informa.to.uuid === this.userId;
    })
    return informaArray;
  }


  ngAfterInit(){
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.mediaSub.unsubscribe();
  }

}
