import { AllUserGraphQLResponse } from './graphQL/all-user-graphQL.response';

export class AllUser {
    uuid: string;
    user: string;
    name: string;
    firstName: string;
    timeZone: string;
    language: string;

    constructor(response: AllUserGraphQLResponse) {
        this.uuid = response.miUsuario[0].uuid;
        this.user = response.miUsuario[0].usuario;
        this.name = response.miUsuario[0].nombre;
        this.firstName = response.miUsuario[0].apellidos;
        this.timeZone = response.miUsuario[0].zonaHoraria;
        this.language = response.miUsuario[0].idioma;
    }
}
