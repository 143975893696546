import { PermisoDashboard } from './../../dashboard/newDashboard.model';
import * as lodash from 'lodash';
import { DashboardArrayResponse, WidgetResponse, WidgetsParamsResponse, WidgetDevicesResponse, WidgetDatatypeResponse } from '../../graphQL/widgets-graphQL-response';
import { NewDashbordModel, NewWidgetsModel, DevicesModel, DataTypeModel } from '../../dashboard/newDashboard.model';
import { WidgetType } from '../widget-interfaces.model';
import { ChartOrientationType } from '../../plotly/plotly-chart.model';

export interface MappedPeriod {
    clave: string;
    valor: string;
    cantidad: string;
}

export class NewDashboardMapper {

    public static fromDashboardResponseCollectionToDashboardCollection(dashCollection: DashboardArrayResponse[]): NewDashbordModel[] {
        if (lodash.isEmpty(dashCollection)) { return []; }
        const auxDashboardCollectionMultiple: NewDashbordModel[][] = [];
        let auxDashboardCollection: NewDashbordModel[] = [];
        auxDashboardCollectionMultiple.push(dashCollection.map(item => this.fromDashboardResponseToNewDashboard(item)));

        return auxDashboardCollection = lodash.flatten(auxDashboardCollectionMultiple);
    }

    public static fromDashboardResponseToNewDashboard(item: DashboardArrayResponse): NewDashbordModel {
        const newDashboard: NewDashbordModel = new NewDashbordModel(item.to.uuid, item.to.nombre, [], []);
        newDashboard.permisoDashboard = []
        newDashboard.permisoDashboard.push(this.fromPermisoResponseToPermisoCollection(item))
        newDashboard.widgets = this.fromWidgetsResponseToNewWidgetCollection(item.to.widgets);
        return newDashboard;
    }
    public static fromWidgetsResponseToNewWidgetCollection(widget: WidgetResponse[]): NewWidgetsModel[] {
        return widget.map(item => {
            if (!lodash.isEmpty(item.to.parametros)) {
                const newWidget: NewWidgetsModel = new NewWidgetsModel();
                newWidget.uuid = item.to.uuid;
                newWidget.name = item.to.nombre;
                newWidget.widgetType = this.getWidgetType(item.to.parametros);
                if (newWidget.widgetType === WidgetType.GAUGE) {
                    newWidget.limit = this.getLimitParam(item.to.parametros);
                    newWidget.min = this.getMinParam(item.to.parametros);
                    newWidget.max = this.getMaxParam(item.to.parametros);
                }
                newWidget.devices = [];
                newWidget.dataType = [];
                newWidget.x = item.x !== null ? item.x : 0;
                newWidget.y = item.y !== null ? item.y : 0;
                newWidget.rows = item.height !== null ? item.height : 3;
                newWidget.cols = item.width !== null ? item.width : 4;
                newWidget.relationUuid = item.uuid;
                newWidget.devices = this.getDeviceCollection(item.to.parametros);
                newWidget.dataType.addRange(this.getDatatypeCollection(item.to.parametros));
                newWidget.period = this.getPeriod(item.to.parametros);
                newWidget.orientation = this.getOrientation(item.to.parametros);
                if (newWidget.widgetType !== undefined) {
                    return newWidget;
                } else {
                    return null;
                }
            }
        });
    }

    public static fromPermisoResponseToPermisoCollection(pd: any): PermisoDashboard {
        return {
            uuid: pd.uuid,
            owner: pd.owner,
            from: { uuid: pd.from.uuid, usuario: pd.from.usuario, apellidos: pd.from.apellidos, nombre: pd.from.nombre },
            to: {
                uuid: pd.to.uuid,
                nombre: pd.to.nombre,
                widgets: pd.to.widgets
            },
            deleted: pd.deleted
        }
    }

    private static getLimitParam(params: WidgetsParamsResponse[]): number {
        let limit: number;
        // tslint:disable-next-line: radix
        params.map(item => { if (item.clave === 'limite') { limit = parseFloat(item.valor); } });
        return limit;
    }

    private static getMinParam(params: WidgetsParamsResponse[]): number {
        let min: number;
        // tslint:disable-next-line: radix
        params.map(item => { if (item.clave === 'min') { min = parseFloat(item.valor); } });
        return min;
    }

    private static getMaxParam(params: WidgetsParamsResponse[]): number {
        let max: number;
        // tslint:disable-next-line: radix
        params.map(item => { if (item.clave === 'max') { max = parseFloat(item.valor); } });
        return max;
    }

    private static getPeriod(params: WidgetsParamsResponse[]): MappedPeriod {
        const newMappedPeriod: MappedPeriod = {
            clave: '',
            valor: '',
            cantidad: ''
        };
        if (params.length > 0) {
            params.map(item => {
                if (item.clave === 'periodo') {
                    newMappedPeriod.clave = item.clave;
                }
            });
            params.map(item => {
                if (item.clave === 'Hour' || item.clave === 'Day' ||
                    item.clave === 'Week' || item.clave === 'Month' || item.clave === 'horas') {
                    newMappedPeriod.valor = item.clave;
                    newMappedPeriod.cantidad = item.valor;
                }
            });
        }
        return newMappedPeriod;
    }

    private static getDeviceCollection(devices: WidgetsParamsResponse[]): DevicesModel[] {
        if (devices.length > 0) {
            const auxArray: WidgetsParamsResponse[] = devices.filter(item => item.clave === 'dises' ||
                item.clave === 'dis');
            if (!lodash.isEmpty(auxArray)) {
                return lodash.flatten(auxArray.map(item => this.iteratesDevice(item.dispositivos)));
            }
        }
    }
    private static iteratesDevice(deviceCollection: WidgetDevicesResponse[]): DevicesModel[] {
        const arr: DevicesModel[] = [];
        deviceCollection.map(device => {
            const newDevice: DevicesModel = new DevicesModel();
            newDevice.name = device.nombre;
            newDevice.uuid = device.uuid;
            if (lodash.isEmpty(newDevice)) {
            } else {
                arr.push(newDevice);
            }

        });
        if (arr.length > 0) {
            return arr;
        }


    }
    private static getDatatypeCollection(dataTypeCollection: WidgetsParamsResponse[]): DataTypeModel[] {

        if (dataTypeCollection.length > 0) {
            const auxArray: WidgetsParamsResponse[] = dataTypeCollection.filter(item => item.clave === 'tipomeds' ||
                item.clave === 'tipomed');
            if (!lodash.isEmpty(auxArray)) {
                return lodash.flatten(auxArray.map(item => this.iteratesDatatype(item.tipodatos)));
            }
        }
    }

    private static iteratesDatatype(dataTypeCollection: WidgetDatatypeResponse[]): DataTypeModel[] {
        const newDataTypeCollection: DataTypeModel[] = [];
        dataTypeCollection.map(dataType => {
            const newDatatype: DataTypeModel = new DataTypeModel();
            newDatatype.name = dataType.nombre;
            newDatatype.uuid = dataType.uuid;
            newDatatype.unity = dataType.unidad;
            newDatatype.codigo = dataType.codigo;
            newDataTypeCollection.push(newDatatype);
        });
        return newDataTypeCollection;

    }

    private static getWidgetType(widgetParamCollection: WidgetsParamsResponse[]): WidgetType {
        if (lodash.isEmpty(widgetParamCollection)) {
            return null;
        }

        if (widgetParamCollection.any(item => item.clave === 'limite')) {
            return WidgetType.GAUGE;
        } else if (
            widgetParamCollection.any(
                item => item.clave === 'tipoplot' && item.valor === 'ClusteredColumns' || item.valor === 'bar')) {
            return WidgetType.CLUSTER_COLUMN;
        } else if (
            widgetParamCollection.any(
                item => item.clave === 'tipoplot' && (item.valor === 'Lines' || item.valor === 'scatter'))) {
            return WidgetType.LINE;
        } else if (
            widgetParamCollection.any(
                item => item.clave === 'tipoplot' && item.valor === 'Pie')) {
            return WidgetType.PIE;
        } else if (
            widgetParamCollection.any(
                item => item.clave === 'tipoplot' && item.valor === 'Areas')) {
            return WidgetType.AREA;
        } else if (
            widgetParamCollection.any(
                item => item.clave === 'tipoplot' && item.valor === 'table')) {
            return WidgetType.TABLE;
        } else if (
            widgetParamCollection.any(
                item => item.clave === 'tipoplot' && item.valor === 'digital')) {
            return WidgetType.DIGITAL;
        } else {
            return WidgetType.UNDEFINED;
        }
    }

    private static getOrientation(widgetParamCollection: WidgetsParamsResponse[]): ChartOrientationType {
        if (lodash.isEmpty(widgetParamCollection)) {
            return null;
        }
        if (widgetParamCollection.any(item => item.clave === 'orientation' && item.valor === 'v')) {
            return ChartOrientationType.VERTICAL;
        } else if (widgetParamCollection.any(item => item.clave === 'orientation' && item.valor === 'h')) {
            return ChartOrientationType.HORIZONTAL;
        } else {
            return ChartOrientationType.NONE;
        }
    }
}
