import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {
   ChangeDetectionStrategy,

} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DataType } from 'src/app/models/data-type.model';
import { DatatypeTreeHierarchi } from 'src/app/models/datatype/datatype-hierarchi.model';
import { DataTypeService } from 'src/app/services/datatype/data-type.service';
import { TimezoneService } from 'src/app/services/timezone.service';
import { TabStepperData } from '../side-graphs/side-graphs.component';
import { WidgetParameter } from '../tab-stepper-mapa-modal/tab-stepper-mapa-modal.component';

@Component({
  selector: 'app-device-datos-mapa-modal',
  templateUrl: './device-datos-mapa-modal.component.html',
  styleUrls: ['./device-datos-mapa-modal.component.scss']
})

export class DeviceDatosMapaModalComponent implements OnInit {

  dataTypeTree: DatatypeTreeHierarchi[];
  timezoneBS: BehaviorSubject<string> = new BehaviorSubject<string>('');
  periodObject: WidgetParameter;
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private timezone: TimezoneService,              
                private datatypeService: DataTypeService,
                private cd: ChangeDetectorRef,
                private translate: TranslateService,
                public dialogRef: MatDialogRef<DeviceDatosMapaModalComponent>,
              ) {   

              } 

  ngOnInit() {    
    this.refreshData();
    this.detectChanges();
    this.datatypeService.getDataType().subscribe(data => {
      this.dataTypeTree = _.sortBy(data, ['name'], ['asc']);
    });
  }

  refreshData() {
    this.timezoneBS.subscribe(data => {
      if (data) {
       
      }
    });
  }

  detectChanges() {
    this.timezone.currentTimeZone$.subscribe((data: string) => {
      this.timezoneBS.next(data);
    });
  }

  generateChart(chart) {
    //this.sidenav.close();
  }

  getPeriod($event: WidgetParameter) {
    this.periodObject = $event;
  }

  addWidget(newWidget: TabStepperData) {
    
  }

  choseData(selectedData:DataType){  
    this.dialogRef.close(selectedData);
  }

}