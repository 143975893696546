import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface Timezone {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}
interface TimezoneResponse {
  timezone: Timezone[];
}

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  private currentTimeZone = new BehaviorSubject<string>('');
  private currentOffset = new BehaviorSubject<number>(0);

  currentTimeZone$ = this.currentTimeZone.asObservable();
  currentOffset$ = this.currentOffset.asObservable();

  constructor(private http: HttpClient) { }

  public setLocale(locale: string) {
    this.currentTimeZone.next(locale);
  }

  public setOffset(offset: number) {
    this.currentOffset.next(offset);
  }
  public getTimezoneCollection(): Observable<Timezone[]> {
    return this.http.get<TimezoneResponse>('assets/data/timezones.json')
      .pipe(item => item.map(value => this.buildArrayString(value)));
  }

  private buildArrayString(responseCollection: TimezoneResponse): Timezone[] {
    const auxTimezoneArray: Timezone[] = [];
    for (const timezone of responseCollection.timezone) {
        auxTimezoneArray.push(timezone);
    }
    return auxTimezoneArray;
  }
}
