import gql from 'graphql-tag';


export const queryFabricas = gql`
  query QueryFabricas {
    datos: fabricas {
      uuid
      nombre
    }
}`;


export const queryDispositivosRaiz = gql`
  query queryDispositivosRaiz{
    datos: dispositivosRaiz{
      uuid
      nombre
    }
  } `;

export const queryDatoByDependencyFrom = gql`
    query queryDatoByDependencyFrom($uuidDep: String){
        findDatoByDependencyFrom(uuidDep: $uuidDep){
            limiteInferior
            limiteSuperior
            descripcion
            uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count
            periodo
            zonaHoraria
            entradas{
              uuid
              topic
              desde
              hasta
              from {
                uuid
                topic
                entradas {
                  uuid
                  topic
                  desde
                  hasta
                }
              }
            }
           
            tiene {
              uuid
              nombre
              __typename
            }
            es {
              uuid
              nombre
              __typename
            }
            calcula {
              uuid
              disabled
              error
              formula
              from {
                uuid
                descripcion
                codigo
                __typename
              }
              __typename
            }
            __typename
           dependeTo {
              uuid
              variable
            }
            dependeFrom {
              uuid
              variable
            }
    }
  }
`

export const queryDatoByDependencyTo = gql`
    query queryDatoByDependencyTo($uuidDep: String){
        findDatoByDependencyTo(uuidDep: $uuidDep){
            limiteInferior
            limiteSuperior
            descripcion
            uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count
            periodo
            zonaHoraria
            entradas{
              uuid
              topic
              desde
              hasta
              from {
                uuid
                topic
                entradas {
                  uuid
                  topic
                  desde
                  hasta
                }
              }
            }
           
            tiene {
              uuid
              nombre
              __typename
            }
            es {
              uuid
              nombre
              __typename
            }
            calcula {
              uuid
              disabled
              error
              formula
              from {
                uuid
                descripcion
                codigo
                __typename
              }
              __typename
            }
            __typename
           dependeTo {
              uuid
              variable
            }
            dependeFrom {
              uuid
              variable
            }
    }
  }
`

export const queryNavegacionDato = gql`
  query queryNavegacionDato($uuid:String) {
    getDato(uuid: $uuid) {
      limiteInferior
      limiteSuperior
      descripcion
      uuid
      next
      invalidated
      stats_q1
      stats_q3
      stats_min
      stats_max
      stats_median
      stats_mean
      stats_stddev
      stats_pbottom
      stats_ptop
      stats_count
      periodo
      zonaHoraria
      entradas{
        uuid
        topic
        desde
        hasta
        from {
          uuid
          topic
          entradas {
            uuid
            topic
            desde
            hasta
          }
        }
      }
      dependeTo {
        uuid
        variable
   
      }
      dependeFrom {
        uuid
        variable
        
      }
      tiene {
        uuid
        nombre
        __typename
      }
      es {
        uuid
        nombre
        __typename
      }
      calcula {
        uuid
        disabled
        error
        formula
        from {
          uuid
          descripcion
          codigo
          __typename
        }
        __typename
      }
      __typename
    }
  }
  
`




export const queryNavegacionDato_back = gql`
    query queryNavegacionDato($uuid: ID) {
        getDato (uuid: $uuid){
            descripcion
            uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count 
            periodo
            zonaHoraria
        entradas{
            uuid
            desde
            hasta
            topic
            Source{
                uuid
                nombre
                topic
            }
        }
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
        depende {
            to {
                uuid
                variable
                Dato {
            uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count 
            periodo
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
                    depende {
                    to {
                        uuid
                        variable
                        Dato {
            uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count 
            periodo
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
                }
            }
            from {
                uuid
                variable
                Dato {
                                    uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count 
            periodo
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
                }
            }
        }
                }
            }
            from {
                uuid
                variable
                Dato {
                       uuid
                       next
                       invalidated
                    stats_q1
                    stats_q3
                    stats_min
                    stats_max
                    stats_median
                    stats_mean
                    stats_stddev
                    stats_pbottom
                    stats_ptop
                    stats_count
                    periodo
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
        depende {
            to {
                uuid
                variable
                Dato {
                                    uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count 
            periodo
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
                }
            }
            from {
                uuid
                variable
                Dato {
                                    uuid
            next
            invalidated
            stats_q1
            stats_q3
            stats_min
            stats_max
            stats_median
            stats_mean
            stats_stddev
            stats_pbottom
            stats_ptop
            stats_count 
            periodo
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                codigo
            }
        }
                }
            }
        }
                }
            }
        }
    }
    }`;




export const queryNavegacionDatoBuena = gql`
query queryNavegacionDatoBuena($uuid: ID) {
    Dato(uuid: $uuid) {
        uuid
        next
        invalidated
        stats_q1
        stats_q3
        stats_min
        stats_median
        stats_mean
        stats_stddev
        stats_pbottom
        stats_ptop
        stats_count
        zonaHoraria
    	calcula {
            uuid
            disabled
            error
            formula
            Funcion {
                uuid
                descripcion
                codigo
                    }
            }
        es {
            uuid
            nombre
        }
        tiene {
            uuid
            nombre
        }
        depende {
            to {
                uuid
              	variable
                Dato {
                    uuid
                    next
                    invalidated
                    stats_q1
                    stats_q3
                    stats_min
                    stats_median
                    stats_mean
                    stats_stddev
                    stats_pbottom
                    stats_ptop
                    stats_count
                    calcula {
                        uuid
                        disabled
                        error
                        Funcion {
                            uuid
                            codigo
                        }
                    }
              
                    }
                }
            
            from {
                uuid
                variable
                Dato {
                    uuid
                    next
                    invalidated
                    stats_q1
                    stats_q3
                    stats_min
                    stats_median
                    stats_mean
                    stats_stddev
                    stats_pbottom
                    stats_ptop
                    stats_count
                    calcula {
                        uuid
                        disabled
                        error
                        Funcion {
                            uuid
                            codigo
                        }
                    }

                }
            }
        }
    }
}`


// Query para zonas horarias:
export const arrayZonasHorarias = gql`
query Dato {
    Dato(filter: {zonaHoraria_not: null}) {
      zonaHoraria
    }
  }`


// Query que nos trae todas las funciones que hay definidas!
export const queryFunciones_back = gql`
    query queryFunciones{
        Funcion(filter:{codigo_not: null}) {
            uuid
            codigo
                }
            }`;
export const queryFunciones = gql`
            query queryFunciones{
                funciones {
                    uuid
                    codigo
                        }
                    }`;

export const queryTodosDatos = gql`
query queryTodosDatos{
    Dato{
        uuid
    }
}`;

/* export const QueryTipoDato = gql`
query queryTipoDato` */

// Mutarion para actualizar valores de un dato mediante el modal
export const ActualizarDato = gql`
mutation ActualizarDato($uuid: ID!, $stats_pbottom: number, $stats_ptop: stats_ptop) {
    UpdateDato(uuid: $uuid, stats_pbottom: $stats_pbottom, stats_ptop: $stats_ptop)}`;


// Mutation para actualizar descripcion, limite inferior y limite superior de un dato
export const updateDato = gql`
mutation ActualizarDato($dato: String, $descripcion: String, $limiteInferior: Float, $limiteSuperior: Float) {
    updateDato(dato: $dato, descripcion: $descripcion, limiteInferior: $limiteInferior, limiteSuperior: $limiteSuperior) {
        descripcion
        limiteInferior
        limiteSuperior
    }}`;

// Mutation para añadir una dependencia a un dato
export const queryAddDependency = gql`
mutation queryAddDependency($datouuid: String!, $depuuid: String){
    addDependency(datouuid: $datouuid, depuuid: $depuuid){
        uuid
    } 
}`

// Mutation para cambiarle la función de cálculo al dato actual:
export const updateFuncion = gql`
mutation updateFuncion($uuid: ID!, $codigo: String) {
    UpdateDato(uuid: $uuid, codigo: $codigo){
        periodo
    }}`;

// Mutation para borrar una dependencia de un dato:
export const queryDeleteDepedency = gql`
mutation queryDeleteDependency($duuid: String){
    deleteDependency(duuid: $duuid)}`

// Mutarion para actualizar la descripcion del dato
export const ActualizarDescripcion = gql`
mutation ActualizarDato($uuid: ID!, $descripcion: String) {
    UpdateDato(uuid: $uuid, descripcion:$descripcion) {
        uuid
    }
}`;



export const queryDatoActual = gql`
    query queryDatoActual($uuid: ID) {
        Dato (uuid: $uuid){
                uuid
                next
                invalidated
                stats_q1
                stats_q3
                stats_min
                stats_max
                stats_median
                stats_mean
                stats_stddev
                stats_pbottom
                stats_ptop
                stats_count 
                zonaHoraria
            es {
                uuid
                nombre
            }
            tiene {
                uuid
                nombre
            }
            calcula {
                uuid
                disabled
                error
                Funcion {
                    uuid
                    codigo
                }
            }
        }
    }`


// Función para recalcular el dato actual
export const recalcularDato = gql`
mutation recalcularDato($uuidDato: String) {
    recalcularDato(uuidDato: $uuidDato){
        uuid
    } 
}`;

export const cambiarVariable = gql`
mutation cambiarVariable($dependencia: String, $variable:String) {
    cambiarVariable(dependencia: $dependencia, variable: $variable) {
        uuid
      }
}`

export const cambiarFormula = gql`
mutation cambiarFormula($calcula: String, $formula: String){
    cambiarFormula(calcula: $calcula, formula: $formula){
        uuid
       }
    }`

export const asignarFuncion = gql`
mutation asignarFuncion($dato: String, $funcion: String){
    asignarFuncion(dato: $dato, funcion: $funcion ) {
        uuid
    }
}`;

export const cambiarZonaHoraria = gql`
mutation UpdateDato($uuid: ID!, $zonaHoraria: String){
    UpdateDato(uuid: $uuid, zonaHoraria: $zonaHoraria) {
        uuid
        zonaHoraria
    }
}`
