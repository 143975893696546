import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './user';
import { JwtResponse } from './jwt-response';
import { pluck, tap, map, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CookieService} from 'ngx-cookie-service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AUTH_SERVER = environment.auth;
  authSubject  =  new  BehaviorSubject(false);
  verifySubject  =  new  BehaviorSubject(false);


  constructor(
    private httpClient: HttpClient,
    private router:Router,
    private cookieService: CookieService,
    ) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    auth(user: User): Observable<HttpResponse<any>> {
      return this.httpClient.post(`${this.AUTH_SERVER}authorize`, user, {observe: 'response'})
      .pipe(
        catchError(err => {
          return of ({status: 403});
       }),
        map(authResponse => {
          return authResponse as HttpResponse<any>;
        })
      )
    }

    refreshToken(): Observable<HttpResponse<JwtResponse>> {
      return this.httpClient.post(`${this.AUTH_SERVER}refresh`, '', {observe: 'response'}).pipe(
       catchError(err => {
        if (err.status === 401){
          this.signOut();
          localStorage.clear();
        }
          return of ({status: 400});
       }),
        map(refreshResponse => {
          return refreshResponse as HttpResponse<JwtResponse>;
        })
      );
    }

    verify(): Observable<HttpResponse<JwtResponse>> {
      return this.httpClient.post(`${this.AUTH_SERVER}verify`, '', {observe: 'response'}).pipe(
        catchError(err => {
          if (err.status === 401){
            this.signOut();
            localStorage.clear();
            if(this.router.url!=='/login' ){
              this.router.navigate(['/login']).then(() => window.location.reload());
            } 
          }
          return of ({status: 403});
       }),
        map(verifyResponse => {
          return (verifyResponse as HttpResponse<JwtResponse>);
        })
      );
    }

    isAuthenticated() {
      return  this.verify().pipe(
        map(res => {
          if (_.isNil(this.cookieService.get('token')) || _.isNil(this.cookieService.get('refresh'))){
            localStorage.clear();
          }
           return res.status === 200 ? true : false
          }
        )
      );
    }

    signOut(): Observable<JwtResponse> {
      return this.httpClient.post(`${this.AUTH_SERVER}revoke`, '').pipe(
        tap(async (res: JwtResponse) => {
        })
      );
    }
}

