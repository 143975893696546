import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DeviceListSettings } from 'src/app/models/deviceSettings.model';
import { ListItem, ListItemSelectable, State } from 'src/app/models/contract/list-item.model';
import { MatDialog } from '@angular/material/dialog';
import { EditFormComponent } from '../shared/edit-form/edit-form.component';
import * as _ from 'lodash';
import { DispositivoService } from 'src/app/services/dispositivo/dispositivo.service';

@Component({
  selector: 'app-mapa-devices-tab',
  templateUrl: './mapa-devices-tab.component.html',
  styleUrls: ['./mapa-devices-tab.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0,
        width: 0
      })),
      transition('void <=> *', animate('0.3s')),
    ])
  ]
})
export class MapaDevicesTabComponent implements OnInit, OnChanges {

  @Input() loading: boolean;
  @Input() itemCollection: ListItem[];
  @Input() deviceSettings: DeviceListSettings;
  @Output() itemSelected: EventEmitter<ListItem> = new EventEmitter<ListItem>();
  @Output() itemNavigate: EventEmitter<ListItem> = new EventEmitter<ListItem>();
  @Output() itemUnselected: EventEmitter<ListItem> = new EventEmitter<ListItem>();
  @Output() goBack: EventEmitter<ListItem> = new EventEmitter<ListItem>();
  @Input() buttonActive: boolean;
  @Input() deviceNavigate: boolean;
  @Input() title: string;
  @Input() isRoot: boolean;

  searchStatus = true;
  actionIcon: string;
  loadingText = 'LOADING.DEVICES';

  constructor(public dialog: MatDialog,
              private dispositivosService: DispositivoService) { }

  ngOnInit() {
    this.listItemSettingsFunc(this.deviceSettings);
  }
  ngOnChanges() { }

  private listItemSettingsFunc(settings: DeviceListSettings) {
    this.actionIcon = settings.actionIcon;
  }

  onChangeSearchStatus() {
    this.searchStatus = !this.searchStatus;
  }

  public hasChildren(collectionToCheck: Array<any>): boolean {
    return !(collectionToCheck === null || collectionToCheck === undefined || collectionToCheck.length === 0);
  }

  onClickItem(itemClicked: ListItem) {
    this.isSelectable() === State.selectable ? this.onSelectItem(itemClicked as ListItemSelectable) : this.itemSelected.emit(itemClicked);
  }

  onClickItemNavegate(itemNavegate: ListItem) {
    this.itemNavigate.emit(itemNavegate);
  }

  private onSelectItem(itemSelectable: ListItemSelectable) {
    if (itemSelectable.selectedState === State.selectable) {
      itemSelectable.selectedState = State.selected;
      this.itemSelected.emit(itemSelectable);
    } else {
      itemSelectable.selectedState = State.selectable;
      this.itemUnselected.emit(itemSelectable);
    }
  }

  private isSelectable(): State {
    if (this.deviceSettings === null || this.deviceSettings === undefined) {
      return null;
    }
    return this.deviceSettings.selectable;
  }

  goBackButton() {
    this.goBack.emit();
  }

  openDialog(device: ListItem) {
    const dialogTitle = 'DEVICE.DEVICE_NAME';
    const field = 'DEVICE.DEVICE_FIELD_NAME';
    const dialogRef = this.dialog.open(EditFormComponent, {
      data: { title: dialogTitle, value: device.name, campo: field },
      minWidth: 270
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!_.isEmpty(result) && result !== name) {
        this.dispositivosService.UpdateDeviceName(device.uuid, result)
          .subscribe(data => {
            if (data !== null) {
              this.itemCollection.map(item => {
                if (item.name === device.name) {
                  item.name = result;
                }
              });
            }
          });
      }
    });
  }
}

